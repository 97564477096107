
import React from 'react';
const PageNotFound=()=>{
   
    
     return (
    <div style={{margin:"15%"}}>
    <h1>404 Not Found</h1>
    <p>The page you requested could not be found.</p>
    </div>
    );
    };


export default PageNotFound;