import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box, Grid } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import DialogBox from "../../../../views/Common/Modals/Dailog"
import { numberWithCommas } from "../../../Common/Utils/ClientUtil";
import retriveMetricFromStr from "../../../Common/Utils/ClientUtil";
import MeasureStackedChart from "../MeasureStackedChart/MeasureStackedChart";
import BarGroup from "views/Common/Charts/BarGroup";
import StackedBar from "views/Common/Charts/HorizontalStackedBarChart";
import PieChart from "views/Common/Charts/PieChart";
import ProgressBar from "views/Common/Charts/ProgressBar";

const useStyles = makeStyles((theme) => ({
  block: {
    margin: "10px !important",
    width: 300,
    height: 175,
    textAlign: "left",
    boxSizing: "border-box",
    boxShadow: "2.73577px 3.6477px 11.855px rgb(0 0 0 / 8%)",
    borderRadius: 6.38347,
    background: "#ffffff",
    padding: 20,
    "& h4": { color: "#333333", marginBottom: 10 },
    "& h5": { color: "#333333", minHeight: 55 },
  },
  value: { color: "#24A148" },
  highlight: { color: "#8E44AD" },
  chartValues: {
    marginTop: 10,
  },
  valBM: { color: "#013598", marginRight: 136 },
  info: {
    padding: "40px 0 !important",
    textAlign: "center",
    "& h3": {
      lineHeight: 10,
    },
  },
  sideBlock: {
    textAlign: "center",
    "& h4": {
      color: "#333333",
      marginBottom: 10,
      position: "relative",
      textTransform: "capitalize",
      "& svg": {
        position: "absolute",
        right: 0,
        color: "#182C52",
        cursor: "pointer",
      },
    },
    "& h5": { color: "#24A148", margin: "15px 0" },
    "& h6": {
      color: "#013598",
      marginBottom: 50,
    },
  },
  cardBlockSvgRed: {
    "& svg": {
      color: "#ffffff",
      borderRadius: 2,
      padding: 2,
      fontSize: 30,
      background: "#E01F29",
    },
  },
  cardBlockSvgGreen: {
    "& svg": {
      color: "#ffffff",
      borderRadius: 2,
      padding: 2,
      fontSize: 30,
      background: "#24A148",
    },
  },
  cardBlockLiftRed: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    padding: 5,
    background: "#FBE8E9",
    color: "#E01F29",
    justifyContent: "center",
    "& span": {
      color: "#E01F29",
    },
  },
  cardBlockLiftGreen: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    padding: 5,
    background: "#EAF6ED",
    justifyContent: "center",
    color: "#24A148",
    "& span": {
      color: "#24A148",
    },
  },
  cardBlockVal: {
    "& h4": {
      display: "inline-block",
      lineHeight: 1.5,
    },
    "& span": {
      display: "inline-block",
      padding: "0 0 0 10px",
    },
  },
  infoArea: {
    textAlign: "center",
    marginBottom: 25,
    "& svg": {
      color: "#013598",
      verticalAlign: "sub",
      marginRight: 10,
    },
  },
}));

export default function ExpandDialog(props) {
  const classes = useStyles();
  const { data } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");
  const handleDialogClose = () => {
    setDialogOpen(false);
    setDialogData("");
  };
  const handleDialogOpen = (val) => {
    setDialogOpen(true);
    setDialogData(val);
  };
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={5} style={{ marginTop: 25 }}>
          {data?.val?.kpiType === "abs" || data?.val?.kpiType === "int" ? (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {/* <Typography
                  component="h4"
                  variant="body1"
                  className={classes.infoArea}
                >
                  <Typography component="span" variant="subtitle1">
                    <InfoOutlinedIcon fontSize={"small"} />
                  </Typography>
                  {data.val.kpiDefn}
                </Typography> */}
                <BarGroup
                  // data={[
                  //   { name: "Pre", data: [data?.val?.benchmark] },
                  //   { name: "Post", data: [data?.val?.testAverage] },
                  // ]}
                  // xAxis={["Pre", "Post"]}
                  // sorting={false}
                  data={[
                    { name: "Pre", data: [data?.val?.benchmark.toFixed(2),data?.val?.segmentArr[0].benchmark.toFixed(2)] },
                    { name: "Post", data: [data?.val?.testAverage.toFixed(2),data?.val?.segmentArr[0].testDuringValue.toFixed(2)] },
                  ]}
                  xAxis={["TEST", "CONTROL"]}
                  sorting={false}
                />
              </Grid>
              <Grid item xs={6}>
                {data?.val?.lift < 0 ? (
                  <Typography
                    component="span"
                    variant="subtitle2"
                    className={classes.cardBlockLiftRed}
                  >
                    <ArrowDropDownIcon />
                    <Typography component="span" variant="subtitle2">
                      {`${data?.val?.lift}     %Lift`}
                    </Typography>
                  </Typography>
                ) : (
                  <Typography
                    component="span"
                    variant="subtitle2"
                    className={classes.cardBlockLiftGreen}
                  >
                    <ArrowDropUpIcon />
                    <Typography component="span" variant="subtitle2">
                      {`${data?.val?.lift}     %Lift`}
                    </Typography>
                  </Typography>
                )}
              </Grid>
              <Grid item xs={6}>
                {data?.val?.pctChange < 0 || data?.val?.pctChange === null ? (
                  <Typography
                    component="span"
                    variant="subtitle2"
                    className={classes.cardBlockLiftRed}
                  >
                    <ArrowDropDownIcon />
                    <Typography component="span" variant="subtitle2">
                      {`${data?.val?.pctChange ? data?.val?.pctChange : "NA"
                        }     %Change`}
                    </Typography>
                  </Typography>
                ) : (
                  <Typography
                    component="span"
                    variant="subtitle2"
                    className={classes.cardBlockLiftGreen}
                  >
                    <ArrowDropUpIcon />
                    <Typography component="span" variant="subtitle2">
                      {`${data?.val?.pctChange ? data?.val?.pctChange : "NA"
                        }     %Change`}
                    </Typography>
                  </Typography>
                )}
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {/* <Typography
                  component="h4"
                  variant="body1"
                  className={classes.infoArea}
                >
                  <Typography component="span" variant="subtitle1">
                    <InfoOutlinedIcon fontSize={"small"} />
                  </Typography>
                  {data.val.kpiDefn}
                </Typography> */}
                <PieChart
                  height={300}
                  data={
                    data?.val?.testAverage > data?.val?.benchmark
                      ? [
                        data?.val?.benchmark,
                        +(data?.val.testAverage - data?.val?.benchmark).toFixed(
                          2
                        ),


                      ]
                      : [
                        data?.val?.testAverage,
                        +(data?.val?.benchmark - data?.val?.testAverage).toFixed(
                          2
                        ),


                      ]
                  }
                  testAvg={data?.val?.testAverage}
                  labels={[
                    data?.val?.testAverage > data?.val?.benchmark
                      ? "Benchmark"
                      : "Test Avg",
                    "%Change",
                    "Total 100%",
                  ]}
                  colors={
                    data?.val?.testAverage > data?.val?.benchmark
                      ? ["#013598", "#24A148", "#EAEAEA"]
                      : ["#E01F29", "#013598", "#EAEAEA"]
                  }
                  sparkline={false}
                />
              </Grid>
              <Grid item xs={12}>
                <Box component="div" className={classes.sideBlock}>
                  <Typography
                    component="h5"
                    variant="subtitle1"
                    style={{
                      color:
                        data?.val?.benchmark < data?.val?.testAverage
                          ? "#24A148"
                          : "#E01F29",
                    }}
                  >
                    {data.val.comment}
                  </Typography>
                  <Typography component="h6" variant="subtitle1">
                    {data.view !== "purchaseBehavior"
                      ? "Pre : "
                      : "Pre : "}
                    {data?.val?.benchmark ? data?.val?.benchmark?.toFixed(2) : ""}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={7}>
          <Grid container spacing={3}>
            {data.val.segmentArr[0].benchmark !== null ? (
              data.val.segmentArr.map((item, index) => (
                <Grid item xs={6} key={index}>
                  <Box
                    component="div"
                    className={classes.block}
                    onClick={(e) => {
                      handleDialogOpen(item);
                    }}
                  >
                    <Typography component="h4" variant="subtitle1" style={{ textTransform: "capitalize" }}>
                      {retriveMetricFromStr(item.segmentName)}
                    </Typography>
                    <Typography component="h5" variant="body2">
                      {/* <Typography component="span" className={classes.value}>
                  {" "}
                  50%{" "}
                </Typography> */}
                      {item.segmentComment === null
                        ? "NA"
                        : item.segmentComment}
                      {/* <Typography component="span" className={classes.highlight}>
                  {" "}
                  App Downloads
                </Typography> */}
                    </Typography>
                    <Typography
                      component="h6"
                      variant="body2"
                      className={classes.chartValues}
                    >
                      <Typography component="span" className={classes.valBM}>
                        {data.view !== "purchaseBehavior"
                          ? "Pre : "
                          : "Pre : "}

                        {numberWithCommas(item?.benchmark?.toFixed(2))}
                      </Typography>
                      <Typography
                        component="span"
                        style={{
                          color:
                            item?.testDuringValue < item?.benchmark
                              ? "#E01F29"
                              : "#24A148",
                        }}
                      >
                               {item.testDuringValue !== null
                          ?              numberWithCommas(item?.testDuringValue?.toFixed(2))
                          : ""}
                      </Typography>
                    </Typography>
                    <StackedBar
                      data={item}
                      viewStatus={data.view}
                      type={data.val.kpiType}
                      colors={
                        item.testDuringValue < item.benchmark
                          ? ["#013598", "#E01F29"]
                          : ["#013598", "#24A148"]
                      }
                    />
                  </Box>
                </Grid>
              ))
            ) : (
              <Typography component="h3" variant="h3" className={classes.info}>
                {"No records to display"}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
      <DialogBox
        open={dialogOpen}
        handleClose={handleDialogClose}
        width={"medium"}
        header={
          dialogData.segmentName
            ? retriveMetricFromStr(dialogData.segmentName)
            : "NA"
        }
        children={
          <>
            {/* <Typography
              component="h4"
              variant="body2"
              style={{ marginBottom: 25 }}
            >
              {dialogData.segmentComment ? dialogData.segmentComment : "NA"}
            </Typography> */}

            {data.view === "purchaseBehavior" &&
              dialogData.segmentName !== "Flight Details" ? (
              <MeasureStackedChart data={dialogData} />
            ) : (
              <ProgressBar data={dialogData} viewStatus={data.view} />
            )}
          </>
        }
      ></DialogBox>
    </>
  );
}
