
import React, { useEffect, useState } from 'react';
import ReactApexCharts from 'react-apexcharts';

const BarChart = props => {
  const [chartInit, setChartInit] = useState();
  const { data, viewStatus, type, colors } = props;

  useEffect(() => {
    setChartInit({
      series: [{
        data: [data.benchmark, data.testDuringValue]
      }],
      options: {
        chart: {
          type: 'bar',
          height: 40,
          stacked:false,
          toolbar: {
            show: false,
          }
        },
        colors: ['#013598'],
        grid: {
          show: false,
          padding: {
            left: 0,
            top: -20,
            bottom: 0
          }
        },
        colors:colors,
        dataLabels: {
          enabled: false
        },
        plotOptions: {
          bar: {
            columnWidth: '45%',
            distributed: false,  
          }
        },
        xaxis: {
          categories: 
           [['Pre'],['Post']],
          labels: {
            style: {
              colors: ['black'],
              fontSize: '14px'
            }
          }
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              if (value === 0) {
                // Display 0 as an integer without decimals
                return 0;
              } else if (value < 1) {
                // Display values less than 1 with 1 decimal places
                return value.toFixed(1);
              } else {
                // Round values greater than or equal to 1 to the nearest integer
                return Math.round(value);
              }
            }
          }
        },
        tooltip: {
          y: {
            formatter: function (value) {
              return value; // Display the actual value from the API
            }
          }
        },
        legend: {
          show: false
        }
      }
    });
  }, []);

  return (
    <React.Fragment>
      {chartInit && (
        <>
          <ReactApexCharts
            options={chartInit?.options}
            series={chartInit?.series}
            type="bar"
            height={120}
          />
        </>
      )}
    </React.Fragment>
  );
};
export default BarChart;
