import React, { forwardRef, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  colors,
  List,
  ListItem,
  Tooltip,
  IconButton
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: { paddingTop: 100 },
  item: {
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 0
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    '& button': {
      color: theme.palette.text.disable,
      width: 24,
      height: 24,
      display: 'flex',
      alignItems: 'center',
      marginLeft: 10
    }
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& button': {
      color: theme.palette.primary.contrastText,
      '& $icon': {
        color: theme.palette.primary.contrastText
      }
    }
  },
  title: {
    display: 'none'
  }
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref}>
    <RouterLink {...props} />
  </div>
));

const SidebarNav = props => {
  const [selectedMenu, setSelectedMenu] = useState('Dashboard');
  const classes = useStyles();

  const pages = [
    {
      title: 'Design Campaign',
      href: '/design-campaign',
      icon: (
        <Tooltip title="Design Campaign">
          <IconButton className={'fas fa-home'} size={'small'} />
        </Tooltip>
      )
    },
    {
      title: 'Settings',
      href: '/settings',
      icon: (
        <Tooltip title="Settings">
          <IconButton className={'fas fa-cog'} size={'small'} />
        </Tooltip>
      )
    },
    {
      title: 'User Guide',
      href: '/user-guide',
      icon: (
        <Tooltip title="User Guide">
          <IconButton className={'fas fa-question-circle'} size={'small'} />
        </Tooltip>
      )
    }
  ];

  return (
    <List className={classes.root}>
      {pages.map(page => (
        <ListItem
          className={classes.item}
          disableGutters
          key={page.title}
          onClick={e => setSelectedMenu(page.title)}>
          <Button
            activeClassName={classes.active}
            className={classes.button}
            component={CustomRouterLink}
            to={page.href}>
            <div className={classes.icon}>{page.icon}</div>
            <div className={classes.title}>{page.title}</div>
          </Button>
        </ListItem>
      ))}
    </List>
  );
};

export default SidebarNav;
