import React, { useState, useEffect } from 'react';
import {
  Button,
  CssBaseline,
  Link,
  Paper,
  Box,
  Grid,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import imageBackground from '../../assets/background.png';
import tredenceLogo from '../../assets/Tredence-Logo.svg';
import banner from '../../assets/Front.svg';
import { UserAgentApplication } from 'msal';

import config from '../../azure-configs/azureConfig';

import { getUserDetails } from '../../azure-services/GraphService';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://tredence.com/">
        Tredence
      </Link>
      {new Date().getFullYear()}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh'
  },
  image: {
    backgroundImage: `url(${imageBackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      height: '100vh'
    }
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  loginPaper: {
    margin: '30px 30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    // height: '100%',
    '& img': {
      width: '60%',
      margin: '10% 0 30%'
    }
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  }
}));

export default function SignInSide(props) {
  const classes = useStyles();
  const [formType, setFormType] = useState('login');
  const history = useHistory();

  const userAgentApplication = new UserAgentApplication({
    auth: {
      clientId: config.appId,
      redirectUri: config.redirectUri,
      postLogoutRedirectUri: config.postLogoutRedirectUri
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: true
    }
  });

  const [loginState, setLoginState] = useState({
    isAuthenticated: false,
    user: {},
    error: null
  });

  const login = async () => {
    try {
      await userAgentApplication.loginRedirect({
        scopes: config.scopes,
        prompt: 'select_account'
      });
      //this will redirect to login page
      //await userAgentApplication.loginRedirect();
      await getUserProfile();
    } catch (err) {
      let error = {};

      if (typeof err === 'string') {
        let errParts = err.split('|');
        error =
          errParts.length > 1
            ? { message: errParts[1], debug: errParts[0] }
            : { message: err };
      } else {
        error = {
          message: err.message,
          debug: JSON.stringify(err)
        };
      }
      setLoginState({
        isAuthenticated: false,
        user: {},
        error: error
      });
    }
  };

  const getUserProfile = async () => {
    try {
      // Get the access token silently
      // If the cache contains a non-expired token, this function
      // will just return the cached token. Otherwise, it will
      // make a request to the Azure OAuth endpoint to get a token

      let accessToken = await userAgentApplication.acquireTokenSilent({
        scopes: config.scopes
      });

      if (accessToken) {
        // Get the user's profile from Graph
        let user = await getUserDetails(accessToken);
        console.log(user);
        localStorage.setItem('userName', user.displayName);
        localStorage.setItem('accessToken', accessToken.accessToken);

        setLoginState({
          isAuthenticated: true,
          user: {
            displayName: user.displayName,
            email: user.mail || user.userPrincipalName,
            givenName: user.givenName,
            surname: user.surname
          },
          error: null
        });
        history.push('/home');
      }
    } catch (err) {
      let error = {};
      if (typeof err === 'string') {
        let errParts = err.split('|');
        error =
          errParts.length > 1
            ? { message: errParts[1], debug: errParts[0] }
            : { message: err };
      } else {
        error = {
          message: err.message,
          debug: JSON.stringify(err)
        };
      }
      setLoginState({
        isAuthenticated: false,
        user: {},
        error: error
      });
    }
  };

  useEffect(() => {
    if (localStorage?.getItem('accessToken')?.length) {
      if (history) {
        history.push('/home');
      }
    }
  }, []);

  const LoginForm = (
    <div className={classes.loginPaper}>
      <img src={tredenceLogo} alt={'Tredence logo'} />

      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        onClick={login}>
        Sign In
      </Button>
      <Box mt={5}>
        <Copyright />
      </Box>
    </div>
  );

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image}>
        <img src={banner} alt={'banner'} />
      </Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        {formType === 'login' && LoginForm}
      </Grid>
    </Grid>
  );
}
