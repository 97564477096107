import React, { useState, useEffect } from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import { Input, InputLabel, FormControl, Button, Card } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import secureAxios from 'api-config/api';
const { Grid, TextField } = require("@material-ui/core")




const RoleMangement = (props) => {

    const [role, setRole] = useState("");
    const [assignRole, setAssignRole] = useState({
        design: false,
        saveStrategy: false,
        applyStrategy: false,
        measure: false,
        download: false
    });

    const handleChange = (event) => {
        setAssignRole({
            ...assignRole,
            [event.target.name]: event.target.checked,
        });
    };

    const HandleRoleManagement = () => {
        console.log("role", role, "toggle", assignRole)
        secureAxios
            .post('/designCampaign/insertRoleManagement', {
                "role": role,
                "designCampaign": assignRole.design,
                "saveStrategy": assignRole.saveStrategy,
                "applyStrategy": assignRole.applyStrategy,
                "measureCampaign": assignRole.measure,
                "download": assignRole.download
            })
            .then(function (res) {
               console.log("Role Management",res.data);
            });


        //props.handleRoleModal();
    }
    return (
        <>
            <Card>
                <Grid item xs={12} container style={{ margin: "2% 0% 0% 20%", minHeight: "300px", minWidth: "500px" }}>
                    <Grid item xs={12}>
                        <TextField id="outlined-basic" label="Enter Role" value={role}
                            variant="outlined" onChange={(e, val) => setRole(e.target.value)} style={{ width: '400px', marginRight: '9%' }} />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: "50px" }}>
                        <FormControl component="fieldset" variant="standard">
                            <FormLabel component="legend">Assign Responsibility</FormLabel>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch checked={assignRole.design} onChange={handleChange} name="design" />
                                    }
                                    label="Design Campaign"
                                />
                                <FormControlLabel
                                    control={
                                        <Switch checked={assignRole.saveStrategy} onChange={handleChange} name="saveStrategy" />
                                    }
                                    label="Save Strategy"
                                />
                                <FormControlLabel
                                    control={
                                        <Switch checked={assignRole.applyStrategy} onChange={handleChange} name="applyStrategy" />
                                    }
                                    label="Apply Strategy"
                                />
                                <FormControlLabel
                                    control={
                                        <Switch checked={assignRole.measure} onChange={handleChange} name="measure" />
                                    }
                                    label="Measure Campaign"
                                />
                                <FormControlLabel
                                    control={
                                        <Switch checked={assignRole.download} onChange={handleChange} name="download" />
                                    }
                                    label="Download"
                                />
                            </FormGroup>
                            {/* <FormHelperText>Be careful</FormHelperText> */}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: "50px" }}>
                        <Button

                            size="small"
                            variant="contained"
                            style={{ marginRight: '1%' }}
                            onClick={e => {

                                props.handleRoleModal();
                                setRole("");
                                setAssignRole({
                                    design: false,
                                    saveStrategy: false,
                                    applyStrategy: false,
                                    measure: false,
                                    download: false
                                });
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            type="submit"
                            size="small"
                            variant="contained"
                            onClick={HandleRoleManagement}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Card>
        </>
    )
}

export default RoleMangement;