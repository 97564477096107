import React, { CSSProperties } from 'react';

import { useCSVReader } from 'react-papaparse';
import _ from 'lodash';
import { Grid, Typography } from '@material-ui/core';
import { CSVLink } from 'react-csv';
import GetAppIcon from '@material-ui/icons/GetApp';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';


const styles = {
  csvReader: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
    width:'300px'
  } ,
  browseFile: {
    width: '40%',
  } ,
  acceptedFile: {
    border: '1px solid #ccc',
    height: 45,
    lineHeight: 2.5,
    paddingLeft: 10,
    width: '70%',
  } ,
  remove: {
    borderRadius: 0,
    padding: '0 20px',
    width:'10%',
    display:'flex',
    justifyContent:'center',
    alignItems:'center'
    // background:'red'
  },
  progressBarBackgroundColor: {
    backgroundColor: 'red',
  } ,
};

export default function UploadCsv(props){
    const { CSVReader } = useCSVReader();

    const sampleData= [
        { storeID: "23"},
        { storeID: "312"},
        { storeID: "524"},
        { storeID: "514"},
        { storeID: "598"},
        { storeID: "533"},
      ];
 

return (
    <>
  {/* <CSVReader /> */}
  <div style={{display:'flex',flexDirection:'row'}}>

  <CSVReader
      onUploadAccepted={(results) => {
        const data= _.flatten(results.data)
        data.shift()
        console.log(data,'upload Data')
        props.setSelectedSTores( data.length? data:[]);
        // console.log('---------------------------');
        // console.log(results);
        // console.log('---------------------------');
      }}
    >
      {({
        getRootProps,
        acceptedFile,
        ProgressBar,
        getRemoveFileProps,
      }) => (
        <>
          <div style={styles.csvReader}>
            <button type='button' {...getRootProps()} style={styles.browseFile}>
              Upload file
            </button>
            <div style={styles.acceptedFile}>
              {acceptedFile && acceptedFile.name}
            </div>
            <button {...getRemoveFileProps()} style={styles.remove}>
              <HighlightOffIcon/>
            </button>
          </div>
          {/* <ProgressBar style={styles.progressBarBackgroundColor} /> */}
        </>
      )}
    </CSVReader>

    <div style={{float:'right',marginLeft:'50px'}}>
                  <Typography component="h5" >
                        {'Download sample CSV'}
                  </Typography>
                   <CSVLink data={sampleData} filename={"upload_stores.csv"} ><GetAppIcon style={{color:'grey'}}/></CSVLink>
                  {/* <CsvDownloader datas={controlCSV} filename="control_attribute" ><GetAppIcon /></CsvDownloader> */}
                
                  </div>
                  </div>
</>

)

}