import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { TextField, Typography } from '@material-ui/core';
import { ErrorMessage, Field } from 'formik';
import { setRandomSampleStore } from 'redux/actions/identifyTest.action';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '30%'
  },
  errorText: {
    color: 'red',
    fontSize: 12
  }
}));

const MultilineTextField = props => {
  const classes = useStyles();
  const { name, label, width } = props;
  return (
    <>
      {props.sourceComp !== 'RandomSample' ? <Field
        as={TextField}
        className={width ? width : classes.formControl}
        name={name}
        multiline
        rows={1}
        label={label}
        variant="outlined"
      /> : <TextField 
          className={width ? width : classes.formControl}
          name={name}
          multiline
          rows={1}
          label={label}
          variant="outlined"
          onChange={(event) => {
            props.dispatch(setRandomSampleStore(event.target.value))
          }}
      />}
      <Typography className={classes.errorText}>
        {props.sourceComp !== 'RandomSample' ? <ErrorMessage name={name} /> : null}
      </Typography>
    </>
  );
};

export default MultilineTextField;
