import React, { useEffect, useState } from "react";

import {
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
// import { APIS } from "../../config";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import TextFieldElement from "views/Common/Filters/TextFieldElement";
import DialogBox from "views/Common/Modals/Dailog";
import AddGroup from "./AddGroup";
import MultilineTextField from "views/Common/Filters/MultilineTextField";
import { useDispatch } from "react-redux";
import SingleSelectDropdown from "views/Common/Filters/SingleSelectDropdown";
import secureAxios from "api-config/api";
import MessageDialog from "views/Common/MessageDialog/MessageDialog";


const useStyles = makeStyles((theme) => ({
  sectionHeading: {
    fontWeight: 400,
    fontSize: 16,
    letterSpacing: "-0.02em",
    color: "#404041",
    textAlign: 'left'
  },
  sectionHeadingLarge: {
    fontWeight: 500,
    fontSize: 16,
    letterSpacing: "-0.02em",
    color: "#404041",
    // marginTop: '-10px',
    margin: '2px 0 15px',
    textAlign: 'left',
    color: '#031330',
    
  },
  primaryBtn: {
    marginTop: 8,
    borderRadius: 0,
    textTransform: "capitalize",
    "&:hover": {
      background: "#031330",
    },
  },
  cancelBtn: {
    "&:hover": {
      backgroundColor: "#e7e7e7",
    },
    margin: "8px 16px 0",
    borderRadius: "0",
    textTransform: "capitalize",
  },
  audSelectBtn: {
    color: "#333333",
    border: "1px solid #333333",
    borderRadius: "6px",
    textTransform: "capitalize",
    padding: "0 10px",
  },
  successMsg: {
    color: "#24A148 !important",
    fontWeight: 500,
    display: "inline-block",
  },
  textRight:{
    display:"flex",
    justifyContent:"end"
  }
}));
const CreateGroupForm = ({onAdd, initialProducts}) => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false);
  const [products, setProducts] = useState(JSON.parse(JSON.stringify(initialProducts)));
  const [division, setDivision] = useState({});
  const [progress, setProgress] = useState(false);
  const [message, setMessage] = useState("");
  const [opens, setOpens] = useState(false);
  const [type, setType] = useState("");
  const validSchema = Yup.object().shape({
    NAME: Yup.string().required("Required"),
    DESCRIPTION: Yup.string(),
    CATEGORY: Yup.string()
  });

  useEffect(()=>{
    setProducts(JSON.parse(JSON.stringify(initialProducts)));
  },[initialProducts])


  const productgroupSave = (values, { resetForm }) => {
    setProgress(true);
    dispatch({
      type:"LOADING",
      payload:true
    })
    const products = [];
    for(let key in division) {
      division[key].classes.forEach((c)=>{
        products.push({
          division_nbr: c.divCode,
          dept_nbr: c.deptCode,
          class_nbr: c.code
        });
      })
    }
    secureAxios
    .post("/master/productgroup", {
      product_grp_name: values.NAME,
      prd_grp_desc: values.DESCRIPTION,
      prd_grp_catg: values.CATEGORY,
      products 
    })
    .then((res) => {
      dispatch({
        type:"LOADING",
        payload:false
      })
      setOpens(true);
      setType("success");
      setMessage("Created new group successfully");
      setProgress(false);
      onAdd();
      resetForm();
      setDivision({});
      setProducts(JSON.parse(JSON.stringify(initialProducts)));
    }).catch((err)=>{
        setOpens(true);
        setType("error");
        setMessage(err?.response?.data?.errors?.map(e => e.msg).join());
        dispatch({
          type:"LOADING",
          payload:false
        })
        setProgress(false);
    });
  }
  //const durationOptions = ["AE", "AERIEs"];

  const handleProductModal = (div, prod) => {
    setDivision(JSON.parse(JSON.stringify(div)));
    setProducts(JSON.parse(JSON.stringify(prod)));
    setOpen(false);
  };

  const getProductsCount = () => {
    let count = 0;
    Object.keys(division).forEach(
      (key) => (count += division[key].classes.length)
    );
    return count;
  };
  //close the popup
  const handleClose = () => {
    setOpens(false);
  };
  return (
    <>
      <MessageDialog
        type={type}
        open={opens}
        message={message}
        close={handleClose}
      />
      <Formik
        initialValues={{
          NAME: "",
          DESCRIPTION: "",
          CATEGORY: "",
          PRODUCTS: [],
        }}
        validationSchema={validSchema}
        onSubmit={(values, params) => {
          productgroupSave(values, params);
        }}
      >
        {({ isValid, dirty, resetForm , handleChange, values}) => (
         <Form
         autoComplete="off"
         noValidate
         style={{ padding: "10px 15px", textAlign: "left" }}
       >
         <Typography className={classes.sectionHeadingLarge}>
           Create Product Group
         </Typography>
         <Grid container spacing={3}>
           <Grid item xs={6}>
             <TextFieldElement
               name={"NAME"}
               label="Group Name"
               width={"w100"}
             />
           </Grid>
           <Grid item xs={6}>
            <SingleSelectDropdown
               width={"w100"}
               value={values.CATEGORY}
               name={"CATEGORY"}
               handleMethod={handleChange}
               label={"Select Category"}
              // options={durationOptions}
               noneRequired={true}
             />
           </Grid>
           <Grid item xs={12}>
             <MultilineTextField
               name={"DESCRIPTION"}
               label="Group Description"
               width={"w100"}
             />
             
           </Grid>
           <Grid item xs={10} >
             <Button
               variant="outlined"
               onClick={() => setOpen(true)}
               className={classes.audSelectBtn}
             >
               Select Product List
             </Button>

             {Object.keys(division).length ? (
               <Typography
                 variant="body2"
                 component="p"
                 className={classes.successMsg}
               >
                 &nbsp; {`${getProductsCount()} Product List(s) Selected`}
               </Typography>
             ) : (
               <Typography variant="body2" component="em">
                 &nbsp; {"No Product List Selected"}
               </Typography>
             )}
           </Grid>

           
             <Grid item xs={12} className={classes.textRight}>
               <Button
                 className={classes.cancelBtn}
                 type="button"
                 variant="contained"
                 onClick={() => {
                   resetForm();
                   setDivision({});
                   setProducts(JSON.parse(JSON.stringify(initialProducts)));
                 }}
               >
                 Reset
               </Button>
                 
               <Button
                 className={classes.primaryBtn}
                 type="Submit"
                 variant="contained"
                 color="primary"
                 disabled={
                   !(dirty && isValid) ||
                   Object.keys(division).length === 0 ||
                   progress
                 }
               >
                 Save
               </Button>
             </Grid>
          
         </Grid>

         <DialogBox
           open={open}
           handleClose={()=> setOpen(false)}
           width={"medium"}
           buttonStatus={false}
           header={
             <div>
               <span>Add Product</span>{" "}
               <span
                 style={{
                   color: "#888",
                   marginLeft: "20px",
                   fontSize: "12px",
                 }}
               >
                 <strong>Hierarchy:</strong> Division &gt; Department &gt;
                 Class
               </span>
             </div>
           }
           contentStyle={{ marginBottom: "70px" }}
           children={
             <AddGroup
               handleProductModal={handleProductModal}
               products={products}
               division={division}
             />
           }
         ></DialogBox>
       </Form>
        )}
      </Formik>
    </>
  );
};

export default CreateGroupForm;
