import {
    MEASURECAMPAIGNDETAIL
  } from '../types';
  
  const initialState = {
    measureCampaignDetail: '',
  };
  const measureCampaign = (state = initialState, action) => {
    switch (action.type) {
      case MEASURECAMPAIGNDETAIL:
        return {
          ...state,
          measureCampaignDetail: action.payload
        };
      default:
        return state;
    }
  };
  
  export default measureCampaign;
  