import {
    Grid,
    Box,
    Button,
    FormControlLabel,
    Checkbox,
  } from "@material-ui/core";
  import React, { useState, useEffect } from "react";
  import Typography from "@material-ui/core/Typography";
  import TreeView from "@material-ui/lab/TreeView";
  import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
  import ChevronRightIcon from "@material-ui/icons/ChevronRight";
  import TreeItem from "@material-ui/lab/TreeItem";
  import useStyles from "./style";
  
  
  const AddGroup = (props) => {
    const classes = useStyles();
    const [products, setProducts] = useState(props.products);
    const [division, setDivision] = useState(props.division?props.division:{});
    const { handleProductModal } = props;
  
    useEffect(() => {
      calculateDivision();
    }, [products]);
  
    const calculateDivision = () => {
      const divs = {};
      for (let divkey in products) {
        const divData = products[divkey];
        for (let depkey in divData.departments) {
          const depData = divData.departments[depkey];
          for (let classkey in depData.class) {
            const classData = depData.class[classkey];
            if (classData.checked) {
              if (!divs[divkey]) {
                divs[divkey] = {
                  division: divData.name,
                  classes: [],
                };
              }
              divs[divkey].classes.push({
                ...classData,
                divCode: divData.code,
                deptCode: depData.code,
              });
            }
          }
        }
      }
      setDivision(divs);
    };
  
    function getOnChange(checked, nodes) {
      if (checked) {
        nodes.checked = true;
      } else {
        nodes.checked = false;
      }
      if (nodes.departments) {
        for (let key in nodes.departments) {
          nodes.departments[key].checked = checked;
          getOnChange(checked, nodes.departments[key]);
        }
      }
      if (nodes.class) {
        for (let key in nodes.class) {
          nodes.class[key].checked = checked;
        }
      }
      setProducts(JSON.parse(JSON.stringify(products)));
    }
  
    const getCheckmarkType = (node) => {
      // console.log('node', node);
      if(typeof node.departments !== 'undefined') {
        const types = Object.values(node.departments).map(n=>{
          return getCheckmarkType(n);
        });
        const max_of_array = Math.max.apply(Math, types);
        const min_of_array = Math.min.apply(Math, types);
        if(max_of_array != min_of_array) {
          return 1;
        }
        return max_of_array;
      } else if(typeof node.class !== 'undefined') {
        const types =  Object.values(node.class).map(n=>{
          return getCheckmarkType(n);
        });
        const max_of_array = Math.max.apply(Math, types);
        const min_of_array = Math.min.apply(Math, types);
        if(max_of_array != min_of_array) {
          return 1;
        }
        return max_of_array;
      }
      return !!node.checked?2:0
    }
  
    const RenderTreeWithCheckboxes = (nodes) => {
      const state = getCheckmarkType(nodes);
      const lastLevel = !(nodes.departments || nodes.class)
      return (
        (lastLevel ? <TreeItem
          key={nodes.code}
          nodeId={nodes.name + nodes.code}
          label={
            <FormControlLabel
              control={
                <Checkbox
                  checked={state===2}
                  indeterminate={state===1}
                  onChange={(event) => {
                    getOnChange(event.currentTarget.checked, nodes);
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className={classes.checkbox}
                />
              }
              label={<>{nodes.name}</>}
              key={nodes.code}
            />
          }
        />: <TreeItem
          key={nodes.code}
          nodeId={nodes.name + nodes.code}
          label={
            <FormControlLabel
              control={
                <Checkbox
                  checked={state===2}
                  indeterminate={state===1}
                  onChange={(event) => {
                    getOnChange(event.currentTarget.checked, nodes);
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className={classes.checkbox}
                />
              }
              label={<>{nodes.name}</>}
              key={nodes.code}
            />
          }
        >
          {nodes.departments
            ? Object.keys(nodes.departments).map((key) =>
                RenderTreeWithCheckboxes(nodes.departments[key])
              )
            : ""}
          {nodes.class
            ? Object.keys(nodes.class).map((key) =>
                RenderTreeWithCheckboxes(nodes.class[key])
              )
            : ""}
        </TreeItem>)
      );
    };
    return (
      <>
        <Grid container spacing={3} className={classes.productGroupContainer}>
          <Grid item md={4}>
            <Box component="div">
              {Object.keys(division).length > 0 ? (
                <Grid item xs={12}>
                  {Object.keys(division).map((key, index) => (
                    <Typography component="div" key={index}>
                      ({division[key].classes.length}) {division[key].division}{" "}
                    </Typography>
                  ))}
                </Grid>
              ) : (
                <Grid item md={12} className={classes.noDataMsg}>
                  <Typography>No selection found</Typography>
                </Grid>
              )}
            </Box>
          </Grid>
          <Grid item md={8} className={"borderLeft"}>
            {/* <div>Division &gt; Department &gt; Product</div> */}
            <Box component="div">
              <TreeView
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpanded={["0", "3", "4"]}
                defaultExpandIcon={<ChevronRightIcon />}
              >
                {Object.keys(products).map((key) => {
                  return RenderTreeWithCheckboxes(products[key]);
                })}
              </TreeView>
            </Box>
            <Grid item xs={12} className={"textRight"} style={{height: '100px'}}></Grid>
          </Grid>
          <Button
                className={classes.primaryBtn}
                type="button"
                variant="contained"
                color="primary"
                onClick={() => handleProductModal(division, products)}
                style={{position:'absolute', bottom: '20px', right: '35px' }}
              >
                Save and Next
              </Button>
        </Grid>
      </>
    );
  };
  
  export default AddGroup;
  