import React, { useEffect, useState } from "react";
import ReactApexCharts from "react-apexcharts";

const StackedBar = (props) => {
  const [chartInit, setChartInit] = useState();
  const { data, viewStatus, type, colors } = props;
  useEffect(() => {
    setChartInit({
      series: [
        {
          name: viewStatus !== "purchaseBehavior" ? "Benchmark" : "Pre",
          data: [data.benchmark],
        },
        {
          name: data.topDataPoint,
          data: [data.testDuringValue],
        },
      ],
      options: {
        chart: {
          type: "bar",
          stacked: true,
          toolbar: {
            show: false,
          },
          sparkline: {
            enabled: true,
          },
        },
        grid: {
          show: false,
        },
        colors: colors ? colors : [],
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        xaxis: {
          show: false,
          labels: {
            // show: false,
            style: {
              colors: ["#ffffff"],
            },
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          show: false,
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        tooltip: {
          fixed: {
            enabled: true,
            position: "bottomLeft", // topRight, topLeft, bottomRight, bottomLeft
            // offsetY: 30,
            // offsetX: 60,
          },

          x: {
            enabled: false,
            show: false,
          },
          y: {
            formatter: (value) => {
              let val;
              if (type !== "pct") {
                val = value;
              } else {
                val = value + "%";
              }
              return val;
            },
          },
        },
      },
    });
  }, []);

  return (
    <React.Fragment>
      {chartInit && (
        <>
          <ReactApexCharts
            options={chartInit.options}
            series={chartInit.series}
            type="bar"
            height={10}
          />
        </>
      )}
    </React.Fragment>
  );
};
export default StackedBar;
