import cloneDeep from 'lodash/cloneDeep';
import { SELECTEDKPIOPTIONS } from '../types';


 
 
const initialState = {
  allKpis: {},
  loading:true
};
const selectedKpiDrillDownOptionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECTEDKPIOPTIONS:
      let tempState = cloneDeep(state.allKpis);
     
      let parentKey =action.payload.parentKey
      let type = action.payload.type;
      let itemKey = action.payload.itemKey;
      let value = action.payload.value;
      let  weightage =   action.payload.weightage;
      //adding item to the list
      if(tempState[parentKey]===undefined){
        tempState[parentKey] = {
          "key":parentKey,
          "value":(parentKey===itemKey?value:null),
          "weightage":((weightage!==undefined && parentKey===itemKey)?weightage:null),
          "children":{
            "product_grp":{
              "key":"product_grp",
              "value":value,
              "weightage":null,
              "children":{}
            },
            "brand":{
              "key":"brand",
              "value":value,
              "weightage":null,
              "children":{}
            }, 
            "division":{
              "key":"division",
              "value":value,
              "weightage":null,
              "children":{
                "department":{
                  "key":"department",
                  "value":value,
                   "weightage":null,
                  "children":{}
                }
              }
            }

          }
        }
      }
       //item exists in the list
      else if(tempState[parentKey]){
        if(type==='primary' && value!==null && weightage===null){
          tempState[parentKey]['value'] = value;
          if(value===false){
            tempState[parentKey]['weightage'] = null;
            Object.keys(state.allKpis[parentKey]['children']).map((item, index)=>{
              tempState[parentKey]['children'][item]['value']=value;
              tempState[parentKey]['children'][item]['weightage']=null;

            })
          }
          else if(value===true){
            tempState[parentKey]['weightage'] = null;
            Object.keys(state.allKpis[parentKey]['children']).map((item, index)=>{
              tempState[parentKey]['children'][item]['value']=value;
              tempState[parentKey]['children'][item]['weightage']=null;

            })
          }
          
        }
        else if(type==='primary' && value===null && weightage!==null){
          tempState[parentKey]['weightage'] = weightage;
        }
        else if(type==='secondary' && value!==null && weightage===null){
          tempState[parentKey]['children'][itemKey]['value'] = value;
          // Object.keys(state.allKpis[parentKey][itemKey]['children']).map((item, index)=>{
          //   tempState[parentKey]['children'][item]['value']=value;
          //   tempState[parentKey]['children'][item]['weightage']=null;

          // })
          if(value===false){
            tempState[parentKey]['children'][itemKey]['weightage'] = weightage;
          }
        }
      }
      return {
        ...state,
        allKpis: tempState
      };
    default:
      return state;
  }
};

export default selectedKpiDrillDownOptionsReducer;
