import React from "react";
import NoWrapper from "./Common/NoWrapper";
import { Grid } from "@material-ui/core";
const Forbidden = () => {
  return (
    <Grid style={{ marginTop: '30px' }}>
      <NoWrapper
        heading={`Sorry, you don't have access to this page  :(`}
        subHeading={`You don't have access to this page.`}
        showBtn={true}
        btnPath={`/home`}
        btnText={'Go Home'}
      />
    </Grid>
  )
}

export default Forbidden;