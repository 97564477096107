import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    background: theme.palette.secondary.light,
    textAlign: 'right',
    '& span': {
      verticalAlign: 'super',
      fontSize: 12,
      color: '#FFF'
    }
  }
}));

const Footer = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography variant="body1">
        {/* <img alt="Logo" src="/images/logos/logo.svg" width={'35px'} /> */}
        <span style={{ fontSize: '16px' }}>Powered by Tredence</span>
      </Typography>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
