import { makeStyles } from '@material-ui/core'

export default  makeStyles((theme) => ({
  checkbox: {
    "&.MuiCheckbox-root": {
      color: "#182C52",
    },
    "&.MuiCheckbox-colorSecondary": {
      "&.Mui-checked": {
        color: "#182C52",
      },
    },
  },
  primaryBtn: {
    marginTop: 8,
    borderRadius: 0,
    textTransform: "capitalize",
    "&:hover": {
      background: "#031330",
    },
  },
  textRight:{
    display:"flex",
    justifyContent:"end"
  }
}));
