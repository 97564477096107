import {
    DRILLDOWNSELECTEDFILTER
} from '../types';

const initialState = {
    drilldownSelectedFilter: {}
};
const drilldownSelectedFilterReducer = (state = initialState, action) => {
    switch (action.type) {
        case DRILLDOWNSELECTEDFILTER:
            return {
                ...state,
                drilldownSelectedFilter: action.payload
            };
        default:
            return state;
    }
};
export default drilldownSelectedFilterReducer;