import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import ReactBreadcrumbs from 'views/Common/Breadcrumb/Breadcrumb';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Box,
  Switch,
  FormControlLabel
} from '@material-ui/core';
import CampaignSummaryPopup from './Popup';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import secureAxios from 'api-config/api';
import { numberWithCommas } from '../../../Common/Utils/ClientUtil';
import { useLocation } from 'react-router-dom';
import html2canvas from 'html2canvas';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import EditIcon from '@material-ui/icons/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import { MaterialReactTable } from 'material-react-table';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 40,
    position: 'relative',
    padding: '0 24px 24px'
  },
  pageHeading: {
    fontSize: 20,
    color: '#182C52'
  },
  summuryCard: {
    borderRadius: '5px',
    flex: 'none',
    alignSelf: 'stretch',
    flexGrow: 0,
    zIndex: 0,
    height: '100px',
    minWidth: '140px',
    width: '180vm',
    margin: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& div p': {
      display: 'flex',
      justifyContent: 'center',
      fontSize: '12vm',
      color: '#333333'
    }
  },
  summuryTbl: {
    '& div div table thead tr': {
      borderBottom: '3px solid rgba(51,51,51,0.3) !important',
      justifyContent: 'center !important'
    },
    '& div div table tbody tr td:nth-child(1)': {
      visibility: 'hidden'
    },
    '& div div table tbody tr td:nth-child(2)': {
      borderRight: '3px solid rgba(51,51,51,0.3) !important'
    },
    '& div div table thead tr th:first-child': {
      visibility: 'hidden'
    }
  },
  summuryTbledit: {
    '& div div div div div button': {
      display: 'none !important'
    },
    '& div div table thead tr': {
      borderBottom: '3px solid rgba(51,51,51,0.3) !important',
      justifyContent: 'center !important'   
      
    },
    '& div div table thead tr th div div button svg': {color: '#fff !important'},
    '& div div table thead tr th:first-child': {
      visibility: 'hidden',
    },
    '& div div table tbody tr td:nth-child(2)': {
      borderRight: '3px solid rgba(51,51,51,0.3) !important'
    }
  },
  configBtn: {
    display: 'flex',
    width: '100%',
    height: '30px',
    padding: '0px 20px',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    background: 'var(--bluw, #182C52)',
    borderRadius: 4,
    border: 'none',
    color: 'var(--neutral-colors-white, #FFF)',
    '&:hover': {
      background: '#031330'
    }
  }
}));

const CampaignSummary = () => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [kpiFilters, setKpiFilters] = useState({});
  const [isDragable, setIsDragable] = useState(false);
  const [brand, setBrand] = useState('ALL');
  const [selectedValues, setSelectedValues] = useState({
    PRODUCT_GRP: ['Total']
  });
  const [capaignData, setCapaignData] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});
  const [appliedFilters, setAppliedFilters] = useState({
    selectedKpi: [],
    productGroup: ['Total'],
    storeId: ['ALL'],
    brands: ['ALL'],
    division: ['ALL'],
    department: ['ALL'],
    class: ['ALL'],
    outletStrategy: ['ALL'],
    mensTier: ['ALL'],
    womensTier: ['ALL'],
    aerieFormat: ['ALL'],
    aerieSetUp: ['ALL'],
    btsPeak: ['ALL'],
    region: ['ALL'],
    factoryDesignation: ['ALL']
  });
  const [columns, setColumns] = useState([]);
  const [tableData, setTableData] = useState([]);
  const campaignId = location?.state?.campaignId;
  const campaignName = location?.state?.name;
  const [outlierChecked, setOutlierChecked] = useState(
    location.state.outlierChecked
  );

  const toggleOutlierChecked = () => {
    setOutlierChecked(prev => !prev);
  };

  const getItems = options => {
    secureAxios
      .post('/measureCampaign/getDrilldownfilters2', {
        ...options
      })
      .then(res => {
        if (Array.isArray(res?.data?.responseData?.productGrps)) {
          res.data.responseData.productGrps.forEach(e => {
            if (e.value == 'Total') {
              e['checked'] = true; // check Total by default
            }
          });
        }
        setKpiFilters(res.data.responseData);
      });
  };

  const applyCheckedSelection = arr => {
    if (!arr) return null;
    arr.forEach(e => {
      if (checkedItems[e.key]) {
        e.checked = true;
      } else {
        e.checked = false;
      }
      e.children = applyCheckedSelection(e.children);
    });
    return arr;
  };

  const getCampainData = async options => {
    let cheksfilters = await handleFilters();
    let filteData = {
      ...options.filters,
      division: options.filters.division.concat(cheksfilters.DIVISION),
      department: options.filters.department.concat(cheksfilters.DEPT),
      class: options.filters.class.concat(cheksfilters.CLASS)
    };
    let data = {
      outlierValue: outlierChecked,
      filters: filteData,
      campaign_id: options.campaign_id,
      page: 1,
      perpage: 10000
    };
    dispatch({
      type: 'LOADING',
      payload: true
    });
    secureAxios
      .post('/measureCampaign/getDrilldownData', {
        ...data
      })
      .then(res => {
        setCapaignData(res.data.responseData);
        dispatch({
          type: 'LOADING',
          payload: false
        });
      });
  };

  useEffect(() => {
    getItems({
      campaign_id: campaignId
    });
  }, []);

  // API call for campaign data-- Onpage  load & Select values from Config
  useEffect(() => {
    getCampainData({
      campaign_id: campaignId,
      filters: {
        ...appliedFilters,
        productGroup: selectedValues
          ? selectedValues.PRODUCT_GRP
          : appliedFilters.productGroup,
        division:
          selectedValues.DIVISION !== undefined &&
          selectedValues.DIVISION.length > 0
            ? appliedFilters.division.concat(selectedValues.DIVISION)
            : appliedFilters.division,
        department:
          selectedValues.DEPT !== undefined && selectedValues.DEPT.length > 0
            ? appliedFilters.department.concat(selectedValues.DEPT)
            : appliedFilters.department,
        class:
          selectedValues.CLASS !== undefined && selectedValues.CLASS.length > 0
            ? appliedFilters.class.concat(selectedValues.CLASS)
            : appliedFilters.class,
        brands: brand != '' ? [brand] : appliedFilters.brands
      }
    });
  }, [selectedValues, brand, outlierChecked]);

  // Rendering Table data-- Onpage  load & Select values from Config & api call
  useEffect(() => {
    handleTabdata();
  }, [kpiFilters, selectedValues, capaignData]);

  useEffect(() => {
    setKpiFilters(prev => ({
      kpis: applyCheckedSelection(prev.kpis),
      productGrps: applyCheckedSelection(prev.productGrps),
      brands: applyCheckedSelection(prev.brands),
      divisions: applyCheckedSelection(prev.divisions)
    }));
  }, [selectedValues]);

  const handleFilters = () => {
    let filters = {
      DIVISION: [],
      DEPT: [],
      CLASS: []
    };
    kpiFilters &&
      kpiFilters.divisions &&
      kpiFilters.divisions.map(division => {
        selectedValues &&
          division.children.map(depart => {
            selectedValues &&
              selectedValues.CLASS &&
              depart.children.map(clas => {
                selectedValues.CLASS.map(citem => {
                  if (citem === clas.label) {
                    !filters.DIVISION.includes(division.label) &&
                      filters.DIVISION.push(division.label);
                    !filters.DEPT.includes(depart.label) &&
                      filters.DEPT.push(depart.label);
                    !filters.CLASS.includes(clas.label) &&
                      filters.CLASS.push(clas.label);
                  }
                });
              });
            selectedValues.DEPT &&
              selectedValues.DEPT.map(item => {
                if (item === depart.label) {
                  !filters.DIVISION.includes(division.label) &&
                    filters.DIVISION.push(division.label);
                  !filters.DEPT.includes(depart.label) &&
                    filters.DEPT.push(depart.label);
                }
              });
          });
      });
    return filters;
  };

  const setValueCallback = useCallback(
    selectedValues => {
      let newCheckItems = {};
      let newSelectedValues = {};
      for (const currentNode of selectedValues) {
        newCheckItems[currentNode.key] = 1;
        newSelectedValues = {
          ...newSelectedValues,
          [currentNode.type]: newSelectedValues[currentNode.type]
            ? [...newSelectedValues[currentNode.type], currentNode.value]
            : [currentNode.value]
        };
      }
      setSelectedValues(newSelectedValues);
      setCheckedItems(newCheckItems);
    },
    [setSelectedValues]
  );

  const closeDrawer = useCallback(() => {
    setIsDrawerOpen(false);
  }, [setIsDrawerOpen]);

  const handleTabdata = () => {
    let fielPrdData =
      Object.entries(selectedValues).length === 0
        ? appliedFilters.productGroup
        : selectedValues.PRODUCT_GRP;
    let fielDivData =
      Object.entries(selectedValues).length === 0 ||
      selectedValues.DIVISION === undefined
        ? []
        : selectedValues.DIVISION;
    let fielDeptData =
      Object.entries(selectedValues).length === 0 ||
      selectedValues.DEPT === undefined
        ? []
        : selectedValues.DEPT;
    let fielClassData =
      Object.entries(selectedValues).length === 0 ||
      selectedValues.CLASS === undefined
        ? []
        : selectedValues.CLASS;

    let mesureData =
      Object.entries(selectedValues).length === 0 ||
      selectedValues.KPI_NAME === undefined
        ? kpiFilters?.kpis
        : kpiFilters?.kpis.filter(item =>
            selectedValues.KPI_NAME.includes(item.value)
          );
    let columndata = [
      {
        header: (
          <Typography component="div">
            <Typography
              component="h3"
              variant="h3"
              style={{
                fontSize: 14,
                fontStyle: 'bold',
                fontWeight: 600,
                color: 'var(--neutral-colors-white, #FFF)',                
                marginLeft: 10
              }}>
              {' '}
              Measure{' '}
            </Typography>
            <Typography
              component="h3"
              variant="h3"
              style={{
                fontSize: 14,
                fontStyle: 'bold',
                fontWeight: 600,
                color: 'var(--neutral-colors-white, #FFF)',
                marginTop: 10
              }}>
              {' '}
              Fields{' '}
            </Typography>
          </Typography>
        ),
        accessorKey: 'field',
        size: 150,
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        },
        enableColumnOrdering: false
      }
    ];
    mesureData &&
      mesureData.map(item => {
        columndata.push({
          header: item.label,
          accessorKey: item.value,
          Cell: ({ renderedCellValue, row }) => (
            <Card
              className={classes.summuryCard}
              key={row.id}
              style={{
                background:
                  renderedCellValue &&
                  renderedCellValue.significance !== undefined
                    ? renderedCellValue.significance === null
                      ? '#FBE8E9'
                      : renderedCellValue.significance < 0.05
                      ? '#EAF6ED'
                      : renderedCellValue.significance > 0.05 &&
                        renderedCellValue.significance < 0.2
                      ? '#FDF8E6'
                      : '#FBE8E9'
                    : '#FFF',
                border:
                  renderedCellValue &&
                  renderedCellValue.significance !== undefined
                    ? renderedCellValue.significance === null
                      ? '1px solid #E01F29'
                      : renderedCellValue.significance < 0.05
                      ? '1px solid #24A148'
                      : renderedCellValue.significance > 0.05 &&
                        renderedCellValue.significance < 0.2
                      ? '1px solid #F0BF0F'
                      : '1px solid #E01F29'
                    : '1px solid #C6C7C8'
              }}>
              <CardContent>
                {renderedCellValue.pctLift != null && (
                  <Typography component="p">
                    {renderedCellValue.pctLift.toFixed(2)}%
                  </Typography>
                )}
                {renderedCellValue.absLift != null && (
                  <Typography component="p">
                    Abs:{renderedCellValue.absLift}
                  </Typography>
                )}
                {renderedCellValue.actual != null && (
                  <Typography component="p">
                    Actual:{renderedCellValue.actual}
                  </Typography>
                )}
                {Object.keys(renderedCellValue).includes('significance') && (
                  <Typography component="p">
                    Significance:
                    {renderedCellValue.significance != null
                      ? ((1 - renderedCellValue.significance) * 100).toFixed(
                          2
                        ) + '%'
                      : 'Null'}
                  </Typography>
                )}
              </CardContent>
            </Card>
          ),
          size: 200,
          muiTableHeadCellProps: {
            align: 'center'
          },
          muiTableBodyCellProps: {
            align: 'center'
          }
        });
      });
    setColumns(columndata);
    // setColumnOrder(columndata.map((c) => c.accessorKey));
    let selectedMesures =
      mesureData && mesureData.length > 0
        ? mesureData.map(item => item.value)
        : [];
    let capaignValues =
      capaignData && selectedMesures.length > 0
        ? capaignData.filter(data => selectedMesures.includes(data.kpi))
        : [];
    let tableSummary = [];
    // Product group
    fielPrdData &&
      fielPrdData.map(field => {
        let tableValues = [
          {
            field: field
          }
        ];
        capaignValues
          .filter(data => data.product_grp === field && data.division === 'ALL')
          .map(item => {
            tableValues.push(handleTabaary(item));
          });
        if (tableValues.length > 1) {
          tableValues = handleEmptycells(tableValues);
          let tabData = {};
          for (let i = 0; i < tableValues.length; i++) {
            Object.assign(tabData, tableValues[i]);
          }
          Object.entries(tabData).length > 0 && tableSummary.push(tabData);
        }
      });

    // Division items
    fielDivData &&
      fielDivData.map(field => {
        let tableValues = [
          {
            field: field
          }
        ];
        capaignValues
          .filter(data => data.division === field && data.dept === 'ALL')
          .map(item => {
            tableValues.push(handleTabaary(item));
          });
        if (tableValues.length > 1) {
          tableValues = handleEmptycells(tableValues);
          let tabData = {};
          for (let i = 0; i < tableValues.length; i++) {
            Object.assign(tabData, tableValues[i]);
          }
          Object.entries(tabData).length > 0 && tableSummary.push(tabData);
        }
      });

    // Department items
    fielDeptData &&
      fielDeptData.map(field => {
        let tableValues = [
          {
            field: field
          }
        ];
        capaignValues
          .filter(data => data.dept === field && data.class === 'ALL')
          .map(item => {
            tableValues.push(handleTabaary(item));
          });
        if (tableValues.length > 1) {
          tableValues = handleEmptycells(tableValues);
          let tabData = {};
          for (let i = 0; i < tableValues.length; i++) {
            Object.assign(tabData, tableValues[i]);
          }
          Object.entries(tabData).length > 0 && tableSummary.push(tabData);
        }
      });
    // Class items
    fielClassData &&
      fielClassData.map(field => {
        let tableValues = [
          {
            field: field
          }
        ];
        capaignValues
          .filter(data => data.class === field)
          .map(item => {
            tableValues.push(handleTabaary(item));
          });
        if (tableValues.length > 1) {
          tableValues = handleEmptycells(tableValues);
          let tabData = {};
          for (let i = 0; i < tableValues.length; i++) {
            Object.assign(tabData, tableValues[i]);
          }
          Object.entries(tabData).length > 0 && tableSummary.push(tabData);
        }
      });

    setTableData(tableSummary);
  };

  const handleEmptycells = values => {
    if (!values.some(value => value.margin_rate)) {
      let obj = { margin_rate: {} };
      values.push(obj);
    }
    if (!values.some(value => value.transactions)) {
      let obj = { transactions: {} };
      values.push(obj);
    }
    if (!values.some(value => value.sales)) {
      let obj = { sales: {} };
      values.push(obj);
    }
    if (!values.some(value => value.traffic)) {
      let obj = { traffic: {} };
      values.push(obj);
    }
    if (!values.some(value => value.actual_cost_amt)) {
      let obj = { actual_cost_amt: {} };
      values.push(obj);
    }
    if (!values.some(value => value.conversion_rate)) {
      let obj = { conversion_rate: {} };
      values.push(obj);
    }
    if (!values.some(value => value.ads)) {
      let obj = { ads: {} };
      values.push(obj);
    }
    if (!values.some(value => value.upt)) {
      let obj = { upt: {} };
      values.push(obj);
    }
    if (!values.some(value => value.units)) {
      let obj = { units: {} };
      values.push(obj);
    }
    if (!values.some(value => value.aur)) {
      let obj = { aur: {} };
      values.push(obj);
    }
    if (!values.some(value => value.inv_oh)) {
      let obj = { inv_oh: {} };
      values.push(obj);
    }
    if (!values.some(value => value.margin_amt)) {
      let obj = { margin_amt: {} };
      values.push(obj);
    }
    return values;
  };

  const handleTabaary = item => {
    return item.kpi === 'margin_rate'
      ? {
          margin_rate: {
            pctLift: item.pct_lift,
            absLift:
              item.abs_lift != null
                ? numberWithCommas(item.abs_lift.toFixed(2))
                : item.abs_lift,
            actual:
              item.test_during_value != null
                ? numberWithCommas(item.test_during_value.toFixed(2))
                : item.test_during_value,
            significance: item.significance
          }
        }
      : item.kpi === 'transactions'
      ? {
          transactions: {
            pctLift: item.pct_lift,
            absLift:
              item.abs_lift != null
                ? numberWithCommas(item.abs_lift.toFixed(2))
                : item.abs_lift,
            actual:
              item.test_during_value != null
                ? numberWithCommas(item.test_during_value.toFixed(2))
                : item.test_during_value,
            significance: item.significance
          }
        }
      : item.kpi === 'sales'
      ? {
          sales: {
            pctLift: item.pct_lift,
            absLift:
              item.abs_lift != null
                ? '$' + numberWithCommas(item.abs_lift.toFixed(2))
                : item.abs_lift,
            actual:
              item.test_during_value != null
                ? '$' + numberWithCommas(item.test_during_value.toFixed(2))
                : item.test_during_value,
            significance: item.significance
          }
        }
      : item.kpi === 'traffic'
      ? {
          traffic: {
            pctLift: item.pct_lift,
            absLift:
              item.abs_lift != null
                ? numberWithCommas(item.abs_lift.toFixed(2))
                : item.abs_lift,
            actual:
              item.test_during_value != null
                ? numberWithCommas(item.test_during_value.toFixed(2))
                : item.test_during_value,
            significance: item.significance
          }
        }
      : item.kpi === 'actual_cost_amt'
      ? {
          actual_cost_amt: {
            pctLift: item.pct_lift,
            absLift:
              item.abs_lift != null
                ? '$' + numberWithCommas(item.abs_lift.toFixed(2))
                : item.abs_lift,
            actual:
              item.test_during_value != null
                ? '$' + numberWithCommas(item.test_during_value.toFixed(2))
                : item.test_during_value,
            significance: item.significance
          }
        }
      : item.kpi === 'conversion_rate'
      ? {
          conversion_rate: {
            pctLift: item.pct_lift,
            absLift:
              item.abs_lift != null
                ? numberWithCommas(item.abs_lift.toFixed(2))
                : item.abs_lift,
            actual:
              item.test_during_value != null
                ? numberWithCommas(item.test_during_value.toFixed(2))
                : item.test_during_value,
            significance: item.significance
          }
        }
      : item.kpi === 'ads'
      ? {
          ads: {
            pctLift: item.pct_lift,
            absLift:
              item.abs_lift != null
                ? '$' + numberWithCommas(item.abs_lift.toFixed(2))
                : item.abs_lift,
            actual:
              item.test_during_value != null
                ? '$' + numberWithCommas(item.test_during_value.toFixed(2))
                : item.test_during_value,
            significance: item.significance
          }
        }
      : item.kpi === 'upt'
      ? {
          upt: {
            pctLift: item.pct_lift,
            absLift:
              item.abs_lift != null
                ? numberWithCommas(item.abs_lift.toFixed(2))
                : item.abs_lift,
            actual:
              item.test_during_value != null
                ? numberWithCommas(item.test_during_value.toFixed(2))
                : item.test_during_value,
            significance: item.significance
          }
        }
      : item.kpi === 'units'
      ? {
          units: {
            pctLift: item.pct_lift,
            absLift:
              item.abs_lift != null
                ? numberWithCommas(item.abs_lift.toFixed(2))
                : item.abs_lift,
            actual:
              item.test_during_value != null
                ? numberWithCommas(item.test_during_value.toFixed(2))
                : item.test_during_value,
            significance: item.significance
          }
        }
      : item.kpi === 'aur'
      ? {
          aur: {
            pctLift: item.pct_lift,
            absLift:
              item.abs_lift != null
                ? '$' + numberWithCommas(item.abs_lift.toFixed(2))
                : item.abs_lift,
            actual:
              item.test_during_value != null
                ? '$' + numberWithCommas(item.test_during_value.toFixed(2))
                : item.test_during_value,
            significance: item.significance
          }
        }
      : item.kpi === 'inv_oh'
      ? {
          inv_oh: {
            pctLift: item.pct_lift,
            absLift:
              item.abs_lift != null
                ? numberWithCommas(item.abs_lift.toFixed(2))
                : item.abs_lift,
            actual:
              item.test_during_value != null
                ? numberWithCommas(item.test_during_value.toFixed(2))
                : item.test_during_value,
            significance: item.significance
          }
        }
      : item.kpi === 'margin_amt' && {
          margin_amt: {
            pctLift: item.pct_lift,
            absLift:
              item.abs_lift != null
                ? '$' + numberWithCommas(item.abs_lift.toFixed(2))
                : item.abs_lift,
            actual:
              item.test_during_value != null
                ? '$' + numberWithCommas(item.test_during_value.toFixed(2))
                : item.test_during_value,
            significance: item.significance
          }
        };
  };

  const exportCanvas = () => {
    
    const divToCapture = document.getElementById('divToExport');
    const fullHeight = divToCapture.scrollHeight;
    const fullWidth = divToCapture.scrollWidth;
    const zoomLevel = window.devicePixelRatio;

    const canvas = document.createElement('canvas');
    canvas.width = fullWidth * zoomLevel;
    canvas.height = fullHeight * zoomLevel;
    const imgHeight = (window.outerHeight + window.innerHeight) * zoomLevel - (1200 - (tableData.length * (tableData.length < 6 ? 100 : 120)));
    const imgHeight2 = (window.outerHeight + window.innerHeight) * zoomLevel + ((tableData.length - 10) * 150);
    const imgWidth = 300 + (columns.length * 200)
    html2canvas(divToCapture, {
      scale: zoomLevel,
      height: tableData.length < 13 ? imgHeight : imgHeight2,
      windowHeight: tableData.length < 13 ? imgHeight : imgHeight2,
      width: imgWidth,
      windowWidth: imgWidth
    }).then(function(canvas) {
      const dataURL = canvas.toDataURL();
      const link = document.createElement('a');
      link.href = dataURL;
      link.download =
        campaignName.replace(/\s/g, '-').toLowerCase() + '-Summary.png';
      link.click();
    });
  };
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <span style={{ float: 'left' }}>
            {' '}
            <ReactBreadcrumbs />
          </span>
          <Box component="div" style={{ float: 'right', textAlign: 'center' }}>
            <Typography
              component="h4"
              variant="h4"
              style={{ fontSize: '16px', color: '#333333' }}>
              Outlier Removal
            </Typography>
            <Typography
              component="h4"
              variant="h4"
              className={classes.pageHeading}>
              <Typography component="p" style={{ display: 'inline-block' }}>
                <Box
                  component="div"
                  style={{ float: 'right', textAlign: 'center' }}></Box>
              </Typography>
              <Typography component="p" style={{ display: 'inline-block' }}>
                <Box
                  component="div"
                  style={{ float: 'right', textAlign: 'center' }}></Box>
                Off
              </Typography>
              <FormControlLabel
                style={{ margin: '0 15px' }}
                value="outlier"
                control={
                  <Switch
                    size="small"
                    checked={outlierChecked}
                    onChange={toggleOutlierChecked}
                    color="primary"
                  />
                }
                labelPlacement="start"
              />
              <Typography component="p" style={{ display: 'inline-block' }}>
                On
              </Typography>
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <CampaignSummaryPopup
        open={isDrawerOpen}
        onClose={closeDrawer}
        data={kpiFilters}
        setValues={setValueCallback}
      />
      <Grid item xs={12} style={{ height: 32 }}>
        <Typography component="h4" variant="h4" className={classes.pageHeading}>
          {campaignName}
        </Typography>
        <Typography
          component="div"
          style={{ display: 'flex', float: 'right', marginTop: -20 }}>
          <Typography component="div" style={{ margin: 10 }}>
            <FormControl style={{ width: 150, height: '30px', marginTop: -20 }}>
              <InputLabel id="demo-simple-select-label">
                Select Brand
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                name="brand"
                value={brand}
                onChange={e => setBrand(e.target.value)}
                label={'brand'}>
                {kpiFilters.brands
                  ? kpiFilters.brands.map((e, index) => (
                      <MenuItem key={index} value={e.label}>
                        {e.label}
                      </MenuItem>
                    ))
                  : []}
              </Select>
            </FormControl>
          </Typography>
          <Typography
            component="div"
            style={{ margin: 8, cursor: 'pointer' }}
            onClick={exportCanvas}>
            <SystemUpdateAltIcon
              style={{ color: '#182C52' }}
              title={'Download Summary'}
            />
          </Typography>
          <Typography
            component="div"
            style={{ margin: 8, cursor: 'pointer' }}
            onClick={e => setIsDragable(!isDragable)}>
            {isDragable ? (
              <EditOffIcon style={{ color: '#182C52' }} title={'Cancel Edit'} />
            ) : (
              <EditIcon style={{ color: '#182C52' }} title={'Edit Summary'} />
            )}
          </Typography>
          <Typography component="div" style={{ margin: 8 }}>
            <button
              className={classes.configBtn}
              onClick={() => setIsDrawerOpen(true)}>
              + Config
            </button>
          </Typography>
        </Typography>
      </Grid>
      <Typography component="div" id="divToExport">
        <Card
          elevation={2}
          style={{ width: '100%', backgroundColor: '#F5F8FF' }}
          className={isDragable ? classes.summuryTbledit : classes.summuryTbl}>
          <MaterialReactTable
            autoResetPageIndex={false}
            enableColumnActions={false}
            enableFullScreenToggle={false}
            enableDensityToggle={false}
            enableFilters={false}
            enableColumnFilters={false}
            columns={columns}
            enablePagination={false}
            data={tableData}
            enableColumnOrdering={isDragable}
            enableRowOrdering={true}
            enableSorting={false}
            enableHiding={false}
            muiTableHeadCellProps={({ table }) => ({
              onDragEnd: () => {
                const { draggingColumn, hoveredColumn } = table.getState();
                if (draggingColumn && hoveredColumn) {
                  let dindex = columns.findIndex(
                    column => column.accessorKey === draggingColumn.id
                  );
                  let hindex = columns.findIndex(
                    column => column.accessorKey === hoveredColumn.id
                  );
                  columns.splice(hindex, 0, columns.splice(dindex, 1)[0]);
                  setColumns([...columns]);
                }
              },
              sx:{
                backgroundColor: '#182C52',
                color: 'var(--neutral-colors-white, #FFF)',
              },
            })}
            muiTableBodyCellProps={{
              sx: {
                fontWeight:'500 !important',
                color: '#182C52 !important',
              },
            }}
            muiTableBodyRowDragHandleProps={({ table }) => ({
              onDragEnd: () => {
                const { draggingRow, hoveredRow } = table.getState();
                if (hoveredRow && draggingRow) {
                  tableData.splice(
                    hoveredRow.index,
                    0,
                    tableData.splice(draggingRow.index, 1)[0]
                  );
                  setTableData([...tableData]);
                }
              }
            })}
            layoutMode="semantic" //instead of the default "semantic" layout mode
          />
        </Card>
      </Typography>
    </div>
  );
};

export default CampaignSummary;
