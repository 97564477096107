// import { colors } from '@material-ui/core';

// const white = '#FFFFFF';
// const black = '#000000';

// export default {
//   black,
//   white,
//   primary: {
//     contrastText: white,
//     dark: colors.indigo[800],
//     main: '#0D5BE1',
//     light: colors.indigo[100]
//   },
//   secondary: {
//     contrastText: white,
//     dark: colors.blueGrey[900],
//     main: colors.blueGrey[400],
//     light: colors.blueGrey[300]
//   },
//   graph: {
//     contrastText: white,
//     primary: '#FBA504',
//     secondary: '#8E44AD'
//   },
//   success: {
//     contrastText: white,
//     dark: colors.green[900],
//     main: colors.green[600],
//     light: colors.green[400]
//   },
//   info: {
//     contrastText: white,
//     dark: colors.blue[900],
//     main: colors.blue[600],
//     light: colors.blue[400]
//   },
//   warning: {
//     contrastText: white,
//     dark: colors.orange[900],
//     main: colors.orange[600],
//     light: colors.orange[400]
//   },
//   error: {
//     contrastText: white,
//     dark: colors.red[900],
//     main: colors.red[600],
//     light: colors.red[400]
//   },
//   text: {
//     secondary: '#031330',
//     primary: '#8D8D8D',
//     disable: '#6EA0F7',
//     link: colors.blue[600]
//   },
//   background: {
//     default: '#F4F6F8',
//     paper: white
//   },
//   chartColors: {
//     trends: ['#FBA504', '#8E44AD', '#00D2D3']
//   },
//   icon: colors.blueGrey[600],
//   divider: colors.grey[200]
// };


import { colors } from "@material-ui/core";

const white = "#FFFFFF";
const black = "#000000";

export default {
  black,
  white,
  primary: {
    contrastText: white,
    dark: '#182C52',
    main: "#182C52",
    light: '#182C52',
  },
  secondary: {
    contrastText: white,
    dark: colors.blueGrey[900],
    main: colors.blueGrey[400],
    light: colors.blueGrey[300],
  },
  graph: {
    contrastText: white,
    primary: "#FBA504",
    secondary: "#8E44AD",
  },
  
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400],
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400],
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400],
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },
  text: {
    secondary: "#031330",
    primary: "#929497",
    disable: "#6EA0F7",
    link: colors.blue[600],
    active: "#182C52",
  },
  background: {
    default: "#FFFFFF",
    paper: white,
  },
  chartColors: {
    trends: ["#FBA504", "#8E44AD", "#00D2D3"],
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200],
};