import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, FormControl } from '@material-ui/core';
import { ErrorMessage } from 'formik';

const useStyles = makeStyles(theme => ({
  formLabel: {
    margin: '5px 0 10px',
    color: '#031330',
    fontWeight: 500
  },
  errorText: {
    color: 'red',
    fontSize: 12
  }
}));

const FileUpload = props => {
  const classes = useStyles();
  const { name, label, handleMethod } = props;
  return (
    <>
      <FormControl component="fieldset">
        <Typography className={classes.formLabel}>{label}</Typography>

        <input
          id="file"
          name={name}
          type="file"
          accept=",.csv"
          onClick = {(e)=>{
            e.target.value='';
          }}
          onChange={event => {
            const files = event.currentTarget.files;
            
            handleMethod(name, files[0]);
            
          }}
        />
      </FormControl>
      <Typography className={classes.errorText}>
        <ErrorMessage name={name} />
      </Typography>
    </>
  );
};

export default FileUpload;
