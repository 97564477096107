import React, { useMemo, useRef, useState } from 'react';
import { Card, CardContent, Button, Grid, Snackbar } from '@mui/material';
import MiniDrawer from './MiniDrawer';
import MaterialTable from 'material-table';
import { getCampaigns } from 'api-config';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { makeStyles } from '@material-ui/styles';
import {
  clearApplicationValues,
  saveCampData,
  setCampaignID,
  setLoading
} from 'redux/actions/application.action';
import DialogBox from 'views/Common/Modals/Dailog';
import SelectGroup from 'components/SelectGroup';
import secureAxios from 'api-config/api';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@mui/material/Alert';
import TRASHICON from 'assets/icons/trash-2.svg';
import COPYICON from 'assets/icons/copy_icon.svg';
import EDITICON from 'assets/icons/edit_icon.svg';
import { IconButton } from '@material-ui/core';
import MessageDialog from 'views/Common/MessageDialog/MessageDialog';
import { getPagePermission } from 'role-config';
import { clearAboutValues } from 'redux/actions/aboutTest.action';
import { clearSampleSizeValues } from 'redux/actions/sampleSize.action';
import { clearIdentifyTestValues } from 'redux/actions/identifyTest.action';
import { clearIdentifyControlValues } from 'redux/actions/identifyControl.action';
import { clearCampaignValues } from 'redux/actions/campaignTemplate.action';
import ConfirmationBox from 'views/Common/ConfirmationBox';
import { setCampaignIsEdit } from 'redux/actions/homePage.action';
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    background: '#F4F5F7',
    '& .MuiTableHead-root .MuiTableCell-root': {
      backgroundColor: '#182C52 !important',
      borderRight: '1px solid #fff !important'
    },
    '& .MuiTableCell-head': {
      fontSize: '12px',
      color: '#fff'
    },
    '& .MuiTableSortLabel-root:hover': {
      color: '#fff !important'
    },
    '& .MuiIconButton-root.Mui-disabled': {
      display: 'none'
    },
    '& .MuiIconButton-root': {
      padding: '4px'
    },
    '& .MuiTableSortLabel-active': {
      color: '#fff',
      '& .MuiTableSortLabel-icon': {
        color: '#fff !important'
      }
    }
  },
  title: {
    color: '#333',
    fontSize: '2em'
  },
  button: {
    backgroundColor: '#182C52 !important'
  },
  deepDiveButton: {
    background: '#E6E7E8 !important',
    color: '#333333 !important',
    fontSize: '14px !important',
    textTransform: 'capitalize !important',
    boxShadow: 'none !important',
    padding: '2px 10px !important'
  },
  subtitle: {
    color: '#333',
    fontSize: '1.5em'
  }
}));

const getStyles = status => {
  let backgroundColor = `#24A148`;
  let color = `#fff`;
  switch (status) {
    case 'UPCOMING':
      backgroundColor = `#F0BF0F`;
      color = `#fff`;
      break;
    case 'ACTIVE':
      backgroundColor = `#F6F7F7`;
      color = `#24A148`;
      break;
    case 'COMPLETED':
      backgroundColor = `#24A148`;
      color = `#fff`;
      break;
    case 'LAUNCHED':
      backgroundColor = `#24A148`;
      color = `#fff`;
      break;
    case 'INPROGRESS':
      backgroundColor = `#E5EEFF`;
      color = `#0159FE`;
      break;
    default:
      backgroundColor = `#24A148`;
      color = `#fff`;
      break;
  }
  return {
    background: `${backgroundColor}`,
    color: `${color}`,
    fontSize: '11px',
    boxShadow: 'none',
    textTransform: 'capitalize',
    lineHeight: '18px',
    cursor: 'auto'
  };
};

const getText = status => {
  let text = status[0].toUpperCase() + status.substring(1).toLowerCase();
  switch (status) {
    case 'UPCOMING':
      text = text;
      break;
    case 'ACTIVE':
      text = text;
      break;
    case 'COMPLETED':
      text = text;
      break;
    case 'LAUNCHED':
      text = text;
      break;
    case 'INPROGRESS':
      text = 'Design in progress';
      break;
    default:
      text = text;
      break;
  }
  return text;
};
const columns = [
  { title: 'Campaign Name', field: 'name', width: 160 },
  { title: 'Campaign Start Date', field: 'startDate', width: 160 },
  { title: 'Campaign End Date', field: 'endDate', width: 160 },
  { title: 'Created Date', field: 'createdDate', width: 140 },
  {
    title: 'Created By',
    field: 'campaignOwner',
    render: rowData => rowData.campaignOwner || 'N/A'
  },
  { title: 'Test Group', field: 'testGrp' },
  { title: 'Test Type', field: 'testType' },
  {
    title: 'Status',
    field: 'status',
    width: 300,
    align: 'center',
    render: rowData => (
      <React.Fragment>
        {rowData.status && (
          <Button variant="contained" style={getStyles(rowData.status)}>
            {getText(rowData.status)}
          </Button>
        )}
      </React.Fragment>
    ),
    lookup: {
      UPCOMING: 'Schedule',
      ACTIVE: 'Active',
      COMPLETED: 'Completed',
      LAUNCHED: 'Launched',
      INPROGRESS: 'Design in progress'
    }
  }
];

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default function Home() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const tableRef = useRef();
  const { campaignList, isEdit } = useSelector(state => state.homePageReducer);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [storeOpen, setStoreOpen] = useState(false);
  const [isEditMsg, setIsEditMsg] = useState(false);
  const [confirmationBox, setConfirmationBox] = useState({
    status: false,
    data: {}
  });
  const [showNotify, setShowNotify] = useState({
    status: false,
    msg: '',
    type: 'success'
  });
  const [warningBox, setWarningBox] = useState({
    status: false,
    data: {}
  });
  useEffect(() => {
    dispatch(setLoading(true));
  }, []);
  let clearEdit;
  // useEffect(() => {
  //   if (
  //     (!isEdit.data && isEdit != false) ||
  //     isEdit.editProgresStatus == 'true'
  //   ) {
  //     setIsEditMsg(true);
  //   }
  //   // } else if (
  //   //     //   (isEdit && isEdit.data == true && isEdit.editProgresStatus == '') ||
  //   //     //   isEdit.editProgresStatus == 'false'
  //   //     // ) {
  //   //     //   let scenario = localStorage.getItem('scenario') ?? 'create-test-control';
  //   //     //   history.push(`/design-campaign/${scenario}`);
  //   //     //   localStorage.removeItem('scenario');
  //   //     //   clearEdit = setTimeout(() => {
  //   //     //     const vals = {
  //   //     //       campaignId: isEdit?.campaignId,
  //   //     //       user: localStorage.getItem('userName'),
  //   //     //       isEdit: false,
  //   //     //       editEnable: false
  //   //     //     };
  //   //     //     localStorage.setItem('campIDinEdit', isEdit?.campaignId);
  //   //     //     dispatch(setCampaignIsEdit(vals));
  //   //     //   }, 60000);
  //   //   }
  // }, [isEdit]);
  console.log(isEdit, 'reducerIsEdit');

  const handleEditDialog = () => {
    // setIsEditMsg(false);
    // const obj = {
    //   status: isEdit?.status,
    //   msg: isEdit?.msg,
    //   data: true
    // };
    // dispatch(setCampaignIsEdit(obj));
    setIsEditMsg(false);
  };

  const handleisEditMode = (rowData, editEnable) => {
    try {
      console.log(rowData, 'rowData');
      const obj = {
        campaignId: rowData?.campaignId,
        isEdit: editEnable,
        editEnable: editEnable,
        user: localStorage.getItem('userName')
      };
      secureAxios.post('/measureCampaign/setCampaignEdit', obj).then(res => {
        // dispacthData(dispatch, GETSTOREDATA, res.data.responseData);
        const currUser = localStorage.getItem('userName');
        dispatch(setCampaignIsEdit(res.data));
        // if (res.data.user == currUser) {
        //   let scenario = rowData.scenario ?? 'create-test-control';
        //   history.push(`/design-campaign/${scenario}`);
        //   localStorage.setItem('campIDinEdit', rowData?.campaignId);

        //   // setTimeout(() => {
        //   setTimeout(() => {
        //     // const vals = {
        //     //   campaignId: rowData?.campaignId,
        //     //   user: localStorage.getItem('userName'),
        //     //   isEdit: false,
        //     //   editEnable: false
        //     // };
        //     localStorage.removeItem('campIDinEdit', rowData?.campaignId);
        //     handleisEditMode(rowData, false);
        //     // dispatch(setCampaignIsEdit(vals));
        //     // }, 60000);
        //   }, 60000);
        // } else
        if (res.data.data && rowData.editProgresStatus !== 'true') {
          let scenario = rowData.scenario ?? 'create-test-control';
          history.push(`/design-campaign/${scenario}`);
          localStorage.setItem('campIDinEdit', rowData?.campaignId);

          // setTimeout(() => {
          setTimeout(() => {
            // const vals = {
            //   campaignId: rowData?.campaignId,
            //   user: localStorage.getItem('userName'),
            //   isEdit: false,
            //   editEnable: false
            // };
            localStorage.removeItem('campIDinEdit', rowData?.campaignId);
            handleisEditMode(rowData, false);
            // dispatch(setCampaignIsEdit(vals));
            // }, 60000);
          }, 1800000);
        } else {
          setIsEditMsg(true);
        }
        console.log(res, 'setEdit');
      });
    } catch (error) {
      console.log(error, 'setEditCampaign');
    }
  };

  const handleClearRedux = () => {
    dispatch(clearApplicationValues());
    dispatch(clearAboutValues());
    dispatch(clearSampleSizeValues());
    dispatch(clearIdentifyTestValues());
    dispatch(clearIdentifyControlValues());
    dispatch(clearCampaignValues());
  };
  const actions = useMemo(
    () => [
      rowData => ({
        icon: () => <img src={EDITICON} />,
        tooltip: 'Edit Campaign',
        onClick: (event, rowData) => {
          handleClearRedux();
          dispatch(setCampaignID(rowData.campaignId));
          dispatch(saveCampData(rowData));
          let scenario = rowData.scenario ?? 'create-test-control';
          history.push(`/design-campaign/${scenario}`);
          // const val = {
          //   campaignId: rowData?.campaignId,
          //   user: localStorage.getItem('userName'),
          //   isEdit: true,
          //   editEnable: true,
          //   scenario: rowData.scenario,
          //   editProgresStatus: rowData?.editProgresStatus
          // };
          // dispatch(setCampaignIsEdit(val));
          // handleisEditMode(rowData, true);
          // localStorage.setItem('scenario', rowData?.scenario);
          // const isEditFlag = localStorage.getItem('isEdit');
          // if (isEdit?.data == true && rowData.editProgresStatus != 'true') {
          //   let scenario = rowData.scenario ?? 'create-test-control';
          //   history.push(`/design-campaign/${scenario}`);
          //   setTimeout(() => {
          //     const vals = {
          //       campaignId: rowData?.campaignId,
          //       user: localStorage.getItem('userName'),
          //       isEdit: false,
          //       editEnable: false
          //     };
          //     localStorage.setItem('campIDinEdit', rowData?.campaignId);
          //     dispatch(setCampaignIsEdit(vals));
          //   }, 60000);
          // } else {
          //   setIsEditMsg(true);
          // }

          // if same user edit
          // if (
          //   JSON.parse(localStorage.getItem('userName')) ==
          //   (isEdit && isEdit.user)
          // ) {
          //   let scenario = rowData.scenario ?? 'create-test-control';
          //   history.push(`/design-campaign/${scenario}`);
          // } else {
          // }

          // setting timout for auto edit enable after timeperiod for partricular campaign

          // calling api to set camp as in Edit mode and restriting other user via redux

          // if (
          //   (isEdit?.data == true && rowData.editProgresStatus == 'false') ||
          //   rowData.editProgresStatus == null ||
          //   isEdit == false
          // ) {
          //   let scenario = rowData.scenario ?? 'create-test-control';
          //   history.push(`/design-campaign/${scenario}`);
          //   setTimeout(() => {
          //     const vals = {
          //       campaignId: rowData?.campaignId,
          //       user: localStorage.getItem('userName'),
          //       isEdit: false,
          //       editEnable: false
          //     };
          //     localStorage.setItem('campIDinEdit', rowData?.campaignId);
          //     dispatch(setCampaignIsEdit(vals));
          //   }, 60000);
          // } else {
          //   console.log('oops');
          //   setIsEditMsg(true);
          // }
        },
        disabled:
          !(rowData.status === 'INPROGRESS') ||
          rowData?.editProgresStatus == true,
        hidden: !(
          getPagePermission('editCampaign') && rowData.status === 'INPROGRESS'
        )
      }),
      rowData => ({
        icon: () => <img src={COPYICON} />,
        tooltip: 'Copy',
        onClick: (event, rowData) => {
          campNameChecker(rowData.campaignId, rowData.name);
        },
        hidden: !getPagePermission('copyCampaign')
      }),
      rowData => ({
        icon: () => <img src={TRASHICON} />,
        tooltip: 'Delete Campaign',
        onClick: (event, rowData) => {
          //Comming Soon
          setConfirmationBox({
            status: true,
            data: rowData
          });
        },
        hidden: !getPagePermission('deleteCampaign')
      }),
      rowData => ({
        icon: () =>
          (rowData.status === 'COMPLETED' || rowData.status === 'ACTIVE') && (
            <Button variant="contained" className={classes.deepDiveButton}>
              Deep Dive
            </Button>
          ),
        tooltip: 'Deep Dive',
        onClick: (event, rowData) => {
          history.push({
            pathname: '/measure-campaign-new/measure-deep-dive',
            state: {
              detail: rowData?.summaryData,
              campaignId: rowData?.campaignId,
              name: rowData?.name,
              region: rowData?.region,
              startDate: rowData?.startDate,
              endDate: rowData?.endDate
            }
          });
        },
        disabled: !(
          rowData.status === 'COMPLETED' || rowData.status === 'ACTIVE'
        )
      })
    ],
    []
  );

  useEffect(() => {
    if (campaignList?.length === 0) dispatch(getCampaigns());
    //dispatch(setLoading(false));
    setIsLoading(false)

  }, []);

  useEffect(() => {
    setTableData(campaignList);
    dispatch(setLoading(false));
    setIsLoading(false)
  }, [campaignList]);

  const duplicateCampaign = (campID, campName) => {
    try {
      if (warningBox.status) {
        setWarningBox({
          status: false,
          data: {}
        });
      }
      secureAxios
        .post(
          '/designCampaign/duplicateCampaign',
          {
            CAMPAIGN_ID: campID,
            CAMPAIGN_NAME: campName
          },
          {
            showLoading: true
          }
        )
        .then(res => {
          let responseData = res?.data;
          setShowNotify({
            status: true,
            msg: responseData.msg,
            type: responseData.status ? 'success' : 'warning'
          });
          if (res.status === 201 || responseData.status) {
            dispatch(getCampaigns());
          }
        });
    } catch (error) {
      setShowNotify({ status: true, msg: 'Catch Error!', type: 'error' });
    }
  };

  const campNameChecker = (campID, campName) => {
    try {
      campName = `${campName} - Copy`;
      secureAxios
        .post(
          '/designCampaign/camp-name-check',
          { CAMPAIGN_NAME: campName },
          {
            showLoading: true
          }
        )
        .then(res => {
          let responseData = res?.data;
          if (res.status === 200 && responseData.status) {
            duplicateCampaign(campID, campName);
          } else {
            setWarningBox({
              status: true,
              data: {
                id: campID,
                name: campName,
                suggstionName: responseData.data
              }
            });
          }
        });
    } catch (error) {
      setShowNotify({ status: true, msg: 'Catch Error!', type: 'error' });
    }
  };

  const handleDelete = () => {
    if (confirmationBox.status && confirmationBox?.data?.campaignId) {
      try {
        let { campaignId } = confirmationBox?.data;
        setConfirmationBox({ status: false, data: {} });
        secureAxios
          .delete(`/designCampaign/campaign/${campaignId}`, {
            showLoading: true
          })
          .then(response => {
            setConfirmationBox({ status: false, data: {} });
            if (response.status === 200) {
              setShowNotify({
                status: true,
                msg: 'Deleted Successfully!',
                type: 'success'
              });
              dispatch(getCampaigns());
            }
          });
      } catch (error) {
        setShowNotify({ status: true, msg: 'Catch Error!', type: 'error' });
      }
    }
  };

  const handleCloseNotify = () => {
    setShowNotify({
      status: false,
      msg: '',
      type: 'success'
    });
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseNotify}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className={classes.root}>
      <Card variant="outlined" className={classes.card}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ textAlign: 'right' }}>
              {getPagePermission('createStore') && (
                <Button
                  className={classes.button}
                  type="button"
                  variant="contained"
                  onClick={() => {
                    dispatch(setLoading(true));
                    setStoreOpen(true);
                    setTimeout(() => {
                      dispatch(setLoading(false));
                    }, 3000);
                  }}>
                  Create Store Group
                </Button>
              )}
              {` `}
              <Button
                className={classes.button}
                type="button"
                variant="contained"
                onClick={() => history.push('/productgroup')}>
                Create Product Group
              </Button>
              {` `}
              {getPagePermission('createCampaign') && (
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={() => setShow(true)}>
                  Create New Campaign
                </Button>
              )}
            </Grid>
            <Grid item xs={12}>
              <MaterialTable
                tableRef={tableRef}
                isLoading={isLoading}
                columns={columns}
                data={tableData}
                title={<span className={classes.title}>Campaign Overview</span>}
                actions={actions}
                options={{
                  actionsColumnIndex: -1,
                  filtering: true,
                  virtualization: true
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {show && <MiniDrawer open={show} handleClose={() => setShow(false)} />}
      {storeOpen && (
        <DialogBox
          open={storeOpen}
          buttonStatus={false}
          header={'Select Stores Group'}
          handleClose={() => {
            setStoreOpen(false);
          }}
          width={700}
          contentStyle={{ marginBottom: '70px' }}>
          <SelectGroup
            setUpdateGrpVal={() => {}}
            handleAudModal={() => {
              setStoreOpen(false);
            }}
          />
        </DialogBox>
      )}

      {confirmationBox.status && (
        <MessageDialog
          open={confirmationBox.status}
          buttonType={false}
          close={() => {
            setConfirmationBox({
              status: false,
              data: {}
            });
          }}
          handleButton={handleDelete}
          type={'error'}
          message={`Are you sure you want to delete campaign "${confirmationBox?.data?.name}" ?`}
        />
      )}
      {warningBox.status && (
        <ConfirmationBox
          open={warningBox.status}
          handleClose={() => {
            setWarningBox({
              status: false,
              data: {}
            });
          }}
          handleSubmit={() =>
            duplicateCampaign(
              warningBox?.data?.id,
              warningBox?.data?.suggstionName
            )
          }
          title={`The campaign name "${warningBox?.data?.name}" already exists!`}
          content={`Click 'okay' to create the campaign with name "${warningBox?.data?.suggstionName}."`}
          subContent={`Would you like to continue ?`}
          // submitBtn={`Okay`}
        />
      )}

      {isEditMsg && (
        <MessageDialog
          type={'error'}
          open={isEditMsg}
          message={isEdit?.msg}
          close={handleEditDialog}
        />
      )}

      {showNotify && showNotify.status && (
        <Snackbar
          open={showNotify.status}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          autoHideDuration={6000}
          onClose={handleCloseNotify}
          action={action}>
          <Alert
            onClose={handleCloseNotify}
            severity={`${showNotify.type}`}
            sx={{ width: '100%' }}>
            {`${showNotify.msg ?? 'This is a success message!'}`}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}
