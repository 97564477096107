import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
    },
    buttonPorperty: {
        background: "#182C52 !important",
        color: "#fff !important",
        borderRadius: '4px !important',
        height: "30px !important",
        textTransform: "capitalize !important",
        "&:hover": {
            backgroundColor: "#031330 !important",
        },
    },
    buttonPorpertyCancel: {
        background: "transparent !important",
        border: "1px solid #000 !important",
        color: "#000 !important",
        borderRadius: '4px !important',
        height: "30px !important",
        textTransform: "capitalize !important",
        "&:hover": {
            backgroundColor: "#fff !important",
        },
    },
    title: {
        color: '#000',
        fontSize: '20px !important',
        fontWeight: '400 !important'
    },
    heading: {
        color: '#000',
        fontSize: '18px',
        fontWeight: 400
    },
    subtitle: {
        color: '#333',
        fontSize: '14px',
        marginTop: '10px !important'
      }
}));

const ConfirmationBox = (props) => {
    const classes = useStyles();
    return (
        <Dialog
            open={props.open}
            onClose={(event, reason) => {
                if (reason !== "backdropClick") {
                    props.handleClose()
                }
            }}
            classes={{ root: classes.root }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {(props.title) && (<DialogTitle id="alert-dialog-title" className={classes.title}>
                {props.title}
            </DialogTitle>)}
            {(props.content || props.subContent) && (<DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {(props.content) && (<Typography component={'h4'} className={classes.heading}>{props.content ?? ''}</Typography>)}
                    {(props.subContent) && (<Typography component={'p'} className={classes.subtitle}>{props.subContent}</Typography>)}
                </DialogContentText>
            </DialogContent>)}
            <DialogActions>
                {(props.handleClose) && (<Button variant="contained" onClick={props.handleClose} className={classes.buttonPorpertyCancel}>{props.cancelBtn ?? 'Cancel'}</Button>)}
                {(props.handleSubmit) && (<Button variant="contained" onClick={props.handleSubmit} className={classes.buttonPorperty} autoFocus>{props.submitBtn ?? 'Okay'}</Button>)}
            </DialogActions>
        </Dialog>
    );
}
export default ConfirmationBox