import {
  MEASURETESTID,
  MEASURECAMPAIGNLEVEL,
  MEASUREEXCLUDEOUTLIER
} from '../types';

const initialState = {
  measureTestId: 1,
  measureCampaignLevel: '',
  measureExcludeOutlier: false
};
const measureReducer = (state = initialState, action) => {
  switch (action.type) {
    case MEASURETESTID:
      return {
        ...state,
        measureTestId: action.payload
      };
    case MEASURECAMPAIGNLEVEL:
      return {
        ...state,
        measureCampaignLevel: action.payload
      };
    case MEASUREEXCLUDEOUTLIER:
      return {
        ...state,
        measureExcludeOutlier: action.payload
      };
    default:
      return state;
  }
};

export default measureReducer;
