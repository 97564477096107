import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Typography, Box } from "@material-ui/core";
import { ErrorMessage } from "formik";
import Flatpickr from "react-flatpickr";

const useStyles = makeStyles((theme) => ({
  errorText: {
    color: "red",
    fontSize: 12,
  },
}));

const FlatDatePicker = (props) => {
  const classes = useStyles();
  require("flatpickr/dist/themes/material_orange.css");
  const { name, label, handleMethod, value,placeHolder, disable, mode, minDate ,maxDate } = props;

  return (
    <Box className={"floatingField"}>
      <Flatpickr
        name={name}
        value={value}
        options={{
          altInputClass: "floatingInput",
          altInput: true,
          altFormat: "d M Y",
          locale: {
            firstDayOfWeek: 1,
          },
          mode: mode,
          showMonths: mode === "range" ? 2 : 1,
          disable: disable ? disable : [],
          minDate: minDate,
          maxDate: maxDate,
          dateFormat: "Y-m-d",
          onChange: function(val) {
            handleMethod(name, val);
          },
        }}
      />
      {label ? <label className={"floatingLabel"}>{label}</label> : ""}
      <Typography className={classes.errorText}>
        <ErrorMessage name={name} />
      </Typography>
    </Box>
  );
};

export default FlatDatePicker;
