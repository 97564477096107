import React, { useState } from 'react';
import Carousel from 'react-simply-carousel';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { makeStyles } from '@material-ui/styles';
import { Typography, Box } from '@material-ui/core';
import DialogBox from 'views/Common/Modals/Dailog';
import {
  numberWithCommas,
  retriveMetricFromStr
} from 'views/Common/Utils/ClientUtil';
import StackedBar from 'views/Common/Charts/HorizontalStackedBarChart';
import BarChart from 'views/Common/Charts/BarChart'
import ProgressBar from 'views/Common/Charts/ProgressBar';
import MediaQuery from 'views/Common/CustomHook/MediaQuery';
import MeasureStackedChart from '../MeasureStackedChart/MeasureStackedChart';

const useStyles = makeStyles(theme => ({
  block: {
    width: 400,
    height: 200,
    textAlign: 'left',
    boxSizing: 'border-box',

    background: '#ffffff',
    cursor: 'pointer',
    '& h4': { color: '#333333', marginBottom: 10 },
    '& h5': { color: '#333333', minHeight: 55 }
  },
  blockLarge: {
    width: 300,
    height: 300,
    textAlign: 'left',
    boxSizing: 'border-box',

    background: '#ffffff',
    cursor: 'pointer',
    '& h4': { color: '#333333', marginBottom: 10 },
    '& h5': { color: '#333333', minHeight: 55 }
  },
  innerBlock: {
    margin: '10px !important',
    padding: 20,
    boxShadow: '2.73577px 3.6477px 11.855px rgb(0 0 0 / 8%)',
    borderRadius: 6.38347,
  },
  value: { color: '#24A148' },
  highlight: { color: '#8E44AD' },
  chartValues: {
    marginTop: 10
  },
  valBM: { color: '#013598', marginRight: 30 },

  info: {
    padding: '40px 0 !important',
    textAlign: 'center',
    '& h3': {
      lineHeight: 10
    }
  }
}));

const box ={
  width:"300px",
  height:"210px"
}

const MeasureCarousel = props => {
  const classes = useStyles();
  const isResponsive = MediaQuery("(max-width:1225px)")
  const [activeSlide, setActiveSlide] = useState(0);
  const { data, view, type } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState('');
  const handleCarouselDialogClose = () => {
    setDialogOpen(false);
    // setDialogData("");
  };
  const handleDialogOpen = val => {
    setDialogOpen(true);
    setDialogData(val);
  };

   

 
  return (
    <>
      <Carousel
        updateOnItemClick
        infinite={false}
        containerProps={{
          style: {
            width: '100%',
            justifyContent: 'space-between'
          }
        }}
        activeSlideIndex={activeSlide}
        activeSlideProps={{}}
        onRequestChange={setActiveSlide}
        forwardBtnProps={{
          children: <ChevronRightIcon />,
          style: {
            width: 24,
            height: 24,
            minWidth: 24,
            alignSelf: 'center',
            background: '#ffffff',
            border: 0,
            position: 'absolute',
            zIndex: 9,
            right: '1%',
            color: '#182C52',
            cursor: 'pointer'
          }
        }}
        backwardBtnProps={{
          children: <ChevronLeftIcon />,
          style: {
            width: 24,
            height: 24,
            minWidth: 24,
            alignSelf: 'center',
            background: '#ffffff',
            border: 0,
            position: 'absolute',
            zIndex: 9,
            color: '#182C52',
            cursor: 'pointer'
          }
        }}
        itemsToShow={3}
        speed={400}>
        {data ? (
          data?.map((item, index) => (
            <Box
              component="div"
              // style={isResponsive?{style.box}}
              className={isResponsive?classes.block:classes.blockLarge}
              key={index}
              onClick={e => {
                handleDialogOpen(item);
              }}>
              <Box component="div" className={classes.innerBlock}>
                <Typography component="h4" variant="subtitle1" style={{textTransform:"capitalize"}}>
                  {retriveMetricFromStr(item?.segmentName)}
                </Typography>
                <Typography component="h5" variant="body2">
                 
                  {item?.segmentComment === null ? 'NA' : item?.segmentComment}
                
                </Typography>
                {/* <Typography
                  component="h6"
                  variant="body2"
                  className={classes.chartValues}>
                  <Typography component="span" className={classes.valBM}>
                    {view !== 'purchaseBehavior' ? 'Benchmark :' : 'Pre :'}{' '}
                    {numberWithCommas(item?.benchmark?.toFixed(2))}
                  </Typography>
                  <Typography
                    component="span"
                    style={{
                      color:
                        item?.testDuringValue < item?.benchmark
                          ? '#E01F29'
                          : '#24A148'
                    }}>
                    {item?.testDuringValue !== null
                      ? numberWithCommas(item?.testDuringValue?.toFixed(2))
                      : ''}
                  </Typography>
                </Typography>  // For HorizontalStackedBarChart*/} 
                {/* <StackedBar
                  data={item}
                  viewStatus={view}
                  type={type}
                  colors={
                    item?.testDuringValue < item?.benchmark
                      ? ['#013598', '#E01F29']
                      : ['#013598', '#24A148']
                  }
                />     // For HorizontalStackedBarChart*/}          
                <BarChart
                data={item}
                viewStatus={view}
                type={type}
                colors={
                  item?.testDuringValue < item?.benchmark
                    ? ['#013598', '#E01F29']
                    : ['#013598', '#24A148']
                }/>
              </Box>
            </Box>
          ))
        ) : (
          <Typography component="h3" variant="h3" className={classes.info}>
            {'No records to display'}
          </Typography>
        )}
        {/* <Box
          component="div"
          className={classes.block}
          style={{
            textAlign: "center",
            lineHeight: 9,
          }}
        >
          {"No more data to display"}
        </Box> */}
        <DialogBox
          open={dialogOpen}
          width={'medium'}
          handleClose={handleCarouselDialogClose}
          header={
            dialogData?.segmentName
              ? retriveMetricFromStr(dialogData?.segmentName)
              : 'NA'
          }
          children={
            <ProgressBar data={dialogData} viewStatus={view} />
          }></DialogBox>
      </Carousel>
    </>
  );
};

export default MeasureCarousel;
