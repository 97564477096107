import React, { Component, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/styles';
import theme from './theme';
// import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import Routes from './routes';

import { useDispatch, useSelector } from 'react-redux';
import { setCampaignIsEditDis } from 'redux/actions/homePage.action';

const browserHistory = createBrowserHistory();

// export default class App extends Component {
//   render() {
//     return (
//       <ThemeProvider theme={theme}>
//         <BrowserRouter history={browserHistory}>
//           <Routes />
//         </BrowserRouter>
//       </ThemeProvider>
//     );
//   }
// }

const App = () => {
  const dispatch = useDispatch();
  const { campaignList, isEdit } = useSelector(state => state.homePageReducer);

  // // const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);
  // useEffect(() => {
  //   const handleTabClose = event => {
  //     event.preventDefault();

  //     console.log('beforeunload event triggered');
  //     if (localStorage.getItem('campIDinEdit')) {
  //       const vals = {
  //         campaignId: localStorage.getItem('campIDinEdit'),
  //         user: localStorage.getItem('userName'),
  //         isEdit: false,
  //         editEnable: false
  //       };
  //       // console.log(
  //       //   localStorage.getItem('userName') == isEdit?.user,
  //       //   'user edit'

  //       dispatch(setCampaignIsEditDis(vals));
  //     }

  //     return (event.returnValue = 'Are you sure you want to exit?');
  //   };

  //   window.addEventListener('beforeunload', handleTabClose);
  //   // window.addEventListener('beforeunload', ev => {
  //   //   ev.preventDefault();
  //   //   console.log('runned ===>>>>>>>>>>>>>>>>>>>');
  //   //   return (ev.returnValue = 'Are you sure you want to close?');
  //   // });
  //   window.addEventListener('unload', handleTabClose);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleTabClose);
  //   };
  // });

  // const handleTabClose = event => {
  //   event.preventDefault();

  //   console.log('beforeunload event triggered');

  //   return (event.returnValue = 'Are you sure you want to exit?');
  // };
  // useBeforeunload(() => handleTabClose());

  // useEffect(() => {
  //   window.sessionStorage.setItem('sessionStart', true);
  // }, []);
  return (
    <>
      <ThemeProvider theme={theme}>
        <BrowserRouter history={browserHistory}>
          <Routes />
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
};
export default App;
