// import React, { useEffect, useState } from 'react';
// import ReactApexCharts from 'react-apexcharts';
// import palette from '../../../theme/palette';
// import { Paper, Typography } from '@material-ui/core';
// import { makeStyles, withStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles(theme => ({
//   tblHeading: {
//     color: theme.palette.text.secondary,
//     padding: '12px 12px 0'
//   }
// }));

// const BarGroup = props => {
//   const classes = useStyles();
//   const [chartInit, setChartInit] = useState();
//   const colors = palette.chartColors.trends;

//   const { chartTitle, xAxisTitle, yAxisTitle } = props;

//   useEffect(() => {
//     const testCount = [];
//     const controlCount = [];
//     const xAxis = [];
//     props.data.forEach(obj => {
//       testCount.push(obj.TEST_COUNT);
//       controlCount.push(obj.CONTROL_COUNT)
//       xAxis.push(obj.BUCKET)
//     })
//     setChartInit({
//       series: [
//         {
//           name: '% Test',
//           data: testCount
//         },
//         {
//           name: '% Control',
//           data: controlCount
//         }
//       ],
//       options: {
//         colors: colors,
//         chart: {
//           height: 500,
//           type: 'bar',
//           toolbar: {
//             show: false
//           }
//         },
//         grid: {
//           show: true
//         },
//         plotOptions: {
//           bar: {
//             dataLabels: {
//               position: 'top'
//             }
//           }
//         },
//         dataLabels: {
//           enabled: true,
//           formatter: function (val) {
//             return val === null ? '' : val;
//           },
//           offsetY: -20,
//           style: {
//             fontSize: '12px',
//             colors: ['#304758']
//           }
//         },
//         xaxis: {
//           show: true,
//           categories: xAxis,
//           axisTicks: {
//             show: false
//           },
//           title: {
//             text: xAxisTitle ? xAxisTitle : '',
//             style: {
//               fontSize: '12px',
//               fontFamily: 'Helvetica, Arial, sans-serif',
//               fontWeight: 100,
//               cssClass: 'apexcharts-yaxis-title'
//             }
//           }
//         },
//         yaxis: {
//           show: true,
//           title: {
//             text: yAxisTitle ? yAxisTitle : '',
//             style: {
//               fontSize: '12px',
//               fontFamily: 'Helvetica, Arial, sans-serif',
//               fontWeight: 100,
//               cssClass: 'apexcharts-yaxis-title'
//             }
//           }
//         },
//         /*tooltip: {
//             y: {
//               formatter: function(y) {
//                 return y + '%';
//               }
//             }
//           },*/
//         legend: {
//           show: true,
//           position: 'bottom',
//           horizontalAlign: 'center'
//         }
//       }
//     });
//   }, []);

//   return (
//     <React.Fragment>
//       {chartInit && (
//         <>
//           <Typography className={classes.tblHeading} variant="h6">
//             {chartTitle}
//           </Typography>
//           <ReactApexCharts
//             options={chartInit.options}
//             series={chartInit.series}
//             type="bar"
//             height={200}
//           />
//         </>
//       )}
//     </React.Fragment>
//   );
// };
// export default BarGroup;
import React, { useEffect, useState } from 'react';
import ReactApexCharts from 'react-apexcharts';
import palette from '../../../theme/palette';
import { Paper, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { toCurrencyFormat, numberWithCommas } from '../Utils/ClientUtil';

const useStyles = makeStyles(theme => ({
  tblHeading: {
    color: theme.palette.text.secondary,
    padding: '12px 12px 0'
  }
}));

const sortAxis = (xAxis, yAxis) => {
  if (
    !(
      Array.isArray(xAxis) &&
      xAxis.length > 0 &&
      Array.isArray(yAxis) &&
      yAxis.length > 0
    )
  ) {
    return { xAxis: [], yAxis: [] };
  }
  let testArr = yAxis?.find(e => e.name == 'Test Store')?.data;
  let controlArr = yAxis?.find(e => e.name == 'Control Stores')?.data;
  let combinedArr = [];

  for (let i = 0; i < testArr?.length; i++) {
    combinedArr.push({
      test: testArr[i],
      control: controlArr[i],
      x: xAxis[i]
    });
  }

  combinedArr.sort((a, b) => Number(a.test) - Number(b.test));

  testArr = [];
  controlArr = [];
  xAxis = [];

  for (const obj of combinedArr) {
    testArr.push(obj.test);
    controlArr.push(obj.control);
    xAxis.push(obj.x);
  }

  return {
    xAxis: xAxis,
    yAxis: [
      {
        name: 'Control Stores',
        data: controlArr
      },
      {
        name: 'Test Store',
        data: testArr
      }
    ]
  };
};

const BarGroup = props => {
  const classes = useStyles();
  const [chartInit, setChartInit] = useState();
  const colors = palette.chartColors.trends;

  const { xAxisTitle, yAxisTitle, data, xAxis, sorting } = props;

  // const sortedAxis = sortAxis(xAxis, data)
  // const sortedAxis = sortAxis(xAxis, data)

  useEffect(() => {
    setChartInit({
      series: data ? data : [],
      options: {
        colors: ['#013598', '#E01F29'],
        chart: {
          type: 'bar',
          toolbar: {
            show: false
          }
        },
        grid: {
          show: false,
          padding: {
            left: 0,
            top: -20,
            bottom: 0
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '50%',
            dataLabels: {
              position: 'top'
            }
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function(val) {
            return val === null ? '' : `${val}`;
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ['#304758']
          }
        },
        xaxis: {
          show: true,
          categories: xAxis ? xAxis : [],
          // categories: xAxis ? (sorting ? sortedAxis?.xAxis : xAxis) : [],
          axisTicks: {
            show: false
          },
          title: {
            text: xAxisTitle ? xAxisTitle : '',
            style: {
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 100,
              cssClass: 'apexcharts-yaxis-title'
            }
          }
        },
        yaxis: {
          show: true,
          title: {
            text: yAxisTitle ? yAxisTitle : '',
            style: {
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 100,
              cssClass: 'apexcharts-yaxis-title'
            }
          },
          axisBorder: {
            show: true
          },
          labels: {
            formatter: function(value) {
              return toCurrencyFormat(value);
            }
          }
        },
        tooltip: {
          y: {
            formatter: function(y) {
              return numberWithCommas(y.toFixed(2));
            }
          }
        },
        legend: {
          show: false,
          position: 'bottom',
          horizontalAlign: 'center'
        }
      }
    });
  }, []);

  return (
    <React.Fragment>
      {chartInit && (
        <>
          <ReactApexCharts
            options={chartInit?.options}
            series={chartInit?.series}
            type="bar"
            height={290}
          />
        </>
      )}
    </React.Fragment>
  );
};
export default BarGroup;
