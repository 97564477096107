import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Paper, Tab, Tabs, AppBar } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { setTabNavigation } from '../../../redux/actions/application.action';

const useStyles = makeStyles(theme => ({
  tabArea: {
    margin: theme.spacing(0, 0),
    '& button': {
      '& span': {
        textTransform: 'capitalize'
      }
    }
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <>{children}</>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const TabSection = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const application = useSelector(state => ({
    ...state.application
  }));
  const { tabNavigation } = application;
  const {
    tabItems,
    tabNavclick,
    variant,
    selectedMenu,
    outlierChecked,
    campaign_id
  } = props;
  const handleChange = (event, newValue) => {
    dispatch(setTabNavigation(newValue));
  };

  return (
    <AppBar position="static" className={classes.tabArea} elevation={0}>
      <Paper square elevation={0}>
        <Tabs
          value={tabNavigation}
          onChange={tabNavclick ? handleChange : ''}
          variant={variant}
          indicatorColor="primary"
          textColor="primary">
          {tabItems.map(item => (
            <Tab key={item.id} label={item.title} {...a11yProps(tabNavigation)} />
          ))}
        </Tabs>
        <Suspense fallback={'Loading...'}>
          <TabPanel value={tabNavigation} index={0}>
            <props.Tab1
              outlierChecked={outlierChecked}
              campaign_id={campaign_id}
            />
          </TabPanel>
          <TabPanel value={tabNavigation} index={1}>
            <props.Tab2
              outlierChecked={outlierChecked}
              campaign_id={campaign_id}
            />
          </TabPanel>
          <TabPanel value={tabNavigation} index={2}>
            <props.Tab3
              outlierChecked={outlierChecked}
              campaign_id={campaign_id}
            />
          </TabPanel>
          <TabPanel value={tabNavigation} index={3}>
            <props.Tab4
              outlierChecked={outlierChecked}
              campaign_id={campaign_id}
            />
          </TabPanel>
          {selectedMenu === 'MeasureCampaign' && props.Tab5 ? (
            <>
              <TabPanel value={tabNavigation} index={4}>
                <props.Tab5 />
              </TabPanel>
            </>
          ) : (
            ''
          )}
        </Suspense>
      </Paper>
    </AppBar>
  );
};

export default TabSection;
