import React, { useEffect, useState, useMemo } from 'react';
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  ZoomableGroup,
  Annotation
} from 'react-simple-maps';

import datas from './worldContinent.json';
import './styleMap.css';
import secureAxios from 'api-config/api';
import { Box, makeStyles, Paper, Tooltip, withStyles } from '@material-ui/core';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useDispatch, useSelector } from 'react-redux';

const geoUrl =
  'https://raw.githubusercontent.com/deldersveld/topojson/master/world-continents.json';

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',

    color: 'rgba(0, 0, 0, 0.87)',

    maxWidth: 220,

    fontSize: theme.typography.pxToRem(12),

    border: '1px solid #dadde9'
  }
}))(Tooltip);
const containerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh'
};

const boxStyle = {
  backgroundColor: '#f2f2f2',
  border: '1px solid #ddd',
  borderRadius: '5px',
  padding: '20px',
  textAlign: 'center'
};

const liftStyle = {
  fontWeight: 'bold',
  fontSize: '20px'
};

const infoStyle = {
  marginBottom: '10px'
};

const useStyles = makeStyles(theme => ({
  dataTable: {
    '& .MuiPaper-elevation2': {
      boxShadow: 'none'
    },

    width: '92%',

    margin: '0 auto'
  },

  Test_Control_Sig: {
    height: '15px',

    width: '15px',

    backgroundColor: '#8B0000',

    borderRadius: '50%',

    display: 'inlineBlock',

    marginLeft: '1%'
  },
  Test_Control: {
    height: '15px',

    width: '15px',

    backgroundColor: '#006400',

    borderRadius: '50%',

    display: 'inlineBlock',

    marginLeft: '11%'
  },

  Test_Store: {
    height: '15px',

    width: '15px',

    backgroundColor: '#ffff00',

    borderRadius: '50%',

    display: 'inlineBlock',

    marginLeft: '1%'
  },
  Test_Control_Dg: {
    height: '15px',

    width: '15px',

    backgroundColor: '#006400',

    borderRadius: '50%',

    display: 'inlineBlock',

    marginLeft: '5%'
  },
  Test_Control_Ng: {
    height: '15px',

    width: '15px',

    backgroundColor: '#00FF00',

    borderRadius: '50%',

    display: 'inlineBlock',

    marginLeft: '5%'
  },
  Test_Control_Lg: {
    height: '15px',

    width: '15px',

    backgroundColor: '#ADFF2F',

    borderRadius: '50%',

    display: 'inlineBlock',

    marginLeft: '5%'
  },
  Test_Control_Dr: {
    height: '15px',

    width: '15px',

    backgroundColor: '#8B0000',

    borderRadius: '50%',

    display: 'inlineBlock',

    marginLeft: '5%'
  },
  Test_Control_Nr: {
    height: '15px',

    width: '15px',

    backgroundColor: '#FF0000',

    borderRadius: '50%',

    display: 'inlineBlock',

    marginLeft: '5%'
  },
  Test_Control_Lr: {
    height: '15px',

    width: '15px',

    backgroundColor: '#FFA07A',

    borderRadius: '50%',

    display: 'inlineBlock',

    marginLeft: '5%'
  }
}));

const MapChart = ({
  setTooltipContent,
  setMapData,
  mapData,
  campaign_id,
  outlierChecked,
  filters,
  store_id,
  drilldownFilter
}) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [position, setPosition] = useState({ coordinates: [-90, 25], zoom: 4 });
  const [scaleFactor, setScaleFactor] = useState(4);
  const [mapFlag, setmapFlag] = useState(false);
  const [stateMapvalue, setstateMapvalue] = useState(0);
  const dispatch = useDispatch();
  const [mapData1, setmapData1] = useState([]);
  useEffect(() => {
    setData(mapData);
  }, [mapData]);

  function handleZoomIn() {
    if (position.zoom >= 4) return;
    setPosition(pos => ({ ...pos, zoom: pos.zoom * 2 }));
  }

  function handleZoomOut() {
    if (position.zoom <= 1) return;
    setPosition(pos => ({ ...pos, zoom: pos.zoom / 2 }));
  }

  function handleMoveEnd(position) {
    setPosition(position);
  }

  const handleMouseEnter = async (campaign_id, storeId, kpiRowId) => {
    dispatch({
      type: 'LOADING',
      payload: true
    });
    try {
      // Make the POST call
      const response = await secureAxios.post(
        '/measureCampaign/getMeasureMapDatas',
        {
          campaign_id,
          storeId,
          outlierValue: outlierChecked,
          filters: filters,
          kpiRowId
        }
      );

      // Process the response and set the tooltip content and enable/disable
      const filteredDatagainstStoreId = response.data.mapStoreData;
      // const tooltipEnabled = response.data.enabled;
      if (filteredDatagainstStoreId.length) {
        filteredDatagainstStoreId.map((val, ind) => {
          const filterdData = response.data.filterData.filter(
            d => d.storeId === val.TEST_STORE_ID
          );
          // if (filterdData && filterdData[0]) {
          //   filteredDatagainstStoreId[ind]['abs_lift'] =
          //     filterdData[0].abs_lift;
          //   filteredDatagainstStoreId[ind]['significance'] =
          //     filterdData[0].significance;
          // }
          response.data.data.map((value, index) => {
            if (val.control_store_id === value.store_id) {
              filteredDatagainstStoreId[ind].test_control_flag =
                value.test_control_flag;
              filteredDatagainstStoreId[ind].data = value;
            }
            if (val.TEST_STORE_ID === value.store_id) {
              const filterdData = response.data.filterData.filter(
                d => d.storeId === val.TEST_STORE_ID
              );
              if (filterdData && filterdData.length > 0) {
                value.abs_lift = filterdData[0].abs_lift;
                value.significance = filterdData[0].significance;
                value.pct_lift = filterdData[0].pct_lift;
                /// value.significance = filterdData[0].significance;
              }
              filteredDatagainstStoreId.push(value);
            }
          });
        });
      }

      let absLift = filteredDatagainstStoreId.abs_lift;
      let sigficancedata = filteredDatagainstStoreId.significance;
      let pctliftdata = filteredDatagainstStoreId.pct_lift;
      // setmapData1(filteredDatagainstStoreId);
      //setMapData(filteredDatagainstStoreId);
      setData(filteredDatagainstStoreId);
      setmapFlag(true);
      setstateMapvalue(2);
      //setTooltipContent(tooltipEnabled ? storeId : '');
    } catch (error) {
      console.error('Error occurred while making the POST call:', error);
    }
    dispatch({
      type: 'LOADING',
      payload: false
    });
  };
  // const handleMouseLeave = async () => {
  //   setData(mapData);
  // };

  // // Define the minimum and maximum circle sizes you want
  // const minCircleSize = 1; // Minimum circle size (default)
  // const maxCircleSize = 6; // Maximum circle size
  // const maxCircleSizes = 3;
  // const maxCircleSizess = 5;
  // console.log(data, 'data-210');
  // // Filter out invalid data with missing or non-numeric abs_lift values
  // const filteredData = data.filter(
  //   item => typeof item.abs_lift === 'number' && !isNaN(item.abs_lift)
  // );

  // // Calculate minAbsLift and maxAbsLift from filteredData
  // const minAbsLift = Math.min(...filteredData.map(item => item.abs_lift));
  // const maxAbsLift = Math.max(...filteredData.map(item => item.abs_lift));
  // console.log(minAbsLift, 'minAbsLift');
  // console.log(maxAbsLift, 'maxAbsLift');
  // // Define the scaling function with a default size of 4
  // const scaleCircleSize = value => {
  //   console.log(value, 'value-217');
  //   console.log(stateMapvalue, 'stateMapvalue-223');

  //   // If the value is not a number (e.g., NaN), return the default size (4)
  //   if (isNaN(value)) {
  //     if (stateMapvalue === 1) {
  //       console.log(value, 'Value is NaN and stateMapvalue is 1');
  //     } else if (stateMapvalue === 0 || stateMapvalue === 2) {
  //       console.log(value, 'Value is NaN and stateMapvalue is 0 or 2');
  //     }
  //     return minCircleSize;
  //   }
  //   if (minAbsLift === maxAbsLift) {
  //     // Handle the case where minAbsLift and maxAbsLift are equal
  //     if ((minAbsLift >= 0 && maxAbsLift < 30) || minAbsLift < 0) {
  //       if (stateMapvalue === 1) {
  //         console.log(
  //           value,
  //           'minAbsLift and maxAbsLift are equal, and stateMapvalue is 1'
  //         );
  //       } else if (stateMapvalue === 0 || stateMapvalue === 2) {
  //         console.log(
  //           value,
  //           'minAbsLift and maxAbsLift are equal, and stateMapvalue is 0 or 2'
  //         );
  //       }
  //       return maxCircleSizes;
  //     } else if (minAbsLift >= 30 && maxAbsLift < 60) {
  //       if (stateMapvalue === 1) {
  //         console.log(
  //           value,
  //           'minAbsLift and maxAbsLift are equal, and stateMapvalue is 1'
  //         );
  //       } else if (stateMapvalue === 0 || stateMapvalue === 2) {
  //         console.log(
  //           value,
  //           'minAbsLift and maxAbsLift are equal, and stateMapvalue is 0 or 2'
  //         );
  //       }
  //       return maxCircleSizess;
  //     } else if (minAbsLift >= 60) {
  //       if (stateMapvalue === 1) {
  //         console.log(
  //           value,
  //           'minAbsLift and maxAbsLift are equal, and stateMapvalue is 1'
  //         );
  //       } else if (stateMapvalue === 0 || stateMapvalue === 2) {
  //         console.log(
  //           value,
  //           'minAbsLift and maxAbsLift are equal, and stateMapvalue is 0 or 2'
  //         );
  //       }

  //       return maxCircleSize;
  //     }
  //   }

  //   // Scale the value to the range of minCircleSize to maxCircleSize
  //   const scaledValue =
  //     minCircleSize +
  //     ((value - minAbsLift) / (maxAbsLift - minAbsLift)) *
  //       (maxCircleSize - minCircleSize);

  //   // Return the scaled value, ensuring it stays within the minCircleSize and maxCircleSize range
  //   return Math.min(maxCircleSize, Math.max(minCircleSize, scaledValue));
  // };
  // Define the minimum and maximum circle sizes you want
  const circleSizes = [1, 2, 3, 4, 5, 6, 7, 8]; // Define your fixed circle sizes for the ranges
  const posRangeBounds = [2, 5, 10, 15, 20, 25, 30]; // Define your range bounds
  const minCircleSize = 1; // Minimum circle size (default)
  const maxCircleSize = 6; // Maximum circle size
  const maxCircleSizes = 3;
  const maxCircleSizess = 5;
  const rangeToCircleSize = {
    [0]: 1, // Range: 0-2
    [2]: 2, // Range: 2-5
    [5]: 3, // Range: 5-10
    [10]: 4, // Range: 10-15
    [15]: 5, // Range: 15-20
    [20]: 6, // Range: 20-25
    [25]: 7, // Range: 25-30
    [30]: 8 // Range: 30+
  };

  // Filter out invalid data with missing or non-numeric abs_lift values
  const filteredData = data.filter(
    item => typeof item.pct_lift === 'number' && !isNaN(item.pct_lift)
  );

  // Calculate minAbsLift and maxAbsLift from filteredData
  const minAbsLift = Math.min(...filteredData.map(item => item.pct_lift));
  const maxAbsLift = Math.max(...filteredData.map(item => item.pct_lift));

  // Define the scaling function based on the mapping of range bounds to fixed circle sizes
  const scaleCircleSize = value => {
    if (isNaN(value)) {
      return rangeToCircleSize[0]; // Return the smallest circle size for NaN values
    }

    if (minAbsLift === maxAbsLift) {
      if (minAbsLift >= 0 && maxAbsLift < 2) {
        return 1;
      } else if (
        (minAbsLift >= 2 && maxAbsLift < 5) ||
        (minAbsLift <= -2 && maxAbsLift > -5)
      ) {
        return 2;
      } else if (
        (minAbsLift >= 5 && maxAbsLift < 10) ||
        (minAbsLift <= -5 && maxAbsLift > -10)
      ) {
        return 3;
      } else if (
        (minAbsLift >= 10 && maxAbsLift < 15) ||
        (minAbsLift <= -10 && maxAbsLift > -15)
      ) {
        return 4;
      } else if (
        (minAbsLift >= 15 && maxAbsLift < 20) ||
        (minAbsLift <= -15 && maxAbsLift > -20)
      ) {
        return 5;
      } else if (
        (minAbsLift >= 20 && maxAbsLift < 25) ||
        (minAbsLift <= -20 && maxAbsLift > -25)
      ) {
        return 6;
      } else if (
        (minAbsLift >= 25 && maxAbsLift < 30) ||
        (minAbsLift <= -25 && maxAbsLift > -30)
      ) {
        return 7;
      } else if (minAbsLift >= 30 || minAbsLift <= -30) {
        return 8;
      }
    }

    // Find the appropriate range for the value
    if (value > 0) {
      let rangeIndex = 0;
      while (
        rangeIndex < posRangeBounds.length &&
        value >= posRangeBounds[rangeIndex]
      ) {
        rangeIndex++;
      }

      // Use the mapped circle size for the range
      return rangeToCircleSize[posRangeBounds[rangeIndex - 1]];
    } else {
      let scale = 1;
      if (value <= -1 && value > -5) {
        scale = 2;
      } else if (value <= -5 && value > -10) {
        scale = 3;
      } else if (value <= -10 && value > -15) {
        scale = 4;
      } else if (value <= -15 && value > -20) {
        scale = 5;
      } else if (value <= -20 && value > -25) {
        scale = 6;
      } else if (value <= -25 && value > -30) {
        scale = 7;
      } else if (value < -30) {
        scale = 8;
      }
      return scale;
    }
  };

  // Now, render the map markers using the scaling function and filteredData
  // ... rest of the code ...

  // Now, render the map markers using the scaling function and filteredData
  // ... rest of the code ...

  const bubbleEffectCSS = `
.bubble-circle {
      animation: scale(1);
      opacity: 0.2;
    }
  `;
  console.log(data, 'data-501');
  return (
    <Paper className={classes.dataTable}>
      <style>{bubbleEffectCSS}</style>
      {/* <div
        onClick={() => this.handleMouseEnter(campaign_id, store_id)}
        onMouseLeave={this.handleMouseLeave}></div> */}
      {/* <div style={{margin :'2%  40% 0% 20%',width:'100%'}}><h2> Map View Stores </h2></div> */}

      <div style={{ display: 'flex', flexDirection: 'row', padding: '2% 1%' }}>
        <div style={{ marginTop: '10px', padding: '2% 1%', zIndex: 9 }}>
          <table style={{ borderCollapse: 'collapse', width: '100%' }}>
            <thead>
              <tr>
                <th style={{ border: '1px solid black', padding: '8px' }}>
                  Significance Range <p>{'(P Value)'}</p>
                </th>
                <th style={{ border: '1px solid black', padding: '8px' }}>
                  Lift
                </th>
                <th style={{ border: '1px solid black', padding: '8px' }}>
                  Color
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ border: '1px solid black', padding: '8px' }}>
                  Significance &lt; 0.05
                </td>
                <td style={{ border: '1px solid black', padding: '8px' }}>+</td>
                <td style={{ border: '1px solid black', padding: '8px' }}>
                  <Box className={classes.Test_Control_Dg}></Box>
                </td>
              </tr>
              <tr>
                <td style={{ border: '1px solid black', padding: '8px' }}>
                  Significance &lt; 0.2
                </td>
                <td style={{ border: '1px solid black', padding: '8px' }}>+</td>
                <td style={{ border: '1px solid black', padding: '8px' }}>
                  <Box className={classes.Test_Control_Ng}></Box>
                </td>
              </tr>
              <tr>
                <td style={{ border: '1px solid black', padding: '8px' }}>
                  Significance &gt; 0.2
                </td>
                <td style={{ border: '1px solid black', padding: '8px' }}>+</td>
                <td style={{ border: '1px solid black', padding: '8px' }}>
                  <Box className={classes.Test_Control_Lg}></Box>
                </td>
              </tr>
              <tr>
                <td style={{ border: '1px solid black', padding: '8px' }}>
                  Significance &lt; 0.05
                </td>
                <td style={{ border: '1px solid black', padding: '8px' }}>-</td>
                <td style={{ border: '1px solid black', padding: '8px' }}>
                  <Box className={classes.Test_Control_Dr}></Box>
                </td>
              </tr>
              <tr>
                <td style={{ border: '1px solid black', padding: '8px' }}>
                  Significance &lt; 0.2
                </td>
                <td style={{ border: '1px solid black', padding: '8px' }}>-</td>
                <td style={{ border: '1px solid black', padding: '8px' }}>
                  <Box className={classes.Test_Control_Nr}></Box>
                </td>
              </tr>
              <tr>
                <td style={{ border: '1px solid black', padding: '8px' }}>
                  Significance &gt; 0.2
                </td>
                <td style={{ border: '1px solid black', padding: '8px' }}>-</td>
                <td style={{ border: '1px solid black', padding: '8px' }}>
                  <Box className={classes.Test_Control_Lr}></Box>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            fontSize: '14px',
            width: '123px',
            marginLeft: '-204px'
          }}>
          Test Store <Box className={classes.Test_Control}></Box> /
          <Box className={classes.Test_Control_Sig}></Box>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            fontSize: '14px',
            marginLeft: '1%'
          }}>
          {' '}
          Control Store  <Box className={classes.Test_Store}></Box>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            fontSize: '14px',
            marginLeft: '30%'
          }}>
          {' '}
          <h2>Map View Stores</h2>
        </div>
      </div>

      <div style={{ width: '100%', marginTop: '-263px', flexGrow: 1 }}>
        {data && data.length >= 0 ? (
          <>
            {mapFlag && (
              <div
                style={{
                  float: 'right',
                  cursor: 'pointer',
                  position: 'relative',
                  top: -89
                }}
                onClick={() => {
                  setData(mapData);
                  setmapFlag(false);
                  setstateMapvalue(0);
                }}>
                <RefreshIcon fontSize="large" color="info" />
              </div>
            )}
            <ComposableMap
              projectionConfig={{ rotate: [-10, 0, 0], center: [-5, 25] }}
              data-tip="">
              <ZoomableGroup
                zoom={position.zoom}
                center={position.coordinates}
                onMoveEnd={handleMoveEnd}
                onMove={x => {
                  setScaleFactor(Math.ceil(x.zoom));
                }}
                style={{ width: '90px' }}>
                <Geographies geography={datas} style={{ width: '90px' }}>
                  {({ geographies, borders, outline }) =>
                    geographies?.map(geo => (
                      <Geography key={geo.rsmKey} geography={geo} fill="#DDD" />
                    ))
                  }
                </Geographies>

                {data && data.length
                  ? data.map(
                      ({
                        i,
                        longitude,
                        latitude,
                        store_id,
                        STATE_PROVINCE_ID,
                        test_control_flag,
                        abs_lift,
                        significance,
                        pct_lift,
                        kpiRowId
                      }) => {
                        return (
                          <HtmlTooltip
                            title={
                              stateMapvalue === 2 && test_control_flag === 1 ? (
                                // If the condition is true
                                <React.Fragment>
                                  <div className="container">
                                    <div className="box">
                                      <h2 className="lift">
                                        Lift: {pct_lift} %
                                      </h2>
                                      <p className="significance">
                                        Significance: {significance}
                                      </p>
                                      <p className="store-id">
                                        Store ID: {store_id}
                                      </p>
                                      <p className="city">
                                        City: {STATE_PROVINCE_ID}
                                      </p>
                                    </div>
                                  </div>
                                  <arrow />
                                </React.Fragment>
                              ) : stateMapvalue === 0 &&
                                test_control_flag === 1 ? (
                                'click here'
                              ) : (
                                ''
                              )
                              // If the condition is false
                              // <React.Fragment></React.Fragment>
                            }>
                            <Marker
                              key={i}
                              coordinates={[longitude, latitude]}
                              data-tooltip-id={'my-tooltip-data-html'}
                              data-tooltip-delay-hide={1000}
                              data-tooltip-html="<div><h3>STORES</h3><ul><li>441</li><li>IN</li></ul></div>"
                              // onMouseEnter={() => {

                              //   setTooltipContent(`${store_id}`);

                              // }}
                              onClick={() => {
                                if (test_control_flag === 1) {
                                  handleMouseEnter(
                                    campaign_id,
                                    store_id,
                                    // outlierChecked,
                                    // drilldownFilter,
                                    kpiRowId
                                  );
                                } else {
                                  // Handle the else case if needed
                                }
                              }}
                              // onMouseEnter={() => {
                              //   if (test_control_flag === 1) {
                              //     handleMouseEnter(campaign_id, store_id);
                              //   } else {
                              //     // Handle the else case if needed
                              //   }
                              // }}
                              // onMouseClick={() => {
                              //   // handleMouseLeave(campaign_id, store_id);
                              //   setData(mapData);
                              // }}
                            >
                              {/* <circle fill="#F53" stroke="#FFF" r={popScale(population)} /> */}
                              <circle
                                r={scaleCircleSize(pct_lift)}
                                fill={
                                  Number(test_control_flag) === 1
                                    ? pct_lift >= 0
                                      ? significance < 0.05
                                        ? '#006400' // Dark green
                                        : significance < 0.2
                                        ? '#00FF00' // Normal green
                                        : '#ADFF2F' // Light green
                                      : significance < 0.05
                                      ? '#8B0000' // Dark red
                                      : significance < 0.2
                                      ? '#FF0000' // Normal red
                                      : '#FFA07A' // Light red
                                    : pct_lift < 0
                                    ? significance < 0.05
                                      ? '#FFA07A' // Light red
                                      : significance < 0.2
                                      ? '#FF0000' // Normal red
                                      : '#8B0000' // Dark red
                                    : '#FFFF00' // Default yellow for test_control_flag !== 1
                                }
                                style={{
                                  opacity: 0.5
                                  // Add any other desired styles here
                                }}
                              />

                              {/* <img  src={markerimg} r={10} strokeWidth={2} alt="fireSpot"/> */}

                              {/* <g

fill="none"

stroke={test_control_flaG=="1"?"#F00":"#00A36C"}

strokeWidth="2"

strokeLinecap="round"

strokeLinejoin="round"

transform="translate(-12, -24)"

>

<circle cx="12" cy={10 / scaleFactor} r={4 / scaleFactor} />

<circle cx="12" cy={10 / scaleFactor} r={""} />

<path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />

</g> */}

                              <text
                                textAnchor="middle"
                                y={-5 / scaleFactor}
                                style={{
                                  fontFamily: 'system-ui',
                                  fill: '#5D5A6D',
                                  fontSize: `${6 / scaleFactor}`
                                }}>
                                {STATE_PROVINCE_ID ? STATE_PROVINCE_ID : 'NA'}
                              </text>

                              {/* <p>{lng}</p> */}
                            </Marker>
                          </HtmlTooltip>
                        );
                      }
                    )
                  : 'No data'}
              </ZoomableGroup>
            </ComposableMap>
          </>
        ) : (
          <div style={{ margin: '5% 43%', width: '100%' }}>
            {' '}
            No Data available for Stores
          </div>
        )}

        {/* <div className="controls">

<button onClick={handleZoomIn}>

<svg

xmlns="http://www.w3.org/2000/svg"

width="24"

height="24"

viewBox="0 0 24 24"

stroke="currentColor"

strokeWidth="3"

>

<line x1="12" y1="5" x2="12" y2="19" />

<line x1="5" y1="12" x2="19" y2="12" />

</svg>

</button> */}

        {/* <button onClick={handleZoomOut}>

<svg

xmlns="http://www.w3.org/2000/svg"

width="24"

height="24"

viewBox="0 0 24 24"

stroke="currentColor"

strokeWidth="3"

>

<line x1="5" y1="12" x2="19" y2="12" />

</svg>

</button> */}

        {/* </div> */}
      </div>
    </Paper>
  );
};

export default MapChart;
