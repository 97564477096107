import React, { useEffect, useState } from 'react';

import {
  Box,
  Grid,
  TableHead,
  TableCell,
  TableRow,
  Typography,
  Button,
  Paper
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import CustomMaterialTable from '../Common/CustomMaterialTable';
import DisplayContainer from '../Common/Utils/DisplayContainer';
// import { PATHS, APIS } from "../../config";
import ReactBreadcrumbs from 'views/Common/Breadcrumb/Breadcrumb';
import CreateGroupForm from './createGroupForm';
import { useDispatch } from 'react-redux';
import ConfirmDelete from 'components/confirm';
import EditGroupForm from './editGroupForm';
import ViewGroup from './viewGroup';
import secureAxios from 'api-config/api';
import MessageDialog from 'views/Common/MessageDialog/MessageDialog';
import { getPagePermission } from 'role-config';
// import MessageDialog from "views/Common/MessageDialog/MessageDialog";

const useStyles = makeStyles(theme => ({
  createBtn: {
    background: '#182C52',
    color: 'white',
    top: '0',
    marginBottom: '33px',
    '&:hover': {
      backgroundColor: '#031330'
    },
    borderRadius: '0'
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: '#E6E7E8',
    borderStyle: 'solid',
    color: '#ffffff',
    fontWeight: 400,
    fontSize: 14,
    padding: '4px 4px',
    textAlign: 'center'
  },
  tableHead: {
    backgroundColor: '#182C52',
    position: 'sticky',
    top: 0,
    marginTop: '10px'
  },
  backButton: {
    background: '#182C52',
    marginTop: '20px',
    borderRadius: 0,
    textTransform: 'capitalize',
    color: '#ffffff',
    '&:hover': {
      background: '#031330'
    },
    float: 'right'
  }
}));

const ProductGroup = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [groups, setGroups] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [viewData, setViewData] = useState(null);
  const [groupData, setGroupData] = useState({});
  const [message, setMessage] = useState('');
  const [opens, setOpens] = useState(false);
  const [type, setType] = useState('');
  const [productsInitial, setProductsInitial] = useState({});

  useEffect(() => {
    fetchData();
    fetchProducts();
  }, []);

  const fetchProducts = () => {
    dispatch({
      type: 'LOADING',
      payload: true
    });
    secureAxios
      .get('/master/products')
      .then(res => {
        if (res.status == 200) {
          setProductsInitial(res.data.data);

          dispatch({
            type: 'LOADING',
            payload: false
          });
        }
      })
      .catch(err => {
        dispatch({
          type: 'LOADING',
          payload: false
        });
      });
  };

  const deleteProductGroup = row => {
    if (getPagePermission('deleteProduct')) {
      const index = groups.findIndex(
        g => g.PRODUCT_GRP_ID === row.PRODUCT_GRP_ID
      );
      groups.splice(index, 1);
      setGroups([...groups]);
      secureAxios
        .delete('/master/productgroup', { data: { id: row.PRODUCT_GRP_ID } })
        .then(res => {
          if (res.status === 200) {
            // fetchData();
          }
        });
    }
  };

  const editProductGroup = id => {
    if (getPagePermission('editProduct')) {
      dispatch({
        type: 'LOADING',
        payload: true
      });
      setEditMode(true);
      setGroupData(null);
      secureAxios
        .get('/master/productgroup' + `/${id}`)
        .then(res => {
          if (res.status === 200) {
            setGroupData(res.data);

            dispatch({
              type: 'LOADING',
              payload: false
            });
          }
        })
        .catch(err => {
          dispatch({
            type: 'LOADING',
            payload: false
          });
        });
    }
  };

  const columns = [
    {
      title: 'Name',
      field: 'product_grp_name',
      render: rowData => <Typography>{rowData.PRODUCT_GRP_NAME}</Typography>,
      customFilterAndSearch: (term, rowData) =>
        rowData.PRODUCT_GRP_NAME.toLowerCase().indexOf(term.toLowerCase()) > -1
    },
    {
      title: 'Category',
      field: 'prd_grp_catg',
      render: rowData => (
        <Typography title={rowData.product_cnt}>
          {rowData.PRD_GRP_CATG}
        </Typography>
      ),
      customFilterAndSearch: (term, rowData) =>
        rowData.PRD_GRP_CATG.toLowerCase().indexOf(term.toLowerCase()) > -1
    },
    {
      title: 'Action',
      field: 'Action',
      filtering: false,
      render: rowData => (
        <div className="action_groups">
          <div className="action_view" onClick={() => setViewData(rowData)}>
            View
          </div>
          {getPagePermission('editProduct') && (<div
            className="action_edit"
            onClick={() => editProductGroup(rowData.PRODUCT_GRP_ID)}>
            Edit
          </div>)}
          {getPagePermission('deleteProduct') && (<ConfirmDelete
            onDelete={() => {
              deleteProductGroup(rowData);
            }}>
            <div className="action_delete">Discard</div>
          </ConfirmDelete>)}
        </div>
      )
    }
  ];

  const fetchData = () => {
    dispatch({
      type: 'LOADING',
      payload: true
    });
    secureAxios
      .get('/master/productgroup')
      .then(res => {
        if (res.status === 200) {
          setGroups(res.data);
          dispatch({
            type: 'LOADING',
            payload: false
          });
        }
      })
      .catch(err => {
        dispatch({
          type: 'LOADING',
          payload: false
        });
      });
  };

  //close the popup
  const handleClose = () => {
    setOpens(false);
  };
  return (
    <>
      <MessageDialog
        type={type}
        open={opens}
        message={message}
        close={handleClose}
      />
      <Box style={{ background: '#F8F8F8' }}>
        <Box component={'div'} style={{ padding: "20px 20px 0" }}>
          <ReactBreadcrumbs />
        </Box>
        <DisplayContainer
          title={'Product Groups'}
          content={
            <Box component="div" container p={1}>
              <Grid container spacing={3}>
                <Grid item md={(getPagePermission('createProduct') || getPagePermission('editProduct')) ? 6 : 12} xs={12}>
                  <CustomMaterialTable
                    title=""
                    columns={columns}
                    data={groups}
                    options={{
                      sorting: true,
                      search: false,
                      toolbar: false,
                      paging: true,
                      pageSize: 10,
                      filtering: true,
                      minBodyHeight: 255,
                      position: 'sticky',

                      cellStyle: rowData => {
                        return {
                          textAlign: 'left'
                        };
                      },
                      rowStyle: rowData => {
                        return {
                          backgroundColor: 'inherit',
                          color: 'inherit',
                          cursor: 'pointer'
                        };
                      }
                    }}
                    components={{
                      Header: () => {
                        return (
                          <TableHead className={classes.tableHead}>
                            <TableRow>
                              <TableCell
                                className={classes.tableRightBorder}
                                rowSpan={2}>
                                Name
                              </TableCell>

                              <TableCell
                                className={classes.tableRightBorder}
                                rowSpan={2}>
                                Category
                              </TableCell>
                              <TableCell
                                className={classes.tableRightBorder}
                                rowSpan={2}>
                                Action
                              </TableCell>
                            </TableRow>
                          </TableHead>
                        );
                      }
                    }}></CustomMaterialTable>
                </Grid>
                <Grid item md={6}>
                  {!(editMode && groupData) && (
                    <Paper elevation={1}>
                      {(getPagePermission('createProduct')) && (<CreateGroupForm
                        onAdd={() => fetchData()}
                        initialProducts={productsInitial}
                      />)}
                    </Paper>
                  )}

                  {editMode && groupData && (
                    <React.Fragment>
                      {(getPagePermission('editProduct')) && (
                        <Paper elevation={1}>
                        <EditGroupForm
                          onAdd={() => {
                            setOpens(true);
                            setType('success');
                            setMessage('Updated successfully');
                            fetchData();
                          }}
                          isEdit={editMode}
                          data={groupData}
                          initialProducts={productsInitial}
                          cancelEdit={() => {
                            setEditMode(false);
                          }}
                        />
                      </Paper>
                      )}  
                    </React.Fragment>
                  )}

                  <Button
                    className={classes.backButton}
                    type="button"
                    onClick={() => history.push('/home')}
                    variant="contained">
                    Back to Main Menu
                  </Button>
                </Grid>
              </Grid>
            </Box>
          }
          contentRight={<></>}
        />
      </Box>
      {viewData && (
        <ViewGroup
          data={viewData}
          onClose={() => setViewData(null)}
          productsList={productsInitial}
        />
      )}
    </>
  );
};

export default ProductGroup;
