import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Paper,
  List,
  ListItem,
  Typography,
  Button,
  Box,
  FormHelperText,
  CircularProgress
} from '@material-ui/core';
import SwitchToggle from 'views/Common/Filters/SwitchToggle';
import MultiSelectDropdown from 'views/Common/Filters/MultiSelectDropdown';
import SingleSelectDropdown from 'views/Common/Filters/SingleSelectDropdown';
import FileUpload from 'views/Common/Filters/FileUpload';
import RadioGroupElement from 'views/Common/Filters/RadioGroupElement';
import secureAxios from 'api-config/api';
import DownloadCsv from 'views/Common/Filters/DownloadCsv';
import csvFile from 'assets/files/Identify Test Exclusion.csv';
import { saveAs } from 'file-saver';
import GetAppIcon from '@material-ui/icons/GetApp';
import { CSVLink } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux';
import { toCamelcaseStr } from 'views/Common/Utils/ClientUtil';
import { AsyncPaginate } from 'react-select-async-paginate';
import AttributeDropDown from './Attribute';
import InfoIcon from '@material-ui/icons/Info';
import Multiselect from 'multiselect-react-dropdown';
import SelectGroup from 'components/SelectGroup';
import DialogBox from 'views/Common/Modals/Dailog';
import FullLoader from 'views/Common/FullLoader/FullLoader';
import { getStoreFromSelectedAttrOption, getStoreGroupsVal, getStoreLists, setAttributeOptionsVal, setFinalCandidateStores } from 'api-config';
import { setLoading } from 'redux/actions/application.action';
import { setExcludeTestStoresData, setExcludeTestStoresList, setSelectedAttribute, setStoresGroupList } from 'redux/actions/identifyTest.action';
import { onHandleClearAttribute } from 'components/helper';
import _ from 'lodash';
import { IDENTIFY_EXCLUDE_RAD_VAL } from 'redux/types';

const useStyles = makeStyles(theme => ({
  // formLabel: {
  //   margin: '16px 0 0'
  // },
  formLabel: {
    margin: '5px 0 10px',
    color: '#031330',
    fontWeight: 500
  },
  warningLabel: {
    margin: '0 !important',
    color: 'orange !important'
  },
  formControl: {
    width: '30%'
  },
  errorText: {
    color: 'red',
    fontSize: 12
  },
  alignRight: {
    float: 'right'
  },
  alignLeft: {
    float: 'left',

  },
  space: {
    height: 20,
    float: 'left',
    width: '100%'
  },
  itemList: {
    padding: '40px 0 0',
    '& li': {
      width: 'auto',
      display: ' inline-block',
      margin: '0 30px 0 0',
      padding: 0,
      '& div': {
        backgroundColor: theme.palette.background.default,
        padding: '20px 0',
        textAlign: 'center',
        borderRadius: 0,
        width: '14em',
        border: '0.1px solid #75757561'
      },
      '& p': {
        marginBottom: 15,
        color: theme.palette.primary.main
      }
    }
  },
  sampleDownload: {
    margin: '16px 0 0'
  },
  toggleArea: {
    padding: '0 !important',
    '& label': {
      '& span.MuiFormControlLabel-label': {
        color: '#031330',
        fontWeight: 500
      }
    }
  },
  infoArea: {
    fontSize: 12,
    '& svg': {
      fontSize: '1rem !important',
      margin: '0 5px 0 0 !important',
      verticalAlign: 'sub'
    }
  },
  formLabel: {
    margin: '5px 0 10px',
    color: '#031330',
    fontWeight: 500
  },
}));

function CreatStoreSegment(props) {
  console.log(props, "props")
  const classes = useStyles();
  const dispatch = useDispatch();
  const aboutTestReducer = useSelector(state => ({
    ...state.aboutTestReducer
  }));
  const sampleSizeReducer = useSelector(state => ({
    ...state.sampleSizeReducer
  }));
  const storeGrpDrpVal = useSelector(
    state => state.identifyTestReducer.storegroup
  );
  const getstorelist = useSelector(
    state => state.identifyTestReducer.getstorelist
  );
  const identifyTestReducer = useSelector(state => ({
    ...state.identifyTestReducer
  }));
  const { attributeoptionselected, finalcandidatestore, selectedattroptions, storegroup, attrstorecount, selectedattribute, storeidsattrselected, createstoresegment, excludeTestStoresData, excludeTestStoresList, storesGroupList, identifytestexcluderadVal } = identifyTestReducer
  let { moreAboutTest, triggerLaunchDagStatus, triggerLaunchDag3Status } = aboutTestReducer;
  let { sampleSizeSemulationData } = sampleSizeReducer;
  console.log("storesGroupList", storesGroupList)
  const [excludeToggle, setExcludeToggle] = useState(true);
  const [csvData, setCsvData] = useState('');
  const { testStore } = props.data.testStore;
  // const [exstoreop, setExStoreOP] = useState("");
  const [searchSelection, setSearchSelection] = useState([]);
  const [storeData, setStoreData] = useState([]);
  const [finalExcludeStore, setFinalExcludeStore] = useState([]);
  // const [storeIDAttr, setStoreIDAttr] = useState([]);
  const [setAlarm, setAetAlarm] = useState(false);
  const [clickedApply, setClickedApply] = useState(false);
  //storeGroup
  const [storeOpen, setStoreOpen] = useState(false);
  const [sdata, setSData] = useState([]);
  const [storeGroupDrpVal, setStoreGroupDrpVal] = useState([]);
  const [storeGroupDrpSel, setStoreGroupDrpsel] = useState([]);
  const [storeGroupDrpids, setStoreGroupDrpids] = useState([]);
  const [updateGrpVal, setUpdateGrpVal] = useState([]);
  const [load, setLoad] = useState(false);






  useEffect(() => {
    setAetAlarm(prev => !prev)
  }, [storeidsattrselected])


  const setClickApplyAttr = () => {
    setClickedApply(true)
  }



  useEffect(() => {
    if (props.testStoreSegment) {
      const data = props.testStoreSegment.getFinalExcludedEntitiesResult ? props.testStoreSegment.getFinalExcludedEntitiesResult : [];
      // const data = props.testStoreSegment.getDownloadButtonStores;
      if (data.length > 0) {
        const headers = Object.keys(data[0]);
        const csvReport = {
          data: data,
          headers: headers,
          filename: `${toCamelcaseStr(moreAboutTest.LEVEL)} Excluded.csv`
        };
        setCsvData(csvReport);
      }
    }
  }, [props.testStoreSegment]);

  useEffect(() => {
    // secureAxios.get('/designCampaign/getStoresList').then(res => {
    //   setStoreData(res.data.responseData);
    // });
    dispatch(getStoreLists({}))
    // getStoreGroups();
    dispatch(getStoreGroupsVal());


  }, []);
  useEffect(() => {
    console.log('onsubmit triggered store group call')
    setTimeout(() => {
      // getStoreGroups();
      dispatch(getStoreGroupsVal());


    }, 7000)
  }, [updateGrpVal]);

  const sleep = ms =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve(undefined);
      }, ms);
    });

  const loadOptions = async (search, prevOptions) => {
    await sleep(1000);
    let filteredOptions;
    if (!search) {
      // filteredOptions = storeData;
      filteredOptions = getstorelist;
    } else {
      const searchLower = search.toLowerCase();

      // filteredOptions = storeData.filter(({ label }) =>
      //   label.toLowerCase().includes(searchLower)
      // );
      filteredOptions = getstorelist.filter(({ label }) =>
        label.toLowerCase().includes(searchLower)
      );
    }
    const hasMore = filteredOptions.length > prevOptions.length + 10;
    const slicedOptions = filteredOptions.slice(
      prevOptions.length,
      prevOptions.length + 10
    );
    return {
      options: slicedOptions,
      hasMore
    };
  };

  const handelToggle = e => {
    setExcludeToggle(e);
    props.testExclusionChange(e);
  };
  const handleStoreList = () => {
    setStoreOpen(prev => !prev);
  };

  const excludeStoreOption = [
    {
      label: 'Select from a List',
      val: 'select'
    },
    {
      label: 'Import List',
      val: 'import'
    }
  ];
  const handleMultiAttribute = (values, item, rem) => {
    console.log({
      "Hitted": "handleMultiAttribute",
      values: values,
      item: item,
      rem: rem
    })
    if (rem) {
      setStoreGroupDrpids([])
      const data = storeGroupDrpSel?.filter((el, i) => {
        return !rem.STORE_GRP_ID.includes(el)
      });
      setStoreGroupDrpsel(data)
    } else {
      setStoreGroupDrpsel(prevState => [...prevState, item?.STORE_GRP_ID])
    }
    dispatch(setStoresGroupList(values));
  }

  const submitStoreGroup = (val) => {

    try {
      secureAxios.post('/designCampaign/getStrGrpStores', {
        strGrpIds: storeGroupDrpSel
      }).then(res => {
        console.log(res);
        let storeIds;
        storeIds = res.data.length > 0 && res.data?.map(item => {
          return item.STORE_ID;
        });
        // setStoreGroupDrpids(prev=>[storeIds,...prev]);
        if (storeIds.length > 0) { setStoreGroupDrpids(prev => storeIds) } else { setStoreGroupDrpids(prev => []) }
        console.log(storeIds, 'storeidsselected')
      })

    } catch (error) {
      console.log(error)
    }
  }

  const getStoreGroups = () => {
    try {
      secureAxios.get('/designCampaign/getStoresGroup')
        .then(function (response) {
          console.log(response);
          setStoreGroupDrpVal(prev => response.data)
          setLoad(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }


  const handleAtrributeRedux = (FinalDataset, val) => {
    let filteredDataAtrr = []
    if (FinalDataset.length > 0 && attributeoptionselected && attributeoptionselected.length > 0) {
      filteredDataAtrr = onHandleClearAttribute(attributeoptionselected, FinalDataset);
      dispatch(setAttributeOptionsVal([...filteredDataAtrr]));

    }
    // console.log(FinalDataset, "Selected Attribute")
    if (FinalDataset.length <= 0) {
      // props.setStore([])
      // setMultiOptionVal([])
      dispatch(setAttributeOptionsVal([]))
      // dispatch(setAttributeOptionsVal(data));

      dispatch(getStoreFromSelectedAttrOption([]))




      // dispatch(setSelectedAttribute([]));

      // props.getChoosenAttt([])
      // setSearchSelection();
    }



    let SelectedAttr = []
    SelectedAttr = FinalDataset.length ? FinalDataset.map(item => item.value) : [];

    // const SelectedAttr = FinalDataset.map(item => {

    //   return String(item.value).replace(/-|_| /g, '_')
    // });
    // console.log(SelectedAttr, 'console.logselected');
    if (!SelectedAttr.length) {
      // props.setStore([])
      // setMultiOptionVal([])
      dispatch(setAttributeOptionsVal([]))

      dispatch(getStoreFromSelectedAttrOption([]))

      dispatch(setSelectedAttribute([]));

      // props.getChoosenAttt([])
      // setSearchSelection();
    }
    // setLoad(true)

    // getSelectedAttriVal(SelectedAttr);

    // setSelAttrMain(SelectedAttr)
    dispatch(setSelectedAttribute(SelectedAttr));
    dispatch(setFinalCandidateStores(FinalDataset));
    // setFinalCandidatStore(FinalDataset);
    // props.getChoosenAttt(SelectedAttr)
    // setSearchSelection();

    val.setShowFilter(false);
  }
  const handleSubAttributeRedux = (multiOptionVals) => {
    let result = _.unionBy(multiOptionVals, attributeoptionselected, 'key');
    dispatch(setAttributeOptionsVal(result));
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button
            className={classes.applyBtn}
            style={{ float: 'right' }}
            color="primary"
            type="button"
            size="small"
            variant="contained"
            onClick={() => {
              // dispatch(setMoreAboutTest(values));
              // history.push('/productgroup');
              dispatch(setLoading(true));
              setStoreOpen(true);
              setTimeout(() => {
                dispatch(setLoading(false));

              }, 3000)

            }}>
            Create Stores Group
          </Button>
        </Grid>
        <Grid item xs={12} md={12}>
          {/* {load && <FullLoader />} */}

          <AttributeDropDown
            //  getChoosenAttt ={props.getChoosenAttt}
            handleAtrributeRedux={handleAtrributeRedux}
            finalcandidatestore={finalcandidatestore}
            selectedattribute={selectedattribute}
            attributeoptionselected={attributeoptionselected}
            handleSubAttributeRedux={handleSubAttributeRedux}

            // handleAtrributeRedux={handleAtrributeRedux}
            campaignID={props.campaignID} setClickApplyAttr={setClickApplyAttr} showApplyBtn={true} />
          <span style={{
            paddingTop: '25',
            color: 'orange',
            textAlign: 'center', fontSize: '13px'
          }} >{(storeidsattrselected.length >= 0 && clickedApply == true) ? `${attrstorecount}  common (storeId's)  has been selected ` : ''}</span>
        </Grid>
        <Grid item xs={12} md={12}>
          {/* <Grid item xs={12}>


     
                  </Grid> */}
          {/* <Grid item md={12}>
                  <span style={{ paddingTop: '25',
    color: 'orange',
    textAlign: 'center',fontSize:'13px'}} >{(storeIDAttr.length>0  && clickedApply==true)?`${storeIDAttr.length } unique attributes (storeId's) selected`:''}</span>
                  </Grid> */}
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.formLabel}>
            Create Test {`${toCamelcaseStr(moreAboutTest.LEVEL)}`} Segment{' '}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Typography style={{ lineHeight: 1 }}>
                Exclude {`${toCamelcaseStr(moreAboutTest.LEVEL)}`} Exposed to
                Ongoing Tests
              </Typography>
            </Grid>

            <Grid item xs={4} className={classes.toggleArea}>
              <SwitchToggle
                style={{ color: '#031330', fontWeight: 500 }}
                name={props.data.exclusionToggle.name}
                label={props.data.exclusionToggle.label}
                values={identifytestexcluderadVal}
                // values={props.data.exclusionToggle.values}
                onChange={value => {
                  props.testExclusionChange(
                    props.data.exclusionToggle.name,
                    value
                  )
                  dispatch(IDENTIFY_EXCLUDE_RAD_VAL(value))
                }}
              />
            </Grid>
          </Grid>
          {props.data.exclusionToggle.values[
            props.data.exclusionToggle.name
          ] ? (
            <Grid container spacing={3} style={{ marginTop: "3rem" }}>
              <Grid item xs={6} className="pt0">
                <MultiSelectDropdown
                  width={'w100'}
                  label={props.data.testGroup.label}
                  name={props.data.testGroup.name}
                  value={props.data.testGroup.values[props.data.testGroup.name]}
                  handleMethod={value => {
                    props.handelTestGroup(props.data.testGroup.name, value);
                  }}
                  options={props.data.testGroup.options}
                />
              </Grid>
              <Grid item xs={6} className="pt0">
                <SingleSelectDropdown
                  width={'w100'}
                  value={props.data.testPeriod.values}
                  name={props.data.testPeriod.name}
                  handleMethod={props.handelTestPeriod}
                  label={props.data.testPeriod.label}
                  options={props.data.testPeriod.options}
                />
              </Grid>
            </Grid>
          ) : (
            ''
          )}
        </Grid>
        <Grid
          item
          xs={3}
          style={{
            marginLeft: '7%',
          }}>
          <Grid container spacing={3} >
            <Grid item xs={4}>
              <Typography style={{ lineHeight: 1 }}>Country</Typography>
            </Grid>
            <Grid item xs={7} className={classes.toggleArea}>
              <SwitchToggle
                name={props.data.geographyToggle.name}
                label={props.data.geographyToggle.label}
                values={props.data.geographyToggle.values}
                onChange={value =>
                  props.testExclusionChange(
                    props.data.geographyToggle.name,
                    value
                  )
                }
              />
            </Grid>
          </Grid>
          {props.data.geographyToggle.values[
            props.data.geographyToggle.name
          ] && (
              <Grid container spacing={3} style={{ marginTop: "3rem" }}>
                <Grid item xs={12} className="pt0">
                  {props.data.geography && (
                    <MultiSelectDropdown
                      width={'w100'}
                      label={props.data.geography.label}
                      name={props.data.geography.name}
                      value={
                        props.data.geography.values[props.data.geography.name]
                      }
                      handleMethod={value => {
                        props.handelTestType(props.data.geography.name, value);
                      }}
                      options={props.data.geography.options}
                    />
                  )}
                </Grid>
              </Grid>
            )}
        </Grid>

        <Box className={classes.space}></Box>
        <Grid item xs={12} className="pb0">
          <RadioGroupElement
            name={'EXCLUDESTORES'}
            defaultvalues={excludeTestStoresData}
            label="Exclude test stores"
            handleMethod={event => {
              props.resetFiledata()
              //setExStoreOP(event.target.value);
              dispatch(setExcludeTestStoresData(event.target.value));

              dispatch(setExcludeTestStoresList([]))
            }}
            options={excludeStoreOption}
          />
        </Grid>
        {excludeTestStoresData === 'import' && (
          <>
            <Grid item xs={3}>
              <FileUpload
                name={props.data.fileUpload.name}
                label={props.data.fileUpload.label}
                handleMethod={props.handelFile}
              />
            </Grid>

            <Grid item xs={3}>
              <Typography className={classes.sampleDownload}>
                <DownloadCsv
                  path={csvFile}
                  fileName={'Identify Test Exclusion'}
                  format={'csv'}
                  label={'Download Sample File'}
                />
              </Typography>
            </Grid>
          </>
        )}

        {excludeTestStoresData === 'select' && (
          <>
            <Grid item xs={6}>
              <Typography className={classes.formLabel}>
                Select test stores
              </Typography>
              <AsyncPaginate
                className="asyncInput"
                value={excludeTestStoresList}
                loadOptions={loadOptions}
                isMulti
                closeMenuOnSelect={false}
                onChange={e => {
                  const FinalDataset = getstorelist.filter(i =>
                    e.find(({ value }) => i.value === value)
                  );
                  // const FinalDataset = storeData.filter(i =>
                  //   e.find(({ value }) => i.value === value)
                  // );
                  // setFinalExcludeStore(FinalDataset);
                  props.excludeStoreDropdown(FinalDataset)
                  dispatch(setExcludeTestStoresList(FinalDataset));
                  setSearchSelection();
                }}
              />
            </Grid>
          </>
        )}
        <Grid item md={12}>
          <Typography className={classes.formLabel}>
            Select Stores Group
          </Typography>
          <Box component="div" container p={1} style={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
            <span>
              <Multiselect
                isObject={true}
                displayValue="STORE_GRP_NAME"
                onKeyPressFn={function noRefCheck() { }}
                onRemove={(val, rem) => handleMultiAttribute(val, null, rem)}
                onSearch={(val) => console.log(val)}
                onSelect={(val, item) => handleMultiAttribute(val, item, null)}
                options={storeGrpDrpVal}
                selectedValues={storesGroupList}
                style={{
                  chips: {
                    background: '#E7E7E7',
                    color: 'black',
                    borderRadius: '4px',
                    fontSize: '10px'
                  },
                  searchBox: {
                    width: '500px',

                  },
                  input: {
                    height: '50px'
                  }
                }}
              />
              {storeGroupDrpids && storeGroupDrpids.length <= 0 ? <FormHelperText className={classes.infoArea}>
                {<InfoIcon />}
                {'Please select Store Group and click on apply'}
              </FormHelperText> :
                <span style={{
                  paddingTop: '25',
                  color: 'orange',
                  textAlign: 'center', fontSize: '13px'
                }} >{`${storeGroupDrpids.length} (storeId's) selected`}</span>
              }
            </span>

            <Button
              className={classes.applyBtn}
              style={{ marginLeft: '2%', marginBottom: '2%' }}
              disable={storeGroupDrpSel && storeGroupDrpSel.length ? false : true}
              color="primary"
              type="button"
              size="small"
              variant="contained"
              onClick={() => submitStoreGroup()}>
              Apply Store Group
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12} className="mt15" >
          <Button
            y={2}
            className={classes.alignLeft}
            color="primary"
            size="small"
            disabled={(attributeoptionselected && attributeoptionselected.length <= 0) && (selectedattribute && selectedattribute.length > 0) ? true : triggerLaunchDagStatus}

            onClick={(e) => props.applyExclusion(e, storeidsattrselected, storeGroupDrpids)}
            variant="contained">
            Apply Exclusion &nbsp;
            {(triggerLaunchDag3Status) && (<CircularProgress color="inherit" size={20} />)}
          </Button>

        </Grid>
        <Grid item xs={12} className="mt15" >

          <span style={{
            paddingTop: '25',
            color: 'orange',

            textAlign: 'center', fontSize: '13px'
          }} >{(attributeoptionselected && attributeoptionselected.length <= 0) && (selectedattribute && selectedattribute.length > 0) ? `Please deselect attributes or select sub attribute to exclude stores` : ''}</span>
        </Grid>
      </Grid>

      {/* {props.testStoreSegment && props.testStoreSegment.length >0 ? ( */}
      {Object.keys(createstoresegment).length !== 0 && createstoresegment.constructor === Object && (
        <List component="nav" className={classes.itemList}>
          <ListItem>
            <Paper>
              <Typography variant={'body1'}>
                {toCamelcaseStr(moreAboutTest.LEVEL)} Excluded
              </Typography>
              <Typography variant={'h3'}>
                {/* {props.testStoreSegment.totalExcludedStores} */}
                {/* {props.totalStorePopulation ? props.totalStorePopulation - props.testStoreSegment.storesLeft : 'NA'} */}
                {createstoresegment.storesExclueded ? createstoresegment.storesExclueded : 0}
              </Typography>
              {csvData.data ? (
                <Typography variant={'h3'}>
                  <CSVLink filename={csvData.filename} data={csvData.data}>
                    {' '}
                    <GetAppIcon
                      style={{
                        fontSize: 25,
                        position: 'absolute',
                        cursor: 'pointer',
                        right: 5,
                        bottom: 5,
                        color: '#0B4EC1'
                      }}
                    />{' '}
                  </CSVLink>
                </Typography>
              ) : (
                ''
              )}
            </Paper>
          </ListItem>
          <ListItem>
            <Paper>
              <Typography variant={'body1'}>
                Candidate Test {toCamelcaseStr(moreAboutTest.LEVEL)}
              </Typography>
              <Typography variant={'h3'}>
                {Object.keys(createstoresegment).length !== 0 && createstoresegment.constructor === Object ? createstoresegment.storesLeft : 0}
              </Typography>
            </Paper>
          </ListItem>
          <ListItem>
            <Typography className={classes.warningLabel}>
              {props.testStoreSegment.storesLeft <
                sampleSizeSemulationData.testSampleSize
                ? `Candidate Test ${toCamelcaseStr(
                  moreAboutTest.LEVEL
                )} is lesser than the recommended Test Sample`
                : ''}
            </Typography>
          </ListItem>
        </List>
      )}

      <DialogBox
        open={storeOpen}
        buttonStatus={false}
        header={'Select Stores Group'}
        handleClose={handleStoreList}
        width={700}
        children={
          <SelectGroup setLoad={setLoad} setUpdateGrpVal={setUpdateGrpVal} handleAudModal={handleStoreList} />
        }
        contentStyle={{ marginBottom: '70px' }}></DialogBox>
    </>
  );
}

export default CreatStoreSegment;
