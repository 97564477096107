import React, { useEffect, useState } from "react";
import DialogBox from "views/Common/Modals/Dailog";
import CustomMaterialTable from "../Common/CustomMaterialTable";
import {
  Box,
  Typography,
  TableHead,
  TableCell,
  TableRow,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import secureAxios from "api-config/api";


const useStyles = makeStyles((theme) => ({
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "#E6E7E8",
    borderStyle: "solid",
    color: "#ffffff",
    fontWeight: 400,
    fontSize: 14,
    padding: "4px 4px",
    textAlign: "center",
  },
  tableHead: {
    backgroundColor: "#40BBA0",
    position: "sticky",
    top: 0,
    marginTop: "10px",
  }
}));

const ViewGroup = ({ data, onClose, productsList }) => {
  const classes = useStyles();
  const [products, setProducts] = useState(null);
  const [load,setLoad] = useState(false)
  useEffect(() => {
    fetchDetails();
  }, []);

  const fetchDetails = () => {
    setLoad(true)
    secureAxios
      .get("/master/productgroup" + `/${data.PRODUCT_GRP_ID}`)
      .then((res) => {
        setLoad(false)
        const finalProducts = [];

        if (res.status === 200) {
          if (res.data.products) {
            for (let p of res.data.products) {
              // console.log(res.data.products,"data");
              if (productsList[p.DIVISION_NBR]) {
                productsList[p.DIVISION_NBR].checked = true;
                if (productsList[p.DIVISION_NBR]) {
                  productsList[p.DIVISION_NBR].departments[
                    p.DEPT_NBR
                  ].checked = true;
                  if (
                    productsList[p.DIVISION_NBR].departments[p.DEPT_NBR].class[
                      p.CLASS_NBR
                    ]
                  ) {
                    finalProducts.push({
                      ...p,
                      division: productsList[p.DIVISION_NBR].name,
                      department:
                        productsList[p.DIVISION_NBR].departments[p.DEPT_NBR]
                          .name,
                      class:
                        productsList[p.DIVISION_NBR].departments[p.DEPT_NBR]
                          .class[p.CLASS_NBR].name,
                    });
                    productsList[p.DIVISION_NBR].departments[p.DEPT_NBR].class[
                      p.CLASS_NBR
                    ].checked = true;
                  }
                }
              }
            }
          }
        }
        // console.log('finalProducts', finalProducts);
        setProducts(finalProducts);
      }).catch((err)=>{
        setLoad(false)
        console.log(err);
      });
  };
  return (
    <>
      <DialogBox
        open={true}
        buttonStatus={false}
        handleClose={onClose}
        width={"medium"}
        header={
          <div>
            <span>{data.PRODUCT_GRP_NAME}</span>
          </div>
        }
        contentStyle={{ marginBottom: "20px", }}
        children={
          <div>
            <div>
              <strong>Category:</strong> {data?.PRD_GRP_CATG}
            </div>
            <div style={{marginBottom: '20px'}}>
              <strong>Description:</strong> {data?.PRD_GRP_DESC}
            </div>
            {
              load ? <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                <CircularProgress size={32}/>
              </div> :
              <>
              {products && <CustomMaterialTable
              title=""
              columns={[
                {
                  render: (rowData) => (
                    <Typography>{rowData.division}</Typography>
                  ),
                },
                {
                  render: (rowData) => (
                    <Typography>{rowData.department}</Typography>
                  ),
                },
                {
                  render: (rowData) => <Typography>{rowData.class}</Typography>,
                },
              ]}
              data={products}
              options={{
                sorting: true,
                search: false,
                toolbar: false,
                paging: true,
                pageSize: 10,
                filtering: false,
              }}
              components={{
                Header: () => {
                  return (
                    <TableHead className={classes.tableHead}>
                      <TableRow>
                        <TableCell className={classes.tableRightBorder}>Division</TableCell>
                        <TableCell className={classes.tableRightBorder}>Department</TableCell>
                        <TableCell className={classes.tableRightBorder}>Class</TableCell>
                      </TableRow>
                    </TableHead>
                  );
                },
              }}
            ></CustomMaterialTable>}
              </>
              
            }
            
          </div>
        }
      ></DialogBox>
    </>
  );
};

export default ViewGroup;
