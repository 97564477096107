import { MOREABOUTTEST, TRIGGERLAUNCHDAG, TRIGGERLAUNCHDAG2, TRIGGERLAUNCHDAG3, TELL_US_MORE_SELECTED_ATTRIBUTES, TELL_US_MORE_SELECTED_SUB_ATTRIBUTES, CLEAR_ABOUT_VALUES, ABOUTTEST_STOREID_ATTR } from '../types';

export const setMoreAboutTest = val => {
  return {
    type: MOREABOUTTEST,
    payload: val
  };
};

export const setTriggerLaunchDagStatus = val => {
  return {
    type: TRIGGERLAUNCHDAG,
    payload: val
  };
};

export const setTriggerLaunchDag2Status = val => {
  return {
    type: TRIGGERLAUNCHDAG2,
    payload: val
  };
};

export const setTriggerLaunchDag3Status = val => {
  return {
    type: TRIGGERLAUNCHDAG3,
    payload: val
  };
};

export const clearAboutValues = () => {
  return {
    type: CLEAR_ABOUT_VALUES
  };
}
export const aboutTestAttrStoreIdsSel = (payload) => {
  return {
    type: ABOUTTEST_STOREID_ATTR,
    payload
  };
}
// export const setTellUsMoreSelectedAttributes = val => {
//   return {
//     type: TELL_US_MORE_SELECTED_ATTRIBUTES,
//     payload: val
//   }
// }

// export const setTellUsMoreSelectedSubAttributes = val => {
//   return {
//     type: TELL_US_MORE_SELECTED_SUB_ATTRIBUTES,
//     payload: val
//   }
// }