import { combineReducers } from 'redux';
import application from './application';
import aboutTestReducer from './aboutTest.reducer';
import sampleSizeReducer from './sampleSize.reducer';
import identifyTestReducer from './identifyTest.reducer';
import identifyControlReducer from './identifyControl.reducer';
import measureReducer from './measure.reducer';
import measureCampaign from './measureCampaign';
import productGroupReducer from './productGroup.reducer';
import campaignTemplateReducer from './campaignTemplate.reducer';
import drilldownFilterReducer from './drilldownFilter.reducer';
import drilldownSelectedFilterReducer from './drilldownSelectedFilter.reducer';
import kpiDrillDownOptionsReducer from './identifyControlKpiDrillDownOptions';
import selectedKpiDrillDownOptionsReducer from './identifyControlKpiDrillDownSelectedOptions';
import kpisReducer from './kpis'
import homePageReducer from './homePage.reducer';

const rootReducer = combineReducers({
  application,
  aboutTestReducer,
  sampleSizeReducer,
  identifyTestReducer,
  identifyControlReducer,
  measureReducer,
  measureCampaign,
  productGroupReducer,
  campaignTemplateReducer,
  drilldownFilterReducer,
  drilldownSelectedFilterReducer,
  kpiDrillDownOptionsReducer,
  selectedKpiDrillDownOptionsReducer,
  kpisReducer,
  homePageReducer
});

export default rootReducer;
