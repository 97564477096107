import { KPIOPTIONS, KPIOPTIONSLOADING, L12MOPTIONS } from '../types';



const initialState = {
  data: {},
  l12mData: {},
  metaData:{
    brand:{
      key:'brand',
      label:'Brand'
    },
    product_grp:{
      key:'product_grp',
      label:'Product Group'
    },
    division:{
      key:'division',
      label:'Division'
    },
    department:{
      key:'department',
      label:'Department'
    }
  },
  loading:true
};
const kpiDrillDownOptionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case KPIOPTIONS:
      return {
        ...state,
        data: action.payload,
        
        loading:false
      };
    case L12MOPTIONS:
      return {
        ...state,
        l12mData: {...state.l12mData, [action.payload.key] : action.payload.value},
      }
    case KPIOPTIONSLOADING: 
      return {
        ...state,
        loading: action.payload
      }
    default:
      return state;
  }
};

export default kpiDrillDownOptionsReducer;
