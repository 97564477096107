import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import 'react-dropdown-tree-select/dist/styles.css';
import { Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  modalContainer: {
    position: 'fixed',
    overflow: 'auto',
    right: 0,
    top: '12vh',
    zIndex: 100,
    display: 'flex',
    width: '29rem',
    height: '100vh',
    flexDirection: 'column',
    border: '1px splid red',
    paddingBottom: '35rem',
    transition: 'all ease 0.5s'
  },
  modalTitleContainer: {
    display: 'flex',
    padding: '2em 1em',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '#182C52'
  },
  title: {
    fontSize: '1.125rem',
    fontFamily: 'Poppins',
    fontWeight: 500,
    color: 'white',
    cursor: 'pointer'
  },
  mainContainer: {
    background: '#FFFFFF',
    padding: '1em'
  },
  inputGroup: {
    background: '#F6F7F7',
    padding: '1em',
    margin: '1em 0'
  },
  inputGroupTitle: {
    color: '#333',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '1.2em',
    display: 'block',
    marginBottom: '10px'
  },
  inputGroupSubtitle: {
    color: '#999',
    fontSize: '0.8em',
    marginTop: '20px',
    display: 'block'
  },
  dropdown: {
    width: '100%',
    '& .dropdown-trigger': {
      width: '25rem',
      maxWidth: '25rem',
      display: 'flex !important',
      justifyContent: 'space-between',
      background: '#FFF',
      '& input': {
        width: '210%',
        border: 'none'
      }
    },
    '& .dropdown-content': {
      width: '25rem',
      maxHeight: '20rem !important',
      overflow: 'auto',

      '& .toggle': {
        color: '#182C52',
        fontSize: '16px'
      }
    }
  },
  applyBtn: {
    display: 'flex',
    width: '100%',
    height: '30px',
    padding: '0px 5px',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    background: 'var(--neutral-colors-white, #FFF)',
    borderRadius: 4,
    border: 'none',
    color: '#182C52',
    '&:hover': {
      background: 'var(--neutral-colors-white, #FFF)',
      color: '#031330',
      opacity : '0.9'
    }
  }
}));

const CampaignSummaryPopup = React.memo(
  ({ open, onClose, data, setValues }) => {
    const classes = useStyles();
    const [selectedValues, setSelectedValues] = useState({});

    useEffect(() => {
      let totalPg = data?.productGrps?.find(e => e.value == 'Total');
      if (totalPg && totalPg.checked) {
        setSelectedValues((prev) => ({ ...prev, [totalPg.key]: totalPg }));
      }
    }, [data]);

    const onChange = (currentNode, selectedNodes) => {
      if (currentNode.checked) {
        setSelectedValues(prev => ({
          ...prev,
          [currentNode.key]: currentNode
        }));
      } else {
        setSelectedValues(prev => {
          delete prev[currentNode.key]
          return prev
        });
      }
    };

    const onApplySelection = () => {
      setValues(Object.values(selectedValues));
      onClose();
    };

    const DropDownTreeSelectKPIS = useMemo(() => {
      return (
        <DropdownTreeSelect
          data={data.kpis || []}
          className={classes.dropdown}
          onChange={onChange}
          mode="hierarchical"
          keepOpenOnSelect={true}
        />
      );
    }, [data]);

    const DropDownTreeSelectDIV = useMemo(() => {
      return (
        <DropdownTreeSelect
          data={data.divisions || []}
          className={classes.dropdown}
          onChange={onChange}
          mode="hierarchical"
          keepOpenOnSelect={true}
        />
      );
    }, [data]);

    const DropDownTreeSelectPG = useMemo(() => {
      return (
        <DropdownTreeSelect
          data={data.productGrps || []}
          className={classes.dropdown}
          onChange={onChange}
          mode="hierarchical"
          keepOpenOnSelect={true}
        />
      );
    }, [data]);

    return (
      <div>
        {open ? (
          <div className={classes.modalContainer}>
            <div className={classes.modalTitleContainer}>
              <div className={classes.title} onClick={onClose}>
                X
              </div>
              <span className={classes.title}>Configuration Panel</span>
              <span>
                <Button className={classes.applyBtn} onClick={onApplySelection}>
                  Apply Filters
                </Button>{' '}
              </span>
            </div>
            <div className={classes.mainContainer}>
              <div className={classes.inputGroup}>
                <label className={classes.inputGroupTitle}>
                  Select Measure
                </label>
                {DropDownTreeSelectKPIS}
              </div>
              <div className={classes.inputGroup}>
                <label className={classes.inputGroupTitle}>Select Fields</label>

                <label className={classes.inputGroupSubtitle}>
                  Select Product Group
                </label>
                {DropDownTreeSelectPG}

                <label className={classes.inputGroupSubtitle}>
                  Select Division&gt; Department&gt; Class
                </label>
                {DropDownTreeSelectDIV}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
);

export default CampaignSummaryPopup;
