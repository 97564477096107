import {
    DRILLDOWNFILTER
} from '../types';
import { drilldownFilterData } from '../../views/Common/Data/drilldownFilter';

const initialState = {
    drilldownFilter: drilldownFilterData
};
const drilldownFilterReducer = (state = initialState, action) => {
    switch (action.type) {
        case DRILLDOWNFILTER:
            return {
                ...state,
                drilldownFilter: action.payload
            };
        default:
            return state;
    }
};
export default drilldownFilterReducer;