// import React from 'react';

// const ProgressBar = props => {
//   const { bgColor, completed, label } = props;

//   const containerStyles = {
//     height: 20,
//     width: '80%',
//     backgroundColor: '#e0e0de',
//     borderRadius: 50,
//     margin: 0,
//     position: 'relative'
//   };

//   const fillerStyles = {
//     height: '100%',
//     width: `${completed}%`,
//     backgroundColor: bgColor,
//     borderRadius: 'inherit',
//     textAlign: 'center'
//   };

//   const labelStyles = {
//     padding: 10,
//     color: 'white',
//     fontWeight: 'bold'
//   };
//   const valStyles = {
//     color: '#000',
//     position: 'absolute',
//     top: 0,
//     right: '6px',
//     fontSize: 10
//   };
//   return (
//     <div style={containerStyles}>
//       <div style={fillerStyles}>
//         <span style={labelStyles}>{label}</span>
//       </div>
//       <span style={valStyles}>{`${completed}%`}</span>
//     </div>
//   );
// };

// export default ProgressBar;

import React from 'react';
import { Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

const ProgressBar = props => {
  const { data, viewStatus } = props;
  const root = {
    width: '100%',
    position: 'relative',
    margin: '0 0 20px',
    float: 'left'
  };
  const containerStyles = {
    height: 36,
    width: '75%',
    backgroundColor: '#e0e0de',
    borderRadius: 0,
    display: 'inline-block',
    position: 'relative',
    float: 'left'
  };
  const info = {
    padding: '40px 0 !important',
    textAlign: 'center'
  };

  return (
    <>
      {data ? (
        data.segmentDatapts
          .sort((a, b) => b.lift - a.lift)
          .map((item, index) => (
            <div style={root} key={index}>
              <div style={containerStyles} className={'dialogProgressbar'}>
                <div
                  style={{
                    width: `${
                      viewStatus !== 'purchaseBehavior'
                        ? item.testDuringValue >= 100
                          ? 100
                          : item.testDuringValue <= 0
                          ? 0
                          : item.testDuringValue
                        : item.percentage >= 100
                        ? 100
                        : item.percentage <= -100
                        ? 100
                        : Math.abs(item.percentage)
                    }%`,
                    backgroundColor:
                      viewStatus !== 'purchaseBehavior'
                        ? item.percentage > 0
                          ? '#24A148'
                          : '#E01F29'
                        : // : item.lift > 0
                        // ? '#24A148'
                        // : '#E01F29'

                        item.percentage >= 1
                        ? '#24A148'
                        : item.percentage <= -1
                        ? '#E01F29'
                        : '#ff8f00'
                  }}
                  className="colorBar">
                  <span
                    style={{
                      color: '#404041'
                      // color: `${
                      //   item.percentage >= 100
                      //     ? "#ffffff"
                      //     : item.percentage <= 0
                      //     ? "#404041"
                      //     : "#ffffff"
                      // }`,
                    }}>
                    {item.dataPointName === 'Flight Details'
                      ? item.flight_id
                      : item.dataPointName}
                  </span>
                </div>
                <span
                  className={'valStyles'}
                  // style={{
                  //   color: `${
                  //     item.percentage >= 100
                  //       ? "#ffffff"
                  //       : item.percentage <= 0
                  //       ? "#404041"
                  //       : "#404041"
                  //   }`,
                  // }}
                >
                  {viewStatus !== 'purchaseBehavior'
                    ? `${item.testDuringValue}%`
                    : `${item.percentage}%`}
                </span>
              </div>
              <div
                className={'liftStyles'}
                style={{
                  color:
                 item.stat_sig < 0.05
                  ? '#24A148'
                  : item.stat_sig > 0.15
                  ? '#E01F29'
                  : '#ff8f00'
                    // viewStatus !== 'purchaseBehavior'
                    //   ? item.percentage > 0
                    //     ? '#24A148'
                    //     : '#E01F29'
                    //   : item.lift > 0
                    //   ? '#24A148'
                    //   : '#E01F29'
                }}>
                <span>
                  {viewStatus !== 'purchaseBehavior' ? '%Change :' : 'Lift :'}
                </span>
                <span>
                  {viewStatus !== 'purchaseBehavior' ? (
                    item.percentage > 0 ? (
                      <ArrowDropUpIcon />
                    ) : (
                      <ArrowDropDownIcon />
                    )
                  ) : item.lift > 0 ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )}

                  <span>
                    {viewStatus !== 'purchaseBehavior'
                      ? item.percentage === 0
                        ? `${item.percentage}%`
                        : `${item.percentage.toFixed(2)}%`
                      : item.lift === 0
                      ? `${item.lift}%`
                      : `${item.lift.toFixed(2)}%`}
                  </span>
                </span>
                <span>&nbsp; {`(${item.stat_sig})`}</span>
              </div>
            </div>
          ))
      ) : (
        <Typography component="h3" variant="h3" style={info}>
          {'No records to display'}
        </Typography>
      )}
    </>
  );
};

export default ProgressBar;
