import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
  root: {
    // paddingTop: 40,
    position: "relative",
    padding: "0 24px 24px"
  },
  pageHeading: {
    fontSize: 20,
    color: '#182C52'
  },
  cardHeading: {
    fontWeight: 400,
    fontSize: 16,
    letterSpacing: "-0.02em",
    color: "#404041",
    paddingBottom: 20,
  },
  detailBlock: {
    width: "95%",
    margin: "0 auto",
  },
  description: {
    background: "#FCFCFC",
    border: "1px solid #E6E7E8",
    padding: 10,
    fontSize: 12,
  },
  heading: {
    fontSize: 14,
    color: "#929497",
    marginBottom: 5,
  },
  value: {
    fontSize: 14,
    color: "#333333",
    fontWeight: 500,
  },
  durationArea: {
    color: "#182C52", 
    fontWeight: 500,
    fontSize: 14,
    "& svg": {
      fontSize: 18,
      marginRight: 5,
      verticalAlign: "text-top",
    },
  },
  redLiftBox: {
    width: "100%",
    height: 150,
    background: "#FBE8E9", 
    textAlign: "center",
    padding: "15px",
    float: "right",
    "& p:first-child": {
      fontSize: 18,
      letterSpacing: "-0.02em",
    },
    "& p:nth-child(2)": {
      color: "#E01F29",
      margin: "15px 0",
      fontSize: 30,
      "& svg": {
        marginRight: 5,
        fontSize: 32,
        verticalAlign: "middle",
      },
    },
    "& p:last-child": {
      color: "#E01F29",
      fontSize: 18,
    },
  },
  greenLiftBox: {
    width: "100%",
    height: 150,
    background: "#EAF6ED",
    textAlign: "center",
    padding: "15px",
    float: "right",
    "& p:first-child": {
      fontSize: 18,
      letterSpacing: "-0.02em",
    },
    "& p:nth-child(2)": {
      color: "#24A148",
      margin: "15px 0",
      fontSize: 30,
      "& svg": {
        marginRight: 5,
        fontSize: 32,
        verticalAlign: "middle",
      },
    },
    "& p:last-child": {
      color: "#24A148",
      fontSize: 18,
    },
  },
}));