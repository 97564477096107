import React, { useState } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

function SwitchToggle(props) {
  const { label, values, name, onChange } = props;
  const [state, setState] = useState(false);

  const handleChange = event => {
    onChange(!state);
    setState(prev => !prev);
  };
  return (
    <FormControlLabel
      control={
        <Switch
          name={name}
          checked={values[name]}
          onChange={handleChange}
          color="primary"
        />
      }
      label={label}
    />
  );
}

export default SwitchToggle;
