import axios from 'axios';

export const config = {
  API_URL: '',
  API_URL_MOCK: '',
  EXTENDED_STATS: true,
};

const env = process?.env;

export const setupConfig = async () => {
  if (env?.NODE_ENV === 'development') {
    config.API_URL = env.REACT_APP_API_URL || '';
  } else {
    const { data: response } = await axios.get('/env');
    config.API_URL = response.env.REACT_APP_API_URL || '';
  }
};