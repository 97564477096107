import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Box,
  TableHead,
  TableCell,
  TableRow,
  Typography,
  Button,
  TablePagination,
  CircularProgress
} from '@material-ui/core/';
import _ from 'lodash';
import { Form, Formik } from 'formik';
import secureAxios from 'api-config/api';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MultiSelectDropdown from '../../../Common/Filters/MultiSelectDropdown';
import {
  getDrilldownFilterData,
  setdrilldownFilterDownFilter,
  updateSelectedDrilldownFilter
} from '../../../../api-config/index';
import MeasureCharts from '../MeasureCharts';
import CustomMaterialTable from '../../../Common/CustomMaterialTable';
import { numberWithCommas } from '../../../Common/Utils/ClientUtil';
import { useLayoutEffect } from 'react';
import ExportCsv from 'views/Common/Utils/ExportCsv';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ReactMapZoom from 'components/Map/leafMapZoom';
import MapChart from 'components/Map/MeasureMap';

const useStyles = makeStyles(theme => ({
  filterArea: {
    padding: '16px 14px',
    background: '#F4F6F8',
    margin: '3px 0 10px 0',
    '& $input': {
      padding: 10
    },
    '& $label': {
      transform: 'translate(14px, 10px) scale(1)'
    },
    '& $div.MuiSelect-outlined.MuiSelect-outlined': {
      padding: 10
    }
  },
  accordHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },

  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: '#E6E7E8',
    borderStyle: 'solid',
    color: '#ffffff',
    fontWeight: 400,
    cursor: 'pointer',
    fontSize: 14,
    padding: '4px 4px',
    textAlign: 'center'
  },
  info: {
    padding: '40px 0 !important',
    textAlign: 'center',
    '& h3': {
      lineHeight: 10
    }
  },
  dropDownArea: {
    minWidth: 165,
    maxWidth: 165,
    padding: '6px !important',
    float: 'left'
  },
  secDropDownArea: {
    minWidth: 185,
    maxWidth: 185,
    padding: '6px !important',
    float: 'left'
  },
  expandArea: {
    float: 'left',
    width: '100%'
  },
  actionArea: {
    float: 'left',
    width: '100%',
    textAlign: 'right',
    marginTop: 10
  },
  submitBtnArea: {
    display: 'inline-block',
    padding: '0 10px',
    '& button': {
      color: '#182C52'
    }
  },
  resetBtnArea: {
    display: 'inline-block',
    padding: '0 10px'
  },
  exportArea: {
    display: 'inline-block',
    '& svg': { verticalAlign: 'middle' }
  },
  defaultArea: {
    width: '90%',
    float: 'left',
    paddingBottom: 12
  },
  moreFiltersArea: {
    width: '10%',
    float: 'left',
    padding: '6px !important',
    '& span': {
      color: '#182C52'
    }
  }
}));

const MeasureDrillHome = props => {
  const application = useSelector(state => ({
    ...state.application
  }));
  const { outlierChecked, campaign_id } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [tblPage, setTblPage] = useState(1);
  const [tblCount, setTblCount] = useState(0);
  const [tblPerpage, setTblPerpage] = useState(10);
  const [tblData, setTblData] = useState([]);
  const [msg, setMsg] = useState('Loading...');
  const [load, setLoad] = useState(false);
  const [graphData, setGraphData] = useState([]);
  const [expandArea, setExpandArea] = useState({ isHidden: true });
  const [downLoaddata, setdownLoaddata] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const [selectButtonToggle, setselectButtonToggle] = useState(true);
  const [stateDropvalue, setstateDropvalue] = useState(0);
  const [mapData, setMapData] = useState([]);
  const [content, setContent] = useState(0);
  const [mapData1, setmapData1] = useState([]);
  const [stateMapvalue, setstateMapvalue] = useState(0);
  //const { campaign_id } = application;

  const handleSort = column => {
    if (column === sortColumn && sortDirection === 'asc') {
      // If the same column is clicked, toggle the sort direction
      setSortDirection('desc');
    } else if (column === sortColumn && sortDirection === 'desc') {
      setSortColumn(undefined);
      setSortDirection(undefined);
    } else {
      // If a new column is clicked, set the sort column and direction
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const renderSortIcon = column => {
    if (column === sortColumn) {
      // Display an arrow icon based on the sort direction
      return sortDirection === 'asc' ? (
        <ArrowUpwardIcon />
      ) : (
        <ArrowDownwardIcon />
      );
    }
    // Display a default icon or null if the column is not sorted
    return null;
  };

  // Your data array
  const [data, setData] = useState([
    { outletStrategy: 'Value 1', mensTier: 'Value 2' }
    // Add more data objects
  ]);

  // Sorting function for sorting the data array based on the sortColumn and sortDirection
  const sortData = () => {
    getTblData(appliedFilters, sortColumn, sortDirection);
    // if (sortColumn) {
    // const sortedData = [...tblData];
    // console.log(tblData, sortColumn)
    // sortedData.sort((a, b) => {
    //   const valueA = a[sortColumn];
    //   const valueB = b[sortColumn];

    //   // Convert values to strings for proper comparison
    //   const stringA = String(valueA);
    //   const stringB = String(valueB);

    //   // Sort the data based on the sortDirection
    //   return sortDirection === 'asc' ? stringA.localeCompare(stringB) : stringB.localeCompare(stringA);
    // });
    // setTblData(sortedData);
    // }
  };
  React.useEffect(() => {
    sortData();
  }, [sortColumn, sortDirection]);

  const [selectedTblKpi, setSelectedTblKpi] = useState({
    id: '',
    name: '',
    type: '',
    label: ''
  });

  const [appliedFilters, setAppliedFilters] = useState({
    selectedKpi: [],
    productGroup: [],
    storeId: [],
    brands: [],
    division: [],
    department: [],
    class: [],
    outletStrategy: [],
    mensTier: [],
    womensTier: [],
    aerieFormat: [],
    aerieSetUp: [],
    btsPeak: [],
    region: [],
    factoryDesignation: []
  });
  const drilldownFilterReducer = useSelector(state => ({
    ...state.drilldownFilterReducer
  }));
  const drilldownSelectedFilterReducer = useSelector(state => ({
    ...state.drilldownSelectedFilterReducer
  }));
  const { drilldownFilter } = drilldownFilterReducer;
  console.log('drilldownFilter', drilldownFilter);
  const columns = [
    // {
    //   field: 'Outlet Strategy',
    //   width: '200px',
    //   colspan: 1,
    //   sorting: true,
    //   filtering: true,
    //   defaultSort: 'asc',
    //   render: rowData => (
    //     <Typography
    //       style={
    //         selectedTblKpi.id === rowData.kpiRowId ? { color: '#404041' } : {}
    //       }>
    //       {rowData.outletStrategy}
    //     </Typography>
    //   )
    // },
    // {
    //   field: 'Mens Tier',
    //   width: '200px',
    //   colspan: 1,
    //   render: rowData => (
    //     <Typography
    //       style={
    //         selectedTblKpi.id === rowData.kpiRowId ? { color: '#404041' } : {}
    //       }>
    //       {rowData.mensTier}
    //     </Typography>
    //   )
    // },
    // {
    //   field: 'Womens Tier',
    //   width: '200px',
    //   colspan: 1,
    //   render: rowData => (
    //     <Typography
    //       style={
    //         selectedTblKpi.id === rowData.kpiRowId ? { color: '#404041' } : {}
    //       }>
    //       {rowData.womensTier}
    //     </Typography>
    //   )
    // },
    // {
    //   field: 'Aerie Format',
    //   width: '200px',
    //   colspan: 1,
    //   render: rowData => (
    //     <Typography
    //       style={
    //         selectedTblKpi.id === rowData.kpiRowId ? { color: '#404041' } : {}
    //       }>
    //       {rowData.aerieFormat}
    //     </Typography>
    //   )
    // },
    // {
    //   field: 'Aerie Setup',
    //   width: '200px',
    //   colspan: 1,
    //   render: rowData => (
    //     <Typography
    //       style={
    //         selectedTblKpi.id === rowData.kpiRowId ? { color: '#404041' } : {}
    //       }>
    //       {rowData.aerieSetUp}
    //     </Typography>
    //   )
    // },
    // {
    //   field: 'BTS Peak',
    //   width: '200px',
    //   colspan: 1,
    //   render: rowData => (
    //     <Typography
    //       style={
    //         selectedTblKpi.id === rowData.kpiRowId ? { color: '#404041' } : {}
    //       }>
    //       {rowData.btsPeak}
    //     </Typography>
    //   )
    // },

    {
      field: 'REGION',
      colspan: 1,
      render: rowData => (
        <Typography
          style={
            selectedTblKpi.id === rowData.region ? { color: '#404041' } : {}
          }>
          {rowData.region}
        </Typography>
      )
    },
    // {
    //   field: 'Factory Designation',
    //   width: '200px',
    //   colspan: 1,
    //   render: rowData => (
    //     <Typography
    //       style={
    //         selectedTblKpi.id === rowData.kpiRowId ? { color: '#404041' } : {}
    //       }>
    //       {rowData.factoryDesignation}
    //     </Typography>
    //   )
    // },
    {
      field: 'KPI',
      width: '200px',
      colspan: 1,
      render: rowData => (
        <Typography
          style={
            selectedTblKpi.id === rowData.kpiRowId ? { color: '#404041' } : {}
          }>
          {rowData.kpiLabel}
        </Typography>
      )
    },

    {
      field: 'PRODUCT GROUP',
      width: '100px',
      colspan: 1,
      render: rowData => (
        <Typography
          style={
            selectedTblKpi.id === rowData.kpiRowId ? { color: '#404041' } : {}
          }>
          {rowData.product_grp}
        </Typography>
      )
    },
    {
      field: 'STORE ID',
      colspan: 1,
      render: rowData => (
        <Typography
          style={
            selectedTblKpi.id === rowData.storeId ? { color: '#404041' } : {}
          }>
          {rowData.storeId}
        </Typography>
      )
    },
    {
      field: 'BRAND',
      colspan: 1,
      render: rowData => (
        <Typography
          style={
            selectedTblKpi.id === rowData.brand ? { color: '#404041' } : {}
          }>
          {rowData.brand}
        </Typography>
      )
    },

    {
      field: 'DIVISION',
      colspan: 1,
      render: rowData => (
        <Typography
          style={
            selectedTblKpi.id === rowData.region ? { color: '#404041' } : {}
          }>
          {rowData.division}
        </Typography>
      )
    },
    {
      field: 'DEPARMENT',
      colspan: 1,
      render: rowData => (
        <Typography
          style={
            selectedTblKpi.id === rowData.region ? { color: '#404041' } : {}
          }>
          {rowData.dept}
        </Typography>
      )
    },
    {
      field: 'CLASS',
      colspan: 1,
      render: rowData => (
        <Typography
          style={
            selectedTblKpi.id === rowData.region ? { color: '#404041' } : {}
          }>
          {rowData.class}
        </Typography>
      )
    },
    {
      field: 'PRE_TEST',
      width: '10%',
      render: rowData => {
        return (
          rowData['PRE_TEST'],
          rowData.test_pre_value
            ? numberWithCommas(rowData.test_pre_value.toFixed(2))
            : ''
        );
      }
    },
    {
      field: 'POST_TEST',
      render: rowData => {
        return (
          rowData['POST_TEST'],
          rowData.test_during_value
            ? numberWithCommas(rowData.test_during_value.toFixed(2))
            : ''
        );
      }
    },
    {
      field: '% Change',
      render: rowData => {
        return (
          rowData['% Change'],
          rowData.test_pct_change ? rowData.test_pct_change.toFixed(2) : ''
        );
      }
    },
    {
      field: 'PRE_CONTROL',
      render: rowData => {
        return (
          rowData['PRE_CONTROL'],
          rowData.control_pre_value
            ? numberWithCommas(rowData.control_pre_value.toFixed(2))
            : ''
        );
      }
    },

    {
      field: 'POST_CONTROL',
      render: rowData => {
        return (
          rowData['POST_CONTROL'],
          rowData.control_during_value
            ? numberWithCommas(rowData.control_during_value.toFixed(2))
            : ''
        );
      }
    },
    {
      field: '% Change',
      render: rowData => {
        return (
          rowData['% Change'],
          rowData.control_pct_change
            ? rowData.control_pct_change.toFixed(2)
            : ''
        );
      }
    },
    {
      field: 'Lift %',
      render: rowData => {
        return (
          rowData['Lift %'], rowData.pct_lift ? rowData.pct_lift.toFixed(2) : ''
        );
      }
    },
    {
      field: 'ABS_LIFT',
      render: rowData => {
        return (
          rowData['ABS_LIFT'],
          rowData.abs_lift ? numberWithCommas(rowData.abs_lift.toFixed(2)) : ''
        );
      }
    },

    {
      field: 'STAT_SIG',
      render: rowData => (
        <Typography
          style={{
            color: rowData.significance < 0.05 ? '#24A148 ' : '#E01F29'
          }}>
          {rowData.significance}
        </Typography>
      )
    }
  ];
  const getTblData = (filters, orderByCol, order) => {
    setAppliedFilters(filters);
    dispatch({
      type: 'LOADING',
      payload: true
    });

    secureAxios
      .post('/measureCampaign/getDrilldownData', {
        outlierValue: outlierChecked,
        filters: filters,
        campaign_id: campaign_id,
        page: tblPage,
        perpage: tblPerpage,
        orderByCol,
        order
      })

      .then(res => {
        if (res.status === 200) {
          //   dispatch({
          //     type: 'LOADING',
          //     payload: false
          //   });
          if (res.data.responseData.length === 0) {
            setMsg('No Data');
          }
          let resData = res?.data?.responseData;
          let count = res?.data?.responseCount;
          let downLoaddata = res?.data?.responseDownload;
          setdownLoaddata(downLoaddata);
          setTblData(resData);
          setTblCount(count || 0);
          setSelectedTblKpi({
            ...selectedTblKpi,
            id: resData[0]['kpiRowId'],
            name: resData[0]['kpi'],
            type: resData[0]['kpiType'],
            label: resData[0]['kpiLabel']
          });
          getGraphData(resData[0]['kpiRowId']);
          // getMapData(filters);
        }
      })

      .catch(err => {
        console.log(err);
      })
      .finally(err => {
        dispatch({
          type: 'LOADING',
          payload: false
        });
        console.log(err);
      });
  };
  //file download
  // console.log(tblData,"tblData");
  // console.log(downLoaddata,"downLoaddata");
  // console.log(updatedExportData,"updatedExportData");
  const updatedExportData = downLoaddata.map(
    ({ summaryData, tableData, ...rest }) => rest
  );
  //  const updatedExportData = downLoaddata.map(
  //   ({ summaryData, tableData, ...rest }) => rest
  // );
  const getGraphData = rowID => {
    setLoad(true);
    // dispatch({
    //     type: 'LOADING',
    //     payload: true
    // });
    secureAxios
      .post('/measureCampaign/getDrilldownGraphData', {
        outlierValue: outlierChecked,
        filters: {},
        campaign_id: campaign_id,
        selectedKpiRowId: rowID
      })
      .then(res => {
        if (res.status === 200) {
          // dispatch({
          //     type: 'LOADING',
          //     payload: false
          // });
          let resData = res?.data?.responseData;
          setGraphData(resData);
        }
      })
      .catch(err => {
        console.log(err);
      })
      .finally(err => {
        setLoad(false);
      });
  };
  const getMapData = (filters, drilldownFilter) => {
    console.log(drilldownFilter, 'drilldownFilter-123');
    console.log(drilldownFilter, 'drilldownFilter-123');
    // setLoad(true);
    console.log(filters, 'filters');
    // setAppliedFilters(filters);
    dispatch({
      type: 'LOADING',
      payload: true
    });
    secureAxios
      .post('/measureCampaign/getMeasureMapData', {
        outlierValue: outlierChecked,
        filters: filters,
        campaignID: campaign_id,
        drilldownFilter: drilldownFilter
      })
      .then(res => {
        if (res.status == 200) {
          // setMapData([]);
          let testcont = res.data.data;
          // console.log(values, 'res.data');
          // setMapData(testcont);
          console.log(testcont, 'res.data-640');
          const filteredDatagainstStoreId = res.data.mapStoreData;

          console.log('hgsfdyuds', filteredDatagainstStoreId);
          // if (filteredDatagainstStoreId.length) {
          //   filteredDatagainstStoreId.map((val, ind) => {
          //     res.data.data.map((value, index) => {
          //       if (val.control_store_id === value.store_id) {
          //         filteredDatagainstStoreId[ind].test_control_flag =
          //           value.test_control_flag;
          //         filteredDatagainstStoreId[ind].data = value;
          //       }
          //       if (val.TEST_STORE_ID === value.store_id) {
          //         filteredDatagainstStoreId.push(value);
          //       }
          //     });
          //   });
          // }
          if (filteredDatagainstStoreId.length) {
            filteredDatagainstStoreId.map((val, ind) => {
              const filterMapData = res.data.filterMapData.filter(
                d => d.storeId === val.TEST_STORE_ID
              );
              res.data.data.map((value, index) => {
                if (val.control_store_id === value.store_id) {
                  filteredDatagainstStoreId[ind].test_control_flag =
                    value.test_control_flag;
                  filteredDatagainstStoreId[ind].data = value;
                }
                if (val.TEST_STORE_ID === value.store_id) {
                  console.log(filterMapData, 'filterMapData-res');
                  const filterMapData = res.data.filterMapData.filter(
                    d => d.storeId === val.TEST_STORE_ID
                  );
                  console.log(filterMapData, 'filterMapData-715');
                  if (filterMapData && filterMapData.length > 0) {
                    console.log(filterMapData, 'filterMapData-718');
                    value.abs_lift = filterMapData[0].abs_lift;
                    value.significance = filterMapData[0].significance;
                    value.pct_lift = filterMapData[0].pct_lift;
                    value.kpiRowId = filterMapData[0].kpiRowId;
                  }
                  console.log(value, 'value-check');
                  filteredDatagainstStoreId.push(value);
                }
              });
            });
          }
          console.log('filteredDatagainstStoreId', filteredDatagainstStoreId);
          //console.log(DRILLDOWNFILTER, 'drilldownFilter-map');
          let absLift = filteredDatagainstStoreId.abs_lift;
          let sigficancedata = filteredDatagainstStoreId.significance;
          let pctliftdata = filteredDatagainstStoreId.significance;
          console.log('filteredDatagainstStoreId', filteredDatagainstStoreId);
          setmapData1(filteredDatagainstStoreId);
          setstateMapvalue(0);
          //setMapData(filteredDatagainstStoreId);
          // setData(res.data);
          //dispatch(setLoading(false));
        }
      });
  };
  console.log('mapdarrrratu', mapData);
  useEffect(() => {
    const fetchData = async storeId => {
      dispatch({
        type: 'LOADING',
        payload: true
      });
      console.log(drilldownFilter, 'drilldownFilter-123');
      try {
        // Make the POST call
        const response = await secureAxios.post(
          '/measureCampaign/getMeasureMapDataLoad',
          {
            outlierValue: outlierChecked,
            filters: drilldownFilter,
            campaignID: campaign_id,
            drilldownFilter: drilldownFilter,
            storeId
          }
        );

        // Process the response and set the tooltip content and enable/disable
        console.log(response, 'response-693');
        const filteredDatagainstStoreId = response.data.mapStoreData;
        // const tooltipEnabled = response.data.enabled;
        console.log('hgsfdyuds-697', filteredDatagainstStoreId);
        if (filteredDatagainstStoreId.length) {
          filteredDatagainstStoreId.map((val, ind) => {
            const filterMapData = response.data.filterMapData.filter(
              d => d.storeId === val.TEST_STORE_ID
            );
            response.data.data.map((value, index) => {
              if (val.control_store_id === value.store_id) {
                filteredDatagainstStoreId[ind].test_control_flag =
                  value.test_control_flag;
                filteredDatagainstStoreId[ind].data = value;
              }
              console.log(val, 'val-744');
              console.log(value, 'val-745');
              if (val.TEST_STORE_ID === value.store_id) {
                console.log(filterMapData, 'filterMapData-res');
                const filterMapData = response.data.filterMapData.filter(
                  d => d.storeId === val.TEST_STORE_ID
                );
                console.log(filterMapData, 'filterMapData-715');
                if (filterMapData && filterMapData.length > 0) {
                  console.log(filterMapData, 'filterMapData-718');
                  value.abs_lift = filterMapData[0].abs_lift;
                  value.significance = filterMapData[0].significance;
                  value.pct_lift = filterMapData[0].pct_lift;
                  value.kpiRowId = filterMapData[0].kpiRowId;
                }
                console.log(value, 'value-check');
                filteredDatagainstStoreId.push(value);
              }
            });
          });
        }
        console.log('filteredDatagainstStoreId', filteredDatagainstStoreId);
        //console.log(DRILLDOWNFILTER, 'drilldownFilter-map');
        let absLift = filteredDatagainstStoreId.abs_lift;
        let sigficancedata = filteredDatagainstStoreId.significance;
        let abspctcedata = filteredDatagainstStoreId.significance;
        console.log('filteredDatagainstStoreId-731', filteredDatagainstStoreId);
        // setmapData1(filteredDatagainstStoreId);
        //setMapData(filteredDatagainstStoreId);
        setmapData1(filteredDatagainstStoreId);
        // setmapFlag(true);
        // setstateMapvalue(1);
        //setTooltipContent(tooltipEnabled ? storeId : '');
      } catch (error) {
        console.error('Error occurred while making the POST call:', error);
      }
      dispatch({
        type: 'LOADING',
        payload: false
      });
    };
    fetchData();
  }, []);
  const handleScrollToMiddle = () => {
    const windowHeight = window.innerHeight;
    const middleOfPage = windowHeight / 1;
    window.scrollTo(0, middleOfPage);
  };
  const toggleExpandArea = () => {
    setExpandArea({ isHidden: !expandArea.isHidden });
  };

  const pushAllToFront = array => {
    const index = array.indexOf('ALL');
    if (index !== -1) {
      array.splice(index, 1);
      array.unshift('ALL');
    }
    return array;
  };

  useEffect(() => {
    console.log(getDrilldownFilterData, 'getDrilldownFilterData');
    dispatch(
      getDrilldownFilterData({
        campaign_id: campaign_id,
        outlierValue: outlierChecked,

        selectedValue: {}
      })
    );
  }, [outlierChecked]);
  useLayoutEffect(() => {
    getTblData(appliedFilters);
  }, [tblPage, tblPerpage]);

  let kpiFormatObj = {
    upt: 'UPT',
    transactions: 'Transaction Count',
    margin_amt: 'Margin Amount',
    traffic: 'Traffic',
    sales: 'Net Sales($)',
    conversion_rate: 'Conversion rate',
    inv_oh: 'Inventory on hand',
    ads: 'ADS',
    units: 'Net Sales Units',
    actual_cost_amt: 'Actual Cost Amount',
    aur: 'AUR',
    margin_rate: 'Margin Rate'
  };

  let kpiInverseFormatObj = {
    UPT: 'upt',
    'Transaction Count': 'transactions',
    'Margin Amount': 'margin_amt',
    Traffic: 'traffic',
    'Net Sales($)': 'sales',
    'Conversion rate': 'conversion_rate',
    'Inventory on hand': 'inv_oh',
    ADS: 'ads',
    'Net Sales Units': 'units',
    'Actual Cost Amount': 'actual_cost_amt',
    AUR: 'aur',
    'Margin Rate': 'margin_rate'
  };

  const BrandDropDownOption = () => {};
  const selectAll = (values, setFieldValue, drilldownFilter) => {
    let appliedDrilldown = { ...appliedFilters };
    console.log(appliedDrilldown, '787.appliedDrilldown');

    // const allOptions = Object.values(drilldownFilter?.primary).flatMap(item =>
    //   Object.values(item.value)
    // );
    // console.log('allOptions',allOptions)
    // const selectedValue = allOptions.filter(option => option !== "all"); // Exclude the "All" option if present
    if (selectButtonToggle) {
      console.log(drilldownFilter, '787--drilldownFilter');
      for (let i in drilldownFilter.primary) {
        if (i) {
          if (drilldownFilter.primary[i].value.includes('ALL'))
            drilldownFilter.primary[i].selectedValue = ['ALL'];
          drilldownFilter.primary.STORE_ID.selectedValue =
            drilldownFilter.primary.STORE_ID.value;
        }
      }
      for (let i in drilldownFilter.secondary) {
        if (i) {
          if (drilldownFilter.secondary[i].value.includes('ALL'))
            drilldownFilter.secondary[i].selectedValue = ['ALL'];
        }
      }
      console.log(drilldownFilter, 'drilldownFilter');
      appliedDrilldown.brands = drilldownFilter.primary.BRAND.selectedValue;
      // appliedDrilldown.selectedKpi = drilldownFilter.primary.KPI_NAME.selectedValue
      appliedDrilldown.productGroup =
        drilldownFilter.primary.PRODUCT_GRP.selectedValue;
      appliedDrilldown.storeId = drilldownFilter.primary.STORE_ID.selectedValue;
      appliedDrilldown.brands = drilldownFilter.primary.BRAND.selectedValue;
      appliedDrilldown.division =
        drilldownFilter.primary.DIVISION.selectedValue;
      appliedDrilldown.department = drilldownFilter.primary.DEPT.selectedValue;
      appliedDrilldown.class = drilldownFilter.primary.CLASS.selectedValue;
      appliedDrilldown.outletStrategy =
        drilldownFilter.secondary.OUTLET_STRATEGY.selectedValue;
      appliedDrilldown.mensTier =
        drilldownFilter.secondary.MENS_TIER.selectedValue;
      appliedDrilldown.womensTier =
        drilldownFilter.secondary.WOMENS_TIER.selectedValue;
      appliedDrilldown.aerieFormat =
        drilldownFilter.secondary.AERIE_FORMAT.selectedValue;
      appliedDrilldown.aerieSetUp =
        drilldownFilter.secondary.AERIE_SET_UP.selectedValue;
      appliedDrilldown.btsPeak =
        drilldownFilter.secondary.BTS_PEAK.selectedValue;
      appliedDrilldown.region = drilldownFilter.secondary.REGION.selectedValue;
      appliedDrilldown.factoryDesignation =
        drilldownFilter.secondary.FACTORY_DESIGNATION.selectedValue;
      setAppliedFilters(appliedDrilldown);
      getTblData(appliedDrilldown);
      dispatch(updateSelectedDrilldownFilter(appliedDrilldown));
      setselectButtonToggle(false);
      setstateDropvalue(1);
    } else {
      console.log('787--NON-TOGGLE');
      for (let i in drilldownFilter.primary) {
        if (i) {
          drilldownFilter.primary[i].selectedValue = [];
        }
      }
      for (let i in drilldownFilter.secondary) {
        if (i) {
          drilldownFilter.secondary[i].selectedValue = [];
        }
      }

      appliedDrilldown.brands = [];
      appliedDrilldown.selectedKpi = [];
      appliedDrilldown.productGroup = [];
      appliedDrilldown.storeId = [];
      appliedDrilldown.brands = [];
      appliedDrilldown.division = [];
      appliedDrilldown.department = [];
      appliedDrilldown.class = [];
      appliedDrilldown.outletStrategy = [];
      appliedDrilldown.mensTier = [];
      appliedDrilldown.womensTier = [];
      appliedDrilldown.aerieFormat = [];
      appliedDrilldown.aerieSetUp = [];
      appliedDrilldown.btsPeak = [];
      appliedDrilldown.region = [];
      appliedDrilldown.factoryDesignation = [];
      setselectButtonToggle(true);
      setstateDropvalue(1);
    }
    if ('STORE_ID' in appliedDrilldown) {
      delete appliedDrilldown.storeId;
  }

    console.log(appliedDrilldown, 'appliedDrilldown');
    getTblData(appliedDrilldown);
    setAppliedFilters(appliedDrilldown);
    setFieldValue(appliedDrilldown);
  };
  console.log('');

  return (
    <>
      <Box component="div" className={classes.filterArea}>
        <Formik
          initialValues={{
            STORE_ID: appliedFilters.storeId,
            KPI_NAME: appliedFilters.selectedKpi,
            PRODUCT_GRP: appliedFilters.productGroup,
            BRAND: appliedFilters.brands,
            DIVISION: appliedFilters.division,
            DEPT: appliedFilters.department,
            CLASS: appliedFilters.class,
            OUTLET_STRATEGY: appliedFilters.outletStrategy,
            MENS_TIER: appliedFilters.mensTier,
            WOMENS_TIER: appliedFilters.womensTier,
            AERIE_FORMAT: appliedFilters.aerieFormat,
            AERIE_SET_UP: appliedFilters.aerieSetUp,
            BTS_PEAK: appliedFilters.btsPeak,
            REGION: appliedFilters.region,
            FACTORY_DESIGNATION: appliedFilters.factoryDesignation
            //selectedKpi: [], productGroup: [], storeId: [], brands: [], division: [], department: [], class: [],
            // outletStrategy: [], mensTier: [], womensTier: [], aerieFormat: [], aerieSetUp: [], btsPeak: [],
            // region: [], factoryDesignation: []
          }}
          enableReinitialize={true}
          onSubmit={values => {
            console.log('787.values--', values);
            console.log('787.stateDropvalue--', stateDropvalue);
            if (stateDropvalue == 0) {
              console.log('787.stateDropvalue--if--codition');
              getTblData({
                selectedKpi: values.KPI_NAME,
                productGroup: values.PRODUCT_GRP,
                storeId: values.STORE_ID,
                brands: values.BRAND,
                division: values.DIVISION,
                department: values.DEPT,
                class: values.CLASS,
                outletStrategy: values.OUTLET_STRATEGY,
                mensTier: values.MENS_TIER,
                womensTier: values.WOMENS_TIER,
                aerieFormat: values.AERIE_FORMAT,
                aerieSetUp: values.AERIE_SET_UP,
                btsPeak: values.BTS_PEAK,
                region: values.REGION,
                factoryDesignation: values.FACTORY_DESIGNATION
              });
              getMapData(
                {
                  selectedKpi: values.KPI_NAME,
                  productGroup: values.PRODUCT_GRP,
                  storeId: values.STORE_ID,
                  brands: values.BRAND,
                  division: values.DIVISION,
                  department: values.DEPT,
                  class: values.CLASS,
                  outletStrategy: values.OUTLET_STRATEGY,
                  mensTier: values.MENS_TIER,
                  womensTier: values.WOMENS_TIER,
                  aerieFormat: values.AERIE_FORMAT,
                  aerieSetUp: values.AERIE_SET_UP,
                  btsPeak: values.BTS_PEAK,
                  region: values.REGION,
                  factoryDesignation: values.FACTORY_DESIGNATION
                },
                drilldownFilter
              );
            } else {
              console.log('787.stateDropvalue--ELSE--codition');
              getMapData(
                {
                  selectedKpi: values.KPI_NAME,
                  productGroup: values.PRODUCT_GRP,
                  storeId: values.STORE_ID,
                  brands: values.BRAND,
                  division: values.DIVISION,
                  department: values.DEPT,
                  class: values.CLASS,
                  outletStrategy: values.OUTLET_STRATEGY,
                  mensTier: values.MENS_TIER,
                  womensTier: values.WOMENS_TIER,
                  aerieFormat: values.AERIE_FORMAT,
                  aerieSetUp: values.AERIE_SET_UP,
                  btsPeak: values.BTS_PEAK,
                  region: values.REGION,
                  factoryDesignation: values.FACTORY_DESIGNATION
                },
                drilldownFilter
              );
              getTblData(appliedFilters);
            }

            // const newFilterData = _.cloneDeep(drilldownFilter)
            // for (let key in newFilterData) {
            //     if (typeof newFilterData[key] === "object") {
            //         for (let nestedKey in newFilterData[key]) {
            //             for (let i in values) {
            //                 if (i === newFilterData[key][nestedKey].dbColumnName) {
            //                     newFilterData[key][nestedKey].value = values[i]
            //                 }
            //             }
            //         }
            //     }
            // }
            // dispatch(updateSelectedDrilldownFilter(newFilterData));
          }}>
          {({ values, setFieldValue, resetForm }) => (
            <Form noValidate autoComplete="off">
              <Grid container spacing={3}>
                <Box component="div" className={classes.defaultArea}>
                  {Object?.entries(drilldownFilter?.primary)?.map(
                    (item, index) => (
                      <Grid item className={classes.dropDownArea} key={index}>
                        {console.log('jdshufy', item, item[1]?.label)}
                        <MultiSelectDropdown
                          width={'w100'}
                          label={item[1]?.label}
                          name={item[0]}
                          value={item[1]?.selectedValue}
                          handleMethod={value => {
                            // debugger
                            if (item[1]?.dbColumnName == 'KPI_NAME') {
                              value = value.map(e => kpiInverseFormatObj[e]);
                            }
                            // value = value.map(e=> kpiInverseFormatObj[e])
                            {
                              console.log('Dropdown Value:', value);
                            }
                            setstateDropvalue(0);
                            setFieldValue(
                              Object?.keys(values)[item[1]?.displayOrder],
                              value
                            );
                            if (item[1]?.impacts?.length > 0 && value?.length) {
                              dispatch(
                                getDrilldownFilterData({
                                  campaign_id: campaign_id,
                                  outlierValue: outlierChecked,
                                  selectedValue: {
                                    value: value,
                                    key: item[0],
                                    impacts: item[1]?.impacts,
                                    selectedArray: drilldownFilter
                                  }
                                })
                              );
                            }
                          }}
                          options={
                            Array.isArray(item[1]?.value)
                              ? item[1].label == 'KPIs'
                                ? item[1]?.value.map(e => kpiFormatObj[e])
                                : pushAllToFront(item[1]?.value)
                              : item[1]?.value
                          }
                        />
                      </Grid>
                    )
                  )}
                </Box>
                {/* <Grid item className={classes.moreFiltersArea}>
               
                </Grid> */}
                <Grid item className={classes.moreFiltersArea}>
                  <Button type="button" onClick={toggleExpandArea}>
                    {expandArea.isHidden ? 'More Filters' : 'Hide Filters'}
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid
                  item
                  className={classes.expandArea}
                  style={{ display: expandArea.isHidden ? 'none' : 'block' }}>
                  <Grid container spacing={3}>
                    {Object?.entries(drilldownFilter?.secondary)?.map(
                      (item, index) => (
                        <Grid
                          item
                          className={classes.secDropDownArea}
                          key={index}>
                          <MultiSelectDropdown
                            width={'w100'}
                            label={item[1]?.label}
                            name={item[0]}
                            value={item[1]?.selectedValue}
                            handleMethod={value => {
                              setFieldValue(
                                Object?.keys(values)[item[1]?.displayOrder],
                                value
                              );
                              if (
                                item[1]?.impacts?.length > 0 &&
                                value?.length
                              ) {
                                dispatch(
                                  getDrilldownFilterData({
                                    campaign_id: campaign_id,
                                    outlierValue: outlierChecked,
                                    selectedValue: {
                                      value: value,
                                      key: item[0],
                                      impacts: item[1]?.impacts,
                                      selectedArray: drilldownFilter
                                    }
                                  })
                                );
                              }
                            }}
                            options={
                              Array.isArray(item[1]?.value)
                                ? pushAllToFront(item[1]?.value)
                                : item[1]?.value
                            }
                          />
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Box component="div" className={classes.actionArea}>
                  <Box component="div" className={classes.submitBtnArea}>
                    <Button type="submit">{'Apply Filters'}</Button>
                  </Box>
                  <Box component="div" className={classes.submitBtnArea}>
                    <Button
                      onClick={() =>
                        selectAll(values, setFieldValue, drilldownFilter)
                      }>
                      {selectButtonToggle ? 'Select All' : 'Deselect All'}
                    </Button>
                  </Box>
                  <Box component="div" className={classes.exportArea}>
                    <ExportCsv
                     style={{ color: '#182C52' }}
                      tableData={updatedExportData}
                      fileName={'Campaign Data'}
                    />
                  </Box>
                  <Box component="div" className={classes.resetBtnArea}>
                    <Button
                      type="reset"
                      style={{ color: '#182C52' }}
                      onClick={() => {
                        dispatch(
                          getDrilldownFilterData({
                            campaign_id: campaign_id,
                            outlierValue: outlierChecked,
                            selectedValue: {},
                            filters: {}
                          })
                        );
                        getTblData({
                          selectedKpi: [],
                          productGroup: [],
                          storeId: [],
                          brands: [],
                          division: [],
                          department: [],
                          class: [],
                          outletStrategy: [],
                          mensTier: [],
                          womensTier: [],
                          aerieFormat: [],
                          aerieSetUp: [],
                          btsPeak: [],
                          region: [],
                          factoryDesignation: []
                        });
                      }}>
                      Reset
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
      {tblData?.length ? (
        <>
          {
            <Grid item xs={12}>
              {/* {matchStatistics.length > 0 ? ( */}
              {/* <ReactMapZoom  mapData={mapData}/> */}
              <div>
                <MapChart
                  setTooltipContent={setContent}
                  mapData={mapData1}
                  setMapData={setMapData}
                  campaign_id={campaign_id}
                  outlierChecked={outlierChecked}
                  filters={appliedFilters}
                />
                {/* < ReactTooltip id="my-tooltip-data-html"
                    >{content}</ReactTooltip>; */}
              </div>
              {/* ) : (
                    ''
                  )} */}
            </Grid>
          }
          {load ? (
            <Box
              component="div"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100vw',
                padding: '10px 0px'
              }}>
              <CircularProgress />
              <Typography>Loading Please wait .....</Typography>
            </Box>
          ) : (
            <>
              {graphData?.hasOwnProperty('testData') &&
              graphData?.testData?.length > 0 ? (
                <MeasureCharts
                  selectedKPI={selectedTblKpi}
                  graphData={graphData}></MeasureCharts>
              ) : (
                <Box
                  component="div"
                  style={{ textAlign: 'center', margin: '50px' }}>
                  {graphData === 'no data' || graphData?.testData?.length === 0
                    ? 'No data found'
                    : ''}
                </Box>
              )}
            </>
          )}

          <CustomMaterialTable
            title=""
            columns={columns}
            data={tblData}
            sorting={true}
            onRowClick={(event, rowData) => {
              //   dispatch({
              //     type: 'LOADING',
              //     payload: true
              //   });
              // handleScrollToMiddle();
              setSelectedTblKpi({
                ...selectedTblKpi,
                id: rowData.kpiRowId,
                name: rowData.kpi,
                type: rowData.kpiType,
                label: rowData.kpiLabel
              });
              getGraphData(rowData.kpiRowId);
              // getMapData(appliedFilters, drilldownFilter);
            }}
            options={{
              sorting: true,
              search: true,
              toolbar: false,
              paging: true,
              emptyRowsWhenPaging: false,
              exportAllData: true,
              pageSize: 100,
              // fixedColumns: { left: 4 },
              cellStyle: rowData => {
                return {
                  textAlign: 'center'
                };
              },
              headerStyle: {
                backgroundColor: '#182C52',
                color: '#FFF'
              },
              rowStyle: rowData => {
                return {
                  backgroundColor:
                    selectedTblKpi.id === rowData.kpiRowId
                      ? '#FFDCCC'
                      : 'inherit',
                  color:
                    selectedTblKpi.id === rowData.kpiRowId
                      ? '#404041'
                      : 'inherit',
                  cursor: 'pointer'
                };
              }
            }}
            components={{
              Pagination: props => {
                const { ActionsComponent, ...tablePaginationProps } = props;
                delete tablePaginationProps.onRowsPerPageChange;
                delete tablePaginationProps.onChangeRowsPerPage;
                return (
                  <TablePagination
                    {...tablePaginationProps}
                    onPageChange={(e, page) => {
                      setTblPage(page + 1);
                    }}
                    onRowsPerPageChange={event => {
                      setTblPerpage(parseInt(event.target.value, 10));
                    }}
                    count={tblCount}
                    page={tblPage - 1}
                    rowsPerPage={tblPerpage}
                    ActionsComponent={subprops => {
                      const {
                        onPageChange,
                        ...actionsComponentProps
                      } = subprops;
                      return (
                        <ActionsComponent
                          {...actionsComponentProps}
                          onChangePage={onPageChange}
                        />
                      );
                    }}
                  />
                );
              },
              Header: () => {
                return (
                  <>
                    <TableHead
                      style={{
                        backgroundColor: '#182C52',
                        position: 'sticky',
                        top: 0
                      }}>
                      <TableRow>
                        {/* <TableCell
                          className={classes.tableRightBorder}
                          rowSpan={2}
                          width={'200px'}
                          onClick={() => handleSort('outletStrategy')}>
                          Outlet Strategy
                          {renderSortIcon('outletStrategy')}
                        </TableCell> */}
                        {/* <TableCell
                          className={classes.tableRightBorder}
                          rowSpan={2}
                          width={'200px'}
                          onClick={() => handleSort('mensTier')}>
                          Mens Tier
                          {renderSortIcon('mensTier')}
                        </TableCell>
                        <TableCell
                          className={classes.tableRightBorder}
                          rowSpan={2}
                          width={'200px'}
                          onClick={() => handleSort('womensTier')}>
                          Womens Tier
                          {renderSortIcon('womensTier')}
                        </TableCell>
                        <TableCell
                          className={classes.tableRightBorder}
                          rowSpan={2}
                          width={'200px'}
                          onClick={() => handleSort('aerieFormat')}>
                          Aerie Format
                          {renderSortIcon('aerieFormat')}
                        </TableCell>
                        <TableCell
                          className={classes.tableRightBorder}
                          rowSpan={2}
                          width={'200px'}
                          onClick={() => handleSort('aerieSetUp')}>
                          Aerie Setup
                          {renderSortIcon('aerieSetUp')}
                        </TableCell>
                        <TableCell
                          className={classes.tableRightBorder}
                          rowSpan={2}
                          width={'200px'}
                          onClick={() => handleSort('btsPeak')}>
                          BTS Peak
                          {renderSortIcon('btsPeak')}
                        </TableCell> */}
                        <TableCell
                          className={classes.tableRightBorder}
                          rowSpan={2}
                          width={'200px'}
                          onClick={() => handleSort('region')}>
                          Region
                          {renderSortIcon('region')}
                        </TableCell>
                        {/* <TableCell
                          className={classes.tableRightBorder}
                          rowSpan={2}
                          width={'200px'}
                          onClick={() => handleSort('factoryDesignation')}>
                          Factory Designation
                          {renderSortIcon('factoryDesignation')}
                        </TableCell> */}
                        <TableCell
                          className={classes.tableRightBorder}
                          rowSpan={2}
                          width={'200px'}
                          onClick={() => handleSort('kpiLabel')}>
                          KPI
                          {renderSortIcon('kpiLabel')}
                        </TableCell>

                        <TableCell
                          className={classes.tableRightBorder}
                          rowSpan={2}
                          width={'100px'}
                          onClick={() => handleSort('product_grp')}>
                          Product Group
                          {renderSortIcon('product_grp')}
                        </TableCell>

                        <TableCell
                          className={classes.tableRightBorder}
                          rowSpan={2}
                          width={'100px'}
                          onClick={() => handleSort('storeId')}>
                          Store ID
                          {renderSortIcon('storeId')}
                        </TableCell>
                        <TableCell
                          className={classes.tableRightBorder}
                          rowSpan={2}
                          width={'100px'}
                          onClick={() => handleSort('brand')}>
                          Brand
                          {renderSortIcon('brand')}
                        </TableCell>
                        <TableCell
                          className={classes.tableRightBorder}
                          rowSpan={2}
                          width={'100px'}
                          onClick={() => handleSort('division')}>
                          Division
                          {renderSortIcon('division')}
                        </TableCell>
                        <TableCell
                          className={classes.tableRightBorder}
                          rowSpan={2}
                          width={'100px'}
                          onClick={() => handleSort('dept')}>
                          Department
                          {renderSortIcon('dept')}
                        </TableCell>
                        <TableCell
                          className={classes.tableRightBorder}
                          rowSpan={2}
                          width={'100px'}
                          onClick={() => handleSort('class')}>
                          Class
                          {renderSortIcon('class')}
                        </TableCell>
                        <TableCell
                          className={classes.tableRightBorder}
                          colSpan={3}
                          align="center"
                          width={'300px'}>
                          {'Test'}
                        </TableCell>
                        <TableCell
                          className={classes.tableRightBorder}
                          colSpan={3}
                          align="center"
                          width={'300px'}>
                          {'Control'}
                        </TableCell>
                        <TableCell
                          className={classes.tableRightBorder}
                          colSpan={3}
                          align="center"
                          width={'300px'}>
                          {'Lift'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          className={classes.tableRightBorder}
                          width={'100px'}
                          onClick={() => handleSort('test_pre_value')}>
                          Pre Avg
                          {renderSortIcon('test_pre_value')}
                        </TableCell>
                        <TableCell
                          className={classes.tableRightBorder}
                          width={'100px'}
                          onClick={() => handleSort('test_during_value')}>
                          Post Avg
                          {renderSortIcon('test_during_value')}
                        </TableCell>
                        <TableCell
                          className={classes.tableRightBorder}
                          width={'100px'}
                          onClick={() => handleSort('test_pct_change')}>
                          {'% Change'}
                          {renderSortIcon('test_pct_change')}
                        </TableCell>
                        <TableCell
                          className={classes.tableRightBorder}
                          width={'100px'}
                          onClick={() => handleSort('control_pre_value')}>
                          Pre Avg
                          {renderSortIcon('control_pre_value')}
                        </TableCell>
                        <TableCell
                          className={classes.tableRightBorder}
                          width={'100px'}
                          onClick={() => handleSort('control_during_value')}>
                          Post Avg
                          {renderSortIcon('control_during_value')}
                        </TableCell>
                        <TableCell
                          className={classes.tableRightBorder}
                          width={'100px'}
                          onClick={() => handleSort('control_pct_change')}>
                          {'% Change'}
                          {renderSortIcon('control_pct_change')}
                        </TableCell>
                        <TableCell
                          className={classes.tableRightBorder}
                          width={'100px'}
                          onClick={() => handleSort('t1.pct_lift')}>
                          {'Lift %'}
                          {renderSortIcon('t1.pct_lift')}
                        </TableCell>
                        <TableCell
                          className={classes.tableRightBorder}
                          width={'100px'}
                          onClick={() => handleSort('abs_lift')}>
                          Abs
                          {renderSortIcon('abs_lift')}
                        </TableCell>

                        <TableCell
                          className={classes.tableRightBorder}
                          width={'100px'}
                          onClick={() => handleSort('significance')}>
                          Stat Sig
                          {renderSortIcon('significance')}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </>
                );
              }
            }}></CustomMaterialTable>
        </>
      ) : (
        <Grid item xs={12}>
          <Typography
            component="h3"
            variant="subtitle2"
            className={classes.info}>
            {msg}
          </Typography>
        </Grid>
      )}
    </>
  );
};

export default MeasureDrillHome;
