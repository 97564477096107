import React, { useEffect, useState } from "react";
import ReactApexCharts from "react-apexcharts";
import palette from "../../../theme/palette";
import { toCurrencyFormat, numberWithCommas } from "../Utils/ClientUtil";

const LineChart = (props) => {
  const [chartInit, setChartInit] = useState();
  let {
    series,
    xAxis,
    title,
    KPI,
    annotationsData,
    showAnnotations,
    chartColors,
    type,
    chartType,
    label,
    markers,
    xAxisTitle,
    yAxisTitle
  } = props;

  let annotationsObj = showAnnotations
    ? {
        annotations: {
          xaxis: [
            {
              x: new Date(annotationsData.preDate).toLocaleDateString(
                'en-US',
                 { month: 'short', day: 'numeric', timeZone: 'UTC' }
              ),
              borderColor: palette.text.secondary,
              label: {
                style: {
                  color: palette.text.secondary,
                },
                orientation: "horizontal",
                text: "Test Start",
              },
            },
            {
              x: new Date(annotationsData.postDate).toLocaleDateString(
                'en-US',
                 { month: 'short', day: 'numeric', timeZone: 'UTC' }
              ),
              borderColor: palette.text.secondary,
              label: {
                style: {
                  color: palette.text.secondary,
                },
                orientation: "horizontal",
                text: "Test End",
              },
            },
          ],
        },
      }
    : {};

  useEffect(() => {
    setChartInit({
      series: series ? series : [],
      options: {
        chart: {
          height: 300,
          type: "area",
          stacked: false,
          toolbar: {
            show: true,
          },
        },
        markers: markers ? markers : {},
        colors: chartColors,
        legend: {
          show: true,
          horizontalAlign: "center",
          showForSingleSeries: true,
          // offsetX: 40,
        },
        title: {
          text: "",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
          width: [1, 1],
        },
        yaxis: [
          {
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
              //color: '#008FFB'
            },

            labels: {
              formatter: function(value) {
                return value;
              },
            },

            title: {
              text: yAxisTitle,
              style: {
                color: "#333333",
                fontSize: 12,
                textTransform: "capitalize",
                fontFamily: '"Poppins", sans-serif',
                fontWeight: 400,
              },
            },
            tooltip: {
              enabled: false,
            },
          },
        ],
        xaxis: {
          show: true,
          categories: xAxis ? xAxis : [],
          tickAmount: (xAxis.length > 40) && (((Math.ceil(xAxis.length / 6)) > 24) ? 24 : (Math.ceil(xAxis.length / 6 ))),
          labels: {
            show: true,
            style: {
              fontSize: "10px",
            },
          },
          title: {
            text: xAxisTitle || "Day",
            style: {
              color: undefined,
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-title',
          },
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          floating: false,
          position: "bottom",
        },
        tooltip: {
          fixed: {
            enabled: true,
            position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
            offsetY: 30,
            offsetX: 60,
          },
          y: {
            formatter: function(y) {
              return y?.toFixed(2);
            },
          },
        },
        grid: {
          show: true,
          borderColor: "#E6E7E8",
          strokeDashArray: 4,
          position: "back",
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
          row: {
            colors: undefined,
            opacity: 0.5,
          },
          column: {
            colors: undefined,
            opacity: 0,
          },
        },
        ...annotationsObj,
      },
    });
  }, [series]);

  return (
    <React.Fragment>
      {chartInit && (
        <ReactApexCharts
          options={chartInit?.options}
          series={chartInit?.series}
          type={chartType ? chartType : "area"}
          height={300}
        />
      )}
    </React.Fragment>
  );
};
export default LineChart;
