/**
 * Measure table css
 */
import { makeStyles } from '@material-ui/core'
export default makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    '& button + svg': {
      marginTop: 5
    },
  },
  formControl: {
    width: "100%",
  },
  refreshIcons: {
    color: "#182C52",
    cursor: "pointer"
  },
  
  paper: {
    borderRadius: '10px !important'
  },
  formControlLabel: {
    color: theme.palette.text.secondary,
    fontWeight: 500
  },
  // campIcon: {
  //   '& svg': {
  //     fontSize: 18,
  //     marginRight: 5,
  //     verticalAlign: 'sub'
  //   }
  // },
  campChannel: {
    background: '#E5EEFF',
    padding: ' 5px 10px',
    color: '#013598',
    fontSize: 14,
    borderRadius: 2
  },
  durationArea: {
    color: '#182C52',
    fontWeight: 400,
    fontSize: 14,
    '& svg': {
      fontSize: 18,
      marginRight: 5,
      verticalAlign: 'text-top'
    }
  },
  tblArea: {
    '& tr.MuiTableRow-hover': {
      '&:hover': { backgroundColor: 'rgb(254 80 0 / 5%)' }
    },
    th: {
      padding: '16px 0'
    }
  },
  description: {
    background: "#FCFCFC",
    border: "1px solid #E6E7E8",
    padding: 10,
    fontSize: 12,
  },
  heading: {
    fontSize: 14,
    color: "#929497",
    marginBottom: 5,
  },
  value: {
    fontSize: 14,
    color: "#333333",
    fontWeight: 500,
  },

  ongoingBtn: {
    background: "#58DA7E",
    color: "#ffffff",
    borderRadius: 0,
    textTransform: "capitalize",
    padding: "2px 8px",
    cursor: "auto",
    "& svg": {
      verticalAlign: "middle",
      fontSize: 18,
      marginRight: 5,
      color: "#ffffff",
      borderRadius: 2,
      padding: 2,
    },
    "&:hover": {
      backgroundColor: "#58DA7E",
    },
  },
  completeBtn: {
    background: "#679BFE",
    color: "#ffffff",
    borderRadius: 0,
    textTransform: "capitalize",
    padding: "2px 8px",
    cursor: "auto",
    "& svg": {
      verticalAlign: "middle",
      fontSize: 18,
      marginRight: 5,
      color: "#ffffff",
      borderRadius: 2,
      padding: 2,
    },
    "&:hover": {
      backgroundColor: "#679BFE",
    },
  },
  campIcon: {
    padding: "3px 5px",
    background: "#E5EEFF",
    borderRadius: 2,
    fontWeight: 500,
    fontSize: 12,
    color: "#013598",
    marginRight: 10,
    "& svg": {
      verticalAlign: "middle",
      fontSize: 12,
      marginRight: 3,
    },
  },
  durationArea: {
    color: "#182C52",
    fontWeight: 400,
    fontSize: 14,
    "& svg": {
      fontSize: 18,
      marginRight: 5,
      verticalAlign: "text-top",
    },
  },
  greenLiftBox: {
    width: 135,
    height: 105,
    background: "#EAF6ED",
    textAlign: "center",
    padding: "10px 0",
    float: "right",
    "& p:first-child": {
      fontSize: 16,
      letterSpacing: "-0.02em",
    },
    "& p:nth-child(2)": {
      color: "#24A148",
      margin: "10px 0",
      fontSize: 22,
      "& svg": {
        marginRight: 5,
        fontSize: 25,
        verticalAlign: "middle",
      },
    },
    "& p:last-child": {
      color: "#24A148",
    },
  },
  redLiftBox: {
    width: 135,
    height: 105,
    background: "#FBE8E9",
    textAlign: "center",
    padding: "10px 0",
    float: "right",
    "& p:first-child": {
      fontSize: 16,
      letterSpacing: "-0.02em",
    },
    "& p:nth-child(2)": {
      color: "#E01F29",
      margin: "10px 0",
      fontSize: 22,
      "& svg": {
        marginRight: 5,
        fontSize: 25,
        verticalAlign: "middle",
      },
    },
    "& p:last-child": {
      color: "#E01F29",
    },
  },
  detailBlock: {
    width: "95%",
    margin: "0 auto",
  },
  deepdiveBtn: {
    width: 135,
    marginTop: 10,
    float: "right",
    borderRadius: 0,
    background: "#182C52",
    border: 0,
    color: "#ffffff",
    textTransform: "capitalize",
    "&:hover": {
      background: "#031330",
    },
  },
  merchArea: {
    cursor: "pointer",
    padding: "3px 5px",
    background: "#E5EEFF",
    fontWeight: 400,
    color: "#333333",
    "& svg": {
      verticalAlign: "middle",
      fontSize: 16,
    },
  },
  merchDialog: {
    "& svg": {
      margin: "0 20px",
    },
    "& h5": {
      marginBottom: 30,
      padding: 10,
    },
  },
}));