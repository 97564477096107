import { makeStyles } from '@material-ui/core'


export default  makeStyles((theme) => ({
    root: {
        overflowY: "hidden",
      },
      paper: {
        width: 280,
    
        borderRadius: "5px",
        overflow: "hidden",
      },
      buttonConatiner: {
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
      },
      buttonPorperty: {
        background: "#182C52",
        color: "white",
        borderRadius: 4,
        height: "30px",
        textTransform: "capitalize",
        "&:hover": {
          backgroundColor: "#031330",
        },
      },
      titleContainer: {
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
      },
      textMessage: {
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        padding: "15px 0px",
        color: "black",
        fontSize: "16px",
        // fontWeight:"bold"
      },
      buttonInnerConatiner:{
        display:"flex",
        justifyContent:"space-between",
        textAlign:"center"
      },
      buttonPorpertyCancel: {
        border: "1px solid #fe5000",
        color: "black",
        borderRadius: 4,
        height: "30px",
        textTransform: "capitalize",
        "&:hover": {
          backgroundColor: "#fff",
        },
      },
}));