import { MOREABOUTTEST, TRIGGERLAUNCHDAG, TRIGGERLAUNCHDAG2, TRIGGERLAUNCHDAG3, TELL_US_MORE_SELECTED_ATTRIBUTES, TELL_US_MORE_FINAL_CANDIDATE_STORE, TELL_US_MORE_SELECTED_SUB_ATTRIBUTES, CLEAR_ABOUT_VALUES, ABOUTTEST_STOREID_ATTR } from '../types';

const initialState = {
  moreAboutTest: {},
  triggerLaunchDagStatus: true,
  triggerLaunchDag2Status: true,
  triggerLaunchDag3Status: true,
  tellUsMoreSelectedAttribute: [],
  tellUsMoreFinalCandidateStore: [],
  tellUsMoreSelectedSubattribute: [],
  aboutteststoreidattrsel: []
};
const aboutTestReducer = (state = initialState, action) => {
  switch (action.type) {
    case MOREABOUTTEST:
      return {
        ...state,
        moreAboutTest: action.payload
      };
    case TRIGGERLAUNCHDAG:
      return {
        ...state,
        triggerLaunchDagStatus: action.payload
      };
    case TRIGGERLAUNCHDAG2:
      return {
        ...state,
        triggerLaunchDag2Status: action.payload
      };
    case TRIGGERLAUNCHDAG3:
      return {
        ...state,
        triggerLaunchDag3Status: action.payload
      };
    case TELL_US_MORE_SELECTED_ATTRIBUTES:
      return {
        ...state,
        tellUsMoreSelectedAttribute: action.payload
      }
    case TELL_US_MORE_FINAL_CANDIDATE_STORE:
      return {
        ...state,
        tellUsMoreFinalCandidateStore: action.payload
      }
    case ABOUTTEST_STOREID_ATTR:
      return {
        ...state,
        aboutteststoreidattrsel: action.payload
      }
    case TELL_US_MORE_SELECTED_SUB_ATTRIBUTES:
      return {
        ...state,
        tellUsMoreSelectedSubattribute: action.payload
      };
    case CLEAR_ABOUT_VALUES:
      return initialState;
    default:
      return state;
  }
};

export default aboutTestReducer;
