import {
  IDENTIFYTEST,
  TESTTABLECOUNT,
  CANDIDATESTSTORE,
  FINALCANDIDATESTSTORE,
  QUERYBUILDERTREE,
  SELECTEDATTRIBUTE,
  ATTRIBUTES,
  ATTRSTORECOUNT,
  QUERYBUILDERDATA,
  STOREGROUP,
  CREATETORESEGMENT,
  CREATETORESEGMENTFILTER,
  GETSTORESQUERYBUILDER,
  RANDOMSAMPLETABLEDATA,
  GETALLSTORES,
  GETSTOREDATA,
  FINALCANDIDATESTSTORES,
  SELECTEDATTROPTIONS,
  SETATTROPTIONSELECTED,
  GETSTORESELECTEDATTROPTION,
  STOREIDATTRSELECTED,
  SETQUERYRULE,
  SETQUERYTREE,
  SETTOTALSTORE,
  SHOWRANDOMSTORETEXT,
  STOREGROUPALL,
  EXCLUDETESTSTORESDATA,
  EXCLUDETESTSTORESLIST,
  STORESGROUPLIST,
  SELECTEDRANDOMSTORELIST,
  IDENTIFY_TEST_REGION,
  IDENTIFY_TEST_SELECTED_REGION,
  IDENTIFY_TEST_RANDOM_SAMPLE,
  IDENTIFY_TEST_RANDOM_SAMPLE_STORE,
  IDENTIFY_NEXT_BTN,
  IDENTIFY_UPLOAD_TEST_STORE_COUNT,
  IDENTIFY_TEST_BRAND,
  IDENTIFY_TEST_BRAND_SELECTED,
  IDENTIFY_TEST_DEPT,
  IDENTIFY_TEST_DEPT_SELECTED,
  IDENTIFY_TEST_DIVISION,
  IDENTIFY_TEST_DIVISION_SELECTED,
  IDENTIFY_TEST_PRODUCT_GROUP,
  IDENTIFY_TEST_PRODUCT_GROUP_SELECTED,
  IDENTIFY_TEST_METRICS,
  IDENTIFY_TEST_METRICS_SELECTED,
  IDENTIFY_TEST_SELECTED_REGION_STOREIDS,
  IDENTIFY_TEST_INCLUDE_STOREID,
  IDENTIFY_TEST_STAT_TABLE_DATA,
  CLEAR_IDENTIFY_TEST_VALUES,
  ATTRSTORESTATUS,
  TELLUSMORERES,
  IDENTIFY_EXCLUDE_RAD_VAL,
} from '../types';

const initialState = {
  identifyTest: {},
  testTableCount: [],
  candidateTestStore: [],
  finaleCandStore: [],
  queryBuilderTree: null,
  selectedattribute: [],
  Attributes: [],
  querybuilderdata: [],
  storegroup: [],
  createstoresegment: {},
  createstoresegmentfilter:{},
  storesfromquerybuilder: [],
  randomsampletabledata: [],
  getallstores: [],
  getstorelist: [],
  finalcandidatestore: [],
  selectedattroptions: [],
  attributeoptionselected: [],
  storesselectedattroptions: [],
  storeidsattrselected: [],
  queryrules: [],
  querytree: {},
  totalstoress: 0,
  showrandomstoretext: false,
  storegroupall: [],
  excludeTestStoresData: '',
  excludeTestStoresList: [],
  storesGroupList: [],
  selectedRandomStoreList: [],
  attrstorecount: 0,
  regions: [],
  selectedRegions: [],
  randomSample: 'no',
  randomSampleStore: 0,
  disabledNextButton: true,
  uploadTestStoreCount: 0,
  identifytestmetrics: [],
  identifytestbrand: [],
  identifytestdept: [],
  identifytestdivision: [],
  identifytestproductgrp: [],
  regionstoreids: [],
  inclusionstoreids: [],
  identifytestmetricssel: [],
  identifytestbrandsel: [],
  identifytestdeptsel: [],
  identifytestproductgrpsel: [],
  identifytestdivisionsel: [],
  identifyteststattabledata: [],
  attrstorestatus: 0,
  tellusmoreres: false,
  identifytestexcluderadVal: 'no'
};
const identifyTestReducer = (state = initialState, action) => {
  // console.log("identifyTestReducer=========>", state, action)
  switch (action.type) {
    case ATTRIBUTES:
      return {
        ...state,
        Attributes: action.payload
      }
    case QUERYBUILDERDATA:
      return {
        ...state,
        querybuilderdata: action.payload
      }
    case STOREGROUP:
      return {
        ...state,
        storegroup: action.payload
      }
    case STOREGROUPALL:
      return {
        ...state,
        storegroupall: action.payload
      }
    case CREATETORESEGMENT:
      return {
        ...state,
        createstoresegment: action.payload
      }
      case CREATETORESEGMENTFILTER:
        return {
          ...state,
          createstoresegmentfilter: action.payload
        }
    case GETSTORESQUERYBUILDER:
      return {
        ...state,
        storesfromquerybuilder: action.payload
      }
    case RANDOMSAMPLETABLEDATA:
      return {
        ...state,
        randomsampletabledata: action.payload
      }
    case GETALLSTORES:
      return {
        ...state,
        getallstores: action.payload
      }
    case GETSTOREDATA:
      return {
        ...state,
        getstorelist: action.payload
      }
    case FINALCANDIDATESTSTORES:
      return {
        ...state,
        finalcandidatestore: action.payload
      }
    case SELECTEDATTROPTIONS:
      return {
        ...state,
        selectedattroptions: action.payload
      }
    case SHOWRANDOMSTORETEXT:
      return {
        ...state,
        showrandomstoretext: action.payload
      }
    case SETATTROPTIONSELECTED:
      return {
        ...state,
        attributeoptionselected: action.payload
      }
    case GETSTORESELECTEDATTROPTION:
      return {
        ...state,
        storesselectedattroptions: action.payload
      }
    case STOREIDATTRSELECTED:
      return {
        ...state,
        storeidsattrselected: action.payload
      }
    case SETQUERYRULE:
      return {
        ...state,
        queryrules: action.payload
      }
    case ATTRSTORECOUNT:
      return {
        ...state,
        attrstorecount: action.payload
      }
    case SETQUERYTREE:
      return {
        ...state,
        querytree: action.payload
      }
    case IDENTIFYTEST:
      return {
        ...state,
        identifyTest: action.payload
      };
    case TESTTABLECOUNT:
      return {
        ...state,
        testTableCount: action.payload
      };
    case CANDIDATESTSTORE:
      return {
        ...state,
        candidateTestStore: action.payload
      };
    case SETTOTALSTORE:
      return {
        ...state,
        totalstoress: action.payload
      };
    case FINALCANDIDATESTSTORE:
      return {
        ...state,
        finaleCandStore: action.payload
      };
    case QUERYBUILDERTREE:
      return {
        ...state,
        queryBuilderTree: action.payload
      };
    case SELECTEDATTRIBUTE:
      return {
        ...state,
        selectedattribute: action.payload
      };
    case EXCLUDETESTSTORESDATA:
      return {
        ...state,
        excludeTestStoresData: action.payload
      };
    case EXCLUDETESTSTORESLIST:
      return {
        ...state,
        excludeTestStoresList: action.payload
      };
    case STORESGROUPLIST:
      return {
        ...state,
        storesGroupList: action.payload
      };
    case SELECTEDRANDOMSTORELIST: {
      return {
        ...state,
        selectedRandomStoreList: action.payload
      }
    }
    case IDENTIFY_TEST_REGION: {
      return {
        ...state,
        regions: action.payload
      }
    }
    case IDENTIFY_TEST_SELECTED_REGION: {
      return {
        ...state,
        selectedRegions: action.payload
      }
    }
    case IDENTIFY_TEST_RANDOM_SAMPLE: {
      return {
        ...state,
        randomSample: action.payload
      }
    }
    case IDENTIFY_TEST_RANDOM_SAMPLE_STORE: {
      return {
        ...state,
        randomSampleStore: action.payload
      }
    }
    case IDENTIFY_NEXT_BTN: {
      return {
        ...state,
        disabledNextButton: action.payload
      }
    }
    case IDENTIFY_UPLOAD_TEST_STORE_COUNT: {
      return {
        ...state,
        uploadTestStoreCount: action.payload
      }
    }
    case IDENTIFY_TEST_METRICS: {
      return {
        ...state,
        identifytestmetrics: action.payload
      }
    }
    case IDENTIFY_TEST_METRICS_SELECTED: {
      return {
        ...state,
        identifytestmetricssel: action.payload
      }
    }
    case IDENTIFY_TEST_BRAND: {
      return {
        ...state,
        identifytestbrand: action.payload
      }
    }
    case IDENTIFY_EXCLUDE_RAD_VAL: {
      return {
        ...state,
        identifytestexcluderadVal: action.payload
      }
    }
    case IDENTIFY_TEST_BRAND_SELECTED: {
      return {
        ...state,
        identifytestbrandsel: action.payload
      }
    }
    case IDENTIFY_TEST_DEPT: {
      return {
        ...state,
        identifytestdept: action.payload
      }
    }
    case IDENTIFY_TEST_DEPT_SELECTED: {
      return {
        ...state,
        identifytestdeptsel: action.payload
      }
    }
    case IDENTIFY_TEST_PRODUCT_GROUP: {
      return {
        ...state,
        identifytestproductgrp: action.payload
      }
    }
    case IDENTIFY_TEST_PRODUCT_GROUP_SELECTED: {
      return {
        ...state,
        identifytestproductgrpsel: action.payload
      }
    }
    case IDENTIFY_TEST_DIVISION: {
      return {
        ...state,
        identifytestdivision: action.payload
      }
    }
    case IDENTIFY_TEST_DIVISION_SELECTED: {
      return {
        ...state,
        identifytestdivisionsel: action.payload
      }
    }
    case IDENTIFY_TEST_SELECTED_REGION_STOREIDS: {
      return {
        ...state,
        regionstoreids: action.payload
      }
    }
    case IDENTIFY_TEST_INCLUDE_STOREID: {
      return {
        ...state,
        inclusionstoreids: action.payload
      }
    }
    case IDENTIFY_TEST_STAT_TABLE_DATA: {
      return {
        ...state,
        identifyteststattabledata: action.payload
      }
    }
    case TELLUSMORERES: {
      return {
        ...state,
        tellusmoreres: action.payload
      }
    }
    case CLEAR_IDENTIFY_TEST_VALUES:
      return initialState;
    case ATTRSTORESTATUS: {
      return {
        ...state,
        attrstorestatus: action.payload
      }
    }

    default:
      return state;
  }
};

export default identifyTestReducer;
