import secureAxios from 'api-config/api';
import { GETCAMPAIGNS, SET_CAMPAIGN_EDIT } from 'redux/types';

export const getCampaigns = val => {
  return {
    type: GETCAMPAIGNS,
    payload: val
  };
};
export const setCampaignIsEdit = val => {
  return {
    type: SET_CAMPAIGN_EDIT,
    payload: val
  };
};

export const setCampaignIsEditDis = val => {
  const { campaignId, isEdit, user, editEnable } = val;
  return dispatch => {
    try {
      const obj = {
        campaignId: campaignId,
        isEdit: isEdit,
        editEnable: editEnable,
        user: user
      };
      localStorage.removeItem('isEdit');

      secureAxios.post('/measureCampaign/setCampaignEdit', obj).then(res => {
        // dispacthData(dispatch, SET_CAMPAIGN_EDIT, res.data);
        dispatch({ type: SET_CAMPAIGN_EDIT, payload: res.data });
        // if (res.data == true) {
        //   localStorage.setItem('isEdit', true);
        // } else {
        //   localStorage.setItem('isEdit', false);
        // }

        console.log(res, 'setEdit');
      });
    } catch (error) {
      console.log('error', error);
    }
  };
};
