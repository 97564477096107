import { CLEAR_SAMPLE_VALUES, SAMPLESIZERECOMMENDATION, SAMPLESIZESIMULATEDATA } from '../types';

const initialState = {
  sampleSizeRecommendation: {},
  sampleSizeSemulationData: {}
};
const sampleSizeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAMPLESIZERECOMMENDATION:
      return {
        ...state,
        sampleSizeRecommendation: action.payload
      };
    case SAMPLESIZESIMULATEDATA:
      return {
        ...state,
        sampleSizeSemulationData: action.payload
      };
    case CLEAR_SAMPLE_VALUES:
      return initialState;
    default:
      return state;
  }
};

export default sampleSizeReducer;
