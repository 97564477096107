import { CLEAR_IDENTIFY_CONTROL_VALUES, IDENTIFYCONTROL } from '../types';
import { NEAREASTMATCH } from '../types';

const initialState = {
  identifyControl: {},
  nearestMatch: []
};
const identifyControlReducer = (state = initialState, action) => {
  switch (action.type) {
    case IDENTIFYCONTROL:
      return {
        ...state,
        identifyControl: action.payload
      };
    case NEAREASTMATCH:
      return {
        ...state,
        nearestMatch: action.payload
      };
    case CLEAR_IDENTIFY_CONTROL_VALUES:
      return initialState;
    default:
      return state;
  }
};


export default identifyControlReducer;
