import { useLocation, useHistory } from 'react-router-dom';
import { parse } from 'query-string';
import React, { useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import secureAxios from 'api-config/api';
import FullLoader from 'views/Common/FullLoader/FullLoader';
import { useState } from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';

const useContainer = makeStyles(theme => ({
  root: {
    height: '100vh'
  }
}));

const Auth = props => {
  const { hash } = useLocation();
  const history = useHistory();
  const [error, setError] = useState('');
  const AUTH_TOKEN = '/login/getAuthToken';
  const classes = useContainer();

  useEffect(() => {
    let data = parse(hash);

    if (data.id_token) {
      let userData = jwtDecode(data.id_token),
        user_role =
          userData?.groups &&
          [...userData?.groups]?.includes('SSO_TALP_STORES_ADMIN')
            ? 'Admin'
            : 'Viewer';
      localStorage.setItem('id_token', data.id_token);
      console.log('userData', userData);

      secureAxios
        .get(AUTH_TOKEN, {
          params: {
            username: userData.preferred_username,
            email: userData.email,
            fullName: userData.name,
            user_role: user_role
          }
        })
        .then(res => {
          if (res.status === 200) {
            if (res.data.status === 'success') {
              localStorage.setItem('accessToken', JSON.stringify(res.data));
              history.push('/home');
              localStorage.setItem('loggedIn', true);
              localStorage.setItem('userName', res.data.name);
            }
          }
        });
    } else {
      setError('Login failed');
    }
  }, []);

  return error.length > 0 ? (
    <Grid
      className={classes.root}
      container
      direction="column"
      justifyContent="center"
      alignItems="center">
      <Typography variant="h5">
        You do not have access to this Application
      </Typography>
      <Typography variant="h5">Please contact your administrator</Typography>
    </Grid>
  ) : (
    <FullLoader />
  );
};

export default Auth;
