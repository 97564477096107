// import * as React from 'react';
// import Button from '@material-ui/core/Button';
// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import Paper from '@material-ui/core/Paper';
// import Draggable from 'react-draggable';

// function PaperComponent(props) {
//   return (
//     <Draggable
//       handle="#draggable-dialog-title"
//       cancel={'[class*="MuiDialogContent-root"]'}
//     >
//       <Paper {...props} />
//     </Draggable>
//   );
// }

// export default function DraggableDialog(props) {
//   const { header, children, open ,buttonStatus=true} = props;

//   return (
//     <div>
//       <Dialog
//         open={open}
//         onClose={props.handleClose}
//         PaperComponent={PaperComponent}
//         aria-labelledby="draggable-dialog-title"
//         fullWidth={true}
//         maxWidth='md'
        
//       >
//         <DialogTitle  id="draggable-dialog-title">
//           {header}
//         </DialogTitle>
//         <DialogContent>
//           {children}
//         </DialogContent>
//         {
//           buttonStatus ? <DialogActions>
//           <Button autoFocus onClick={props.handleClose}>
//             Close
//           </Button>
//         </DialogActions>
//         :""}
//       </Dialog>
//     </div>
//   );
// }

import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Dialog, IconButton, Typography } from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  dialogWidth: {
    "& div.MuiDialog-paper": {
      width: 1920,
    },
  },
  dialogMediumWidth: {
    "& div.MuiDialog-paper": {
      width: 800,
    },
  },
  dialogSmallWidth: {
    "& div.MuiDialog-paper": {
      width: 500,
    },
  },
}));
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: "16px 24px 0",
    height: "50px"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: {
    color: '#182C52',
    fontWeight: theme.typography.fontWeightMedium,
    borderBottom: "1px solid #182C52",
    width: "max-content",
    borderRadius: 0,
    lineHeight: 2,
    textTransform:"capitalize"
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({}))(MuiDialogContent);

export default function DraggableDialog(props) {
  const classes = useStyles();
  const { handleClose, header, children, open, width, contentStyle } = props;

  return (
    <div>
      <Dialog
        className={width === 'medium'? classes.dialogMediumWidth:width ? classes.dialogWidth : classes.dialogSmallWidth}
        maxWidth="xl"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
       
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {/* <Typography
            variant="inherit"
            style={{
              borderBottom: "1px solid #fe5b10",
              width: "max - content",
            }}
          > */}
          {header}
          {/* </Typography> */}

          {/* <br />
          <Typography
            style={{
              borderTop: "1px solid #fe5b10",
              color: "#ffffff",
            }}
            variant="inherit"
          >
            {" "}
            {header}
          </Typography> */}
        </DialogTitle>
        <DialogContent  style={contentStyle?{overflowX: 'hidden', padding: '5px', ...contentStyle}:{overflowX: 'hidden', padding: '5px'}}>{children}</DialogContent>
      </Dialog>
    </div>
  );
}
