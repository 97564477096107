import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Typography } from '@material-ui/core';

import { ErrorMessage } from 'formik';

const useStyles = makeStyles(theme => ({
  // formControl: {
  //   width: '30%'
  // },
  // selectEmpty: {
  //   marginTop: theme.spacing(2)
  // },
  // errorText: {
  //   color: 'red',
  //   fontSize: 12
  // }
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  formControl: {
    width: "100%",
  },
  errorText: {
    color: "red",
    fontSize: 12,
  },
}));

function SingleSelectDropdown(props) {
  const classes = useStyles();
  const { value, name, width, handleMethod, options } = props;
  const noneRequired =
    props.noneRequired === undefined ? true : props.noneRequired;

  return (
    <FormControl className={width ? width : classes.formControl} variant="outlined">
      <InputLabel id="demo-simple-select-label">{props.label}</InputLabel>
      <Select
        // labelId="demo-simple-select-outlined-label"
        // id="demo-simple-select-outlined"
        // name={name} 
        // value={value[name]}
        // onChange={handleMethod}
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        name={name}
        value={typeof value === 'string'? value:value[name]}
        onChange={handleMethod}
        label={props.label}
        >
        {noneRequired && <MenuItem value="">None</MenuItem>}

        {options
          ? options.map(value => (
            <MenuItem key={value.metric? value.metric:value} value={value.metric?value.metric :value}>
              {value.label? value.label: value}
            </MenuItem>
            ))
          : ''}
      </Select>
      <Typography className={classes.errorText}>
        <ErrorMessage name={name} />
      </Typography>
    </FormControl>
  );
}

export default SingleSelectDropdown;
