import { Grid, Button } from '@material-ui/core';
import React, { useLayoutEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { Form, Formik } from 'formik';
import MeasureTable from './MeasureTable';
import FlatDatePicker from 'views/Common/Filters/FlatDatePicker';
import ReactBreadcrumbs from 'views/Common/Breadcrumb/Breadcrumb';
import secureAxios from 'api-config/api';
import MultiSelectDropdown from 'views/Common/Filters/MultiSelectDropdown';
import { format } from 'date-fns';
import useStyles from './Style';
//redux
import { useDispatch } from 'react-redux';
import ExportCsv from 'views/Common/Utils/ExportCsv';
import SearchTextField from 'views/Common/Filters/SearchTextField';
import MessageDialog from 'views/Common/MessageDialog/MessageDialog';


const MeasureView = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [dateRange, setDateRange] = useState([]);
  const [campaignData, setCampaignData] = useState([]);
  const [group, setGroup] = useState([]);
  const [type, setType] = useState([]);
  const [selectedName] = useState('');
  const [selectedType, setSelectedType] = useState([]);
  const [dataLevel] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [filterVal, setFilterVal] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [types, setTypes] = useState("");
  //api call for date range
  const getDateoptions = () => {
    secureAxios
      .get('/measureCampaign/getDateRange')
      .then(res => {
        if (res.status === 200) {
          setDateRange(res?.data);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  //api call for testtype
  const getTypeoptions = (val, status) => {
    secureAxios
      .post('/measureCampaign/getTestType', { filters: val })
      .then(res => {
        if (res.status === 200) {
          let resData = res?.data?.responseData;
          setType(resData);
          if (status) {
            setSelectedType(resData);
          } else {
            let newSelected = val?.testType?.filter(item =>
              resData.includes(item)
            );
            setSelectedType(newSelected);
          }
        }
      })
      .catch(err => {
        console.log(err);
      })
      .catch(err => {
        console.log(err);
      });
  };

  //api call for testtype
  const getGroupoptions = (val, status) => {
    secureAxios
      .post('/measureCampaign/getTestGroup', { filters: val })
      .then(res => {
        if (res.status === 200) {
          let resData = res.data.responseData;
          setGroup(resData);
          if (status) {
            setSelectedGroup(resData);
          } else {
            let newSelected = val?.testGroup?.filter(item =>
              resData.includes(item)
            );
            setSelectedGroup(newSelected);
          }
        }
      });
  };

  //api call for table list
  const fetchTblData = val => {
    dispatch({
      type: 'LOADING',
      payload: true
    });
    secureAxios
      .post('/measureCampaign/getCampaignList', {
        filters: val
      })
      .then(function (response) {
        dispatch({
          type: 'LOADING',
          payload: false
        });
        setCampaignData(response?.data?.responseData?.campaignList);
        setSearchData(response?.data?.responseData?.campaignList);
      })
      .catch(err => {
        dispatch({
          type: 'LOADING',
          payload: false
        });
        console.log(err);
      })
      .catch(err => {
        dispatch({
          type: 'LOADING',
          payload: false
        });
        console.log(err);
      });
  };

  //filter table data
  const handleFilter = e => {
    if (e.target.value === '') {
      setCampaignData(searchData);
    } else {
      const filterResult = searchData.filter(item =>
        item?.name?.toLowerCase()?.includes(e.target.value?.toLowerCase())
      );
      setCampaignData(filterResult);
    }
    setFilterVal(e.target.value);
  };

  const masterAPIcalls = val => {
    getDateoptions();
    getGroupoptions(val, 'firstLoad');
    getTypeoptions(val, 'firstLoad');
    fetchTblData(val);
  };

  useLayoutEffect(() => {
    let sub = true;
    if (sub) {
      masterAPIcalls({
        startPeriod: '',
        endPeriod: '',
        name: '',
        testType: [],
        dataLevel: []
      });
    }
    return () => {
      sub = false;
    };
  }, []);

  //delete campaignList

  const deleteCampaign = campaignId => {
    dispatch({
      type: "LOADING",
      payload: true
    })
    secureAxios
      .delete(`measureCampaign/getCampaignList/${campaignId}`)
      .then(response => {
        dispatch({
          type: "LOADING",
          payload: false
        })
        setOpen(true);
        setMessage('Deleted successfully');
        setTypes("success");
        // console.log(response);
        resetSearch();
        masterAPIcalls({
          startPeriod: '',
          endPeriod: '',
          name: '',
          testType: [],
          dataLevel: [],
          testGroup: []
        });
      })
      .catch(err => {
        setOpen(true);
        setMessage('Error');
        setTypes("error");

        console.error(err);
      });
  };

  //file download
  const updatedExportData = campaignData.map(
    ({ summaryData, tableData, ...rest }) => rest
  );

  function resetSearch() {
    // document.getElementById('searchBox')?.value = '';
    setFilterVal('');
  }
  //close the snackbar
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className={classes.root}>
      <ReactBreadcrumbs />
      <MessageDialog
        type={types}
        open={open}
        message={message}
        close={handleClose}
      />
      <Paper elevation={2}>
        <Formik
          initialValues={{
            DATERANGE: [dateRange.startPeriod, dateRange.endPeriod],
            NAME: selectedName,
            GROUP: selectedGroup,
            TYPE: selectedType
          }}
          enableReinitialize={true}
          onSubmit={values => { }}>
          {({ setFieldValue, dirty, resetForm, handleChange, values }) => (
            <Form
              autoComplete="off"
              noValidate
              style={{ padding: '25px 30px', textAlign: 'left' }}>
              <Grid container spacing={3}>
                <Grid item md={3} xs={12}>
                  <SearchTextField
                    width={'w100'}
                    name={'NAME'}
                    label="Campaign Name"
                    value={filterVal}
                    onChange={e => handleFilter(e)}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <MultiSelectDropdown
                    width={'w100'}
                    value={values.GROUP}
                    name={'GROUP'}
                    label={'Test Group'}
                    handleMethod={value => {
                      setSelectedGroup(value);
                      setFieldValue('GROUP', value);
                      getTypeoptions({
                        startDate: dateRange.startPeriod,
                        endDate: dateRange.endPeriod,
                        name: selectedName,
                        testGroup: value,
                        testType: selectedType,
                        dataLevel: dataLevel
                      });
                      fetchTblData({
                        startDate: dateRange.startPeriod,
                        endDate: dateRange.endPeriod,
                        name: selectedName,
                        testGroup: value,
                        testType: selectedType,
                        dataLevel: dataLevel
                      });
                    }}
                    options={group}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <MultiSelectDropdown
                    width={'w100'}
                    value={values.TYPE}
                    name={'TYPE'}
                    label={'Test Type'}
                    options={type}
                    noneRequired={true}
                    handleMethod={value => {
                      setSelectedType(value);
                      setFieldValue('TYPE', value);
                      fetchTblData({
                        startDate: dateRange.startPeriod,
                        endDate: dateRange.endPeriod,
                        name: selectedName,
                        testGroup: selectedGroup,
                        testType: value,
                        dataLevel: dataLevel
                      });
                      getGroupoptions({
                        startDate: dateRange.startPeriod,
                        endDate: dateRange.endPeriod,
                        name: selectedName,
                        testGroup: selectedGroup,
                        testType: selectedType,
                        dataLevel: dataLevel
                      });
                    }}
                  />
                </Grid>
                <Grid item md={2} xs={12}>
                  <FlatDatePicker
                    name={'DATERANGE'}
                    label="Select Period"
                    value={values?.DATERANGE}
                    mode={'range'}
                    handleMethod={(name, value) => {
                      if (value.length === 2) {
                        setFieldValue('DATERANGE', value);
                        setDateRange({
                          startPeriod: format(value[0], 'yyyy-MM-dd'),
                          endPeriod: format(value[1], 'yyyy-MM-dd')
                        });
                        getGroupoptions({
                          startPeriod: format(value[0], 'yyyy-MM-dd'),
                          endPeriod: format(value[1], 'yyyy-MM-dd'),
                          name: selectedName,
                          testGroup: selectedGroup,
                          testType: selectedType,
                          dataLevel: dataLevel
                        });
                        getTypeoptions({
                          startPeriod: format(value[0], 'yyyy-MM-dd'),
                          endPeriod: format(value[1], 'yyyy-MM-dd'),
                          name: selectedName,
                          testGroup: selectedGroup,
                          testType: selectedType,
                          dataLevel: dataLevel
                        });
                        fetchTblData({
                          startPeriod: format(value[0], 'yyyy-MM-dd'),
                          endPeriod: format(value[1], 'yyyy-MM-dd'),
                          name: selectedName,
                          testGroup: selectedGroup,
                          testType: selectedType,
                          dataLevel: dataLevel
                        });
                      }
                    }}
                  />
                </Grid>
                <div
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}>
                  <Grid item md={1} xs={12}>
                    {/* <RefreshIcon
                      // style={{color:"#182C52",cursor:"pointer"}}
                      className={classes.refreshIcons}
                      onClick={() => {
                        resetForm();
                        masterAPIcalls({
                          startPeriod: '',
                          endPeriod: '',
                          name: '',
                          testType: [],
                          dataLevel: [],
                          testGroup: []
                        });
                        // setSelectedName('');

                        // document.getElementById('searchBox').value = '';
                      }}
                    /> */}
                    <div style={{ display: 'flex', textAlign: 'center' }}>
                      <Button
                        className={classes.refreshIcons}
                        type="reset"
                        onClick={() => {
                          resetForm();
                          resetSearch();
                          masterAPIcalls({
                            startPeriod: '',
                            endPeriod: '',
                            name: '',
                            testType: [],
                            dataLevel: [],
                            testGroup: []
                          });
                        }}>
                        Reset
                      </Button>
                      <ExportCsv
                        tableData={updatedExportData}
                        fileName={'Campaign Data'}
                      />
                    </div>
                  </Grid>
                  {/* <Grid item md={1}>
                  
                    <ExportCsv
                      tableData={updatedExportData}
                      fileName={'Campaign Data'}
                    />
                  </Grid> */}
                </div>
              </Grid>
            </Form>
          )}
        </Formik>

        <Grid item md={12}>
          <MeasureTable data={campaignData} deleteCampaign={deleteCampaign} />
        </Grid>
      </Paper>
    </div>
  );
};

export default MeasureView;
