import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { MapContainer , TileLayer, Marker, Popup } from 'react-leaflet';
import './styleMap.css';

class ReactMapZoom extends Component {
  state = {
    center: [51.505, -0.091],
    markers:[{ lat: 49.8419, lng: 24.0315 },
      { lat: 49.9, lng: 26 }],
    zoom: 0,
  };

  render() {
    return (
      <div>
        {/* <MapContainer  center={this.state.center} zoom={this.state.zoom}>
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
          />
          {this.state.markers.map(item=>{
            console.log(item,'item');
            return <Marker position={[item.lat,item.lng]}>
            <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
            </Popup>
          </Marker>
          })
        }
          
        </MapContainer> */}
        <MapContainer center={[51.505, -0.09]} zoom={13} scrollWheelZoom={false}>
  <TileLayer
    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  />
  <Marker position={[51.505, -0.09]}>
    <Popup>
      A pretty CSS3 popup. <br /> Easily customizable.
    </Popup>
  </Marker>
</MapContainer>
      </div>
    );
  }
}

export default ReactMapZoom;

// // const rootElement = document.getElementById('root');
// // ReactDOM.render(<App />, rootElement);


// import { MapContainer, TileLayer, useMap } from 'react-leaflet'


// const ReactLeafletMap =(props)=>{
//     return (
//         <>
//         <div>
//         <MapContainer center={[51.505, -0.09]} zoom={13} scrollWheelZoom={false}>
//   <TileLayer
//     attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//     url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//   />
//   <Marker position={[51.505, -0.09]}>
//     <Popup>
//       A pretty CSS3 popup. <br /> Easily customizable.
//     </Popup>
//   </Marker>
// </MapContainer>
//         </div>
//         </>
//     )
// }

// export default ReactLeafletMap;