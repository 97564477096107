import { Box, Button, Grid, Paper, Select, TextField } from "@material-ui/core";
import secureAxios from "api-config/api";
import Multiselect from "multiselect-react-dropdown";
import React from "react";
import TextFieldElement from "views/Common/Filters/TextFieldElement";
import { makeStyles } from '@material-ui/core/styles';
import { useEffect } from "react";
import MaterialTable from "material-table";
import uniqid from 'uniqid';
import Typography from "theme/typography";
import UploadCsv from "components/uploadCSV/uploadCsv";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import PublishIcon from '@material-ui/icons/Publish';
import Switch from '@material-ui/core/Switch';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useDispatch, useSelector } from "react-redux";
import { getAllStoresVal, getStoreGroupsAll, getStoreGroupsVal, submitStoresGroup } from "api-config";
import { setLoading } from "redux/actions/application.action";
import { DeleteForever, Edit } from '@material-ui/icons';
import { useRef } from "react";
// import Button from '@material-ui/core/Button';




const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    addGrp: {
        // padding:'0.5% 0.5%',
        // display:'right'
        height: '34px',
        float: 'right'

    },
    formLabel: {
        margin: '5px 5px 10px',
        color: '#031330',
        fontWeight: 500
    },
}));

const SelectGroup = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const identifytestreducer = useSelector(
        state => state.identifyTestReducer
    );
    const { getallstores } = identifytestreducer

    const [storesData, setStoresData] = React.useState([]);
    const [selectedSTores, setSelectedSTores] = React.useState([]);
    const [groupName, setGroupName] = React.useState([]);
    const [groups, SetGroups] = React.useState([]);
    const [groupsCreated, setgroupsCreated] = React.useState([]);
    const [storeGroupObj, setStoreGroupObj] = React.useState({});
    const [allGoupsVal, setAllGroupsval] = React.useState([]);
    const [isError, setIsError] = React.useState(false);

    //toggle//

    const [alignment, setAlignment] = React.useState('left');
    const [formats, setFormats] = React.useState('');
    const [isUpload, setIsUpload] = React.useState({
        checkedA: false,
        checkedB: true,
    });
    const [deletedGroups, setDeletedGroups] = React.useState([]);
    const [editGroupId, setEditGroupId] = React.useState(null);
    const [editStoresList, setEditStoresList] = React.useState([]);
    const multiselectRef = useRef();




    const identifyTestReducer = useSelector(state => ({
        ...state.identifyTestReducer
    }));
    const { attributeoptionselected, storegroupall, storegroup, storeidsattrselected, createstoresegment } = identifyTestReducer


    const handleChange = (event) => {
        setIsUpload({ ...isUpload, [event.target.name]: event.target.checked });
    };

    const handleFormat = (event, newFormats) => {
        if (newFormats.length) {
            setFormats(newFormats);
        }
    };

    const handleAlignment = (event, newAlignment) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
        }
    };

    const resetSelectField = () => {
        multiselectRef.current.resetSelectedValues();
    };

    //-----------------------//

    // useEffect(() => {
    //     const arr = [];
    //     arr.push(storeGroupObj);
    //     // const val =groups.length > 0 ? Object.entries( groups[0]):[]

    //     // SetGroups(prev=> [storeGroupObj]);
    //     console.log(groups, 'groups');
    //     // setAllGroupsval(val);
    // }, [groups]);

    useEffect(() => {



        const onfirstRender = async () => {
            await dispatch(setLoading(true));
            await dispatch(getStoreGroupsAll({ SetGroups }))
            await dispatch(getAllStoresVal([]));
        }


        onfirstRender()


    }, [])

    const submitGroup = async () => {
        const payloadVal = {
            groups: groupsCreated,
            deletedGroups: deletedGroups
        }
        console.log(groups, groupsCreated, payloadVal, "groupsCreated on submit")
        dispatch(submitStoresGroup(payloadVal));

        // const groupsVal = await secureAxios
        //     .post('/designCampaign/submitStoreGroup', {
        //         store: groups
        //     })
        //     .then(function (res) {
        //         //   return res.data.map(item => {
        //         //     // kpisArray.push(item.kpi_name.toLowerCase());

        //         //   });
        //         console.log(res, 'submit group')
        //     });
    }

    const handleMultiAttribute = (values, item, rem) => {
        if (rem) {
            const data = selectedSTores?.filter((el, i) => {
                return !rem.STORE_ID.includes(el)
            });
            setSelectedSTores(data)

        } else {
            setSelectedSTores(prevState => [...prevState, item?.STORE_ID])
        }

    }
    const obj = {};
    const addStoreGroup = () => {
        const id = uniqid('stores-');
        const obj1 = {
            id: editGroupId ? editGroupId : id,
            group: groupName,
            stores: selectedSTores.toString()
        }
        // const obj1 = {
        //     [`${groupName}`]: selectedSTores
        // }
        if (editGroupId) {
            let temp_groups = [...groups],
                temp_groupCreated = [...groupsCreated];
            temp_groups = temp_groups.map(ele => {
                if (ele.id === obj1.id) ele = obj1;
                return ele;
            });
            if (temp_groupCreated.findIndex(ele => ele.id === obj1.id) !== -1) {
                temp_groupCreated = temp_groupCreated.map(ele => {
                    if (ele.id === obj1.id) ele = obj1;
                    return ele;
                })
            } else temp_groupCreated = [obj1, ...temp_groupCreated];
            SetGroups(temp_groups);
            setgroupsCreated(temp_groupCreated);
        } else if (!storeGroupObj[groupName]) {
            SetGroups(prev => [obj1, ...prev]);
            setgroupsCreated(prev => [obj1, ...prev]);

        }
        setStoreGroupObj(prev => (
            {
                ...prev,
                [`${groupName}`]: selectedSTores
            }
        ));
        setSelectedSTores([]);
        resetSelectField();

        // if(!storeGroupObj[groupName]){

        //     setStoreGroupObj(prev=>(
        //         {...prev,
        //             [`${groupName}`]:selectedSTores
        //         }
        //         ));

        // SetGroups(prev=> [storeGroupObj]);


        // }else{

        // }

        console.log(obj, groups, 'obj')

    }


    // useEffect(()=>{
    //     console.log(storegroup,storeGroupObj, groups, 'useefect enter store group');
    //     // storegroupall


    //         SetGroups(prev =>{
    //          const arr=   [storegroupall, ...prev];
    //          return arr.flat()
    // });



    // })

    const getStore = () => {
        secureAxios
            .get('/designCampaign/getAllStores', {
                params: []
            }).then((res) => {
                console.log(res.data, 'getAllstores')
                setStoresData(res.data)
            })
    }

    const editStores = (val) => {
        let temp_list = [];
        [...val.stores.split(',')].map(ele => temp_list.push({ 'STORE_ID': ele }));
        setEditGroupId(val.id);
        setGroupName(val.group);
        setEditStoresList(temp_list);
        setSelectedSTores([...val.stores.split(',')]);
    }

    const deleteStores = (id) => {
        let temp_groups = [...groups],
            temp_deleteStores = [...deletedGroups];
        temp_groups = temp_groups.filter(ele => ele.id !== id);
        temp_deleteStores.push(id);
        SetGroups(temp_groups);
        setDeletedGroups(temp_deleteStores);
    }


    const columns = [
        // {title:'ID',field:'id'},
        { title: 'Store Group', field: 'group' },
        { title: 'Stores', field: 'stores' },
    ]

    return (
        <>
            <Grid container style={{ minHeight: '300px' }}>
                <Grid item xs={6}>

                    <Paper variant="outlined" elevation={3}  >
                        {/* {groups && groups.map((item, i) => {
                            const con = Object.entries(item);

                            return (
                                <span>{con[0][0]}:{con[0][1]?.toString()}</span>
                            )
                        })
                        } */}
                        <MaterialTable
                            title="Selected Stores"
                            data={groups}
                            columns={columns}
                            editable={{
                                // onRowAdd: (newRow) => new Promise((resolve, reject) => {
                                //     const updatedRows = [...groups, { id: Math.floor(Math.random() * 100), ...newRow }]
                                //     setTimeout(() => {
                                //         SetGroups(updatedRows)
                                //         resolve()
                                //     }, 2000)
                                // }),
                                // onRowDelete: selectedRow => new Promise((resolve, reject) => {
                                //     const index = selectedRow.tableData.id;
                                //     const updatedRows = [...groups]
                                //     updatedRows.splice(index, 1)
                                //     setTimeout(() => {
                                //         SetGroups(updatedRows)
                                //         resolve()
                                //     }, 2000)
                                // }),
                                // onRowUpdate: (updatedRow, oldRow) => new Promise((resolve, reject) => {
                                //     const index = oldRow.tableData.id;
                                //     const updatedRows = [...groups]
                                //     updatedRows[index] = updatedRow
                                //     setTimeout(() => {
                                //         SetGroups(updatedRows)
                                //         resolve()
                                //     }, 2000)
                                // })

                            }}
                            options={{
                                actionsColumnIndex: -1, addRowPosition: "first",
                                headerStyle: {
                                    // backgroundColor: '#40BBA0',
                                    backgroundColor: '#182C52',
                                    borderWidth: 0,
                                    borderRightWidth: 1,
                                    borderColor: '#E6E7E8',
                                    borderStyle: 'solid',
                                    color: '#ffffff',
                                    fontWeight: 400,
                                    fontSize: 14,
                                    padding: '4px 4px',
                                    textAlign: 'center',

                                },
                                sorting: true,
                                search: false,
                                toolbar: true,
                                paging: true,
                                pageSize: 5,
                                filtering: true,
                                minBodyHeight: 200,
                                position: 'sticky',
                                cellStyle: rowData => {
                                    return {
                                        textAlign: 'left'
                                    };
                                },
                                rowStyle: rowData => {
                                    return {
                                        backgroundColor: 'inherit',
                                        color: 'inherit',
                                        cursor: 'pointer'
                                    };
                                }
                            }}
                            actions={[
                                {
                                    icon: () => <Edit fontSize="small" />,
                                    tooltip: 'Edit',
                                    onClick: (e, rowData) => {
                                        editStores(rowData);
                                    }
                                },
                                {
                                    icon: () => <DeleteForever fontSize="small" />,
                                    tooltip: 'Delete',
                                    onClick: (e, rowData) => {
                                        deleteStores(rowData.id);
                                    }
                                }
                            ]}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={6} style={{ padding: '0.5%' }}>
                    <Paper >

                        <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', float: 'right' }}>
                            <h3 className={classes.formLabel}>
                                Upload Stores :
                            </h3>
                            <Switch
                                checked={isUpload.checkedA}
                                onChange={handleChange}
                                name="checkedA"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                        </Box>



                        <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '5%' }}>
                            <h3 className={classes.formLabel}>
                                Group Name:
                            </h3>
                            <TextField id="outlined-basic" label="Stores Group Name" value={groupName}
                                variant="outlined" onChange={(e, val) => setGroupName(e.target.value)} style={{ width: '400px', marginRight: '9%' }} />
                        </Box>
                        {/* <Box component="div" container p={1}>
                            <h3 className={classes.formLabel}>
                                Select Stores ID's
                            </h3>
                        </Box> */}

                        <Box component="div" container p={1} style={{ marginTop: '3%' }}>
                            <h3 className={classes.formLabel}>
                                {isUpload.checkedA ? `Upload Stores ID's` : `Select Stores ID's`}
                            </h3>

                            {isUpload.checkedA ?

                                <UploadCsv setSelectedSTores={setSelectedSTores} />

                                : <Multiselect
                                    ref={multiselectRef}
                                    isObject={true}
                                    displayValue="STORE_ID"
                                    onKeyPressFn={function noRefCheck() { }}
                                    onRemove={(val, rem) => handleMultiAttribute(val, null, rem)}
                                    onSearch={(val) => console.log(val)}
                                    onSelect={(val, item) => handleMultiAttribute(val, item, null)}
                                    options={getallstores}
                                    selectedValues={editGroupId ? editStoresList : []}
                                    style={{
                                        chips: {
                                            background: '#E7E7E7',
                                            color: 'black',
                                            borderRadius: '4px',
                                            fontSize: '10px'
                                        }
                                    }}
                                />
                            }
                        </Box>
                        <Box component="div" container p={1} style={{ display: 'flex', alignContent: 'center', justifyContent: 'flex-end' }}>

                            <Button
                                className={classes.addGrp}

                                color="primary"
                                disabled={selectedSTores.length > 0 ? false : true}
                                onClick={e => {
                                    //   dispatch(setQueryBuilderTree(queryParam.tree));
                                    //   dispatch(setTabNavigation(3));
                                    if (groupName.length) {
                                        addStoreGroup();
                                        setGroupName('')
                                    } else {
                                        setIsError(true)
                                    }


                                }}
                                size="small"
                                variant="contained">
                                {editGroupId ? "Save" : "Add Group"}
                            </Button>
                        </Box>
                        <Box>
                            {!isError ? '' :
                                <Alert severity="error" onClose={() => { setIsError(false) }}>
                                    <AlertTitle>Error</AlertTitle>
                                    No Group Name</Alert>

                            }
                        </Box>

                    </Paper>
                </Grid>
                {/* <Grid item md={12}>
                    
                </Grid> */}
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'end' }}>
                    <Button
                        className={classes.alignRight}
                        style={{ marginRight: '1%' }}
                        // color="primary"
                        // disabled={selectedSTores.length > 0 ? false : true}
                        onClick={e => {
                            //   dispatch(setQueryBuilderTree(queryParam.tree));
                            //   dispatch(setTabNavigation(3));
                            // addStoreGroup()
                            // console.log(groups,"onsubmit groups")
                            props.handleAudModal();
                            SetGroups([]);
                        }}
                        size="small"
                        variant="contained">
                        Cancel
                    </Button>
                    <Button
                        className={classes.alignRight}
                        color="primary"
                        disabled={groups.length > 0 ? false : true}
                        onClick={e => {
                            console.log(groups, "onsubmit groups");
                            submitGroup();
                            props.handleAudModal();
                            SetGroups([]);
                            props.setUpdateGrpVal([]);
                            // props.setLoad(true);
                            dispatch(setLoading(true));

                        }}
                        size="small"
                        variant="contained">
                        Submit
                    </Button>

                </Grid>
            </Grid>
        </>
    )
}

export default SelectGroup;

{/* //obj */ }
{/* {
                    allGoupsVal?.map((item,i)=> {
                        return (
                            <>
                            <span>{item[0]}</span>:<span>{item[1]}</span>
                            </>
                        )
                    })
                  } */}