import React from 'react';

import { Box} from '@material-ui/core';


import ReactBreadcrumbs from 'views/Common/Breadcrumb/Breadcrumb';

const CreateGroup = () => {
  return (
    <>
      <Box style={{ background: '#F8F8F8', marginTop: '-1%', height: '100%' }}>
        <Box component={'div'} style={{ margin: '2% 2% 1% 2%' }}>
          <ReactBreadcrumbs />
        </Box>
        <Box></Box>
      </Box>
    </>
  );
};

export default CreateGroup;
