import { GETCAMPAIGNS, SET_CAMPAIGN_EDIT } from '../types';

const initialState = {
  campaignList: [],
  isEdit: false
};

const homePageReducer = (state = initialState, action) => {
  switch (action.type) {
    case GETCAMPAIGNS:
      return {
        ...state,
        campaignList: action.payload
      };
    case SET_CAMPAIGN_EDIT:
      return {
        ...state,
        isEdit: action.payload
      };
    default:
      return state;
  }
};

export default homePageReducer;
