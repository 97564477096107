import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Box, Grid, Paper, Typography, Link } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  info: {
      padding: '0 20px 20px',
    textAlign: "center",
    "& h3": {
      lineHeight: 10,
    },
  },
  paper: {
    position: "relative",
    // marginTop: "10px",
    // marginInline: "20px",
    padding: "10px",
    borderRadius:"0px"
  },
}));

const DisplayContainer = ({
  title,
  subtitle,
  content,
  contentRight,
  paperElevation,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  function viewTimeLineHandler() {
    console.log("clicked");
    setOpen((prev) => true);
  }

  function handleModalClose() {
    console.log("clicked");
    setOpen((prev) => false);
  }

  return (
    <>
      <Box
        component="div"
        container
        className={classes.info}
      >
        <Paper
          className={classes.paper}
          elevation={paperElevation ? paperElevation : 1}
        >
          <Grid container>
            <Grid item md={12}>
              <Grid
                container
                style={{ display: title ? "flex" : "none"}}
              >
                <Grid item md={3}>
                  <Typography
                    variant="subtitle1"
                    align="left"
                    style={{ color: "#464A53",padding:"8px" }}
                  >
                    {title}
                  </Typography>
                </Grid>
                <Grid item md={9}>
                  {contentRight}
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12}>
              {content}
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  );
};

export default DisplayContainer;
