import React, { useState } from 'react';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Paper
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import DialogBox from '../../../../views/Common/Modals/Dailog';
import useStyles from './Style';
import { useEffect } from 'react';
import secureAxios from 'api-config/api';
import InfoComp from 'components/InfoComp';
import PieChart from 'views/Common/Charts/PieChart';
import MeasureCarousel from '../MeasureCarousel/MeasureCarousel';
import ExpandDialog from './ExpandDialog';
import { useDispatch } from 'react-redux';
import { numberWithCommas } from 'views/Common/Utils/ClientUtil';
const MeasureHightLight = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [expandDialogOpen, setExpandDialogOpen] = useState(false);
  const [campaignEngagement, setCampaignEngagement] = useState([]);
  const [expandDialogData, setExpandDialogData] = useState({
    val: {},
    view: ''
  });
  const handleExpandDialogOpen = (val, view) => {
    setExpandDialogOpen(true);
    setExpandDialogData({
      ...expandDialogData,
      val: val,
      view: view
    });
  };
  const handleExpandDialogClose = () => {
    setExpandDialogOpen(false);
  };

  //api call for
  const fetchData = async () => {
    try {
      dispatch({
        type: 'LOADING',
        payload: 'true'
      });
      const res = await secureAxios.get(
        '/measureCampaign/getBusinessPerformance/',
        {
          params: {
            outlierValue: props?.outlierChecked,
            camp_id: props?.campaign_id
          }
        }
      );
      setCampaignEngagement(res?.data?.responseData);
      dispatch({
        type: 'LOADING',
        payload: false
      });
    } catch (err) {}
  };

  useEffect(() => {
    let sub = true;
    if (sub) {
      fetchData();
    }
    return () => {
      return () => false;
    };
  }, [props?.outlierChecked]);

  const handleValueformat = (val, txt) => {
    const testarr = [
      'Transaction Count',
      'Inventory On Hand',
      'Traffic',
      'Net Sales (Units)'
    ];
    if (txt?.includes('%')) {
      return val?.toFixed(2) + '%';
    } else {
      let newval = val?.toFixed(2)?.split('.');
      if (newval?.length === 2 && testarr?.includes(txt) && newval[1][1] < 2) {
        let result = numberWithCommas(val?.toFixed(1));
        return txt?.includes('$') ? '$' + result : result;
      } else {
        let result = numberWithCommas(val?.toFixed(2));
        return txt?.includes('$') || txt.includes('AUR') || txt.includes('ADS')
          ? '$' + result
          : result;
      }
    }
  };
  return (
    <>
      <Card className={classes.cardItem} elevation={2}>
        <CardContent>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography className={classes.sectionHeading}>
              Business Performance
            </Typography>
            <Paper elevation={3} className={classes.root}>
              <div className={classes.upperHalf}>
                <Typography variant="body1" style={{ color: '#24A148',fontSize:'8px' }}>
                  Statistically significant
                </Typography>
              </div>
              <div className={classes.lowerHalf}>
                <Typography variant="body1" style={{ color: '#E01F29',fontSize:'8px' }}>
                  Statistically insignificant
                </Typography>
              </div>
            </Paper>
          </div>
          <Accordion
            className={
              campaignEngagement.overallStatus === 'Green'
                ? classes.accordGreen
                : classes.accordRed
            }>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header">
              <Typography>
                {campaignEngagement.overallStatus === 'Green' ? (
                  <TrendingUpIcon />
                ) : (
                  <TrendingDownIcon />
                )}
                {campaignEngagement.topDescription}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {Object?.keys(campaignEngagement).length &&
              campaignEngagement.kpiRows.length ? (
                campaignEngagement.kpiRows.map((item, index) => (
                  <Grid
                    container
                    spacing={3}
                    className={classes.accordBlock}
                    key={index}>
                    <Grid item xs={4}>
                      <Box component="div" className={classes.sideBlock}>
                        <Typography component="h4" variant="subtitle1">
                          {item.kpiLabel}
                          <Typography component="span" variant="subtitle1">
                            <InfoComp
                              content={<InfoOutlinedIcon fontSize={'small'} />}
                              data={item.kpiDefn}
                            />
                          </Typography>
                          <ZoomOutMapIcon
                            style={{ color: '#182C52' }}
                            onClick={e => {
                              handleExpandDialogOpen(
                                item,
                                'campaignEngagement'
                              );
                            }}
                          />
                        </Typography>
                      </Box>

                      {item.kpiType === 'abs' || item.kpiType === 'int' ? (
                        <Box component="div">
                          <Grid container spacing={3}>
                            {item?.lift < 0 ? (
                              <Grid
                                item
                                xs={2}
                                className={classes.cardBlockSvgRed}>
                                <TrendingDownIcon />
                              </Grid>
                            ) : (
                              <Grid
                                item
                                xs={2}
                                className={classes.cardBlockSvgGreen}>
                                <TrendingUpIcon />
                              </Grid>
                            )}
                            <Grid item xs={5} className={classes.cardBlockVal}>
                              <Typography component="h4" variant="h4">
                                {handleValueformat(
                                  item.testAverage,
                                  item.kpiLabel
                                )}
                              </Typography>

                              <Typography component="span" variant="caption">
                                Post
                              </Typography>
                            </Grid>

                            <Grid item xs={5}>
                              {item.significance === null ||
                              item.significance >= 0.2 ? (
                                <Typography
                                  component="span"
                                  variant="subtitle2"
                                  className={classes.cardBlockLiftRed}>
                                  {/* {item.lift < 0 ? <ArrowDropDownIcon /> :  <ArrowDropUpIcon /> } */}
                                  <Typography
                                    component="span"
                                    variant="subtitle2">
                                    {`${item.lift}     %Lift`}
                                  </Typography>
                                </Typography>
                              ) : item.significance > 0.05 &&
                                item.significance < 0.2 ? (
                                <Typography
                                  component="span"
                                  variant="subtitle2"
                                  className={classes.cardBlockLiftYellow}>
                                  {/* {item.lift < 0 ? <ArrowDropDownIcon /> :  <ArrowDropUpIcon /> } */}
                                  <Typography
                                    component="span"
                                    variant="subtitle2">
                                    {`${item.lift}     %Lift`}
                                  </Typography>
                                </Typography>
                              ) : (
                                <Typography
                                  component="span"
                                  variant="subtitle2"
                                  className={classes.cardBlockLiftGreen}>
                                  {/* {item.lift < 0 ? <ArrowDropDownIcon /> :  <ArrowDropUpIcon /> } */}
                                  <Typography
                                    component="span"
                                    variant="subtitle2">
                                    {`${item.lift}     %Lift`}
                                  </Typography>
                                </Typography>
                              )}
                            </Grid>
                          </Grid>
                          <Grid container spacing={3}>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={5} className={classes.cardBlockVal}>
                              <Typography component="h4" variant="h4">
                                {item.benchmark === null
                                  ? 'NA'
                                  : handleValueformat(
                                      item?.benchmark,
                                      item?.kpiLabel
                                    )}
                              </Typography>

                              <Typography component="span" variant="caption">
                                Pre
                              </Typography>
                            </Grid>

                            <Grid item xs={5}>
                              {item.significance === null ||
                              item.significance >= 0.2 ? (
                                <Typography
                                  component="span"
                                  variant="subtitle2"
                                  className={classes.cardBlockLiftRed}>
                                  {/* {(item.pctChange < 0 || item.pctChange === null) ? <ArrowDropDownIcon /> :  <ArrowDropUpIcon /> } */}
                                  <Typography
                                    component="span"
                                    variant="subtitle2">
                                    {`${
                                      item.pctChange ? item.pctChange : 'NA'
                                    }     %Change`}
                                  </Typography>
                                </Typography>
                              ) : item.significance > 0.05 &&
                                item.significance < 0.2 ? (
                                <Typography
                                  component="span"
                                  variant="subtitle2"
                                  className={classes.cardBlockLiftYellow}>
                                  {/* {(item.pctChange < 0 || item.pctChange === null) ? <ArrowDropDownIcon /> :  <ArrowDropUpIcon /> } */}
                                  <Typography
                                    component="span"
                                    variant="subtitle2">
                                    {`${
                                      item.pctChange ? item.pctChange : 'NA'
                                    }     %Change`}
                                  </Typography>
                                </Typography>
                              ) : (
                                <Typography
                                  component="span"
                                  variant="subtitle2"
                                  className={classes.cardBlockLiftGreen}>
                                  {/* {(item.pctChange < 0 || item.pctChange === null) ? <ArrowDropDownIcon /> :  <ArrowDropUpIcon /> } */}
                                  <Typography
                                    component="span"
                                    variant="subtitle2">
                                    {`${
                                      item?.pctChange ? item?.pctChange : 'NA'
                                    }     %Change`}
                                  </Typography>
                                </Typography>
                              )}
                            </Grid>
                          </Grid>
                        </Box>
                      ) : (
                        <Grid container spacing={3}>
                          <Grid item xs={6}>
                            <PieChart
                              data={
                                item?.testAverage > item?.benchmark
                                  ? [
                                      item?.benchmark,
                                      item.testAverage - item.benchmark
                                    ]
                                  : [
                                      item.testAverage,
                                      item.benchmark - item.testAverage
                                    ]
                              }
                              testAvg={item.testAverage}
                              labels={[
                                item.testAverage > item.benchmark
                                  ? 'Benchmark'
                                  : 'Test Avg',
                                '%Change',
                                'Total'
                              ]}
                              colors={
                                item.testAverage > item.benchmark
                                  ? ['#013598', '#24A148', '#EAEAEA']
                                  : ['#E01F29', '#013598', '#EAEAEA']
                              }
                              sparkline={true}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Box component="div" className={classes.sideBlock}>
                              <Typography
                                component="h5"
                                variant="body1"
                                style={{
                                  color:
                                    item.benchmark < item.testAverage
                                      ? '#24A148'
                                      : '#E01F29'
                                }}>
                                {item.comment}
                              </Typography>
                              <Typography component="h6" variant="body2">
                                Benchmark :
                                {item?.benchmark
                                  ? item?.benchmark.toFixed(2)
                                  : ''}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                    <Grid item xs={8}>
                      {item?.segmentArr[0]?.benchmark === null ? (
                        ''
                      ) : (
                        <MeasureCarousel
                          data={item?.segmentArr}
                          view={'purchaseBehavior'}
                          type={item?.kpiType}
                        />
                      )}
                    </Grid>
                  </Grid>
                ))
              ) : (
                <Typography
                  component="h3"
                  variant="h3"
                  className={classes.info}>
                  {'No records to display'}
                </Typography>
              )}
            </AccordionDetails>
          </Accordion>
        </CardContent>
      </Card>

      <DialogBox
        width={1920}
        open={expandDialogOpen}
        handleClose={handleExpandDialogClose}
        header={expandDialogData?.val?.kpiLabel}
        children={<ExpandDialog data={expandDialogData} />}></DialogBox>
    </>
  );
};

export default MeasureHightLight;
