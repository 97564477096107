import {
  IDENTIFYTEST,
  TESTTABLECOUNT,
  CANDIDATESTSTORE,
  FINALCANDIDATESTSTORE,
  QUERYBUILDERTREE,
  SELECTEDATTRIBUTE,
  EXCLUDETESTSTORESDATA,
  EXCLUDETESTSTORESLIST,
  STORESGROUPLIST,
  SELECTEDRANDOMSTORELIST,
  IDENTIFY_TEST_REGION,
  IDENTIFY_TEST_SELECTED_REGION,
  IDENTIFY_TEST_RANDOM_SAMPLE,
  IDENTIFY_TEST_RANDOM_SAMPLE_STORE,
  IDENTIFY_NEXT_BTN,
  IDENTIFY_UPLOAD_TEST_STORE_COUNT,
  IDENTIFY_TEST_BRAND,
  IDENTIFY_TEST_BRAND_SELECTED,
  IDENTIFY_TEST_DEPT,
  IDENTIFY_TEST_DEPT_SELECTED,
  IDENTIFY_TEST_DIVISION,
  IDENTIFY_TEST_DIVISION_SELECTED,
  IDENTIFY_TEST_PRODUCT_GROUP,
  IDENTIFY_TEST_PRODUCT_GROUP_SELECTED,
  IDENTIFY_TEST_METRICS,
  IDENTIFY_TEST_METRICS_SELECTED,
  IDENTIFY_TEST_SELECTED_REGION_STOREIDS,
  IDENTIFY_TEST_INCLUDE_STOREID,
  IDENTIFY_TEST_STAT_TABLE_DATA,
  CLEAR_IDENTIFY_TEST_VALUES,
  ATTRSTORESTATUS,
  SETTOTALSTORE,
  TELLUSMORE,
  TELLUSMORERES,
  IDENTIFY_EXCLUDE_RAD_VAL
} from '../types';

export const setIdentifyTest = val => {
  return {
    type: IDENTIFYTEST,
    payload: val
  };
};
export const setIdentifyTestMetricsVal = val => {
  return {
    type: IDENTIFY_TEST_METRICS,
    payload: val
  };
};
export const setIdentifyTestSelMetricsVal = val => {
  return {
    type: IDENTIFY_TEST_METRICS_SELECTED,
    payload: val
  };
};
export const setIdentifyTestBrandVal = val => {
  return {
    type: IDENTIFY_TEST_BRAND,
    payload: val
  };
};
export const setIdentifyTestStatTableData = val => {
  return {
    type: IDENTIFY_TEST_STAT_TABLE_DATA,
    payload: val
  };
};
export const setIdentifyTestSelBrandVal = val => {
  return {
    type: IDENTIFY_TEST_BRAND_SELECTED,
    payload: val
  };
};
export const setIdentifyTestDeptVal = val => {
  return {
    type: IDENTIFY_TEST_DEPT,
    payload: val
  };
};
export const setIdentifyTestSelDeptVal = val => {
  return {
    type: IDENTIFY_TEST_DEPT_SELECTED,
    payload: val
  };
};
export const setIdentifyTestProductGrpVal = val => {
  return {
    type: IDENTIFY_TEST_PRODUCT_GROUP,
    payload: val
  };
};
export const setIdentifyTestSelProductGrpVal = val => {
  return {
    type: IDENTIFY_TEST_PRODUCT_GROUP_SELECTED,
    payload: val
  };
};
export const setIdentifyTestDivisionGrpVal = val => {
  return {
    type: IDENTIFY_TEST_DIVISION,
    payload: val
  };
};
export const setIdentifyTestSelDivisionGrpVal = val => {
  return {
    type: IDENTIFY_TEST_DIVISION_SELECTED,
    payload: val
  };
};

export const setTestTableCount = val => {
  return {
    type: TESTTABLECOUNT,
    payload: val
  };
};
export const setIdentifyIclusionstoreids = val => {
  return {
    type: IDENTIFY_TEST_INCLUDE_STOREID,
    payload: val
  };
};
export const setRegionStoreIds = val => {
  return {
    type: IDENTIFY_TEST_SELECTED_REGION_STOREIDS,
    payload: val
  };
};

export const setQueryBuilderTree = val => {
  return {
    type: QUERYBUILDERTREE,
    payload: val
  };
};

export const setCandidateTestStore = val => {
  console.log(val, 'AcTION CANDIDATE');
  return {
    type: CANDIDATESTSTORE,
    payload: val
  };
};
export const setSelectedAttribute = val => {
  console.log(val, 'AcTION CANDIDATE');
  return {
    type: SELECTEDATTRIBUTE,
    payload: val
  };
};
export const setFinaleSelCandidateTestStore = val => {
  console.log(val, 'FAcTION CANDIDATE');
  return {
    type: FINALCANDIDATESTSTORE,
    payload: val
  };
};
export const setExcludeTestStoresData = val => {
  return {
    type: EXCLUDETESTSTORESDATA,
    payload: val
  };
};
export const setExcludeTestStoresList = val => {
  return {
    type: EXCLUDETESTSTORESLIST,
    payload: val
  };
};
export const setStoresGroupList = val => {
  return {
    type: STORESGROUPLIST,
    payload: val
  };
};
export const setSelectedRandomStoreList = val => {
  return {
    type: SELECTEDRANDOMSTORELIST,
    payload: val
  };
};

export const setRegionList = val => {
  return {
    type: IDENTIFY_TEST_REGION,
    payload: val
  };
};

export const setRegionSelectedList = val => {
  return {
    type: IDENTIFY_TEST_SELECTED_REGION,
    payload: val
  };
};

export const setRandomSample = val => {
  return {
    type: IDENTIFY_TEST_RANDOM_SAMPLE,
    payload: val
  };
};

export const setRandomSampleStore = val => {
  return {
    type: IDENTIFY_TEST_RANDOM_SAMPLE_STORE,
    payload: val
  };
};

export const setIdentifyTestNextButton = val => {
  return {
    type: IDENTIFY_NEXT_BTN,
    payload: val
  }
};
export const setUploadTestStoreCount = val => {
  return {
    type: IDENTIFY_UPLOAD_TEST_STORE_COUNT,
    payload: val
  };
};
export const setExcludeTestRadioVal = val => {
  return {
    type: IDENTIFY_EXCLUDE_RAD_VAL,
    payload: val
  };
};
export const clearIdentifyTestValues = () => {
  return {
    type: CLEAR_IDENTIFY_TEST_VALUES
  };
}
export const setTotalStoresCount = (val) => {
  return {
    type: SETTOTALSTORE,
    payload: val
  };
}
export const setTellUsMoreRes = val => {
  return {
    type: TELLUSMORERES,
    payload: val
  };
};
export const setAttributeStatus = (val) => {
  return {
    type: ATTRSTORESTATUS,
    payload: val
  };
}