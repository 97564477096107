import { CLEAR_IDENTIFY_CONTROL_VALUES, IDENTIFYCONTROL } from '../types';
import { NEAREASTMATCH } from '../types';

export const setIdentifyControl = val => {
  return {
    type: IDENTIFYCONTROL,
    payload: val
  };
};

export const setNeareastMatch = val => {
  return {
    type: NEAREASTMATCH,
    payload: val
  };
};
export const clearIdentifyControlValues = () => {
  return {
    type: CLEAR_IDENTIFY_CONTROL_VALUES
  };
}
