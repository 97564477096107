import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  AppBar,
  Hidden,
  IconButton,
  Toolbar,
  List,
  ListItem,
  ListItemText,
  Button,
  Typography,
  Grid,
  FormHelperText
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncPaginate } from 'react-select-async-paginate';
import _ from 'lodash';
import { useEffect } from 'react';

import secureAxios from 'api-config/api';
import { useState } from 'react';
import MultiSelectDropdown from 'views/Common/Filters/MultiSelectDropdown';
import Multiselect from 'multiselect-react-dropdown';
import FullLoader from 'views/Common/FullLoader/FullLoader';

import { setSelectedAttribute } from '../../../redux/actions/identifyTest.action';
import {
  getAttribute,
  getSelectedAttributeOptions,
  getStoreFromSelectedAttrOption,
  setAttributeOptionsVal,
  setFinalCandidateStores,
  setTellUsMoreFinalCandidateStore,
  setTellUsMoreSelectedAttributes,
  setTellUsMoreSelectedSubAttributes
} from 'api-config';
import { setLoading } from 'redux/actions/application.action';
import { onHandleClearAttribute } from 'components/helper';
import { aboutTestAttrStoreIdsSel } from 'redux/actions/aboutTest.action';

const allStoreID = [];

const useStyles = makeStyles(theme => ({
  formLabel: {
    margin: '5px 0 10px',
    color: '#031330',
    fontWeight: 500
  },
  formLabels: {
    margin: '5px 0 10px',
    color: '#787878',
    fontWeight: 400
  },
  asyncinput: {
    width: '360px',
    marginTop: '3%'
  },
  mainAttrDrp: {
    width: '70%'
  },
  infoArea: {
    fontSize: 12,
    '& svg': {
      fontSize: '1rem !important',
      margin: '0 5px 0 0 !important',
      verticalAlign: 'sub'
    }
  }
}));



const sleep = ms =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve(undefined);
    }, ms);
  });

const DropdownList = props => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // const application = useSelector(state => ({
  //   ...state.application
  // }));
  // const identifyTestReducer = useSelector(state => ({
  //   ...state.identifyTestReducer
  // }));
  // const aboutTestReducer = useSelector(state => ({
  //   ...state.aboutTestReducer
  // }));
  // const { attributeoptionselected, storeidsattrselected, selectedattribute } = identifyTestReducer;
  // const { tellUsMoreSelectedAttribute, tellUsMoreSelectedSubattribute } = aboutTestReducer;
  // const [searchSelections, setSearchSelections] = useState([]);
  const [storeDatas, setStoreDatas] = useState([]);
  const [dropDatas, setDropDatas] = useState([]);
  // const [finalCandidateStoress, setFinalCandidatStores] = useState([]);
  const [finalCandidateStoressAll, setFinalCandidatStoresAll] = useState([]);
  const [multiOption, setMultiOption] = useState([]);
  const [multiOptionVal, setMultiOptionVal] = useState([]);
  const [initialSelectedValues, setInitialSelectedValues] = useState([]);
  // const [AttrChosenValSel, setAttrChosenValSel] = useState([]);
  let itemLabel = `Select ${props.selected.label}`;
  const { attributeoptionselected } = props;

  // function setMultiOptions(val) {
  //   const storeMatchData = val.map(item => {
  //     return { [`${item.value}`]: item.storeID }
  //   })
  //   setFinalCandidatStoresAll(storeMatchData);
  //   const multiSelectOptions = _.map(val, 'value');
  //   console.log(multiSelectOptions, "multiSelectOptions")
  //   setMultiOption(multiSelectOptions);
  // }

  useEffect(() => {
    if (attributeoptionselected.find(ele => ele.key === props.selected.value)) {
      let temp = [];
      temp = attributeoptionselected
        .find(ele => ele.key === props.selected.value)
        .val.map(ele => {
          return { key: props.selected.value, val: ele };
        });
      setInitialSelectedValues(temp);
    } else setInitialSelectedValues([]);
  }, [attributeoptionselected]);

  useEffect(() => {
    let result;
    if (storeDatas?.length) {
      result = _.find(storeDatas, function(obj) {
        if (obj.ATTRIBUTE == props.selected.label) {
          return true;
        }
      });
    }
    setDropDatas(result);
  }, [storeDatas]);

  useEffect(() => {
    if (attributeoptionselected.length <= 0) {
      // props.setStore([])
      dispatch(getStoreFromSelectedAttrOption([]));
    }
    const attVAlues = props.attrVal.length
      ? props.attrVal.map(item => {
          if (item[props.selected.label]) {
            return {
              label: item[props.selected.label],
              value: item[props.selected.label],
              storeID: item[`${props.selected.label}_storeID`]
            };
          }
        })
      : [];

    let newImplAttrVal = props.attrVal.length
      ? props.attrVal.map(item => {
          let obj = {};
          const key = Object.keys(item);
          const val = Object.values(item);
          let Datas = Object.entries(item);

          obj['key'] = Datas[0][0];
          obj['val'] = Datas[0][1];
          console.log(Datas, obj);
          return obj;
        })
      : [];

    // const uniqueData = _.uniqWith(attVAlues,_.isEqual);
    const uniqueData = _.uniqBy(attVAlues, `label`);
    // const uniqueData = attVAlues.reduce((acc, item) => {
    //   console.log(acc,item,'intialacc')
    //   const found = acc.find(i => i.ATTR_VALUE == item.ATTR_VALUE &&  i.ATTRIBUTE == item.ATTRIBUTE );
    //      console.log(found,'found')

    //   if(found) {
    //      found.storeID.push(item.storeID);
    //   } else {
    //      acc.push({ATTRIBUTE :item.ATTRIBUTE,ATTR_VALUE: item.ATTR_VALUE, storeID: [item.storeID]})
    //     console.log(acc,'acc')
    //   }

    //   return acc;
    // }, []);
    const uniqueDatas = _.compact(uniqueData);
    const AttrValueUn = _.compact(attVAlues);
    console.log(attVAlues, AttrValueUn, 'check it');
    const formatStores =
      AttrValueUn.length > 0
        ? AttrValueUn.reduce((acc, item) => {
            console.log(acc, item, 'intialacc');
            const found = acc.find(
              i => i.value == item.value && i.label == item.label
            );
            console.log(found, 'found');

            if (found) {
              found.storeID.push(item.storeID);
            } else {
              acc.push({
                value: item.value,
                label: item.label,
                storeID: [item.storeID]
              });
              console.log(acc, 'acc');
            }

            return acc;
          }, [])
        : [];
    console.log(formatStores, props.selected.label, 'formatStores');
    // setMultiOptions(formatStores);

    let filteredVal = newImplAttrVal.filter(
      item => item.key == String(props.selected.label)
    );
    setStoreDatas(uniqueData ? filteredVal : attVAlues);
  }, [props.attrVal]);

  const getStroreVal = val => {
    try {
      secureAxios
        .post('/designCampaign/getstoreAttriVal', {
          selectedAttr: val
        })
        .then(res => {
          console.log(res.data, 'AttributeData');
          // const attVAlues =   res.data.map(item=>{
          //   return item[props.selected.label]
          // })
          // setAttrVal(res.data.data);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (
      attributeoptionselected.length &&
      props.multiOptionVals && props.multiOptionVals.length > 0
    ) {
      attributeoptionselected.map(item => {
        if (props.multiOptionVals.length) {
          // dispatch(setAttributeOptionsVal([...attributeoptionselected, ...props.multiOptionVals]));
          props.handleSubAttributeRedux(props.multiOptionVals);
        }
      });
    } else if (
      attributeoptionselected.length == 0 &&
      props.multiOptionVals && props.multiOptionVals.length > 0
    ) {
      // dispatch(setAttributeOptionsVal([...attributeoptionselected, ...props.multiOptionVals]));
      props.handleSubAttributeRedux(props.multiOptionVals);

      // setTimeout(() => {
      //   dispatch(setAttributeOptionsVal([...attributeoptionselected, ...props.multiOptionVals]));

      // }, 1000)
    }
  }, [props.multiOptionVals]);

  function getUniqueListBy(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()];
  }

  const handleMultiAttribute = (values, item, rem) => {
    // props.setSelChossenAttr((prevState => [...prevState, attrSel]));
    // setAttrChosenValSel((prevState => [...prevState, attrSel]));

    // console.log(attributeoptionselected)
    if (rem) {
      // const data = attributeoptionselected?.filter(function (str) { return str.indexOf(rem.value) === -1; })
      let data = attributeoptionselected?.map(function(str) {
        if (rem.key == str.key && str.val.length > 0) {
          let values = str.val.filter(item => item !== rem.val);
          str['val'] = values;
        }
        if (str.val && str.val.length <= 0) {
          str = null;
        }
        return str;
      });
      data = _.compact(data);
      data = getUniqueListBy(data, 'key');

      // const data = props.SelChossenAttr?.filter((el, i) => {
      //   return !rem.storeID.includes(el)
      // }
      // const data = props.SelChossenAttr?.filter((el, i) => {
      //   return !rem.storeID.includes(el)
      // }
      // const data = props.multiOptionVal?.filter((el,i) =>{
      //   return  el != rem.storeID[i]
      // }
      // );
      // props.setMultiOptionVal(data);
      // props.handleSubAttributeRedux(data)
      props.setMultiOptionVals(prev => data);

      // props.setSelChossenAttr(data);
    } else {
      let obj = {};

      obj['key'] = item.key;
      obj['val'] = [item.val];
      let mulOptVal;
      if (props.multiOptionVals.length) {
        mulOptVal = props.multiOptionVals.map((value, i) => {
          if (value && value.key == item.key) {
            value.val = [...value.val, item.val];
            return value;
          } else {
            props.setMultiOptionVals(prev => [obj]);
            // setMultiOptionVal(prev => [...prev, obj]);
            // props.setMultiOptionVals(prev => [...prev, obj])
          }
          // return value
        });
      } else {
        props.setMultiOptionVals(prev => [obj]);
        // setMultiOptionVal(prev => [...prev, obj]);
      }
      mulOptVal = _.compact(mulOptVal);
      mulOptVal = mulOptVal.length && getUniqueListBy(mulOptVal, 'key');
      mulOptVal.length && props.setMultiOptionVals(prev => mulOptVal);
      // mulOptVal.length && setMultiOptionVal(prev => mulOptVal)

      // const attrSel = item.value;
      // props.setSelChossenAttr((prevState => [...prevState, attrSel]));
      // if (attributeoptionselected.length) {
      //   attributeoptionselected.map(item => {
      //     if (item.key !== props.multiOptionVals[0].key && props.multiOptionVals.length) {

      //       dispatch(setAttributeOptionsVal([...attributeoptionselected, ...props.multiOptionVals]));

      //     }
      //   })
      // } else if (attributeoptionselected.length == 0) {
      //   dispatch(setAttributeOptionsVal([...attributeoptionselected, ...props.multiOptionVals]));
      //   // setTimeout(() => {
      //   //   dispatch(setAttributeOptionsVal([...attributeoptionselected, ...props.multiOptionVals]));

      //   // }, 1000)

      // }

      // const stId = item?.storeID.forEach(ele => {
      //   props.setMultiOptionVal((prevState => [...prevState, ele]));

      // })

      // props.setStore(prevState=>[...prevState,values])}
    }
  };
  //   const loadOptions = async (search, prevOptions) => {
  //     await sleep(1000);
  //     let filteredOptions;
  //     if (!search) {
  //       filteredOptions = storeDatas;
  //     } else {
  //       const searchLower = search.toLowerCase();
  // //      const storeMatchData= storeDatas.map(item=> {
  // //         return {[item.value]:item.storeID}
  // //       })
  // //       setFinalCandidatStoresAll(storeMatchData);
  // // const multiSelectOptions = _.map(storeDatas,'value');
  // // setMultiOption(multiSelectOptions);
  //       filteredOptions = storeDatas.filter(({ label }) =>
  //         label.toLowerCase().includes(searchLower)
  //       );
  //     }
  //     const hasMore = filteredOptions.length > prevOptions.length + 10;
  //     const slicedOptions = filteredOptions.slice(
  //       prevOptions.length,
  //       prevOptions.length + 10
  //     );
  //     return {
  //       options: slicedOptions,
  //       hasMore
  //     };
  //   };



  
  return (
    <>
      <Grid item md={4} style={{ padding: '1% 3%' }}>
        <Typography className={classes.formLabel}>{itemLabel}</Typography>

        <Multiselect
          // customCloseIcon={<><img src={img} /></>}
          closeIcon={true}
          displayValue="val"
          // isObject={true}
          onKeyPressFn={val => console.log(val)}
          onRemove={(val, rem) => handleMultiAttribute(val, null, rem)}
          onSearch={val => console.log(val)}
          onSelect={(val, item) => handleMultiAttribute(val, item, null)}
          // options={props.attrVal}
          selectedValues={initialSelectedValues}
          // selectedValues={multiOptionVal[props.index] ? multiOptionVal[props.index].val : []}
          options={storeDatas}
          style={{
            chips: {
              background: '#E7E7E7',
              color: 'black',
              borderRadius: '4px',
              fontSize: '10px'
            },
            multiselectContainer: {
              color: '#182C52',
              width: '420px'
            },
            closeIcon: {
              color: 'black'
            },
            searchBox: {
              border: 'none',
              width: '420px',
              border: '1px solid #DDDDDD',
              'border-radius': '0px',
              background: 'white'
              // padding:' 0.5% 2%',
            }
          }}
        />
        {/* <AsyncPaginate
                className={classes.asyncinput}
                value={searchSelections}
                loadOptions={loadOptions}
                isMulti
                closeMenuOnSelect={false}
                onChange={async (e,rem) => {
                    const FinalDataset = storeDatas.filter(i =>
                        e.find(({ label }) => i.label === label)
                    );
    
 if(props.attrVal.length >0){
  FinalDataset.map((item)=>{
                   
    item.storeID.map(item=>{
       props.setStore(prevState=>[...prevState,item])}
      //  props.setSelectedAtrrSToreIDs(prevState=>[...prevState,item])}
       );

  
  } );
 }else{
  props.setStore([])
 }

        

                    setFinalCandidatStores(FinalDataset);
     
                    setSearchSelections();
                }}
            /> */}
      </Grid>
    </>
  );
};

const AttributeDropDown = props => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { showLabel = true } = props;

  const identifyTestReducer = useSelector(state => ({
    ...state.identifyTestReducer
  }));
  const {
    Attributes,
    selectedattroptions,
    selectedRegions
  } = identifyTestReducer;
  //console.log(storesselectedattroptions, Attributes, attributeoptionselected, selectedattribute, finalcandidatestore, selectedattroptions,"checkvalue");
  //ContactSupportOutlined.log
  //useState
  const [searchSelection, setSearchSelection] = useState([]);
  // const [storeData, setStoreData] = useState([]);
  // const [finalCandidateStores, setFinalCandidatStore] = useState([]);
  // const [attrVal, setAttrVal] = useState([]);
  // const [selectedAtrrSToreIDs, setSelectedAtrrSToreIDs] = useState([]);
  // const [multiOptionVal, setMultiOptionVal] = useState([]);
  const [clickedApply, setClickedApply] = useState(false);
  const [load, setLoad] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [multiOptionVals, setMultiOptionVals] = useState([]);

  // const [SelChossenAttr, setSelChossenAttr] = useState([]);
  // const [SelAttrMain, setSelAttrMain] = useState([]);

  // const submitSelectedAttributeStore = (val) => {
  //   console.log(multiOptionVal, "multiOptionVal")
  //   props.setStore(multiOptionVal)
  //   setClickedApply(prev => true)
  //   props.setClickApplyAttr()
  // }

  //destructuring props
  const {
    finalcandidatestore,
    selectedattribute,

    attributeoptionselected,
    storesselectedattroptions
  } = props;
  function getUniqueListBy(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()];
  }

  useEffect(() => {
    if (attributeoptionselected.length > 0) {
      setMultiOptionVals(attributeoptionselected);
    }
  }, []);

  useEffect(() => {
    let temp = [];
    temp = [...selectedattribute].map(ele => {
      return { label: ele, value: ele };
    });
    setSearchSelection(temp);
    getSelectedAttriVal(selectedattribute);
  }, [selectedattribute]);
  // useEffect(() => {
  //   let temp = [];
  //   temp = [...selectedattribute].map(ele => {
  //     return { 'label': ele, 'value': ele };
  //   })
  //   setSearchSelection(temp);
  // }, [selectedattribute])
  const submitSelectedAttributeStore = val => {
    // const {selectedattribute,attributeoptionselected,campaignID,setClickedApply} = val;
    dispatch(setLoading(true));

    const attributeoptionselecteds = getUniqueListBy(
      attributeoptionselected,
      'key'
    );
    console.log(attributeoptionselecteds, 'attributeoptionselected');

    let finalSelectedAttribute = [
      'OUTLET_STRATEGY',
      'MENS_TIER',
      'WOMENS_TIER',
      'AERIE_FORMAT',
      'AERIE_SET_UP',
      'BTS_PEAK',
      'FACTORY_DESIGNATION',
      'ASSORTMENT'
    ];
    if (selectedattribute.length > 0) {
      let selectAtt = selectedattribute.map(item => {
        // return item.replaceAll(/-|_| /g, "_").toUpperCase()
        return `${item}`.toUpperCase();
      });

      finalSelectedAttribute = finalSelectedAttribute.concat(selectAtt);
      finalSelectedAttribute = _.uniq(finalSelectedAttribute);
    }

    const payloadVal = {
      param: {
        selectedAttr: selectedattribute,
        SelChossenAttr: attributeoptionselected,
        campaignID: props.campaignID,
        attributeoptionselected: attributeoptionselecteds,
        GEOGRAPHY: selectedRegions,
        aboutTest: true
      },
      // selectedattribute: selectedattribute,
      attributeoptionselected: attributeoptionselected,
      // campaignID: props.campaignID,
      setClickedApply: setClickedApply,
      setClickApplyAttr: props.setClickApplyAttr
      // setStore:props.setStore,
      // setMultiOptionVal:setMultiOptionVal
    };
    try {
      if (attributeoptionselected.length > 0) {
        dispatch(getStoreFromSelectedAttrOption(payloadVal));
        // props.handleInclusionAttributes();
      } else {
        dispatch(setLoading(false));
        // Handle the case when attributeoptionselected is empty
        // console.log('Please select at least one attribute option.');
        // You can show a message to the user or perform any other action as needed
      }
      props.setDisabled && props.setDisabled(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // getAttributeData();
    dispatch(getAttribute([]));
  }, []);

  //functions

  const getAttributeData = () => {
    // dispatch({
    //   type:"LOADING",
    //   payload:true
    // })
    // setLoad(true)
    try {
      secureAxios
        .get('/designCampaign/storeDropdown', {
          params: []
        })
        .then(res => {
          console.log(res.data, 'AttributeData');

          const storeDatas = _.chain(res.data)
            .map(i => ({
              label: i.ATTRIBUTE,
              value: i.ATTRIBUTE
            }))
            .orderBy(['label'], ['asc'])
            .value();
          // setStoreData(storeDatas);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getSelectedAttriVal = val => {
    // setAttrVal([]);
    console.log(props, 'attribute props');
    let payloadVal = {
      selectedAttr: val,
      campaignID: props.campaignID,
      aboutTest: props.aboutTest
    };
    dispatch(getSelectedAttributeOptions(payloadVal));
    // try {
    //   secureAxios
    //     .post('/designCampaign/storeDropdownVal', {
    //       selectedAttr: val,
    //       campaignID: props.campaignID
    //     }).then((res) => {
    //       console.log(res.data, 'AttributeData');
    //       // setTimeout(()=>{
    //       //   setLoad(false);
    //       // },6000)
    //       setAttrVal(res.data.data);
    //     })
    // } catch (error) {
    //   console.log(error)
    // }
  };

  // const handleAtrributeRedux=(attributeVal)=>{

  //     let filteredDataAtrr = []
  //     if (FinalDataset.length > 0 && tellUsMoreSelectedSubattribute && tellUsMoreSelectedSubattribute.length > 0) {
  //       filteredDataAtrr = onHandleClearAttribute(tellUsMoreSelectedSubattribute, FinalDataset);
  //       dispatch(setTellUsMoreSelectedSubAttributes([...filteredDataAtrr]));

  //     }
  //     // console.log(FinalDataset, "Selected Attribute")
  //     if (FinalDataset.length <= 0) {
  //       // props.setStore([])
  //       // setMultiOptionVal([])
  //       dispatch(setTellUsMoreSelectedSubAttributes([]))
  //       // dispatch(setAttributeOptionsVal(data));

  //       dispatch(getStoreFromSelectedAttrOption([]))

  //       // dispatch(setSelectedAttribute([]));

  //       // props.getChoosenAttt([])
  //       // setSearchSelection();
  //     }

  //     let SelectedAttr = []
  //     SelectedAttr = FinalDataset.length ? FinalDataset.map(item => item.value) : [];

  //     // const SelectedAttr = FinalDataset.map(item => {

  //     //   return String(item.value).replace(/-|_| /g, '_')
  //     // });
  //     // console.log(SelectedAttr, 'console.logselected');
  //     if (!SelectedAttr.length) {
  //       // props.setStore([])
  //       // setMultiOptionVal([])
  //       dispatch(setTellUsMoreSelectedSubAttributes([]))

  //       dispatch(getStoreFromSelectedAttrOption([]))

  //       dispatch(setTellUsMoreSelectedAttributes([]));

  //       // props.getChoosenAttt([])
  //       setSearchSelection();
  //     }
  //     // setLoad(true)

  //     // getSelectedAttriVal(SelectedAttr);

  //     // setSelAttrMain(SelectedAttr)
  //     dispatch(setTellUsMoreSelectedAttributes(SelectedAttr));
  //     dispatch(setFinalCandidateStores(FinalDataset));
  //     // setFinalCandidatStore(FinalDataset);
  //     // props.getChoosenAttt(SelectedAttr)
  //     setSearchSelection();

  //     setShowFilter(false);

  // }

  const loadOptions = async (search, prevOptions) => {
    await sleep(1000);
    let filteredOptions;
    if (!search) {
      filteredOptions = Attributes;
    } else {
      const searchLower = search.toLowerCase();

      filteredOptions = Attributes.filter(({ label }) =>
        label.toLowerCase().includes(searchLower)
      );
    }
    const hasMore = filteredOptions.length > prevOptions.length + 10;
    const slicedOptions = filteredOptions.slice(
      prevOptions.length,
      prevOptions.length + 10
    );   
     const slicedOptionsnew = [...slicedOptions].filter(({ label }) => 
    !['Assortment', 'Peak', 'Factory Designation', 'TALP Set Up','Country'].includes(label)  //to remove assortment,talp setup,factory designation and peak from dropdown
    );
    return {
      options: slicedOptionsnew,
    
    };
  };
 

  
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: '5px',
      border: '2px solid #ccc',
      boxShadow: state.isFocused ? '0 0 0 2px #3699FF' : null
    }),
    option: (provided, state) => ({
      ...provided,
      zIndex: state.isFocused ? 9 : null,
      opacity: state.isFocused ? 1 : null,
      backgroundColor: state.isFocused ? '#ededed' : null,
      color: state.isFocused ? 'black' : null
    }),
    // menuList: (provided, state) => ({
    //   ...provided,
    //   // background: 'rgba(255,255,255, 1)',
    //   zIndex: 9,
    //   height: '200px',
    //   background: 'white',
    //   opacity: 1,
    //   color: 'black'
    // })
  };

  // const onHandleClearAttribute = (a, b) => {
  //   a.forEach(function (item, index, array) {
  //     // var ItemIndex = b.filter(b => b.key === item.key);
  //     var ItemIndex = b.findIndex(b => {
  //       return b.label === a[index].key

  //     });
  //     // console.log(ItemIndex,'fir');

  //     a.splice(ItemIndex, 1)
  //   })
  //   console.log(a, 'onHandleClearAttribute')
  //   return a;
  // }

  return (
    <>
      {load && <FullLoader />}
      <Grid container>
        <Grid item md={12}>
          {showLabel && (
            <>
              {props.included ? (
                <Typography className={classes.formLabel}>
                  Population Selection :
                </Typography>
              ) : (
                ''
              )}
              {!props.included ? (
                <Typography className={classes.formLabel}>
                  Select Attributes (To be excluded)
                </Typography>
              ) : (
                <Typography className={classes.formLabels}>
                  Select Attributes (To be included)
                </Typography>
              )}
            </>
          )}

          <AsyncPaginate
            className={classes.mainAttrDrp}
            onBlur={() => console.log('onBlur')}
            onMenuClose={() => getSelectedAttriVal(selectedattribute)}
            value={searchSelection}
            loadOptions={loadOptions}
            styles={customStyles}
            isMulti
            closeMenuOnSelect={false}
            onChange={async e => {
              const FinalDataset = Attributes.filter(i =>
                e.find(({ label }) => i.label === label)
              );

              let SelectedAttr = [];
              SelectedAttr = FinalDataset.length
                ? FinalDataset.map(item => item.value)
                : [];

              let filteredDataAtrr = [];
              filteredDataAtrr = onHandleClearAttribute(
                multiOptionVals,
                FinalDataset
              );
              setMultiOptionVals(filteredDataAtrr);

              if (SelectedAttr.length <= 0) {
                dispatch(getStoreFromSelectedAttrOption([]));
                dispatch(aboutTestAttrStoreIdsSel([]));
                props.setDisabled && props.setDisabled(false);
              }

              const val = {
                setShowFilter
              };
              props.handleAtrributeRedux(FinalDataset, val);
            }}
          />
        </Grid>
        <FormHelperText className={classes.infoArea}>
          {clickedApply ? '' : <InfoIcon />}
          {selectedattroptions && selectedattroptions.length <= 0
            ? `Please select attributes you wish to utilize for filtering the stores`
            : !clickedApply && !props.included
            ? 'Please apply selected attributes to excluded stores'
            : 'Please apply selected attributes to Included stores'}
        </FormHelperText>
        <Grid item xs={12}>
          <Button
            color="primary"
            disabled={
              selectedattroptions && selectedattroptions.length > 0
                ? false
                : true
            }
            onClick={() => {
              setLoad(true);
              props.setDisabled && props.setDisabled(true);
              setShowFilter(prev => true);
              setTimeout(() => {
                setLoad(false);
              }, 3000);
            }}
            style={{
              marginTop: '3%',
              padding: '0.2% 1%',
              color: 'white',
              background: '#182C52',
              fontSize: '14px'
            }}>
            Show attribute filters
          </Button>
        </Grid>
      </Grid>
      {/* <div style={{display:'flex',flexDirection:'row'}}> */}
      {selectedattroptions &&
      selectedattroptions.length > 0 &&
      (showFilter || attributeoptionselected.length > 0) ? (
        <Grid
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'baseline',
            marginTop: '3%',
            background: '#F1F1F1',
            padding: '3% 1%'
          }}
          container>
          {props.finalcandidatestore.map((item, index) => {
            return (
              <DropdownList
                selected={item}
                index={index}
                multiOptionVal={storesselectedattroptions}
                SelChossenAttr={attributeoptionselected}
                multiOptionVals={multiOptionVals}
                setMultiOptionVals={setMultiOptionVals}
                attrVal={selectedattroptions ? selectedattroptions : []}
                attributeoptionselected={attributeoptionselected}
                handleSubAttributeRedux={props.handleSubAttributeRedux}
              />
            );
          })}

          <Grid
            item
            md={12}
            style={{ display: 'flex', justifyContent: 'end', marginTop: '2%' }}>
            {attributeoptionselected &&
            props.attributeoptionselected.length > 0 &&
            props.showApplyBtn &&
            props.aboutTest ? (
              <Button
                color="primary"
                onClick={() => submitSelectedAttributeStore()}
                style={{
                  padding: '0.2% 1%',
                  color: 'white',
                  background: '#182C52',
                  fontSize: '14px'
                }}>
                Apply Selected Filter
              </Button>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      ) : (
        ''
      )}
    </>
  );
};

export default AttributeDropDown;
