import React from 'react';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import { Tooltip, Typography } from '@material-ui/core';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import MessageDialog from '../MessageDialog/MessageDialog';

const ExportCsv = props => {
  const [open, setOpen] = React.useState(false);
  const handleExport = async (csvData, fileName) => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.csv';
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'csv', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    await FileSaver.saveAs(data, fileName + fileExtension);
    await setOpen(true);
  };

  return (
    <>
      <MessageDialog
        open={open}
        close={() => setOpen(false)}
        type={'success'}
        message={'File downloaded successfully'}
      />
      <Tooltip title={props.title ? props.title : 'Download'}>
        <SystemUpdateAltIcon
          cursor="pointer"
          style={props.style}
          onClick={e => {
            handleExport(props.tableData, props.fileName);
          }}
        />
      </Tooltip>
    </>
  );
};

export default ExportCsv;
