import { UPDATETEMPLATE, SELECTEDTEMPLATE, SETTEMPLATELIST, CLEAR_CAMPAIGN_VALUES } from '../types';

export const updateTemplate = val => {
  return {
    type: UPDATETEMPLATE,
    payload: val
  };
};

export const setSelectedTemplate = val => {
  return {
    type: SELECTEDTEMPLATE,
    payload: val
  };
};

export const setTemplateList = val => {
  return {
    type: SETTEMPLATELIST,
    payload: val
  };
};
export const clearCampaignValues = () => {
  return {
    type: CLEAR_CAMPAIGN_VALUES
  };
};
