import {
  UPDATETEMPLATE,
  SELECTEDTEMPLATE,
  SETTEMPLATELIST,
  CLEAR_CAMPAIGN_VALUES
} from '../types';

const initialState = {
  selectedTemplate: null,
  savedTemplate: null,
  templateList: []
};

const campaignTemplateReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATETEMPLATE:
      return {
        ...state,
        savedTemplate: action.payload
      };
    case SELECTEDTEMPLATE:
      return {
        ...state,
        selectedTemplate: action.payload
      };
    case SETTEMPLATELIST:
      return {
        ...state,
        templateList: action.payload
      };
    case CLEAR_CAMPAIGN_VALUES:
      return initialState;
    default:
      return state;
  }
};

export default campaignTemplateReducer;
