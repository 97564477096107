import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import store from '../src/redux/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import App from './App';
import AuthProvider from '../src/azure-services/Authprovider';
// import 'react-tooltip/dist/react-tooltip.css'
import 'leaflet/dist/leaflet.css';

ReactDOM.render(
  <Provider store={store}>
    <AuthProvider>
      <App />
    </AuthProvider>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
