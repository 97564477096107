import {
  LOADING,
  DATE,
  TABNAVIGATION,
  TABNESTED,
  PREVIOUS,
  // MOREABOUTTEST,
  // SAMPLESIZERECOMMENDATION,
  // IDENTIFYTEST,
  // IDENTIFYCONTROL,
  CAMPAIGNID,
  // SAMPLESIZESIMULATEDATA,
  // MEASURETESTID,
  // MEASURECAMPAIGNLEVEL,
  // TESTTABLECOUNT,
  SELECTEDMETRICS,
  LAUNCHED,
  RESETSTATE,
  // MEASUREEXCLUDEOUTLIER,
  CLEAR_APPLICATION_VALUES,
  SAVECAMPDATA,
  DESIGN_CAMP_TAB_NAV
} from '../types';

const initialState = {
  loading: false,
  date: new Date(),
  tabNavigation: 0,
  tabNested: 0,
  previous: false,
  launched: false,
  designCampaign: {
    // moreAboutTest: {},
    // sampleSizeRecommendation: {},
    // identifyTest: {},
    // identifyControl: {},
    // sampleSizeSemulationData: {},
    // testTableCount: [],
    slesectedMetrics: {}
  },
  campaignID: '',
  saveCampData: {},
  designCampTabNav: {
    currentTab: 0,
    fromTab : 0,
    state: ''
  }
  // measureTestId: 1,
  // measureCampaignLevel: '',
  // measureExcludeOutlier: false
};
const application = (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.payload };
    case DATE:
      return { ...state, date: action.payload };
    case TABNAVIGATION:
      return { ...state, tabNavigation: action.payload };
    case TABNESTED:
      return { ...state, tabNested: action.payload };
    case PREVIOUS:
      return { ...state, previous: action.payload };
    // case MOREABOUTTEST:
    //   return {
    //     ...state,
    //     designCampaign: {
    //       ...state.designCampaign,
    //       moreAboutTest: action.payload
    //     }
    //   };
    // case SAMPLESIZERECOMMENDATION:
    //   return {
    //     ...state,
    //     designCampaign: {
    //       ...state.designCampaign,
    //       sampleSizeRecommendation: action.payload
    //     }
    //   };
    // case IDENTIFYTEST:
    //   return {
    //     ...state,
    //     designCampaign: {
    //       ...state.designCampaign,
    //       identifyTest: action.payload
    //     }
    //   };
    // case IDENTIFYCONTROL:
    //   return {
    //     ...state,
    //     designCampaign: {
    //       ...state.designCampaign,
    //       identifyControl: action.payload
    //     }
    //   };
    case CAMPAIGNID:
      return { ...state, campaignID: action.payload };
    // case SAMPLESIZESIMULATEDATA:
    //   return {
    //     ...state,
    //     designCampaign: {
    //       ...state.designCampaign,
    //       sampleSizeSemulationData: action.payload
    //     }
    //   };
    // case MEASURETESTID:
    //   return { ...state, measureTestId: action.payload };
    // case MEASURECAMPAIGNLEVEL:
    //   return { ...state, measureCampaignLevel: action.payload };
    // case MEASUREEXCLUDEOUTLIER:
    //   return { ...state, measureExcludeOutlier: action.payload };
    // case TESTTABLECOUNT:
    //   return {
    //     ...state,
    //     designCampaign: {
    //       ...state.designCampaign,
    //       testTableCount: action.payload
    //     }
    //   };
    case SELECTEDMETRICS:
      return {
        ...state,
        designCampaign: {
          ...state.designCampaign,
          slesectedMetrics: action.payload
        }
      };
    case LAUNCHED:
      return { ...state, launched: action.payload };
    // case LAUNCHED:
    //   return {
    //     ...state,
    //     designCampaign: {
    //       ...state.designCampaign,
    //       launched: action.payload
    //     }
    //   };
    case RESETSTATE:
      if (action.payload) {
        return initialState;
      }
    case CLEAR_APPLICATION_VALUES: 
      return initialState;
    case SAVECAMPDATA:
      return { ...state, saveCampData: action.payload };
    case DESIGN_CAMP_TAB_NAV:
      return { ...state, designCampTabNav: action.payload}
    default:
      return state;
  }
};

export default application;
