export const drilldownFilterData = {
    "primary": {
        "STORE_ID": {
            "dbColumnName": "STORE_ID",
            "label": "Store ID",
            "requiredFilter": true,
            "type": "multiselect",
            "active": true,
            "level": "primary",
            "displayOrder": 0,
            "impacts": ["OUTLET_STRATEGY", "MENS_TIER", "WOMENS_TIER", "AERIE_FORMAT", "AERIE_SET_UP", "BTS_PEAK", "REGION", "FACTORY_DESIGNATION"],
            "defaultValue": [],
            "value": [], "selectedValue": []
        },
        "KPI_NAME": {
            "dbColumnName": "KPI_NAME",
            "label": "KPIs",
            "requiredFilter": true,
            "type": "multiselect",
            "active": true,
            "level": "primary",
            "displayOrder": 1,
            "impacts": [],
            "defaultValue": [],
            "value": [], "selectedValue": []
        },
        "PRODUCT_GRP": {
            "dbColumnName": "PRODUCT_GRP",
            "label": "Product Group",
            "requiredFilter": true,
            "type": "multiselect",
            "active": true,
            "level": "primary",
            "displayOrder": 2,
            "impacts": [],
            "defaultValue": [],
            "value": [], "selectedValue": []
        },
        "BRAND": {
            "dbColumnName": "BRAND",
            "label": "Brand",
            "requiredFilter": true,
            "type": "multiselect",
            "active": true,
            "level": "primary",
            "displayOrder": 3,
            "impacts": ["DIVISION", "DEPT", "CLASS"],
            "defaultValue": [],
            "value": [], "selectedValue": []
        },
        "DIVISION": {
            "dbColumnName": "DIVISION",
            "label": "Division",
            "requiredFilter": true,
            "type": "multiselect",
            "active": true,
            "level": "primary",
            "displayOrder": 4,
            "impacts": ["DEPT", "CLASS"],
            "defaultValue": [],
            "value": [], "selectedValue": []
        },
        "DEPT": {
            "dbColumnName": "DEPT",
            "label": "Department",
            "requiredFilter": true,
            "type": "multiselect",
            "active": true,
            "level": "primary",
            "displayOrder": 5,
            "impacts": ["CLASS"],
            "defaultValue": [],
            "value": [], "selectedValue": []
        },
        "CLASS": {
            "dbColumnName": "CLASS",
            "label": "Class",
            "requiredFilter": true,
            "type": "multiselect",
            "active": true,
            "level": "primary",
            "displayOrder": 6,
            "impacts": [],
            "defaultValue": [],
            "value": [], "selectedValue": []
        },
    },
    "secondary":
    {
        "OUTLET_STRATEGY": {
            "dbColumnName": "OUTLET_STRATEGY",
            "label": "Outlet Strategy",
            "requiredFilter": true,
            "type": "multiselect",
            "active": true,
            "level": "secondary",
            "displayOrder": 7,
            "impacts": ["STORE_ID"],
            "defaultValue": [],
            "value": [], "selectedValue": []
        },
        "MENS_TIER": {
            "dbColumnName": "MENS_TIER",
            "label": "Mens Tier",
            "requiredFilter": true,
            "type": "multiselect",
            "active": true,
            "level": "secondary",
            "displayOrder": 8,
            "impacts": ["STORE_ID"],
            "defaultValue": [],
            "value": [], "selectedValue": []
        },
        "WOMENS_TIER": {
            "dbColumnName": "WOMENS_TIER",
            "label": "Womens Tier",
            "requiredFilter": true,
            "type": "multiselect",
            "active": true,
            "level": "secondary",
            "displayOrder": 9,
            "impacts": ["STORE_ID"],
            "defaultValue": [],
            "value": [], "selectedValue": []
        },
        "AERIE_FORMAT": {
            "dbColumnName": "AERIE_FORMAT",
            "label": "Aerie Format",
            "requiredFilter": true,
            "type": "multiselect",
            "active": true,
            "level": "secondary",
            "displayOrder": 10,
            "impacts": ["STORE_ID"],
            "defaultValue": [],
            "value": [], "selectedValue": []
        },
        "AERIE_SET_UP": {
            "dbColumnName": "AERIE_SET_UP",
            "label": "Aerie Set Up",
            "requiredFilter": true,
            "type": "multiselect",
            "active": true,
            "level": "secondary",
            "displayOrder": 11,
            "impacts": ["STORE_ID"],
            "defaultValue": [],
            "value": [], "selectedValue": []
        },
        "BTS_PEAK": {
            "dbColumnName": "BTS_PEAK",
            "label": "BTS Peak",
            "requiredFilter": true,
            "type": "multiselect",
            "active": true,
            "level": "secondary",
            "displayOrder": 12,
            "impacts": ["STORE_ID"],
            "defaultValue": [],
            "value": [], "selectedValue": []
        },
        "REGION": {
            "dbColumnName": "REGION",
            "label": "Region",
            "requiredFilter": true,
            "type": "multiselect",
            "active": true,
            "level": "secondary",
            "displayOrder": 13,
            "impacts": ["STORE_ID"],
            "defaultValue": [],
            "value": [], "selectedValue": []
        },
        "FACTORY_DESIGNATION": {
            "dbColumnName": "FACTORY_DESIGNATION",
            "label": "Factory Designation",
            "requiredFilter": true,
            "type": "multiselect",
            "active": true,
            "level": "secondary",
            "displayOrder": 14,
            "impacts": ["STORE_ID"],
            "defaultValue": [],
            "value": [], "selectedValue": []
        },
    }
}