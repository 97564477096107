import axios from 'axios';
import store from '../redux/store';
import { setLoading } from '../redux/actions/application.action';
// import urlVar from './apiconfig';
import { config } from '../config/index';
// import {envVars} from '../../kubernetes/envconfigs/talp-store-frontend/sit.yaml'
// require("dotenv").config();

// console.log(process.env.REACT_APP_API_HOST, process, 'yaml var');
let backendURL;

if (process.env.NODE_ENV === 'production') {
  // backendURL = `talpa-store-backend-as.azurewebsites.net`;
  backendURL = `https://talpa-store-backend-as.azurewebsites.net`;
} else if (process.env.NODE_ENV === 'development') {
  backendURL = `http://localhost:8080`;
  // backendURL = `https://talpa-store-backend-as.azurewebsites.net`;
}

let secureAxios = axios.create({
  baseURL: backendURL
});

secureAxios.interceptors.request.use(
  function(config) {
    // const user = JSON.parse(localStorage.getItem('accessToken'));

    // if (user && user.hasOwnProperty('role')) {
    //   config.headers.user_role = user?.role || 'Admin';
    // }

    if (
      config.hasOwnProperty('showLoading') &&
      config.showLoading &&
      !store.getState().application.loading
    ) {
      axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';
      axios.defaults.headers['Access-Control-Allow-Origin'] = '*';
      store.dispatch(setLoading(true));
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

secureAxios.interceptors.response.use(
  function(response) {
    if (
      response.config.hasOwnProperty('showLoading') &&
      response.config.showLoading &&
      store.getState().application.loading
    ) {
      store.dispatch(setLoading(false));
    }
    return response;
  },
  function(error) {
    return Promise.reject(error);
  }
);

export default secureAxios;
