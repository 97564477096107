import { KPIS } from '../types';



const initialState = {
  items:[],
  loading:true,
};
const kpisReducer = (state = initialState, action) => {
  switch (action.type) {
    case KPIS:
      return {
        ...state,
        items: action.payload.responseData,
        loading:false,
      };
    default:
      return state;
  }
};

export default kpisReducer;
