import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

const RouteWithLayout = props => {
  const { layout: Layout, component: Component, ...rest } = props;

  // if( localStorage?.getItem('loggedIn')){
  //   return <SignInSide />
  // }

  return (
    <Route
      {...rest}
      render={matchProps =>
        localStorage.getItem('accessToken')?.length ? (
          <Suspense fallback={<div>Loading</div>}>
            <Layout>
              <Component {...matchProps} />
            </Layout>
          </Suspense>
        ) : (
          <Redirect from="/" to="/login" />
        )
      }
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
};

export default RouteWithLayout;
