import React from "react";
import { Button, Card, Grid, Typography } from "@material-ui/core";
import NORESULT from '../../assets/no-result.svg'
import { useHistory } from "react-router-dom/cjs/react-router-dom";
const NoWrapper = (props) => {
    const history = useHistory();
    return (
        <Card style={{ width: '100%', padding: '20px 0px 20px', }}>
            <Grid container style={{ textAlign: 'center' }}>
                <Grid item xs={12}>
                    <div style={{ textAlign: 'center' }}>
                        {(props.heading) && (<Typography variant="h3">{props.heading} </Typography>)}
                        
                        {(props.subHeading) && (<Typography variant="h6">{props.subHeading}</Typography>)}
                    </div>
                </Grid>
                <Grid item xs={12} style={{ margin: '20px 0'}}>
                    <img src={NORESULT} width={300} height={300} />
                </Grid>
                {(props.showBtn) && (<Grid item xs={12}>
                    <Button variant="contained" onClick={() => history.push(`${(props.btnPath) ?? '/home'}`)}>{`${(props.btnText) ?? 'Go Back'}`}</Button>
                </Grid>)}
            </Grid>
        </Card>
    )
}

export default NoWrapper;