import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Radio,
  RadioGroup,
  Typography,
  FormControl,
  FormControlLabel
} from '@material-ui/core';
import { ErrorMessage } from 'formik';

const useStyles = makeStyles(theme => ({
  formLabel: {
    margin: '5px 0 10px',
    color: '#031330',
    fontWeight: 500
  },
  errorText: {
    color: 'red',
    fontSize: 12
  },
  radio: {
    '&$checked': {
      color: '#031330'
    }
  },
}));

const RadioGroupElement = props => {
  const classes = useStyles();
  const { name, label, handleMethod, options, defaultvalues } = props;
  return (
    <>
      <FormControl component="fieldset">
        <Typography className={classes.formLabel}>{label}</Typography>
        <RadioGroup
          row
          name={name}
          onChange={handleMethod}
          value={defaultvalues}>
          {options.map(option => (
            <FormControlLabel
              key={option.label}
              value={option.val}
              control={<Radio />}
              label={option.label}
              disabled={option.disabled}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <Typography className={classes.errorText}>
        {props.sourceComp !== 'RandomSample' ? <ErrorMessage name={name} /> : null}
      </Typography>
    </>
  );
};

export default RadioGroupElement;
