import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { saveAs } from 'file-saver';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    height: '100%'
  },
  downloadBtn: {
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    fontWeight: 500,
    marginTop: 32,
    '& svg': {
      fontSize: 25,
      marginLeft: '10px',
      verticalAlign: 'bottom'
    }
  }
}));

function DownloadCsv(props) {
  const classes = useStyles();
  const downloadFile = () => {
    saveAs(props.path, `${props.fileName}.${props.format}`);
  };

  return (
    <Typography className={classes.downloadBtn} onClick={downloadFile}>
      {props.label}
      <CloudDownloadOutlinedIcon />
    </Typography>
  );
}

export default DownloadCsv;
