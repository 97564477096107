import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Button
} from '@material-ui/core';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DateRangeIcon from '@material-ui/icons/DateRange';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import { useHistory } from 'react-router-dom';
import useStyles from './Style';
import Moment from 'moment';
import FaceIcon from '@material-ui/icons/Face';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useEffect } from 'react';
import secureAxios from 'api-config/api';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
// import CsvDownloader from 'react-csv-downloader';
import { CSVLink } from 'react-csv';
import { setLoading } from 'redux/actions/application.action';

const CampaignDetailBlock = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [Sdata, setSdata] = useState(null);
  const [identifyCSV, setIdentifyCSV] = useState([]);
  const [controlCSV, setControlCSV] = useState([]);
  const [matchStatsCSV, setmatchStatCSV] = useState([]);

  const selectedAttribute = useSelector(
    state => state.identifyTestReducer.selectedattribute
  );
  let finalSelectedAttribute = [
    'OUTLET_STRATEGY',
    'MENS_TIER',
    'WOMENS_TIER',
    'AERIE_FORMAT',
    'AERIE_SET_UP',
    'BTS_PEAK',
    'FACTORY_DESIGNATION'
  ];
  // if(selectedAttribute.length >0){
  //  let selectAtt=selectedAttribute.map((item)=>{
  //    return item.replaceAll(" ","_").toUpperCase()
  //  })

  //   finalSelectedAttribute= finalSelectedAttribute.concat(selectAtt)
  //   finalSelectedAttribute= _.uniq(finalSelectedAttribute)
  // }
  console.log('final', finalSelectedAttribute);
  const { data } = props;

  const downlaodIdentifyCSV = () => {
    dispatch(setLoading(true));
    secureAxios
      .get('/measureCampaign/downloadIdentifyCSV', {
        params: {
          CAMPAIGN_ID: data?.campaignId,
          SELECTED_ATTRIBUTE: finalSelectedAttribute
        }
      })
      .then(response => {
        // console.log("res",response)
        if (response.data) {
          setIdentifyCSV(response.data);
        }
      });
  };
  const downlaodControlCSV = () => {
    secureAxios
      .get('/measureCampaign/downloadControlCSV', {
        params: {
          CAMPAIGN_ID: data?.campaignId,
          SELECTED_ATTRIBUTE: finalSelectedAttribute
        }
      })
      .then(response => {
        console.log('res', response);
        if (response.data) {
          setControlCSV(response.data);
          dispatch(setLoading(false));
        }
      });
  };
  const downlaodMatchStatsCSV = () => {
    secureAxios
      .get('/measureCampaign/downlaodMatchStatsCSV', {
        params: {
          CAMPAIGN_ID: data?.campaignId,
          SELECTED_ATTRIBUTE: finalSelectedAttribute
        }
      })
      .then(response => {
        console.log('res', response);
        if (response.data) {
          setmatchStatCSV(response.data);
          dispatch(setLoading(false));
        }
      });
  };

  useEffect(() => {
    const getUsers = async () => {
      const data = await downlaodIdentifyCSV();
      const data2 = await downlaodControlCSV();
      const data3 = await downlaodMatchStatsCSV();
    };

    getUsers(); // run it, run it

    return () => {
      // this now gets called when the component unmounts
    };
  }, []);
  // console.log(data);
  //redirect to measure deep dive page
  const redirectDeepdive = sdata => {
    setSdata(sdata);
    history.push({
      pathname: '/measure-campaign-new/measure-deep-dive',
      state: {
        detail: sdata?.summaryData,
        campaignId: sdata?.campaignId,
        name: sdata?.name,
        region: sdata?.region,
        startDate: sdata?.startDate,
        endDate: sdata?.endDate
      }
    });
  };

  const csvData = [
    ['firstname', 'lastname', 'email'],
    ['Ahmed', 'Tomi', 'ah@smthing.co.com'],
    ['Raed', 'Labes', 'rl@smthing.co.com'],
    ['Yezzi', 'Min l3b', 'ymin@cocococo.com']
  ];

  return (
    <Box component="div" className={classes.detailBlock}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card elevation={0}>
            <CardContent>
              <Box className={classes.info}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography className={classes.description}>
                      {data?.desc === null ? 'NA' : data?.desc}
                    </Typography>
                  </Grid>
                  <Grid item xs={10} sm={10} md={10}>
                    <Grid container spacing={3}>
                      <Grid item xs={6} sm={6} md={3}>
                        <Typography className={classes.heading}>
                          {'Region:'}
                        </Typography>
                        <Typography component="h5" className={classes.value}>
                          {' '}
                          {data.summaryData.region &&
                          data.summaryData.region != null
                            ? data.summaryData.region
                            : 'NA'}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={3}>
                        <Typography className={classes.heading}>
                          {' '}
                          {'Status:'}
                        </Typography>
                        {data?.summaryData?.status ? (
                          <Button
                            className="p0"
                            style={{ textTransform: 'capitalize' }}
                            startIcon={
                              data?.summaryData?.status === 'COMPLETED' ? (
                                <CheckCircleOutlineIcon
                                  style={{ color: 'rgb(36, 161, 72)' }}
                                />
                              ) : (
                                <WatchLaterIcon />
                              )
                            }>
                            {data?.summaryData?.status}
                          </Button>
                        ) : (
                          <Typography component="h5" className={classes.value}>
                            {'NA'}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={3}>
                        <Typography className={classes.heading}>
                          {'Created on:'}
                        </Typography>
                        <Typography className={classes.durationArea}>
                          <DateRangeIcon fontSize="small" />
                          {data?.summaryData?.createDate === null || !data
                            ? 'NA'
                            : Moment(
                                data?.summaryData?.createDate?.value
                              ).format('MMM D, YYYY')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={3}>
                        <Typography className={classes.heading}>
                          {'Created by:'}
                        </Typography>
                        {/* <Typography component="h5" className={classes.value}>
                      {data?.summaryData?.campaignOwner === null
                        ? 'NA'
                        : data?.summaryData?.campaignOwner}
                    </Typography> */}
                        {data?.summaryData.campaignOwner &&
                        data?.summaryData.campaignOwner != null ? (
                          <Button
                            className="p0"
                            startIcon={
                              <FaceIcon style={{ color: '#182C52' }} />
                            }>
                            {data?.summaryData?.campaignOwner}
                          </Button>
                        ) : (
                          <Typography component="h5" className={classes.value}>
                            {'NA'}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={3}>
                        <Typography component="h5" className={classes.heading}>
                          {'Test Attribute CSV'}
                        </Typography>
                        <CSVLink
                          data={identifyCSV}
                          filename={'test_attribute.csv'}
                          //  onClick={()=>{
                          //   identifyCSV
                          //  }}
                        >
                          <GetAppIcon
                            style={{ color: 'grey' }}
                            disable={
                              identifyCSV && identifyCSV.length > 0
                                ? false
                                : true
                            }
                          />
                        </CSVLink>
                        {/* <CsvDownloader datas={identifyCSV} filename="test_attribute"><GetAppIcon /></CsvDownloader> */}
                      </Grid>
                      <Grid item xs={12} sm={12} md={3}>
                        <Typography component="h5" className={classes.heading}>
                          {'Control Attribute CSV'}
                        </Typography>
                        <CSVLink
                          data={controlCSV}
                          filename={'control_attribute.csv'}>
                          <GetAppIcon
                            style={{ color: 'grey' }}
                            disable={
                              controlCSV && controlCSV.length > 0 ? false : true
                            }
                          />
                        </CSVLink>
                        {/* <CsvDownloader datas={controlCSV} filename="control_attribute" ><GetAppIcon /></CsvDownloader> */}
                      </Grid>
                      <Grid item xs={12} sm={12} md={3}>
                        <Typography component="h5" className={classes.heading}>
                          {'Match Statistics CSV'}
                        </Typography>
                        <CSVLink
                          data={matchStatsCSV}
                          filename={'match_satistics.csv'}>
                          <GetAppIcon
                            style={{ color: 'grey' }}
                            disable={
                              matchStatsCSV && matchStatsCSV.length > 0
                                ? false
                                : true
                            }
                          />
                        </CSVLink>
                        {/* <CsvDownloader datas={controlCSV} filename="control_attribute" ><GetAppIcon /></CsvDownloader> */}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2} sm={2} md={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      {data?.summaryData?.lift !== null ? (
                        <Box
                          component="div"
                          className={
                            data?.summaryData?.lift < 0
                              ? classes.redLiftBox
                              : classes.greenLiftBox
                          }>
                          <Typography className={classes.heading}>
                            {data?.summaryData?.primaryKpi
                              ? data?.summaryData?.primaryKpi
                              : 'NA'}
                          </Typography>
                          <Typography>
                            {data?.summaryData?.lift < 0 ? (
                              <TrendingDownIcon />
                            ) : (
                              <TrendingUpIcon />
                            )}
                            {data?.summaryData?.lift}%
                          </Typography>
                          <Typography>{'LIFT'}</Typography>
                        </Box>
                      ) : (
                        <Box
                          component="div"
                          className={
                            data?.lift
                              ? classes.redLiftBox
                              : classes.greenLiftBox
                          }>
                          <Typography className={classes.heading}>
                            NA
                          </Typography>
                          <Typography>
                            <TrendingUpIcon />
                          </Typography>
                          <Typography>{'LIFT'}</Typography>
                        </Box>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Button
                        className={classes.deepdiveBtn}
                        variant="outlined"
                        onClick={() => {
                          redirectDeepdive(data);
                        }}>
                        Deep Dive
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CampaignDetailBlock;
