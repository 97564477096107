import React from 'react';

import {Button, Dialog, DialogActions, DialogTitle} from "@material-ui/core";
import { useState } from 'react';
const ConfirmComponent = ({
    title,
    onConfirm,
    onCancel,
    confirmText,
}) => {
    return (<Dialog
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle style={{minWidth: '300px'}}>
          {title}
        </DialogTitle>
        <DialogActions>
          <Button onClick={onCancel}>Cancel</Button>
          <Button onClick={onConfirm} autoFocus style={{color: '#FF0000'}}>
            {confirmText}
          </Button>
        </DialogActions>
      </Dialog>)
}
const ConfirmDelete = ({
    onDelete,
    children
}) => {
    const [open, setOpen] = useState(false);
    return (<>
        {open && <ConfirmComponent title={'Are you sure to discard?'} onCancel={()=>setOpen(false)} onConfirm={()=>{
            onDelete();
            setOpen(false);
        }} confirmText={'Discard'}  />}
        <span onClick={()=>setOpen(true)}>{children}</span>
    </>)
}
export default ConfirmDelete;