import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  root: {
    paddingTop: 40,
    position: "relative",
  },
  cardHeading: {
    fontWeight: 400,
    fontSize: 16,
    letterSpacing: "-0.02em",
    color: "#404041",
    paddingBottom: 20,
  },
  sectionHeading: {
    fontWeight: 400,
    fontSize: 14,
    color: "#404041",
    padding: "20px 0",
  },
  accordGreen: {
    background: "#EAF6ED",
    borderRadius: 2,
    borderLeft: "3px solid #24A148",
    marginBottom: 15,
    "& p": {
      fontSize: 12,
      color: "#404041",
      "& svg": {
        color: "#24A148",
        verticalAlign: "middle",
        marginRight: 5,
      },
    },
  },
  accordRed: {
    background: "#FBE8E9",
    borderRadius: 2,
    borderLeft: "3px solid #E01F29",
    marginBottom: 15,
    "& p": {
      fontSize: 12,
      color: "#404041",
      "& svg": {
        color: "#E01F29",
        verticalAlign: "middle",
        marginRight: 5,
      },
    },
  },
  dualAccord: {
    color: "#E01F29 !important",
  },
  rightText: {
    float: "right",
    color: "#333333",
    cursor: "pointer",
    "& svg": {
      verticalAlign: "bottom",
      color: "#333333",
    },
  },
  backBtn: {
    float: "right",
    cursor: "pointer",
    position: "absolute",
    top: "-40px",
    right: 0,
    "& svg": {
      verticalAlign: "middle",
    },
    "& span": {
      verticalAlign: "middle",
    },
  },
  pageHeading: {
    fontSize: 20,
    color: "#333333",
  },
  breadcrumb: {
    padding: "10px 5px",
    color: "#333333",
    "& svg": {
      verticalAlign: "middle",
      fontSize: 16,
      color: "#FE5000",
    },
  },
  description: {
    background: "#FCFCFC",
    border: "1px solid #E6E7E8",
    padding: 10,
    fontSize: 12,
  },
  heading: {
    fontSize: 14,
    color: "#929497",
    marginBottom: 5,
  },
  value: {
    fontSize: 14,
    color: "#333333",
    fontWeight: 500,
  },
  ongoingBtn: {
    background: "#58DA7E",
    color: "#ffffff",
    borderRadius: 0,
    textTransform: "capitalize",
    padding: "2px 8px",
    cursor: "auto",
    "& svg": {
      verticalAlign: "middle",
      fontSize: 18,
      marginRight: 5,
      color: "#ffffff",
      borderRadius: 2,
      padding: 2,
    },
    "&:hover": {
      backgroundColor: "#58DA7E",
    },
  },
  completeBtn: {
    background: "#679BFE",
    color: "#ffffff",
    borderRadius: 0,
    textTransform: "capitalize",
    padding: "2px 8px",
    cursor: "auto",
    "& svg": {
      verticalAlign: "middle",
      fontSize: 18,
      marginRight: 5,
      color: "#ffffff",
      borderRadius: 2,
      padding: 2,
    },
    "&:hover": {
      backgroundColor: "#679BFE",
    },
  },
  groupBtn: {
    background: "#FBA504",
    color: "#ffffff",
    borderRadius: 0,
    textTransform: "capitalize",
    padding: "2px 8px",
    textAlign: "left",
    "&:hover": {
      backgroundColor: "#FBA504",
    },
  },
  campIcon: {
    padding: "3px 5px",
    background: "#E5EEFF",
    borderRadius: 2,
    fontWeight: 500,
    fontSize: 12,
    color: "#013598",
    marginRight: 10,
    "& svg": {
      verticalAlign: "middle",
      fontSize: 12,
      marginRight: 3,
    },
  },
  durationArea: {
    color: "#FE5000",
    fontWeight: 400,
    fontSize: 14,
    "& svg": {
      fontSize: 18,
      marginRight: 5,
      verticalAlign: "text-top",
    },
  },
  greenLiftBox: {
    width: 135,
    height: 204,
    background: "#EAF6ED",
    textAlign: "center",
    padding: "40px 10px",
    float: "right",
    "& p:first-child": {
      fontSize: 18,
      letterSpacing: "-0.02em",
    },
    "& p:nth-child(2)": {
      color: "#24A148",
      margin: "15px 0",
      fontSize: 30,
      "& svg": {
        marginRight: 5,
        fontSize: 32,
        verticalAlign: "middle",
      },
    },
    "& p:last-child": {
      color: "#24A148",
      fontSize: 18,
    },
  },
  redLiftBox: {
    width: 135,
    height: 204,
    background: "#FBE8E9",
    textAlign: "center",
    padding: "40px 10px",
    float: "right",
    "& p:first-child": {
      fontSize: 18,
      letterSpacing: "-0.02em",
    },
    "& p:nth-child(2)": {
      color: "#E01F29",
      margin: "15px 0",
      fontSize: 30,
      "& svg": {
        marginRight: 5,
        fontSize: 32,
        verticalAlign: "middle",
      },
    },
    "& p:last-child": {
      color: "#E01F29",
      fontSize: 18,
    },
  },
  detailBlock: {
    width: "95%",
    margin: "0 auto",
  },
  deepdiveBtn: {
    marginTop: 10,
    float: "right",
    borderRadius: 0,
    background: "#182C52",
    border: 0,
    color: "#ffffff",
    textTransform: "capitalize",
    "&:hover": {
      background: "#031330",
    },
  },
  merchArea: {
    cursor: "pointer",
    padding: "3px 5px",
    background: "#E5EEFF",
    fontWeight: 400,
    color: "#333333",
    "& svg": {
      verticalAlign: "middle",
      fontSize: 16,
    },
  },
  merchDialog: {
    "& svg": {
      margin: "0 20px",
    },
    "& h5": {
      marginBottom: 30,
      padding: 10,
    },
  },
  accordBlock: {
    background: "#ffffff",
    "& h4": { color: "#333333", marginBottom: 10 },
    "& h5": { color: "#333333" },
  },
  sideBlock: {
    "& h4": {
      color: "#333333",
      marginBottom: 10,
      position: "relative",
      textTransform: "capitalize",
      "& svg": {
        position: "absolute",
        right: 0,
        color: "#FE5000",
        cursor: "pointer",
      },
      "& span": {
        "& svg": {
          position: "relative",
          verticalAlign: "sub",
          margin: "0 10px",
          color: "#013598",
        },
      },
    },
    "& h5": { color: "#24A148", margin: "15px 0" },
    "& h6": {
      color: "#013598",
    },
  },
  info: {
    padding: "40px 0 !important",
    textAlign: "center",
    "& h3": {
      lineHeight: 10,
    },
  },
  cardBlockSvgRed: {
    "& svg": {
      color: "#ffffff",
      borderRadius: 2,
      padding: 2,
      fontSize: 30,
      background: "#E01F29",
    },
  },
  cardBlockSvgGreen: {
    "& svg": {
      color: "#ffffff",
      borderRadius: 2,
      padding: 2,
      fontSize: 30,
      background: "#24A148",
    },
  },
  cardBlockLiftRed: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    padding: 5,
    background: "#FBE8E9",
    color: "#E01F29",
    "& span": {
      color: "#E01F29",
    },
  },
  cardBlockLiftYellow: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    padding: 5,
    background: "#FDF8E6",
    color: "#F0BF0F",
    "& span": {
      color: "#F0BF0F",
    },
  },
  cardBlockLiftGreen: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    padding: 5,
    background: "#EAF6ED",
    color: "#24A148",
    "& span": {
      color: "#24A148",
    },
  },
  cardBlockVal: {
    padding: "12px 0 !important",
    "& h4": {
      display: "inline-block",
      lineHeight: 1.5,
    },
    "& span": {
      display: "inline-block",
      padding: "0 0 0 10px",
      fontWeight: 500,
    },
  },
  root: {
    width: 120, // Adjust width as needed
    height: 40, // Adjust height as needed
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white', // Background color of the paper
  },
  upperHalf: {
    width: '100%',
    height: '50%',
    backgroundColor: '#C1E1C1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  lowerHalf: {
    width: '100%',
    height: '50%',
    backgroundColor: '#FBE8E9',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
