import React, { useEffect, useState } from "react";
import ReactApexCharts from "react-apexcharts";

const BasicStackedBar = (props) => {
  const [chartInit, setChartInit] = useState();
  const { data, categories } = props;

  useEffect(() => {
    setChartInit({
      series: data,
      options: {
        chart: {
          type: "bar",
          stacked: true,
          // stackType: "100%",
          toolbar: {
            show: false,
          },
          sparkline: {
            enabled: false,
          },
        },
        colors: ["#680891", "#8A0BC1", "#BD3EF4", "#CE6EF7", "#DE9FF9"],
        grid: {
          show: false,
          padding: {
            left: 0,
            top: -20,
            bottom: 0,
          },
        },
        dataLabels: {
          style: {
            colors: ["#ffffff"],
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },

        xaxis: {
          categories: categories,
        },
        yaxis: {
          show: true,
          labels: {
            show: true,
          },
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: true,
          },
        },
        legend: {
          reversed: true,
        },
        tooltip: {
          x: {
            enabled: false,
            show: false,
          },
          y: {
            formatter: (value) => {
              return value + "%";
            },
          },
        },
      },
    });
  }, []);

  return (
    <React.Fragment>
      {chartInit && (
        <>
          <ReactApexCharts
            options={chartInit?.options}
            series={chartInit?.series}
            type="bar"
            height={290}
          />
        </>
      )}
    </React.Fragment>
  );
};
export default BasicStackedBar;
