import { SAMPLESIZERECOMMENDATION, SAMPLESIZESIMULATEDATA, CLEAR_SAMPLE_VALUES } from '../types';

export const setSampleSizeRecommendation = val => {
  return {
    type: SAMPLESIZERECOMMENDATION,
    payload: val
  };
};

export const setSampleSizeSemulationData = val => {
  return {
    type: SAMPLESIZESIMULATEDATA,
    payload: val
  };
};

export const clearSampleSizeValues = val => {
  return {
    type: CLEAR_SAMPLE_VALUES
  };
}