import React, { useState } from 'react';
import { Switch, Grid, FormControlLabel, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MeasureDeatil from './MeasureDetail/MeasureDeatil';
import ReactBreadcrumbs from 'views/Common/Breadcrumb/Breadcrumb';
import ExportCsv from 'views/Common/Utils/ExportCsv';
import { useEffect } from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  }
}));
const MeasureDeepHome = () => {
  const classes = useStyles();
  const [outlierChecked, setOutlierChecked] = useState(true);
  const [downLoaddata, setdownLoaddata] = useState([]);
  // console.log({outlierChecked});
  //function for toggle
  const toggleOutlierChecked = () => {
    setOutlierChecked(prev => !prev);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  
  //let downloaddata = JSON.parse(sessionStorage.getItem("download"));
  const updatedExportDatas = downLoaddata.map(
    ({ summaryData, tableData, ...rest }) => rest
  );
  return (
    <div>
      <div className={classes.root}>
        <Grid container spacing={3}>
        
          <Grid item xs={12}>
            <span style={{ float: 'left' }}>
              {' '}
              <ReactBreadcrumbs />
            </span>
            <Box component="div" style={{ float: 'right', textAlign: "center" }}>
              
              <Typography component="h4" variant="h4" style={{ fontSize: "16px", color: "#333333" }}>
                
               Outlier Removal</Typography>
               
              <Typography component="h4" variant="h4" className={classes.pageHeading}>
              <Typography component="p" style={{ display: "inline-block" }}><Box component="div" style={{ float: 'right', textAlign: "center" }} >
                    
                  </Box></Typography>
                <Typography component="p" style={{ display: "inline-block" }}><Box component="div" style={{ float: 'right', textAlign: "center" }} >
                  </Box>Off</Typography>
                <FormControlLabel
                  style={{ margin: "0 15px" }}
                  value="outlier"
                  control={
                    <Switch
                      size="small"
                      checked={outlierChecked}
                      onChange={toggleOutlierChecked}
                      color="primary"
                    />
                  }
                  labelPlacement="start"
                />
                <Typography component="p" style={{ display: "inline-block" }}>On</Typography>
              </Typography>

            </Box>
            
            {/* <Box component="div" style={{ float: 'right', textAlign: "center",margin: "0 15px"}}>
              
              <Typography component="h4" variant="h4" style={{ fontSize: "16px", color: "#333333" }}>
                
              <ExportCsv
                      tableData={updatedExportDatas}
                      fileName={'Campaign Data'}
                    /></Typography>
               
            

            </Box> */}
          </Grid>
        </Grid>
      </div>
      <MeasureDeatil outlierChecked={outlierChecked} />
    </div>
  );
};

export default MeasureDeepHome;
