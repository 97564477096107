import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Box } from '@material-ui/core';
import LineChart from '../../Common/Charts/LineChart';
import { retriveMetricFromStr } from '../../Common/Utils/ClientUtil';
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
  chartArea: {
    padding: '20px 0',

    '& p': {
      color: '#333333',
      fontSize: 14,
      textTransform: 'capitalize'
    }
  }
}));

const MeasureCharts = props => {
  const classes = useStyles();
  const { selectedKPI, graphData } = props;
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={6}>
        <Box className={classes.chartArea}>
          <Typography>{`Daily ${retriveMetricFromStr(
            selectedKPI.label
          )} (Test Vs. Control)`}</Typography>
          <LineChart
            title={selectedKPI.name}
            xAxis={
              Array.isArray(graphData.testControlXAxis)
                ? graphData.testControlXAxis.map(e =>
                    new Date(e).toLocaleDateString(
                      'en-US',

                      { month: 'short', day: 'numeric', timeZone: 'UTC' }
                    )
                  )
                : graphData.testControlXAxis
            }
            series={[
              { name: 'Test', data: graphData.testData },
              { name: 'Control', data: graphData.controlData }
            ]}
            annotationsData={graphData.annotationsData}
            showAnnotations={true}
            chartColors={['#8F2EFF', '#F7C76E']}
            type={selectedKPI.type}
            label={selectedKPI.label}
          />
        </Box>
      </Grid>

      <Grid item xs={12} sm={12} md={6}>
        <Box className={classes.chartArea}>
          <Typography>
            {`%Lift in ${retriveMetricFromStr(selectedKPI.label)} (Daily)`}
          </Typography>

          <LineChart
            title={'Lift %'}
            xAxis={graphData.liftXAxis}
            series={[{ name: 'Lift', data: graphData.liftData }]}
            annotationsData={graphData.annotationsData}
            showAnnotations={false}
            chartColors={['#FB04E2']}
            type={selectedKPI.type}
            label={selectedKPI.label}
            xAxisTitle={'Days from Campaign Start Date'}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default MeasureCharts;
