import React, { Suspense } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import MeasureDeepHome from 'views/MeasureCampaign/MeasureDeepDive/MeasureDeepHome';
import MeasureView from 'views/MeasureCampaign/MeasureView/MeasureView';
import CampaignSummary from 'views/MeasureCampaign/MeasureDeepDive/CampaignSummary';
import ProductGroup from 'views/ProductGroup';
import CreateGroup from './views/ProductGroup/createGroup';
import { RouteWithLayout } from './components';
import { Main as MainLayout } from './layouts';
import Login from './views/Login/Logins';
import PageNotFound from 'views/PageNotFound/pageNotFound';
import Home from 'views/Home/index';
import Auth from 'views/Auth/Auth';
import Logout from 'views/Logout/Logout';
import Forbidden from 'views/Forbidden';

const DashboardView = React.lazy(() => import('./views/Dashboard'));
const DesignCampaign = React.lazy(() =>
  import('./views/DesignCampaign/DesignCampaign')
);
const MeasureCampaign = React.lazy(() =>
  import('./views/MeasureCampaign/MeasureCampaign​')
);

const routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/login" />

      <Route path="/login" component={Login} />
      <Route path="/auth" component={Auth} />
      <RouteWithLayout
        component={DesignCampaign}
        exact
        layout={MainLayout}
        path="/design-campaign/:scenario"
      />
      <RouteWithLayout
        component={MeasureCampaign}
        exact
        layout={MainLayout}
        path="/measure-campaign"
      />
      <RouteWithLayout
        component={MeasureView}
        exact
        layout={MainLayout}
        path="/measure-campaign-new"
      />
      <RouteWithLayout
        component={ProductGroup}
        exact
        layout={MainLayout}
        path="/productgroup"
      />
      <RouteWithLayout
        component={CreateGroup}
        exact
        layout={MainLayout}
        path="/creategroup"
      />
      <RouteWithLayout
        component={MeasureDeepHome}
        exact
        layout={MainLayout}
        path="/measure-campaign-new/measure-deep-dive"
      />
      <RouteWithLayout
        component={CampaignSummary}
        exact
        layout={MainLayout}
        path="/measure-campaign-new/measure-deep-dive/campaign-summary"
      />
      <RouteWithLayout
        component={Home}
        exact
        layout={MainLayout}
        path="/home"
      />
      <RouteWithLayout component={Logout} exact path="/logout" />
      <RouteWithLayout
        component={Forbidden}
        exact
        layout={MainLayout}
        path="/403"
      />
      <RouteWithLayout component={PageNotFound} layout={MainLayout} />

      {/* </Suspense> */}
    </Switch>
  );
};

export default routes;
