import {
  Grid,
  Typography,
  Card,
  CardContent,
  Box,
  Button
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import useStyles from './Style';
import TabSection from '../../../Common/Utils/Tabs';
import MeasureHightLight from '../MeasureHighLights/MeasureHightLight';
import { useLocation } from 'react-router-dom';
import MeasureDrillHome from '../../MeasureDrillDown/MeasureDrillDownHome/MeasureDrillHome';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import secureAxios from 'api-config/api';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Moment from 'moment';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { useHistory } from 'react-router-dom';
import ExportCsv from 'views/Common/Utils/ExportCsvOutlier';
import { useDispatch, useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import { setLoading } from 'redux/actions/application.action';
import GetAppIcon from '@material-ui/icons/GetApp';
const MeasureDeepdive = ({ outlierChecked, props }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [data, setData] = useState('');
  const campaign_id = location?.state?.campaignId;
  const [downLoaddata, setdownLoaddata] = useState([]);

  const tabItems = [
    {
      id: 1,
      title: 'Campaign Highlights'
    },
    {
      id: 2,
      title: 'Campaign Drill Down'
    }
  ];

  //api call for details
  useEffect(() => {
    // setLoad(true)
    let isSub = true;
    if (isSub) {
      secureAxios
        .get('/measureCampaign/campListId', {
          params: {
            campaign_id: campaign_id,
            outlierValue: outlierChecked
          }
        })
        .then(res => {
          setData(res?.data?.queryResult?.[0]);
          let downLoaddata = res?.data?.responseDownload;
          setdownLoaddata(downLoaddata);
        })
        .catch(err => {
          console.log(err);
        });
    }
    return () => {
      isSub = false;
    };
  }, [outlierChecked]);
  const updatedExportDatas = downLoaddata.map(
    ({ summaryData, tableData, ...rest }) => rest
  );
  const dispatch = useDispatch();
  const [identifyCSV, setIdentifyCSV] = useState([]);
  const [controlCSV, setControlCSV] = useState([]);
  const [matchStatsCSV, setmatchStatCSV] = useState([]);
  let finalSelectedAttribute = [
    'OUTLET_STRATEGY',
    'MENS_TIER',
    'WOMENS_TIER',
    'AERIE_FORMAT',
    'AERIE_SET_UP',
    'BTS_PEAK',
    'FACTORY_DESIGNATION'
  ];
  const downlaodIdentifyCSV = () => {
    dispatch(setLoading(true));
    secureAxios
      .get('/measureCampaign/downloadIdentifyCSV', {
        params: {
          CAMPAIGN_ID: campaign_id,
          SELECTED_ATTRIBUTE: finalSelectedAttribute
        }
      })
      .then(response => {
        // console.log("res",response)
        if (response.data) {
          setIdentifyCSV(response.data);
        }
      });
  };
  const downlaodControlCSV = () => {
    secureAxios
      .get('/measureCampaign/downloadControlCSV', {
        params: {
          CAMPAIGN_ID: campaign_id,
          SELECTED_ATTRIBUTE: finalSelectedAttribute
        }
      })
      .then(response => {
        console.log('res', response);
        if (response.data) {
          setControlCSV(response.data);
          dispatch(setLoading(false));
        }
      });
  };
  const downlaodMatchStatsCSV = () => {
    secureAxios
      .get('/measureCampaign/downlaodMatchStatsCSV', {
        params: {
          CAMPAIGN_ID: campaign_id
          // SELECTED_ATTRIBUTE: finalSelectedAttribute
        }
      })
      .then(response => {
        console.log('res', response);
        if (response.data) {
          setmatchStatCSV(response.data);
          dispatch(setLoading(false));
        }
      });
  };

  useEffect(() => {
    const getUsers = async () => {
      const data = await downlaodIdentifyCSV();
      const data2 = await downlaodControlCSV();
      const data3 = await downlaodMatchStatsCSV();
    };

    getUsers(); // run it, run it

    return () => {
      // this now gets called when the component unmounts
    };
  }, []);

  return (
    <>
      <div className={classes.root}>
        <Box
          component="div"
          style={{
            float: 'right',
            textAlign: 'center',
            margin: '-61px 145px'
          }}>
          <Typography
            component="h4"
            variant="h4"
            style={{ fontSize: '16px', color: '#333333' }}>
            <ExportCsv
              tableData={updatedExportDatas}
              fileName={'Outlier Data'}
            />
          </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              component="h4"
              variant="h4"
              className={classes.pageHeading}>
              {data?.campaign_name ? data?.campaign_name : 'NA'}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Card className={classes.cardItem} elevation={2}>
              <CardContent>
                <Typography className={classes.cardHeading}>
                  Campaign Details
                </Typography>
                <Box component="div" className={classes.detailBlock}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography className={classes.description}>
                        {data?.campaign_description == null
                          ? 'NA'
                          : data.campaign_description}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={10}>
                      <Grid container spacing={3}>
                        <Grid item xs={4} sm={4} md={3}>
                          <Typography className={classes.heading}>
                            {'Test group:'}
                          </Typography>
                          <Typography component="h5" className={classes.value}>
                            {data?.test_grp ? data?.test_grp : 'NA'}
                          </Typography>
                        </Grid>
                        <Grid item xs={4} sm={4} md={3}>
                          <Typography className={classes.heading}>
                            {'Test Type:'}
                          </Typography>
                          <Typography component="h5" className={classes.value}>
                            {data?.test_type ? data?.test_type : 'NA'}
                          </Typography>
                        </Grid>
                        <Grid item xs={2} sm={2} md={3}>
                          <Typography className={classes.heading}>
                            {'Region:'}
                          </Typography>
                          <Typography component="h5" className={classes.value}>
                            {data?.region ? data?.region : 'NA'}
                          </Typography>
                        </Grid>
                        <Grid item xs={2} sm={2} md={3}>
                          <Typography className={classes.heading}>
                            {'Status:'}
                          </Typography>

                          {data?.campaign_status ? (
                            <Button
                              className="p0"
                              style={{ textTransform: 'capitalize' }}
                              startIcon={
                                data?.campaign_status === 'COMPLETED' ? (
                                  <CheckCircleOutlineIcon
                                    style={{ color: 'rgb(36, 161, 72)' }}
                                  />
                                ) : (
                                  <WatchLaterIcon />
                                )
                              }>
                              {data?.campaign_status}
                            </Button>
                          ) : (
                            <Typography
                              component="h5"
                              className={classes.value}>
                              {'NA'}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                          <Typography className={classes.heading}>
                            {'Campaign Duration:'}
                          </Typography>
                          <Typography className={classes.durationArea}>
                            {data?.start_date ? (
                              <>
                                <DateRangeIcon fontSize="small" />
                                {Moment(data?.start_date?.value)?.format(
                                  'MMM D, YYYY'
                                )}
                                {' - '}{' '}
                                {Moment(data?.end_date?.value)?.format(
                                  'MMM D, YYYY'
                                )}
                              </>
                            ) : (
                              'NA'
                            )}
                          </Typography>
                        </Grid>{' '}
                        <Grid item xs={4} sm={4} md={3}>
                          <Typography className={classes.heading}>
                            {'Campaign Pre Period:'}
                          </Typography>
                          <Typography className={classes.durationArea}>
                            <Typography className={classes.durationArea}>
                              {data?.pre_start_date ? (
                                <>
                                  <DateRangeIcon fontSize="small" />
                                  {Moment(data?.pre_start_date?.value).format(
                                    'MMM D, YYYY'
                                  )}
                                  {' - '}{' '}
                                  {Moment(data?.pre_end_date?.value).format(
                                    'MMM D, YYYY'
                                  )}
                                </>
                              ) : (
                                'NA'
                              )}
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item xs={4} sm={4} md={3}>
                          <Typography className={classes.heading}>
                            {'Created on:'}
                          </Typography>
                          <Typography className={classes.durationArea}>
                            {data?.created_date == null
                              ? 'NA'
                              : Moment(data?.created_date?.value).format(
                                  'MMM D, YYYY'
                                )}
                          </Typography>
                        </Grid>
                        <Grid item xs={4} sm={4} md={3}>
                          <Typography className={classes.heading}>
                            {'Created by:'}
                          </Typography>
                          <Typography component="h5" className={classes.value}>
                            {data?.created_by ? data?.created_by : 'NA'}
                          </Typography>
                          <Grid item xs={4} sm={4} md={3}></Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={2}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12}>
                          {data?.pct_lift !== null ? (
                            <Box
                              component="div"
                              className={
                                data?.pct_lift < 0
                                  ? classes.redLiftBox
                                  : classes.greenLiftBox
                              }>
                              <Typography className={classes.heading}>
                                {data?.primary}
                              </Typography>
                              <Typography>
                                {data?.pct_lift < 0 ? (
                                  <TrendingDownIcon />
                                ) : (
                                  <TrendingUpIcon />
                                )}
                                {data?.pct_lift}%
                              </Typography>
                              <Typography>{'LIFT'}</Typography>
                            </Box>
                          ) : (
                            <Box
                              component="div"
                              className={
                                data?.pct_lift
                                  ? classes.redLiftBox
                                  : classes.greenLiftBox
                              }>
                              <Typography className={classes.heading}>
                                NA
                              </Typography>
                              <Typography>
                                <TrendingUpIcon />
                              </Typography>
                              <Typography>{'LIFT'}</Typography>
                            </Box>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <Typography component="h5" className={classes.heading}>
                        {'Test Attribute CSV'}
                      </Typography>
                      <CSVLink
                        data={identifyCSV}
                        filename={'test_attribute.csv'}
                        //  onClick={()=>{
                        //   identifyCSV
                        //  }}
                      >
                        <GetAppIcon
                          style={{ color: '#182C52' }}
                          disable={
                            identifyCSV && identifyCSV.length > 0 ? false : true
                          }
                        />
                      </CSVLink>
                      {/* <CsvDownloader datas={identifyCSV} filename="test_attribute"><GetAppIcon /></CsvDownloader> */}
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <Typography component="h5" className={classes.heading}>
                        {'Control Attribute CSV'}
                      </Typography>
                      <CSVLink
                        data={controlCSV}
                        filename={'control_attribute.csv'}>
                        <GetAppIcon
                          style={{ color: '#182C52' }}
                          disable={
                            controlCSV && controlCSV.length > 0 ? false : true
                          }
                        />
                      </CSVLink>
                      {/* <CsvDownloader datas={controlCSV} filename="control_attribute" ><GetAppIcon /></CsvDownloader> */}
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <Typography component="h5" className={classes.heading}>
                        {'Matching Statistics CSV'}
                      </Typography>
                      <CSVLink
                        data={matchStatsCSV}
                        filename={'match_satistics.csv'}>
                        <GetAppIcon
                          style={{ color: '#182C52' }}
                          disable={
                            matchStatsCSV && matchStatsCSV.length > 0
                              ? false
                              : true
                          }
                        />
                      </CSVLink>
                      {/* <CsvDownloader datas={controlCSV} filename="control_attribute" ><GetAppIcon /></CsvDownloader> */}
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Button
              color="primary"
              style={{ float: 'right', color: '#182C52', fontWeight: 600 }}
              onClick={() =>
                history.push({
                  pathname: 'measure-deep-dive/campaign-summary',
                  state: { ...location.state, outlierChecked }
                })
              }>
              Campaign Summary
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <TabSection
                tabNavclick={true}
                selectedMenu=""
                tabItems={tabItems}
                Tab1={MeasureHightLight}
                Tab2={MeasureDrillHome}
                outlierChecked={outlierChecked}
                campaign_id={campaign_id}
                data={data}
              />
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default MeasureDeepdive;
