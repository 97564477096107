export const onHandleClearAttribute = (a, b) => {
    let temp = [...b].map(ele => ele.label);
    return a.filter(ele => temp.includes(ele.key));
}

export const readFileData = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            const fileData = reader.result;
            resolve(fileData);
        };
        reader.onerror = () => {
            reject(reader.error);
        };
        reader.readAsText(file);
    });
};

export const createCSVFileObject = (csvData, fileName) => {
    const blob = new Blob([csvData], { type: 'text/csv' });
    const file = new File([blob], fileName, { type: 'text/csv' });
    return file;
};

export const encodeToSingleLine = (inputString) => {
    const singleLineString = inputString.replace(/\n/g, '\\n');
    const encodedString = Buffer.from(singleLineString).toString('base64');
    return encodedString;
};

export const decodeToMultiLine = (encodedString) => {
    const decodedBuffer = Buffer.from(encodedString, 'base64');
    const singleLineString = decodedBuffer.toString();
    const multiLineString = singleLineString.replace(/\\n/g, '\n');
    return multiLineString;
}

export const uniqueArrayItem = (array) => {
    const newItems =  array.reduce((result, element) => {
      var normalize = x => typeof x === 'string' ? x.toLowerCase() : x;
  
      var normalizedElement = normalize(element);
      if (result.every(otherElement => normalize(otherElement) !== normalizedElement))
        result.push(element);
  
      return result;
    }, []);
    return newItems
}