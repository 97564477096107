import {
    PRODUCT_LIST
  } from '../types';
  
  const initialState = {
    productList: []
  };
  const productGroupReducer = (state = initialState, action) => {
    switch (action.type) {
      case PRODUCT_LIST:
        return {
          ...state,
          productList: action.payload
        };
      default:
        return state;
    }
  };
  
  export default productGroupReducer;
  