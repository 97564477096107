export const USFormatNumber = number => {
  let formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0
  });
  return formatter.format(number);
};

export const toCurrencyFormat = labelValue => {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + 'B'
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
    ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + 'M'
    : // Three Zeroes for Thousands
    Math.abs(Number(labelValue)) >= 1.0e3
    ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(1) + 'K'
    : Math.abs(Number(labelValue));
};

export const toCamelcaseStr = str => {
  // Convert first charector to uppercase
  if (str) {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);
  }
};

export const toFloatValue = num => {
  // Convert float number to 2 decimal points number
  if (num && num % 1 != 0) {
    return num.toFixed(2);
  } else {
    return num;
  }
};

export const checkDecimal = num => {
  // checking floating number, if number is float then fixed to 2 decimal else number is integer then number will be separated by comma
  if (num % 1 != 0) {
    return parseFloat(parseFloat(num).toFixed(2)).toLocaleString('en', {
      minimumFractionDigits: 2
    });
  } else {
    return parseInt(num).toLocaleString();
  }
};

const toMetricFormat = (value, key) => {
  // All metric unit formating with value
  const metric = key?.toLowerCase();
  if (
    metric == 'sales' ||
    metric == 'cost_to_serve' ||
    metric == 'labor_cost' ||
    metric == 'avg_income' || 
    metric == 'ads' || 
    metric == 'aur' ||
    metric == 'margin_amt' || 
    metric == 'conversion_rate' || 
    metric == 'actual_cost_amt' || 
    metric == 'margin_rate'
  ) {
    return `$${checkDecimal(value)}`;
  } else if(
    metric == 'basket size' ||
    metric == 'aur' ||
    metric == 'traffic' ||
    metric == 'upt' ||
    metric == 'transactions' ||
    metric == 'inv_oh' ||
    metric == 'units'
    ){
    return Math.round(value).toLocaleString()
  } else if (
    metric == 'out_of_stock' ||
    metric == 'oos' ||
    metric == 'productivity' ||
    metric == 'market_share'
  ) {
    return `${checkDecimal(value)}%`;
  } else if (metric == 'labor_hours') {
    return `${checkDecimal(value)} hrs`;
  } else if (metric == 'avg_tenure') {
    return `${checkDecimal(value)} Years`;
  } else if (
    metric == 'visits' ||
    metric == 'units' ||
    metric == 'basket_size' ||
    metric == 'csat' ||
    metric == 'nps' ||
    metric == 'price_perception' ||
    metric == 'cleanliness_score' ||
    metric == 'fast_score' ||
    metric == 'total_items' ||
    metric == 'total_sku' ||
    metric == 'friendly_score' ||
    metric == 'product_availability' ||
    metric == 'no_of_customers' ||
    metric == 'new_customers' ||
    metric == 'repeat_customers' ||
    metric == 'reactivated_customers' ||
    metric == 'population_density'
  ) {
    return `${checkDecimal(value)}`;
  } else {
    return value;
  }
};
export default toMetricFormat;

export const retriveMetricFromStr = str => {
  str = str?.toLowerCase();
  if (str?.includes('__')) {
    const txtArr = str?.split('__');
    const txtArrLength = txtArr?.length;
    return txtArr[txtArrLength - 1];
  } else {
    return str;
  }
};

export const testTypeValidation = {
  assortment: true,
  pricing: true,
  remodel: true,
  'front end': false,
  promotion: true,
  'store event': true,
  labor: false,
  schedule: false
};

export const getMetricUnit = key => {
  // All metric unit formating
  const metric = key.toLowerCase();
  if (
    metric == 'sales' ||
    metric == 'cost_to_serve' ||
    metric == 'aur' ||
    metric == 'labor_cost' ||
    metric == 'avg_income'
  ) {
    return `$`;
  } else if (
    metric == 'out_of_stock' ||
    metric == 'oos' ||
    metric == 'productivity' ||
    metric == 'market share'
  ) {
    return `%`;
  } else if (metric == 'labor_hours') {
    return `hrs`;
  } else {
    return '';
  }
};
export const onlyDeptMapping = {
  'no of customers': true,
  'new customers': true,
  'repeat customers': true,
  'reactivated customers': true,
  'market share': true
};
export const onlyStoreMapping = {
  'cleanliness score': true,
  'fast score': true,
  'friendly score': true,
  'product availability': true,
  'price perception': true
};

export const lineChartMapping = {
  'no of customers': true,
  'new customers': true,
  'repeat customers': true,
  'reactivated customers': true
};

export const noRoundKPI = {
  'market share': true,
  'fast score': true,
  'cleanliness score': true,
  'friendly score': true,
  'price perception': true,
  'product availability': true
};
export const noRoundKPIList = [
  'market share',
  'fast score',
  'cleanliness score',
  'friendly score',
  'price perception',
  'product availability'
];
export const numberWithCommas = number => {
  return number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
