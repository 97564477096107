import React from 'react';
import { Typography, Paper, Box } from '@material-ui/core';
import CampaignDetailBlock from './CampaignDetailBlock';
import MaterialTable from 'material-table';
import { TablePagination } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Moment from 'moment';
import _ from 'lodash';
import useStyles from './Style';
import { DeleteForever } from '@material-ui/icons';
import MessageDialog from 'views/Common/MessageDialog/MessageDialog';
import { useEffect } from 'react';

function PatchedPagination(props) {
  const {
    ActionsComponent,
    onChangePage,
    onChangeRowsPerPage,
    ...tablePaginationProps
  } = props;

  return (
    <TablePagination
      {...tablePaginationProps}
      // @ts-expect-error onChangePage was renamed to onPageChange
      onPageChange={onChangePage}
      onRowsPerPageChange={onChangeRowsPerPage}
      ActionsComponent={subprops => {
        const { onPageChange, ...actionsComponentProps } = subprops;
        return (
          // @ts-expect-error ActionsComponent is provided by material-table
          <ActionsComponent
            {...actionsComponentProps}
            onChangePage={onPageChange}
          />
        );
      }}
    />
  );
}

const MeasureTable = props => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [deletedId, setDeleteId] = React.useState('');
  const [tableData,setTableData] = React.useState([]);
  const { data, dataLevel, deleteCampaign } = props;


  const maptableData=  (datas)=>{
let values =   datas.map(item=>{
item['startDate']=item.startDate?.value;
item['endDate']=item.endDate?.value;
return item;
})
return values;
  }


  useEffect(()=>{
    const setTableDatas= async ()=>{
      const datas= await maptableData(props.data);
      setTableData(datas);
      console.log(datas,'mapped dats')
    };
    setTableDatas();
    console.log('measure Table data', data,tableData);

  },[data])

  const segColumns = [
    {
      title: 'Name',
      field: 'name',
      render: rowData => <Typography>{rowData.name}</Typography>
    },
    {
      title: 'Test Group',
      field: 'testGrp',

      render: rowData => <Typography>{rowData.testGrp}</Typography>
    },

    {
      title: 'Test Type',
      field: 'testType',

      render: rowData => <Typography>{rowData.testType}</Typography>
    },
    {
      title: 'Duration',
      field: 'duration',
      customSort: (a, b) => {
        const startDateA = a.startDate || '';
        const startDateB = b.startDate || '';
    
        if (startDateA === startDateB) {
          const endDateA = a.endDate || '';
          const endDateB = b.endDate || '';
          return endDateA.localeCompare(endDateB);
        }
    
        return startDateA.localeCompare(startDateB);
      },
      render: rowData => (
        <Typography className={classes.durationArea}>
          <DateRangeIcon fontSize="small" />
          {Moment(rowData.startDate).format('MMM D, YYYY')}
          {' - '}
          {Moment(rowData.endDate).format('MMM D, YYYY')}
        </Typography>
      )
    }
    
    
    // {
    //   title: 'Action',
    //   field: 'Action',

    //   render: rowData => <Typography>Delete</Typography>
    // },
  ];

  const rowsPerPageOptions = [5, 10, 25, 50,100];

  function handleClose() {
    setOpen(false);
    setDeleteId('');
  }

  const deleteHandler = () => {
    deleteCampaign(deletedId);
    setOpen(false);
    setDeleteId('');
  };

  const confirmHandler = id => {
    setDeleteId(id);
    setOpen(true);
  };

  return (
    <>
      <MessageDialog
        open={open}
        buttonType={false}
        close={handleClose}
        handleButton={deleteHandler}
        type={'error'}
        message={'Are you sure to delete'}
      />
      <Box component="div" className={classes.tblArea}>
        <MaterialTable
        key={ data.length }
          elevation={0}
          components={{
            Pagination: PatchedPagination,
            Container: props => (
              <Paper {...props} elevation={0} variant="outlined" square />
            )
          }}
          options={{
            // cellStyle: rowData => {
            //   return {
            //     padding: '0 16px'
            //   };
            // },
            headerStyle: {
              backgroundColor: '#182C52',
              color: '#FFF'
            },
            emptyRowsWhenPaging:false,
            exportAllData: true,
            doubleHorizontalScroll:true,
            actionsColumnIndex: -1,
            search: false,
            toolbar: false,
            pageSizeOptions: rowsPerPageOptions,
            exportButton: {
              csv: true,
              pdf: false
            },
          }}
          actions={[
            {
              icon: () => <DeleteForever fontSize="small" />,
              tooltip: 'Delete',
              onClick: (e, rowData) => {
                confirmHandler(rowData.campaignId);
               
              }
            }
          ]}
          columns={segColumns}
          data={tableData.length ? tableData:data}
          title=""
          detailPanel={rowData => {
            return <CampaignDetailBlock data={rowData} dataLevel={dataLevel} />;
          }}
          // onRowClick={(togglePanel) => togglePanel()}
        />
      </Box>
    </>
  );
};

export default MeasureTable;
