import {
  LOADING,
  STOREGROUPALL,
  SHOWRANDOMSTORETEXT,
  SETTOTALSTORE,
  SETQUERYTREE,
  SETQUERYRULE,
  STOREIDATTRSELECTED,
  GETSTORESELECTEDATTROPTION,
  SELECTEDATTROPTIONS,
  SETATTROPTIONSELECTED,
  FINALCANDIDATESTSTORES,
  ATTRIBUTES,
  GETSTOREDATA,
  GETALLSTORES,
  QUERYBUILDERDATA,
  STOREGROUP,
  RANDOMSAMPLETABLEDATA,
  CREATETORESEGMENT,
  CREATETORESEGMENTFILTER,
  GETSTORESQUERYBUILDER,
  FINALCANDIDATESTSTORE,
  DRILLDOWNFILTER,
  DRILLDOWNSELECTEDFILTER,
  KPIOPTIONS,
  KPIS,
  SELECTEDKPIOPTIONS,
  ATTRSTORECOUNT,
  TELL_US_MORE_SELECTED_ATTRIBUTES,
  TELL_US_MORE_SELECTED_SUB_ATTRIBUTES,
  TELL_US_MORE_FINAL_CANDIDATE_STORE,
  GETCAMPAIGNS,
  ABOUTTEST_STOREID_ATTR,
  KPIOPTIONSLOADING
} from 'redux/types';

// import { LOADING, STOREGROUPALL, SHOWRANDOMSTORETEXT, SETTOTALSTORE, SETQUERYRULE, SETQUERYTREE, STOREIDATTRSELECTED, GETSTORESELECTEDATTROPTION, SELECTEDATTROPTIONS, SETATTROPTIONSELECTED, FINALCANDIDATESTSTORES, ATTRIBUTES, GETSTOREDATA, GETALLSTORES, QUERYBUILDERDATA, STOREGROUP, RANDOMSAMPLETABLEDATA, CREATETORESEGMENT, GETSTORESQUERYBUILDER, FINALCANDIDATESTSTORE, DRILLDOWNFILTER, DRILLDOWNSELECTEDFILTER } from 'redux/types';
// import { async } from 'validate.js';
import secureAxios from './api';
import _ from 'lodash';
import store from '../redux/store';
import CreatStoreSegment from 'views/DesignCampaign/IdentifyTest/CreatStoreSegment';
import {
  setAttributeStatus,
  setCandidateTestStore,
  setTestTableCount
} from '../redux/actions/identifyTest.action';
import { setLoading } from 'redux/actions/application.action';

let state = store.getState();
console.log(state, state.identifyTestReducer, 'globalstate');
let { storesfromquerybuilder } = state.identifyTestReducer;

const dispacthData = (dispatch, type, val) => {
  dispatch({
    type: type,
    payload: val
  });
};

export const getAttribute = val => {
  return dispatch => {
    try {
      const datas = secureAxios
        .get('/designCampaign/storeDropdown', {
          params: []
        })
        .then(res => {
          console.log(res.data, 'AttributeData');
          const storeDatas = _.chain(res.data)
            .map(i => ({
              label: i.ATTRIBUTE,
              value: i.ATTRIBUTE
            }))
            .orderBy(['label'], ['asc'])
            .value();
          // setStoreData(storeDatas);
          dispatch({
            type: ATTRIBUTES,
            payload: storeDatas
          });
        });
    } catch (error) {
      console.log(error);
    }
  };
};

export const getQueryBuilderData = val => {
  const { campaignID, fields, getMetricUnit, checkUnderscoreMetric } = val;
  return dispatch => {
    try {
      secureAxios
        .get('/designCampaign/getQueryBuilderRules', {
          showLoading: true,
          params: { CAMPAIGN_ID: campaignID }
        })
        .then(response => {
          console.log('getQueryBuilderRules', response);
          response.data.map(val => {
            Object.assign(fields, {
              [val.features]: {
                label: `${val.properFeatures == 'Aur' ? 'AUR' :
                          val.properFeatures == 'Ads' ? 'ADS':
                          val.properFeatures == 'Upt' ? 'UPT':
                          val.properFeatures} ${getMetricUnit(checkUnderscoreMetric(val.features))
                  ? `(${getMetricUnit(checkUnderscoreMetric(val.features))})`
                  : ''
                  }`,
                type: val.type,
                valueSources: ['value'],
                fieldSettings: val.field
              }
            });
          });
          //   setQueryfields(fields);
          dispacthData(dispatch, QUERYBUILDERDATA, fields);
        });
    } catch (error) { }
  };
};

export const getStoreGroupsVal = val => {
  return dispatch => {
    try {
      secureAxios
        .get('/designCampaign/getStoresGroup')
        .then(function (response) {
          console.log(response);
          // setStoreGroupDrpVal(prev => response.data)
          dispacthData(dispatch, STOREGROUP, response.data);
          // setLoad(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
};
export const getStoreGroupsAll = val => {
  return dispatch => {
    try {
      secureAxios
        .get('/designCampaign/getStoresGroupAll')
        .then(function (response) {
          // console.log(response);
          // setStoreGroupDrpVal(prev => response.data)
          const data = response.data.map(item => {
            item['group'] = item['STORE_GRP_NAME'];
            delete item['STORE_GRP_NAME'];
            return item;
          });
          // Log to console
          console.log(data, 'getStoresGroupAll');

          dispacthData(dispatch, STOREGROUPALL, data);
          val && val.SetGroups(data);
          // dispatch(setLoading(false));
          // setLoad(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
};

export const createStoreSegments = val => {
  const { campaignID, storesAttribute, storesGroup, formData } = val;
  return dispatch => {
    if (storesAttribute) {
      secureAxios.post('/designCampaign/create-test-store-segment', formData, {
        showLoading: true
      }).then(response => {
        if (response.status) {
          const totalExcludedStoresCount =
            Number(response?.data?.getDownloadButtonStores?.length) +
            Number(storesAttribute?.length) +
            Number(storesGroup?.length);
          let addStoreAttributeStores = response?.data?.getDownloadButtonStores;
          const overallExcludedStotStores = _.uniq(addStoreAttributeStores);
          console.log(
            'overover',
            overallExcludedStotStores,
            totalExcludedStoresCount
          );
          const dataStoreSeg = {
            storesLeft: response.data.storesLeft,
            totalExcludedStores: totalExcludedStoresCount,
            totalExcludedStoresData: overallExcludedStotStores,
            getFinalExcludedEntitiesResult: overallExcludedStotStores,
            campaignId: campaignID,
            storesExclueded: response.data.storesExclueded
          };
          dispacthData(dispatch, CREATETORESEGMENT, dataStoreSeg);
          // setHideGenerateCtrlBtn(true);
        }
      });
    } else {
      dispacthData(dispatch, CREATETORESEGMENT, []);
    }
  };
};

export const createStoreSegmentsfilter = val => {
  const { campaignID, storesAttribute, storesGroup, formData } = val;
  return dispatch => {
    if (storesAttribute) {
      secureAxios.post('/designCampaign/create-test-store-segment', formData, {
        showLoading: true
      }).then(response => {
        if (response.status) {
          const totalExcludedStoresCount =
            Number(response?.data?.getDownloadButtonStores?.length) +
            Number(storesAttribute?.length) +
            Number(storesGroup?.length);
          let addStoreAttributeStores = response?.data?.getDownloadButtonStores;
          const overallExcludedStotStores = _.uniq(addStoreAttributeStores);
          console.log(
            'overover',
            overallExcludedStotStores,
            totalExcludedStoresCount
          );
          const dataStoreSeg = {
            storesLeft: response.data.storesLeft,
            totalExcludedStores: totalExcludedStoresCount,
            totalExcludedStoresData: overallExcludedStotStores,
            getFinalExcludedEntitiesResult: overallExcludedStotStores,
            campaignId: campaignID,
            storesExclueded: response.data.storesExclueded
          };
          dispacthData(dispatch, CREATETORESEGMENTFILTER, dataStoreSeg);
          // setHideGenerateCtrlBtn(true);
        }
      });
    } else {
      dispacthData(dispatch, CREATETORESEGMENTFILTER, []);
    }
  };
};
export const setQueryRule = val => {
  return dispatch => {
    try {
      dispacthData(dispatch, SETQUERYRULE, val);
    } catch (err) {
      console.log(err);
    }
  };
};

export const setQueryTree = val => {
  return dispatch => {
    try {
      dispacthData(dispatch, SETQUERYTREE, val);
    } catch (err) {
      console.log(err);
    }
  };
};

export const setTotalScore = val => {
  return dispatch => {
    dispacthData(dispatch, SETTOTALSTORE, val);
  };
};
export const getStoresForQueryBuilder = val => {
  const { query, param, setTotalStoreval } = val;

  return dispatch => {
    if (query) {
      secureAxios
        .post('/designCampaign/get-total-stores-from-query-builder', param, {
          showLoading: true
        })
        .then(response => {
          if (response.status == 200) {
            // setQueryBuilderData(response.data?.totalStores);
            // setTotalStores(response.data?.totalStores?.length);
            dispatch(setCandidateTestStore(response.data?.totalStores));
            dispacthData(
              dispatch,
              SETTOTALSTORE,
              response.data?.totalStores?.length
            );
            // setTotalScore(response.data?.totalStores?.length);
            // dispacthData(dispatch,SETTOTALSTORE,val)
            dispacthData(
              dispatch,
              GETSTORESQUERYBUILDER,
              response.data?.totalStores
            );

            // setTotalStoreval(response.data?.totalStores?.length);
          }
        });
    } else {
      if (param) {
        secureAxios
          .post('/designCampaign/get-total-stores-from-query-builder', param, {
            showLoading: true
          })
          .then(response => {
            if ((response.status = 200)) {
              //   setQueryBuilderData(response.data?.totalStores);
              //   setTotalStores(response.data?.totalStores?.length);
              dispatch(setCandidateTestStore(response.data?.totalStores));
              dispacthData(
                dispatch,
                SETTOTALSTORE,
                response.data?.totalStores?.length
              );

              // setTotalStoreval(response.data?.totalStores?.length);
              dispacthData(
                dispatch,
                GETSTORESQUERYBUILDER,
                response.data?.totalStores
              );
            }
          });
      } else {
        dispatch(setCandidateTestStore([]));
        dispacthData(dispatch, SETTOTALSTORE, 0);

        //   setTotalStoreval(0);
        dispacthData(dispatch, GETSTORESQUERYBUILDER, []);
      }
    }
  };
};

export const setRandomSampeData = val => {
  return dispatch => {
    let value = val ? val : [];
    dispacthData(dispatch, RANDOMSAMPLETABLEDATA, value);
  };
};
export const getRandomSampleData = val => {
  const {
    v,
    setLoading,
    setRunningMsg,
    interval,
    queryBuilderData,
    radioVal,
    teststoreidentified,
    params,
    setShowIdentifiedStoresText,
    setSampleStoreTableData,
    setHideNextBtn,
    setShowTestStoreGeneratedErrorMsg
  } = val;
  let state = store.getState();
  console.log(state, state.identifyTestReducer, 'globalstate');
  let { storesfromquerybuilder } = state.identifyTestReducer;
  return dispatch => {
    try {
      secureAxios
        .get('/designCampaign/generate-sample-test-stores', {
          params: params
        }, { showLoading: true })
        .then(response => {
          let tempData = [];
          if (response.status == 200) {
            dispatch(setLoading(false));
            setRunningMsg({
              msg: response.data.msg,
              status: response.data.status
            });
            if (response.data.status === 'Success') {
              // clearInterval(interval);
              dispatch(setTestTableCount(tempData));
              let data = [];
              if (storesfromquerybuilder.length > 0) {
                storesfromquerybuilder.forEach(obj => {
                  response.data.responseData.forEach(o => {
                    // if (o.STORE_ID == obj.STORE_ID) {
                    //   data.push(o);
                    // }
                    if (o.store_id == obj.store_id) {
                      data.push(o);
                    }
                  });
                });
              } else {
                response.data.responseData.forEach(o => {
                  data.push(o);
                });
              }
              if (teststoreidentified == 'yes') {
                // tempData = data.slice(0, data.length);
                tempData = data;
                // setShowIdentifiedStoresText(true);
                // dispacthData(dispatch,SHOWRANDOMSTORETEXT,true);
                showRandomText(true);
              } else {
                if (radioVal == 'yes') {
                  if (!isNaN(v) || v) {
                    if (v == 0) {
                      tempData = data.slice(0, data.length);
                    } else {
                      let val = v;
                      val = parseInt(val);
                      tempData = data.slice(0, val);
                    }
                  }
                } else {
                  const count = data.length;
                  tempData = data.slice(0, count);
                }
              }

              //   setSampleStoreTableData(tempData);
              dispatch(setTestTableCount(tempData));
              setHideNextBtn(false);
              if (response.data.responseData.length === 0) {
                setShowTestStoreGeneratedErrorMsg({
                  status: true,
                  msg:
                    response.data.responseData.length > 0
                      ? `No. of Store is ${response.data.responseData.length} identified as Test Store`
                      : 'No Store is identified as Test Store, Please reupload a different list',
                  type:
                    response.data.responseData.length > 0 ? 'success' : 'error'
                });
              }else{
                setShowTestStoreGeneratedErrorMsg({
                  status: false,
                  msg: '',
                  type: 'error'
                });
              }
              //   dispacthData(dispatch,RANDOMSAMPLETABLEDATA,tempData);
              // setRandomSampeData(dispatch,tempData)
              dispacthData(dispatch, RANDOMSAMPLETABLEDATA, tempData);
            } else if (
              response.data.status === 'Stopped' ||
              response.data.status === 'Failed'
            ) {
              //   setSampleStoreTableData([]);
              // setRandomSampeData(dispatch,[])
              dispacthData(dispatch, RANDOMSAMPLETABLEDATA, []);

              clearInterval(interval);
              setHideNextBtn(false);
            } else {
              //   setSampleStoreTableData([]);
              // setRandomSampeData(dispatch,[])
              dispacthData(dispatch, RANDOMSAMPLETABLEDATA, []);
            }
          }
        });
    } catch (error) { }
  };
};

export const showRandomText = val => {
  return dispatch => {
    dispacthData(dispatch, SHOWRANDOMSTORETEXT, val);
  };
};

export const getAllStoresVal = val => {
  return dispatch => {
    try {
      secureAxios
        .get('/designCampaign/getAllStores', {
          params: []
        })
        .then(res => {
          console.log(res.data, 'getAllstores');
          // setStoresData(res.data)
          dispacthData(dispatch, GETALLSTORES, res.data);
        });
    } catch (error) { }
  };
};

export const submitStoresGroup = val => {
  const { groups, deletedGroups } = val;
  return dispatch => {
    try {
      secureAxios
        .post('/designCampaign/submitStoreGroup', {
          store: groups,
          deleteStores: deletedGroups
        })
        .then(function (res) {
          //   return res.data.map(item => {
          //     // kpisArray.push(item.kpi_name.toLowerCase());

          //   });
          dispatch(setLoading(false));
          dispacthData(dispatch, LOADING, false);
          console.log(res, 'submit group');
        });
    } catch (error) { }
  };
};
export const getStoreLists = val => {
  const { groups, CAMPAIGN_ID } = val;
  return dispatch => {
    try {
      secureAxios.get(`/designCampaign/getStoresList`, {
        params: { CAMPAIGN_ID }
      }).then(res => {
        dispacthData(dispatch, GETSTOREDATA, res.data.responseData);
      });
    } catch (error) { }
  };
};

//selected Attribute
export const setFinalCandidateStores = val => {
  const { groups } = val;

  return dispatch => {
    try {
      dispacthData(dispatch, FINALCANDIDATESTSTORES, val);
    } catch (error) {
      console.log('finalcandidatestore attribute', error);
    }
  };
};

//get selected attribute options values
export const getSelectedAttributeOptions = val => {
  const { selectedAttr, campaignID } = val;

  return dispatch => {
    try {
      secureAxios
        .post('/designCampaign/storeDropdownVal', {
          selectedAttr: selectedAttr,
          campaignID: campaignID
        })
        .then(res => {
          console.log(res.data, 'AttributeData');
          // setTimeout(()=>{
          //   setLoad(false);
          // },6000)
          dispacthData(dispatch, SELECTEDATTROPTIONS, res.data.data);
          // setAttrVal(res.data.data);
        });
    } catch (error) {
      console.log(error);
    }
  };
};

export const setAttributeOptionsVal = val => {
  return dispatch => {
    try {
      dispacthData(dispatch, SETATTROPTIONSELECTED, val);
    } catch (error) {
      console.log(error);
    }
  };
};
export const getStoreFromSelectedAttrOption = val => {
  const {
    param,
    attributeoptionselected,
    setClickedApply,
    setClickApplyAttr,
    aboutTest
  } = val;



  return dispatch => {
    try {
      if (attributeoptionselected) {
        secureAxios
          .post('/designCampaign/storeDropdownValFilter', param)
          .then(res => {
            console.log(res.data, 'AttributeData');
            if (!res.data.data) {
              dispacthData(dispatch, GETSTORESELECTEDATTROPTION, []);
              dispacthData(dispatch, STOREIDATTRSELECTED, []);
              dispacthData(dispatch, ATTRSTORECOUNT, []);
              dispacthData(dispatch, ABOUTTEST_STOREID_ATTR, []);


              return;
            }
            const results = _.map(res.data.data, 'storeID');
            console.log(res, 'results');
            // setTimeout(()=>{
            //   setLoad(false);
            // },6000)
            //   setMultiOptionVal(res.data.data);
            if (param.aboutTest) {
              dispacthData(dispatch, ABOUTTEST_STOREID_ATTR, res.data.data);

            } else {
              dispacthData(dispatch, GETSTORESELECTEDATTROPTION, res.data.data);
              dispacthData(dispatch, STOREIDATTRSELECTED, res.data.data);
              dispacthData(dispatch, ATTRSTORECOUNT, res.data.attrCount);
            }

            dispatch(setAttributeStatus(res.data.success));

            //   props.setStore(res.data.data)
            setClickedApply(prev => true);
            // setClickApplyAttr();
            dispatch(setLoading(false));
          });
      } else {
        dispacthData(dispatch, GETSTORESELECTEDATTROPTION, []);
        dispacthData(dispatch, STOREIDATTRSELECTED, []);
        dispacthData(dispatch, ATTRSTORECOUNT, []);
      }
    } catch (error) {
      console.log(error);
    }
  };
};

// const setShowIdentifiedStoresText=()=>{
//     return dispatch=>{

//     }
// }

export const getDrilldownFilterData = val => {
  return dispatch => {
    dispacthData(dispatch, LOADING, true);
    try {
      secureAxios.post('measureCampaign/getDrilldownfilters', val).then(res => {
        if (res.status === 200) {
          dispacthData(dispatch, LOADING, false);
          dispacthData(dispatch, DRILLDOWNFILTER, res?.data?.responseData);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const setdrilldownFilterDownFilter = val => {
  return {
    type: DRILLDOWNFILTER,
    payload: val
  };
};
export const updateSelectedDrilldownFilter = val => {
  return {
    type: DRILLDOWNSELECTEDFILTER,
    payload: val
  };
};

export const getKpiDrillDownOptions = val => {
  return dispatch => {
    try {
      secureAxios.post('/designCampaign/getKpiDetails', val).then(res => {
        console.log(res.data, 'getKpiDrillDownOptions');
        // setStoresData(res.data)
        dispacthData(dispatch, KPIOPTIONS, res.data);
      });
    } catch (error) { }
  };
};

export const setKpiDrillDownLoading = val => {
  return dispatch => {
    dispacthData(dispatch, KPIOPTIONSLOADING, val);
  };
};

export const setKpiDrillDownOptions = val => {
  return dispatch => {
    dispacthData(dispatch, SELECTEDKPIOPTIONS, val);
  };
};

export const getKpis = val => {
  return dispatch => {
    try {
      secureAxios.get('/designCampaign/getMetricsKpi').then(res => {
        console.log(res.data, 'KPIS');
        // setStoresData(res.data)
        dispacthData(dispatch, KPIS, res.data);
      });
    } catch (error) { }
  };
};

export const setTellUsMoreSelectedAttributes = val => {
  return dispatch => {
    try {
      dispacthData(dispatch, TELL_US_MORE_SELECTED_ATTRIBUTES, val);
    } catch (err) {
      console.log(err);
    }
  };
};

export const setTellUsMoreFinalCandidateStore = val => {
  return dispatch => {
    try {
      dispacthData(dispatch, TELL_US_MORE_FINAL_CANDIDATE_STORE, val);
    } catch (err) {
      console.log(err);
    }
  };
};

export const setTellUsMoreSelectedSubAttributes = val => {
  return dispatch => {
    try {
      dispacthData(dispatch, TELL_US_MORE_SELECTED_SUB_ATTRIBUTES, val);
    } catch (err) {
      console.log(err);
    }
  };
};

// HOME
export const getCampaigns = val => {
  return dispatch => {
    try {
      dispatch({
        type: 'LOADING',
        payload: true
      });
      secureAxios
        .get('/measureCampaign/getCampaignList')
        .then(function (response) {
          let responseData = response?.data
          dispatch({
            type: 'LOADING',
            payload: false
          });
          if (responseData && responseData?.data) {
            dispacthData(dispatch, GETCAMPAIGNS, responseData.data);
          }
        });
    } catch (error) { }
  };
};
