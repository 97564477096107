export const rolePermission = () => {
  let permission = {
    Admin: {
      createCampaign: true,
      editCampaign: true,
      deleteCampaign: true,
      copyCampaign: true,
      createProduct: true,
      editProduct: true,
      deleteProduct: true,
      createStore: true
    },
    Viewer: {
      createCampaign: false,
      editCampaign: false,
      deleteCampaign: false,
      copyCampaign: false,
      createProduct: false,
      editProduct: false,
      deleteProduct: false,
      createStore: false
    }
  };
  return permission;
};
export const getPagePermission = action => {
  // const userData = JSON.parse(localStorage.getItem('apiToken'));
  //   const userData = JSON.parse(localStorage.getItem('apiToken'));
  const role = 'Admin';
  let permission = rolePermission();
  let permissionData =
    permission && permission[`${role}`] && permission[`${role}`][`${action}`]
      ? permission[`${role}`][`${action}`]
      : false;
  return permissionData;
};
