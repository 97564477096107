import React from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import { NavLink, useHistory } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/styles';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  breadcrumb: {
    paddingTop: '10px',
    '& ol': {
      '&:last-child': {
        '& a': {
          color: 'blue !important'
        }
      }
    }
  }
}));

export const PATHS = {
  LOGIN: '/login',
  MEASURE_CAMPAIGN: '/measure-campaign-new',
  DESIGN_CAMPAIGN: '/design-campaign',
  PRODUCT_GROUP: '/design-campaign/productgroup',
  PRODUCT_GROUP_CREATE: '/design-campaign/product-group/new',
  HOME: '/home'
};

export default function ReactBreadcrumbs() {
  const router = useHistory();
  const classes = useStyles();  
  function generateBreadcrumbs() {
    const asPathWithoutQuery = router.location.pathname.split(/[#?]/)[0];    
    const asPathNestedRoutes = router.location.pathname
      .split('/')
      .filter(v => v.length > 0).filter(item => item !== 'measure-campaign-new')
    let crumblist = asPathNestedRoutes.map((subpath, idx) => {
      const href = '/' + asPathNestedRoutes.slice(0, idx + 1).join('/');

      const title = subpath;
      return { text: title, href };
    });
    if (crumblist[0].text == '/design-campaign') {
      crumblist.shift();
    }

    return [
      { href: PATHS.DESIGN_CAMPAIGN, text: 'design-campaign' },

      ...crumblist
    ];
  }

  const breadcrumbs = generateBreadcrumbs();

  return (
    <Breadcrumbs
      style={{ color: 'orange', padding: "0 0 20px 0"}}
      separator="›"
      aria-label="breadcrumb"
      maxItems={3}
    >
      {breadcrumbs.map((crumb, idx) => (
        <Crumb
          {...crumb}
          key={idx}
          first={idx === 0}
          style={{ paddingTop: '5%' }}
        />
      ))}
    </Breadcrumbs>
  );
}

function Crumb({ text, href, first = false }) {
  const location = useLocation();
  const history = useHistory();
  text = text.replace('measure-campaign-new', 'MeasureHome');
  text = text.replace('measure-deep-dive', 'Measure Deepdive');
  text = text.replace('campaign-summary', 'CampaignSummary');
  text = text.replace('design-campaign', '');
  text = text.replace('productgroup', 'Product Group');
  text = text.replace('new', 'New');
  if (first) {
    return (
      <NavLink
        underline="hover"
        style={{ color: '#333333', fontSize: '13px' }}
        to={`/home`}
      >
        Home
      </NavLink>
    );
  }
  
  return (
    (text != 'CampaignSummary' && text != 'MeasureDeepdive') ?
    <NavLink
      underline="hover"
      style={{ color: ' #182C52', fontSize: '13px', fontWeight : 500 }}
      to={href}>
      {text}
    </NavLink>
    : <Typography variant="p" 
    style={{ color: ' #182C52', fontSize: '13px',  cursor: 'pointer', fontWeight : 500 }} 
    onClick = {() => 
      history.push({
        pathname: text === 'MeasureDeepdive' ? '/measure-campaign-new/measure-deep-dive' : '/measure-campaign-new/measure-deep-dive/campaign-summary',
        state: {...location.state}
      })
    
    }>
      {text}</Typography>
  );
}
