import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Drawer, IconButton, FormControl, Button } from '@mui/material';
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearApplicationValues } from 'redux/actions/application.action';
import { clearAboutValues } from 'redux/actions/aboutTest.action';
import { clearSampleSizeValues } from 'redux/actions/sampleSize.action';
import { clearIdentifyTestValues } from 'redux/actions/identifyTest.action';
import { clearIdentifyControlValues } from 'redux/actions/identifyControl.action';
import { clearCampaignValues } from 'redux/actions/campaignTemplate.action';

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: 300,
        flexShrink: 0,
    },
    drawerPaper: {
        width: 300,
        height: '270px !important',
        top: '15% !important',
        right: 0,
        padding: '20px !important',
        overflow: 'hidden',
        borderRadius: '25px 0 0 25px !important',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    closeButton: {
        marginLeft: '-10px !important',
        padding: '0px !important'
    },
    radioBtn: {
        fontSize: '12px !important',
        marginTop: '9px !important',
        textDecoration: 'none !important',
        textTransform: 'unset !important'
    }
}));

const MiniDrawer = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const options = [
        {
            label: 'I want to create my test set and control set',
            value: 'create-test-control'
        },
        {
            label: 'I know my test set, I want to create my control set',
            value: 'create-control'
        },
        {
            label: 'I know my test and control set',
            value: 'all'
        }
    ];

    const handleResetDesignCamStatus = () => {
        dispatch(clearApplicationValues())
        dispatch(clearAboutValues())
        dispatch(clearSampleSizeValues())
        dispatch(clearIdentifyTestValues())
        dispatch(clearIdentifyControlValues())
        dispatch(clearCampaignValues())
    }

    return (
        <div>
            <Drawer
                className={classes.drawer}
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor="right"
                hideBackdrop={true}
                open={props.open}
                onClose={() => props.handleClose()}
            >
                <Grid container spacing={2}>
                    <Grid item xs={11}>
                        <div>
                            Please select a suitable scenario to proceed
                        </div>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={() => props.handleClose()}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl>
                            {(options.map((item, index) => {
                                return <Button variant="outlined" className={classes.radioBtn} key={`option_${index}`} onClick={(e) => {
                                    handleResetDesignCamStatus()
                                    history.push(`/design-campaign/${item.value}`)
                                }}>{item.label}</Button>
                            }))}
                        </FormControl>
                    </Grid>
                </Grid>
            </Drawer>
        </div>
    );
};

export default MiniDrawer;
