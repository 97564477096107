import React, { forwardRef, useState } from 'react';
import { NavLink as RouterLink, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  AppBar,
  Hidden,
  IconButton,
  Toolbar,
  List,
  ListItem,
  ListItemText,
  Button
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { loadCSS } from 'fg-loadcss';
import Tooltip from '@material-ui/core/Tooltip';
import { UserAgentApplication } from 'msal';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import config from '../../azure-configs/azureConfig';
import tredenceLogo from '../../assets/Tredence-Logo.svg';
import AeoLogo from '../../assets/Tredence-Logo.svg';
// import AeoLogo from '../../assets/dual.png';
import { useDispatch, useSelector } from 'react-redux';
import { setStateStatus } from '../../redux/actions/application.action';
import DialogBox from '../../../src/views/Common/Modals/Dailog';
import RoleMangement from './RoleMangement';
import secureAxios from 'api-config/api';
import { stringify } from 'query-string';
import { setCampaignIsEditDis } from 'redux/actions/homePage.action';

const useStyles = makeStyles(theme => ({
  flexGrow: {
    flexGrow: 1
  },
  navItems: {
    flexGrow: 1,
    display: 'flex',
    padding: 0,
    '& li': {
      width: 'auto',
      padding: 0
    },
    '& li:nth-child(2)': {
      paddingLeft: 0
    }
  },
  userIcon: {
    verticalAlign: 'bottom'
  },
  item: {
    padding: '0 10px',
    textTransform: 'none',
    marginTop: 7,
    height: 56,
    borderBottom: '4px solid #FFFFFF'
  },
  active: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    borderBottom: '4px solid #182C52',
    borderRadius: 0,
    '& $icon': {
      color: theme.palette.primary.contrastText
    }
  },
  text: {
    color: theme.palette.text.secondary,
    '& button': {
      verticalAlign: 'bottom'
    }
  },
  storeMark: {
    fontSize: '24px',
    color: 'pink'
  },
  topBar: {
    backgroundColor: theme.palette.background.paper,
    // zIndex: 9,
    '& p:first-child': {
      color: theme.palette.text.secondary,
      display: 'inline',
      fontSize: 18,
      paddingLeft: 65,
      verticalAlign: 'text-top',
      fontWeight: 500
    },
    '& .Mui-disabled': {
      color: '#929497'
    }
  }
}));

const Topbar = props => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const userAgentApplication = new UserAgentApplication({
    auth: {
      clientId: config.appId,
      redirectUri: config.redirectUri,
      postLogoutRedirectUri: config.postLogoutRedirectUri
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: true
    }
  });
  const application = useSelector(state => ({
    ...state.application
  }));

  // const { launched } = application;
  // const userName = localStorage.getItem('userName');
  // const application = useSelector(state => ({
  //   ...state.application
  // }));

  const { launched } = application;

  const handleRedirectHome = () => {
    history.push('/home');
  };
  // const userName = JSON.parse(localStorage.getItem('apiToken')).fullName;
  const userName = localStorage.getItem('userName');

  const logout = async () => {
    // const id_token = localStorage.getItem('id_token');
    // localStorage.clear();
    // const { data: { baseURL, redirectURL } = {} } = await secureAxios.get(
    //   '/login/getLogoutDetails',
    //   {}
    // );

    // const params = stringify({
    //     post_logout_redirect_uri: redirectURL,
    //     id_token_hint: id_token
    //   }),
    //   logoutURL = `${baseURL}/logout?${params}`;
    userAgentApplication.logout();
    localStorage.clear();
    // history.push('/login');
  };

  const restForm = e => {
    if (e.href == '/design-campaign' && launched) {
      dispatch(setStateStatus(true));
    }
  };

  React.useEffect(() => {
    const node = loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css')
    );
    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);

  const [selectedMenu, setSelectedMenu] = useState('');

  const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref}>
      <RouterLink {...props} />
    </div>
  ));

  const pages = [
    {
      title: 'Home',
      href: '/home'
    },
    {
      title: 'Design Campaign',
      href: '/design-campaign'
    },
    {
      title: 'Measure Campaign​',
      href: '/measure-campaign-new'
    }
  ];
  const handleRole = () => {
    setOpen(prev => !open);
  };
  return (
    <AppBar position="fixed" className={classes.topBar}>
      <Toolbar>
        <List component="ul" className={classes.navItems}>
          <ListItem disableGutters style={{ width: '40%' }} alt="Logo">
            {/* <ListItemText primary={<Typography>TALP</Typography>} /> */}
            <img
              src={AeoLogo}
              alt={'Talp '}
              style={{ height: '60px', margin: '0 0 0 0' }}
              onClick={handleRedirectHome}
            />
            <ListItemText primary={<Typography>TALP STORES</Typography>} />
          </ListItem>

          <div
            style={{ marginLeft: '5%', display: 'flex', flexDirection: 'row' }}>
            {pages.map(page => (
              <ListItem
                disableGutters
                key={page.title}
                onClick={e => {
                  restForm(page);
                  // console.log(
                  //   page.title,
                  //   localStorage.getItem('campIDinEdit'),
                  //   page.title == '/Home' &&
                  //     localStorage.getItem('campIDinEdit')
                  // );
                  // if (
                  //   page.title == 'Home' &&
                  //   localStorage.getItem('campIDinEdit')
                  // ) {
                  //   const vals = {
                  //     campaignId: localStorage.getItem('campIDinEdit'),
                  //     user: localStorage.getItem('userName'),
                  //     isEdit: false,
                  //     editEnable: false
                  //   };
                  //   dispatch(setCampaignIsEditDis(vals));
                  // }
                  setSelectedMenu(page.title);
                }}>
                <Button
                  activeClassName={classes.active}
                  className={classes.item}
                  component={CustomRouterLink}
                  to={page.href}
                  disabled={
                    page.href === '/design-campaign' ||
                    page.href === '/measure-campaign-new'
                  }>
                  {page.title}
                </Button>
              </ListItem>
            ))}
          </div>
        </List>

        <div className={classes.flexGrow} />

        <Hidden mdDown>
          {/* <Button
           // Name={classes.applyBtn}
            color="primary"
            type="submit"
            size="small"
            onClick={() => setOpen(true)}
            variant="contained">
            Role
          </Button> */}
          <Typography variant={'subtitle2'} className={classes.text}>
            <IconButton
              className={'fas fa-user-circle userIcon'}
              size={'small'}
            />
            &nbsp;
            {userName ? userName : 'Default User'}
          </Typography>
          &nbsp; &nbsp; &nbsp; &nbsp;
          {/* <Tooltip title="Logout">
            <IconButton
              className={'fas fa-power-off text'}
              size={'small'}
              onClick={logout}
            />
          </Tooltip> */}
          <PowerSettingsNewIcon
            style={{ color: '#929497', cursor: 'pointer' }}
            size={'small'}
            onClick={logout}
          />
        </Hidden>
      </Toolbar>
      <DialogBox
        open={open}
        buttonStatus={false}
        header={'Role Management'}
        handleClose={handleRole}
        width={300}
        children={<RoleMangement handleRoleModal={handleRole} />}
        contentStyle={{ marginBottom: '20px' }}></DialogBox>
    </AppBar>
  );
};

export default Topbar;
