import React, { useState, useEffect,useRef } from 'react';
import { Input, InputLabel, FormControl } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import { ListItemIcon } from '@material-ui/core';
import _ from 'lodash';

import { Typography } from '@material-ui/core';

import { ErrorMessage, Field } from 'formik';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "30%",
  },
  errorText: {
    color: "red",
    fontSize: 12,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};



const MultiSelectDropdown = props => {
  const classes = useStyles();
  const { value, label, name, options, handleMethod, width } = props;
  const [chosen, setChosen] = useState([]);
  const isAllSelected = options.length > 0 && chosen.length === options.length;

  const handleChange = event => {
    const val = event.target.value;
    if (val[val.length - 1] === 'all') {
      setChosen(chosen.length === options.length ? [] : options);
      handleMethod(chosen.length === options.length ? [] : options);
      return;
    }
    setChosen(val);
    handleMethod(val);
  };
  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);
  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
    setChosen(value);
  }, [value]);

  return (
    <FormControl
    className={width ? width : classes.formControl}
    variant="outlined"
  >
      <InputLabel  id="demo-mutiple-name-label" ref={inputLabel}> {label}</InputLabel>
      <Select
       labelId="demo-mutiple-checkbox-label"
       id="demo-mutiple-checkbox"
        name={name}
        multiple={true}
        value={chosen}
        onChange={handleChange}
        labelWidth={labelWidth}
        // input={<Input />}
        renderValue={chosen => chosen.join(', ')}
        MenuProps={MenuProps}>
        <MenuItem
          value="all"
          classes={{
            root: isAllSelected ? classes.selectedAll : ''
          }}>

          {/* <ListItemIcon>
            <Checkbox
              classes={{ indeterminate: classes.indeterminateColor }}
              checked={isAllSelected}
              indeterminate={
                chosen.length > 0 && chosen.length < props.options.length
              }
            />
          </ListItemIcon> */}
          {options.length > 0 ? (
            <ListItemIcon>
              <Checkbox
                classes={{ indeterminate: classes.indeterminateColor }}
                checked={isAllSelected}
                indeterminate={
                  chosen.length > 0 && chosen.length < props.options.length
                }
              />
            </ListItemIcon>
          ) : (
            ""
          )}
          {/* <ListItemText
            classes={{ primary: classes.selectAllText }}
            primary="Select All"
          /> */}
           <ListItemText
            classes={{ primary: classes.selectAllText }}
            primary={options.length > 0 ? "Select All" : "No Data Found"}
          />
        </MenuItem>

        {props
          ? options.map(value => (
              <MenuItem key={value} value={value}>
                <ListItemIcon>
                  <Checkbox checked={chosen.indexOf(value) > -1} />
                </ListItemIcon>
                <ListItemText primary={value} />
              </MenuItem>
            ))
          : ''}
      </Select>
      <Typography className={classes.errorText}>
        <ErrorMessage name={name} />
      </Typography>
    </FormControl>
  );
};

export default MultiSelectDropdown;
