import {
  TABNAVIGATION,
  PREVIOUS,
  CAMPAIGNID,
  LOADING,
  TABNESTED,
  LAUNCHED,
  RESETSTATE,
  CLEAR_APPLICATION_VALUES,
  SAVECAMPDATA,
  DESIGN_CAMP_TAB_NAV
} from '../types';

export const setTabNavigation = val => {
  return {
    type: TABNAVIGATION,
    payload: val
  };
};
export const setPrevious = val => {
  return {
    type: PREVIOUS,
    payload: val
  };
};
export const setCampaignID = val => {
  return {
    type: CAMPAIGNID,
    payload: val
  };
};
export const setLoading = val => {
  return {
    type: LOADING,
    payload: val
  };
};
export const setTabNested = val => {
  return {
    type: TABNESTED,
    payload: val
  };
};
export const setLaunched = val => {
  return {
    type: LAUNCHED,
    payload: val
  };
};
export const setStateStatus = val => {
  return {
    type: RESETSTATE,
    payload: val
  };
};
export const clearApplicationValues = () => {
  return {
    type: CLEAR_APPLICATION_VALUES
  };
};
export const saveCampData = (val) => {
  return {
    type: SAVECAMPDATA,
    payload: val
  };
};
export const saveDesignCampTabNav = (val) => {
  return {
    type: DESIGN_CAMP_TAB_NAV,
    payload: val
  };
}