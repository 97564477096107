export const LOADING = 'LOADING';
export const DATE = 'DATE';
export const TABNAVIGATION = 'TABNAVIGATION';
export const TABNESTED = 'TABNESTED';
export const PREVIOUS = 'PREVIOUS';
export const MOREABOUTTEST = 'MOREABOUTTEST';
export const SAMPLESIZERECOMMENDATION = 'SAMPLESIZERECOMMENDATION';
export const IDENTIFYTEST = 'IDENTIFYTEST';
export const IDENTIFYCONTROL = 'IDENTIFYCONTROL';
export const CAMPAIGNID = 'CAMPAIGNID';
export const SAMPLESIZESIMULATEDATA = 'SAMPLESIZESIMULATEDATA';
export const MEASURETESTID = 'MEASURETESTID';
export const MEASURECAMPAIGNLEVEL = 'MEASURECAMPAIGNLEVEL';
export const TESTTABLECOUNT = 'TESTTABLECOUNT';
export const SELECTEDMETRICS = 'TESTTABLECOUNT';
export const LAUNCHED = 'LAUNCHED';
export const RESETSTATE = 'RESETSTATE';
export const MEASUREEXCLUDEOUTLIER = 'MEASUREEXCLUDEOUTLIER';
export const UPDATETEMPLATE = 'UPDATETEMPLATE';
export const SELECTEDTEMPLATE = 'SELECTEDTEMPLATE';
export const MEASURECAMPAIGNDETAIL = 'MEASURECAMPAIGNDETAIL';
export const PRODUCT_LIST = 'PRODUCT_LIST';
export const SETTEMPLATELIST = 'SETTEMPLATELIST';
export const CANDIDATESTSTORE = 'CANDIDATESTSTORE';
export const FINALCANDIDATESTSTORE = 'FINALCANDIDATESTSTORE';
export const QUERYBUILDERTREE = 'QUERYBUILDERTREE';
export const SELECTEDATTRIBUTE = 'SELECTEDATTRIBUTE';
export const NEAREASTMATCH = 'NEAREASTMATCH';

export const ATTRIBUTES = 'ATTRIBUTES';
export const QUERYBUILDERDATA = 'QUERYBUILDERDATA';
export const STOREGROUP = 'STOREGROUP';
export const CREATETORESEGMENT = 'CREATETORESEGMENT';
export const CREATETORESEGMENTFILTER = 'CREATETORESEGMENTFILTER';
export const GETSTORESQUERYBUILDER = 'GETSTORESQUERYBUILDER';
export const RANDOMSAMPLETABLEDATA = 'RANDOMSAMPLETABLEDATA';
export const GETALLSTORES = 'GETALLSTORES';
export const GETSTOREDATA = 'GETSTOREDATA';
export const FINALCANDIDATESTSTORES = 'FINALCANDIDATESTSTORES';
export const SELECTEDATTROPTIONS = 'SELECTEDATTROPTIONS';
export const SETATTROPTIONSELECTED = 'SETATTROPTIONSELECTED';
export const GETSTORESELECTEDATTROPTION = 'GETSTORESELECTEDATTROPTION';
export const STOREIDATTRSELECTED = 'STOREIDATTRSELECTED';
export const SETQUERYRULE = 'SETQUERYRULE';
export const SETQUERYTREE = 'SETQUERYTREE';
export const SETTOTALSTORE = 'SETTOTALSTORE';
export const SHOWRANDOMSTORETEXT = 'SHOWRANDOMSTORETEXT';
export const STOREGROUPALL = 'STOREGROUPALL';
export const ATTRIBUTESELECTED = 'ATTRIBUTESELECTED';
export const ATTRIBUTEVALUES = 'ATTRIBUTEVALUES';
export const ATTRSTORECOUNT = 'ATTRSTORECOUNT';

export const DRILLDOWNFILTER = 'DRILLDOWNFILTER';
export const DRILLDOWNSELECTEDFILTER = 'DRILLDOWNSELECTEDFILTER';
export const EXCLUDETESTSTORESDATA = 'EXCLUDETESTSTORESDATA';
export const EXCLUDETESTSTORESLIST = 'EXCLUDETESTSTORESLIST';
export const STORESGROUPLIST = 'STORESGROUPLIST';
export const SELECTEDRANDOMSTORELIST = 'SELECTEDRANDOMSTORELIST';

export const TRIGGERLAUNCHDAG = 'TRIGGERLAUNCHDAG';
export const TRIGGERLAUNCHDAG2 = 'TRIGGERLAUNCHDAG2';
export const TRIGGERLAUNCHDAG3 = 'TRIGGERLAUNCHDAG3';
export const KPIOPTIONS = 'KPIOPTIONS';
export const L12MOPTIONS = 'L12MOPTIONS';
export const L12MOPTIONSRESET = 'L12MOPTIONSRESET';
export const SELECTEDKPIOPTIONS = 'SELECTEDKPIOPTIONS';
export const KPIS = 'KPIS';
export const KPIOPTIONSLOADING = 'KPIOPTIONSLOADING';

///4th tab identify test new
export const IDENTIFY_TEST_BRAND = 'IDENTIFY_TEST_BRAND';
export const IDENTIFY_TEST_BRAND_SELECTED = 'IDENTIFY_TEST_BRAND_SELECTED';
export const IDENTIFY_TEST_DEPT = 'IDENTIFY_TEST_DEPT';
export const IDENTIFY_TEST_DEPT_SELECTED = 'IDENTIFY_TEST_DEPT_SELECTED';
export const IDENTIFY_TEST_DIVISION = 'IDENTIFY_TEST_DIVISION';
export const IDENTIFY_TEST_DIVISION_SELECTED =
  'IDENTIFY_TEST_DIVISION_SELECTED';
export const IDENTIFY_TEST_PRODUCT_GROUP = 'IDENTIFY_TEST_PRODUCT_GROUP';
export const IDENTIFY_TEST_PRODUCT_GROUP_SELECTED =
  'IDENTIFY_TEST_PRODUCT_GROUP_SELECTED';
export const IDENTIFY_TEST_METRICS = 'IDENTIFY_TEST_METRICS';
export const IDENTIFY_TEST_METRICS_SELECTED = 'IDENTIFY_TEST_METRICS_SELECTED';

export const TELL_US_MORE_SELECTED_ATTRIBUTES =
  'TELL_US_MORE_SELECTED_ATTRIBUTES';
export const TELL_US_MORE_FINAL_CANDIDATE_STORE =
  'TELL_US_MORE_FINAL_CANDIDATE_STORE';
export const TELL_US_MORE_SELECTED_SUB_ATTRIBUTES =
  'TELL_US_MORE_SELECTED_SUB_ATTRIBUTES';

// Identify Test: Region Include
export const IDENTIFY_TEST_REGION = 'IDENTIFY_TEST_REGION';
export const IDENTIFY_TEST_SELECTED_REGION = 'IDENTIFY_TEST_SELECTED_REGION';
export const IDENTIFY_TEST_SELECTED_REGION_STOREIDS =
  'IDENTIFY_TEST_SELECTED_REGION_STOREIDS';
export const IDENTIFY_TEST_INCLUDE_STOREID = 'IDENTIFY_TEST_INCLUDE_STOREID';
export const IDENTIFY_TEST_STAT_TABLE_DATA = 'IDENTIFY_TEST_STAT_TABLE_DATA';

// Identify Test: Random sample
export const IDENTIFY_TEST_RANDOM_SAMPLE = 'IDENTIFY_TEST_RANDOM_SAMPLE';
export const IDENTIFY_TEST_RANDOM_SAMPLE_STORE =
  'IDENTIFY_TEST_RANDOM_SAMPLE_STORE';

//Identify Inner Step Button
export const IDENTIFY_NEXT_BTN = 'IDENTIFY_NEXT_BTN';

//Identify UPLOAD Test Store Count
export const IDENTIFY_UPLOAD_TEST_STORE_COUNT =
  'IDENTIFY_UPLOAD_TEST_STORE_COUNT';

// Home Page
export const GETCAMPAIGNS = 'GETCAMPAIGNS';

//Clean ALL State
export const CLEAR_APPLICATION_VALUES = 'CLEAR_APPLICATION_VALUES';
export const CLEAR_ABOUT_VALUES = 'CLEAR_ABOUT_VALUES';
export const CLEAR_SAMPLE_VALUES = 'CLEAR_SAMPLE_VALUES';
export const CLEAR_IDENTIFY_TEST_VALUES = 'CLEAR_IDENTIFY_TEST_VALUES';
export const CLEAR_IDENTIFY_CONTROL_VALUES = 'CLEAR_IDENTIFY_CONTROL_VALUES';
export const CLEAR_CAMPAIGN_VALUES = 'CLEAR_CAMPAIGN_VALUES';

export const ATTRSTORESTATUS = 'ATTRSTORESTATUS';
export const SAVECAMPDATA = 'SAVECAMPDATA';
export const TELLUSMORERES = 'TELLUSMORERES';
export const ABOUTTEST_STOREID_ATTR = 'ABOUTTEST_STOREID_ATTR';

export const DESIGN_CAMP_TAB_NAV = 'DESIGNCAMPTABNAV';
export const IDENTIFY_EXCLUDE_RAD_VAL = 'IDENTIFY_EXCLUDE_RAD_VAL';
export const SET_CAMPAIGN_EDIT = 'SET_CAMPAIGN_EDIT';
